<template>
  <div class="sidebar" role="navigation">
    <div class="sidebar-backdrop" @click="closeSidebarPanel"></div>
    <transition name="slide">
      <div v-show="isPanelOpen" class="sidebar-panel pt-36 lg:pt-40 4xl:pt-72 pb-10">
        <div class="panel-inner mx-auto ">
          <div class="text-column ">
            <slot></slot>
          </div>
          <div class="image-column">
            <illustration data="deltaker2" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Illustration from '@/component/Block/Illustration'
// import { store, mutations } from '@/store/store.js'

export default {
  components: {
    Illustration,
  },
  methods: {
    // closeSidebarPanel: mutations.toggleNav
    closeSidebarPanel() {
      this.$store.dispatch('toggleNav')
    }
  },
  computed: {
    isPanelOpen() {
      return this.$store.getters.getNavOpenState
      // return store.isNavOpen
    }
  }
}
</script>
