<template>

  <div class="component-inner">

    <div
    v-if="block.options && block.options.layout === 'large'"
    :class="block.options && block.options.layout === 'large' ? 'flex-1 w-1/2' : ''">


    </div>

    <div :class="block.options && block.options.layout === 'large' ? 'flex-1 w-1/2' : ''">

      <header v-if="block.options.title">
        <h3>{{ block.options.title | capitalize  }}</h3>
      </header>

      <dl
      role="presentation">

        <div
        v-for="(item, index) in block.accordion.accordion_items"
        :key="index"
        class="accordion-item border-b border-brand-green mb-8"
        :class="{'is-active': contentVisible === index}">

          <dt
          class="item-header accordion-item-title">
            <button class="toggle accordion-item-trigger pb-8 text-brand-green flex gap-4 justify-between w-full items-center text-left" @click="contentVisible === index ? contentVisible = false : contentVisible = index">
              <h3 class="title accordion-item-title-text grow flex-1 mb-0">{{ item.title }}</h3>
              <icon graphic="arrow" :class="{ 'arrow-down': contentVisible !== index, 'arrow-up': contentVisible === index }"></icon>
            </button>
          </dt>

          <transition name="slide">
            <dd class="item-details accordion-item-details" v-if="contentVisible === index">
              <div class="inner accordion-item-details-inner bg-purple-light text-black pt-8 pb-16 px-10 text-lg xl:text-xl 2xl:text-2xl" v-html="item.paragraph" />
            </dd>
          </transition>

        </div>

      </dl>

    </div>

  </div>

</template>

<script>
import Icon from "../Icon.vue"

export default {
  name: 'Accordion',
  props: {
    block: Object
  },
  data : () => ({
    contentVisible: false
  }),
  components: {
    "icon": Icon,
  },
}
</script>
