<template>
  <figure class="icon w-24 h-24 lg:w-32 lg:h-32 3xl:w-48 3xl:h-48" :class="['icon-' +color]" v-if="data">

    <template v-if="data === 'baby_onesie'">
      <svg version="1.1" viewBox="0 0 248 248">
        <path class="fill-current" d="M226.1,78.1c-1.1-2.8-3.2-5.2-5.3-7.4c-6.6-7.2-12.9-16.1-21.4-21.4c-6.7-4.2-14-7.2-20.1-12.3
          c-1.3-1.1-2.6-2.3-4.4-2.7c-3.3-0.8-6.2,1.2-9.1,2.3c-6,2.4-12.4,3.5-18.8,3.3c-13.1-0.3-25.6-6.6-33.2-16.4c-2.3-3-13.2,1.6-16,2.4
          c-4.4,1.3-8.8,2.6-13.1,4c-8.5,2.8-16.8,6.2-24.9,9.9c-4.1,1.9-8.2,3.9-12.2,5.8c-2,1-4,1.3-4.2,3c-0.1,1.1,1.7,4.4,2.3,6.2
          c2.5,7.2,4.3,13.6,6.3,20.5c0.5,1.9,0.2,4.6,2.2,5.1c1.7,0.5,6.9,1.1,9.1,1.7c3.3,0.8,6.6,1.6,9.9,2.4c1.3,0.3-10,42.2-10.8,45.4
          c-2.5,9.8-4.6,19.8-8,29.3c-0.9,2.6-2.3,5.1-1.6,7.9c0.6,2.3,2.2,4.6,3.5,6.6c4.9,8.1,11,16.2,12.3,25.7c0.4,2.6,0.2,5.3,1.1,7.9
          c0.9,2.6,2.9,4.5,5.5,5.7c2.7,1.3,5.8,2,8.7,2.9c13,4.1,26.7,7,40.2,8.8c6.3,0.9,14.3,2.9,18.6-3.2c1.4-2,2.1-4.4,3.1-6.6
          c3.3-7.3,9-13.7,16.1-18.1c2.6-1.6,5.4-2.8,7.8-4.6c5-3.7,6.6-8.9,6.8-14.6c0.2-5.9,0.2-12.2,1-18.2c0.9-6.2,3-12.2,4.6-18.3
          c3.3-12.3,6-24.7,8.6-37.1c1.4,0.3,2.7,0.6,4,1.1c3,1,7.6,3.7,9.1,2c0.9-1,3.3-3.3,4.1-4.3c4.9-6.2,10.9-12.2,16.9-18.7
          C227.4,81.5,227.3,81.4,226.1,78.1L226.1,78.1z"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M105.1,22.5c0,0-2,27.9,29.3,34s46.1-20.2,46.1-20.2
          "/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M69.7,210.4c0,0,8.3-7.8,13.5-15.2
          s17.7-7.9,29.8-4.8s19.4,6.1,22.2,19.7c2.3,11.3,5,15.4,5,15.4"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M53.2,158.8c0,0,14.7,2.5,20.3,12s11.4,20,12.8,21.4
          "/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M177.2,180.8c0,0-11.6-2.1-22.2,4.1
        c-10.6,6.3-19.8,14.2-23.9,15.2"/>
      </svg>
    </template>

    <template v-if="data === 'face_worried'">
      <svg version="1.1" viewBox="0 0 248 248">
        <path class="fill-current" d="M202.2,102c-1-4.1-2.4-8-4.1-11.8c-18.8-41.8-74.2-59.5-114.7-38.7c-46.5,24-56.1,98.8-18.2,134.9
          c11.7,11.2,26.7,16.8,42.5,19c25.2,3.4,56.6-1.1,74.3-21.2c8.5-9.7,15-21.2,18.8-33.6C205.5,135.4,206.3,117.9,202.2,102L202.2,102z
          "/>
        <path d="M78.3,96.2L74.9,94c10-15,25.1-9.6,25.3-9.6l-1.4,3.8l0.7-1.9l-0.7,1.9C98.3,88.1,86.5,83.9,78.3,96.2L78.3,96.2L78.3,96.2z
          "/>
        <path d="M165.2,96.1c-0.3-0.5-6.6-11.6-20.4-8.1l-1-3.9c17-4.3,24.9,9.9,25,10.1L165.2,96.1L165.2,96.1z"/>
        <path d="M99,107.8c-1.4-1.3-3.3-1.2-5.5,1.9c-3.8,5.5-0.9,14.1,2.9,13.9c4.5-0.3,5.2-6.4,4.9-9.8C101.1,111.3,100.3,109,99,107.8z"
          />
        <path d="M96.2,125.6c-1.9,0-3.7-1.2-4.9-3.4c-2-3.5-2.4-9.3,0.6-13.6c1.5-2.2,3.2-3.4,4.9-3.6c1.3-0.1,2.6,0.3,3.6,1.3
          c1.5,1.5,2.6,4.2,2.9,7.2c0.1,1.1,0.4,6.6-2.4,9.8c-1.2,1.3-2.7,2.1-4.4,2.2L96.2,125.6C96.2,125.5,96.2,125.6,96.2,125.6z
           M97.2,109L97.2,109c-0.1,0-0.8,0.1-2,1.9c-2,2.9-1.7,7-0.4,9.3c0.6,1,1.1,1.4,1.5,1.4c0.7,0,1.2-0.3,1.6-0.8
          c1.3-1.5,1.6-4.6,1.4-6.9c-0.2-2.3-1-4-1.7-4.7l0,0C97.5,109.1,97.3,109,97.2,109L97.2,109L97.2,109z"/>
        <path d="M151.8,107.7c-1.5-1.3-3.4-1.1-5.4,2.2c-3.5,5.7-0.2,14.1,3.6,13.7c4.5-0.5,4.9-6.7,4.4-10.1
          C154,111.1,153.1,108.8,151.8,107.7L151.8,107.7z"/>
        <path d="M149.9,125.6c-1.9,0.1-3.8-1.1-5.1-3.2c-2.1-3.4-2.9-9.2-0.1-13.6c1.4-2.3,3-3.5,4.7-3.8c1.3-0.2,2.6,0.2,3.7,1.2
          c1.6,1.4,2.8,4,3.2,7c0.1,1.1,0.8,6.5-1.9,9.9c-1.1,1.4-2.6,2.2-4.3,2.4L149.9,125.6L149.9,125.6L149.9,125.6z M150,108.9L150,108.9
          c-0.1,0-0.8,0.2-1.9,2c-1.9,3-1.4,7.1,0.1,9.3c0.6,1,1.2,1.4,1.6,1.3c0.7-0.1,1.2-0.4,1.6-0.9c1.2-1.5,1.4-4.7,1.1-6.9
          c-0.3-2.3-1.2-3.9-1.9-4.6l0,0C150.3,109,150.1,108.9,150,108.9L150,108.9L150,108.9z"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M99.8,167c0,0,9.7-12,27.1-12s22.6,11,22.6,11"/>
      </svg>
    </template>

    <template v-if="data === 'face_embarrassed'">
      <svg version="1.1" viewBox="0 0 248 248">
        <path class="fill-current" d="M197,164.2c28.8-49.8-11.3-121.8-68-123.2c-37.6-1-75,26-84.4,65.4c-14.3,59.9,46.7,112.3,102,97
          C167.6,197.7,186.2,183,197,164.2L197,164.2z"/>
        <path d="M79.7,104l-3.7-1.4c6.4-16.8,22.4-14.9,22.5-14.9l-0.5,4l0.3-2l-0.3,2C97.4,91.6,85,90.2,79.7,104L79.7,104L79.7,104z"/>
        <path d="M164.7,102.2c-0.2-0.5-6.2-11.8-20.2-8.7l-0.9-3.9c17.1-3.9,24.6,10.6,24.7,10.8L164.7,102.2L164.7,102.2z"/>
        <path d="M100,107.8c-1.4-1.3-3.3-1.2-5.5,1.9c-3.8,5.5-0.9,14.1,2.9,13.9c4.5-0.3,5.2-6.4,4.9-9.8C102.1,111.3,101.3,109,100,107.8z
          "/>
        <path d="M97.2,125.6c-1.9,0-3.7-1.2-4.9-3.4c-2-3.5-2.4-9.3,0.6-13.6c1.5-2.2,3.2-3.4,4.9-3.6c1.3-0.1,2.6,0.3,3.6,1.3
          c1.5,1.5,2.6,4.2,2.9,7.2c0.1,1.1,0.4,6.6-2.4,9.8c-1.2,1.3-2.7,2.1-4.4,2.2L97.2,125.6C97.2,125.5,97.2,125.6,97.2,125.6z
           M98.2,109L98.2,109c-0.1,0-0.8,0.1-2,1.9c-2,2.9-1.7,7-0.4,9.3c0.6,1,1.1,1.4,1.5,1.4c0.7,0,1.2-0.3,1.6-0.8
          c1.3-1.5,1.6-4.6,1.4-6.9c-0.2-2.3-1-4-1.7-4.7l0,0C98.5,109.1,98.3,109,98.2,109L98.2,109L98.2,109z"/>
        <path d="M152.8,107.7c-1.5-1.3-3.4-1.1-5.4,2.2c-3.5,5.7-0.2,14.1,3.6,13.7c4.5-0.5,4.9-6.7,4.4-10.1
          C155,111.1,154.1,108.8,152.8,107.7L152.8,107.7z"/>
        <path d="M150.9,125.6c-1.9,0.1-3.8-1.1-5.1-3.2c-2.1-3.4-2.9-9.2-0.1-13.6c1.4-2.3,3-3.5,4.7-3.8c1.3-0.2,2.6,0.2,3.7,1.2
          c1.6,1.4,2.8,4,3.2,7c0.1,1.1,0.8,6.5-1.9,9.9c-1.1,1.4-2.6,2.2-4.3,2.4L150.9,125.6L150.9,125.6L150.9,125.6z M151,108.9L151,108.9
          c-0.1,0-0.8,0.2-1.9,2c-1.9,3-1.4,7.1,0.1,9.3c0.6,1,1.2,1.4,1.6,1.3c0.7-0.1,1.2-0.4,1.6-0.9c1.2-1.5,1.4-4.7,1.1-6.9
          c-0.3-2.3-1.2-3.9-1.9-4.6l0,0C151.3,109,151.1,108.9,151,108.9L151,108.9L151,108.9z"/>
        <path fill="#E31836" d="M86.8,133.9c-4.6-4.7-13.6-6.7-18.4-0.4c-3.5,4.6-1.9,11,3.1,13.9c4.5,2.6,11.8,2,15.6-1.7
          C91.1,141.8,90,137.2,86.8,133.9L86.8,133.9z"/>
        <path fill="#E31836" d="M184.5,130.9c-2.6-3.9-8.7-5.8-13.5-4.6c-4.9,1.1-8.9,4.4-9.4,9.6c-0.7,7.2,6.5,11.7,13,10.2
          c4.3-0.9,9.5-3.9,10.8-8.4C186.2,135,185.7,132.7,184.5,130.9L184.5,130.9z"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M109.5,168.8c0,0,28.3,11,43.8-9.6"/>
      </svg>
    </template>

    <template v-if="data === 'face_embarrassed_red'">
      <svg version="1.1" viewBox="0 0 248 248">
        <path class="fill-current" d="M197,164.2c28.8-49.8-11.3-121.8-68-123.2c-37.6-1-75,26-84.4,65.4c-14.3,59.9,46.7,112.3,102,97
          C167.6,197.7,186.2,183,197,164.2L197,164.2z"/>
        <path d="M79.7,104l-3.7-1.4c6.4-16.8,22.4-14.9,22.5-14.9l-0.5,4l0.3-2l-0.3,2C97.4,91.6,85,90.2,79.7,104L79.7,104L79.7,104z"/>
        <path d="M164.7,102.2c-0.2-0.5-6.2-11.8-20.2-8.7l-0.9-3.9c17.1-3.9,24.6,10.6,24.7,10.8L164.7,102.2L164.7,102.2z"/>
        <path d="M100,107.8c-1.4-1.3-3.3-1.2-5.5,1.9c-3.8,5.5-0.9,14.1,2.9,13.9c4.5-0.3,5.2-6.4,4.9-9.8C102.1,111.3,101.3,109,100,107.8z
          "/>
        <path d="M97.2,125.6c-1.9,0-3.7-1.2-4.9-3.4c-2-3.5-2.4-9.3,0.6-13.6c1.5-2.2,3.2-3.4,4.9-3.6c1.3-0.1,2.6,0.3,3.6,1.3
          c1.5,1.5,2.6,4.2,2.9,7.2c0.1,1.1,0.4,6.6-2.4,9.8c-1.2,1.3-2.7,2.1-4.4,2.2L97.2,125.6C97.2,125.5,97.2,125.6,97.2,125.6z
           M98.2,109L98.2,109c-0.1,0-0.8,0.1-2,1.9c-2,2.9-1.7,7-0.4,9.3c0.6,1,1.1,1.4,1.5,1.4c0.7,0,1.2-0.3,1.6-0.8
          c1.3-1.5,1.6-4.6,1.4-6.9c-0.2-2.3-1-4-1.7-4.7l0,0C98.5,109.1,98.3,109,98.2,109L98.2,109L98.2,109z"/>
        <path d="M152.8,107.7c-1.5-1.3-3.4-1.1-5.4,2.2c-3.5,5.7-0.2,14.1,3.6,13.7c4.5-0.5,4.9-6.7,4.4-10.1
          C155,111.1,154.1,108.8,152.8,107.7L152.8,107.7z"/>
        <path d="M150.9,125.6c-1.9,0.1-3.8-1.1-5.1-3.2c-2.1-3.4-2.9-9.2-0.1-13.6c1.4-2.3,3-3.5,4.7-3.8c1.3-0.2,2.6,0.2,3.7,1.2
          c1.6,1.4,2.8,4,3.2,7c0.1,1.1,0.8,6.5-1.9,9.9c-1.1,1.4-2.6,2.2-4.3,2.4L150.9,125.6L150.9,125.6L150.9,125.6z M151,108.9L151,108.9
          c-0.1,0-0.8,0.2-1.9,2c-1.9,3-1.4,7.1,0.1,9.3c0.6,1,1.2,1.4,1.6,1.3c0.7-0.1,1.2-0.4,1.6-0.9c1.2-1.5,1.4-4.7,1.1-6.9
          c-0.3-2.3-1.2-3.9-1.9-4.6l0,0C151.3,109,151.1,108.9,151,108.9L151,108.9L151,108.9z"/>
        <path fill="#FDB200" d="M86.8,133.9c-4.6-4.7-13.6-6.7-18.4-0.4c-3.5,4.6-1.9,11,3.1,13.9c4.5,2.6,11.8,2,15.6-1.7
          C91.1,141.8,90,137.2,86.8,133.9L86.8,133.9z"/>
        <path fill="#FDB200" d="M184.5,130.9c-2.6-3.9-8.7-5.8-13.5-4.6c-4.9,1.1-8.9,4.4-9.4,9.6c-0.7,7.2,6.5,11.7,13,10.2
          c4.3-0.9,9.5-3.9,10.8-8.4C186.2,135,185.7,132.7,184.5,130.9L184.5,130.9z"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M109.5,168.8c0,0,28.3,11,43.8-9.6"/>
      </svg>
    </template>

    <template v-if="data === 'face_in_love'">
      <svg version="1.1" viewBox="0 0 248 248">
        <path class="fill-current" d="M206,111.2c-2.2-14.6-7.8-28.8-16.7-40c-0.1-0.2-0.3-0.5-0.4-0.7c-27.1-37.1-90.6-38.1-123.8-9
          c-31.6,27.7-33.3,84-5.3,114.8c19.6,21.6,50.9,33.1,79.9,29.3c14.9-2,29.8-7.7,41-18c14.5-13.4,23.4-32.8,25.8-52.3
          C207.3,127.5,207.2,119.3,206,111.2L206,111.2z"/>
        <path d="M100.7,76c4.3,0,7.5,1.3,7.6,1.3l-1.5,3.7c-0.5-0.2-11.6-4.6-19.6,6.2l-3.2-2.4C89.2,77.7,95.7,76,100.7,76L100.7,76
          L100.7,76z"/>
        <path d="M148.4,75c10.3,0,16.2,8,16.3,8.1l-3.2,2.3l1.6-1.2l-1.6,1.2c-0.3-0.4-7.5-10-19.9-5l-1.5-3.7C143.1,75.5,145.9,75,148.4,75
          z"/>
        <path d="M112,101.3c-1.2-3.6-4.5-6.4-8.4-6.1c-2.1,0.2-3.8,1.2-5,2.8c-0.7,1-1.2,2.3-1.8,3.3c-2-3-4.5-5.8-8.2-6.3
          c-4.3-0.6-7.8,2.5-8.5,6.7c-0.8,4.6,1.7,8.7,4.1,12.4c1.9,2.9,4.1,5.7,6.3,8.4c1.5,1.8,4,6.4,6.8,5.4c1.9-0.7,3.4-2.4,4.7-3.8
          c3.1-3.2,5.9-6.9,8.1-10.8C112,109.6,113.3,105.4,112,101.3L112,101.3z"/>
        <path d="M167.3,101.4c-0.5-3.9-3.7-7.7-7.9-7.4c-4.4,0.4-6.4,4.3-8.9,7.4c-2.3-3.1-5-5.8-9-6.3c-4.6-0.5-8.5,2.8-8.4,7.5
          c0.1,2.6,1.2,4.9,2.6,7.1c1.4,2.3,2.8,4.5,4.3,6.7c2.9,4.4,6.1,8.6,10,12.2c2.8,2.5,11.2-12.3,12.2-13.9
          C164.7,110.9,167.9,106.3,167.3,101.4L167.3,101.4z"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M80,141c0,0,15.5,28.6,45.2,28.8
          s40.9-24.6,41.3-28.8"/>
      </svg>
    </template>

    <template v-if="data === 'face_sad'">
      <svg version="1.1" viewBox="0 0 248 248">
        <path class="fill-current" d="M205.1,112.2c-2.2-14.6-4.8-29.8-13.7-41c-0.1-0.2-0.3-0.5-0.4-0.7C163.9,33.8,111,31.2,76.2,58
          c-11.9,9.1-25.2,22.4-30,36.6c-9.2,26.9-3.6,60.5,15.8,81.8c19.6,21.6,43.3,32.5,72.3,28.6c14.9-2,31.8-5.7,43-16
          c14.5-13.4,26.1-33.2,28.4-52.7C206.5,128.5,206.3,120.3,205.1,112.2z"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M111.4,169.8c0,0,18.7-14.9,33.6,0"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M135.4,119.4c0,0,23.6,25.6,39.6-2.4"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M115.4,118.8c0,0-14.4,27.2-39.3,0"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M155.2,82.8c0,0,6.8,15.2,22.8,11.2"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M94.2,82.8c0,0-4.3,17-21.9,9.4"/>
      </svg>
    </template>

    <template v-if="data === 'face_surprised'">
      <svg version="1.1" viewBox="0 0 248 248">
        <path class="fill-current" d="M202.1,102c-1-4.1-2.4-8.2-4-12.2c-11-26.1-38.2-42.7-65.7-46c-29.1-3.6-62.6,7.8-79.3,33
          c-2,3.1-3.8,6.4-5.2,9.8c-2.3,5.7-3.7,11.7-4.4,17.8c-1.3,11.3-0.5,22.8,0.8,34c1.9,16.4,5.8,34.1,17.7,46.5
          c10.3,10.7,25.2,15.5,39.5,17.9c16.6,2.8,33.4,2.9,49.9-0.8c13.8-3,25.2-8.4,33.5-20.2C201.2,158.9,208.9,129.4,202.1,102L202.1,102
          z"/>
        <path d="M85.2,87.3l-3.6-1.8c8.2-16.1,23.8-12.5,24-12.4l-0.9,3.9l0.5-1.9l-0.5,1.9C104.1,76.8,91.9,74.1,85.2,87.3L85.2,87.3
          L85.2,87.3z"/>
        <path d="M164.2,86.2c-0.2-0.5-6.5-11.7-20.4-8.2l-1-3.9c17-4.2,24.8,10.1,24.9,10.2L164.2,86.2z"/>
        <path d="M97.4,105.4c-1.7-1-3.5-0.5-5,3c-2.6,6.2,2.1,14,5.8,12.9c4.3-1.2,3.8-7.4,2.7-10.7C100.2,108.4,98.9,106.3,97.4,105.4
          L97.4,105.4z"/>
        <path d="M98.4,123.4c-1.9,0.4-3.9-0.4-5.6-2.3c-2.7-3-4.3-8.6-2.3-13.4c1-2.5,2.4-4,4-4.5c1.2-0.4,2.6-0.2,3.8,0.6
          c1.8,1.1,3.4,3.5,4.3,6.4c0.3,1,1.8,6.3-0.2,10.1c-0.8,1.6-2.2,2.6-3.8,3.1L98.4,123.4L98.4,123.4L98.4,123.4z M95.8,107L95.8,107
          c-0.1,0-0.8,0.3-1.6,2.3c-1.4,3.3-0.2,7.2,1.6,9.2c0.8,0.8,1.4,1.2,1.8,1c0.6-0.2,1.1-0.5,1.4-1.1c0.9-1.7,0.6-4.8-0.1-7
          c-0.7-2.2-1.8-3.7-2.6-4.2l0,0C96.1,107,96,106.9,95.8,107C95.8,107,95.8,107,95.8,107z"/>
        <path d="M155.3,106.2c-1.2-1.6-3.1-1.7-5.8,1.1c-4.6,4.9-3.1,13.8,0.7,14.1c4.5,0.4,6.1-5.6,6.4-9
          C156.8,110,156.3,107.6,155.3,106.2z"/>
        <path d="M149.8,123.4c-1.9-0.3-3.5-1.8-4.4-4.1c-1.4-3.8-1-9.5,2.6-13.3c1.8-1.9,3.6-2.9,5.4-2.8c1.3,0.1,2.5,0.7,3.4,1.9
          c1.3,1.7,1.9,4.5,1.8,7.5c-0.1,1.1-0.6,6.5-3.9,9.3c-1.3,1.2-3,1.7-4.7,1.5L149.8,123.4L149.8,123.4L149.8,123.4z M153.3,107.1
          L153.3,107.1c-0.1,0-0.8,0-2.3,1.5c-2.4,2.6-2.8,6.6-1.8,9.2c0.4,1.1,0.9,1.6,1.3,1.6c0.7,0.1,1.2-0.1,1.7-0.6
          c1.5-1.3,2.3-4.3,2.5-6.6c0.1-2.3-0.4-4.1-0.9-4.8l0,0C153.5,107.3,153.4,107.2,153.3,107.1L153.3,107.1L153.3,107.1z"/>
        <path d="M126.9,142.6c-6.6-0.6-13.3,3.5-13.9,13.4c-1,15,6,24,16,22C145.2,174.8,144,144.1,126.9,142.6z"/>
      </svg>
    </template>

    <template v-if="data === 'face_afraid'">
      <svg version="1.1" viewBox="0 0 248 248">
        <path class="fill-current" d="M205.7,111.3c-3.1-20.9-9.7-39.3-30.3-53.9c-23.6-16.7-53.5-20.4-80.6-12C57,57,37.7,96.3,40.8,135.2
          c5.2,64.8,83.4,91.1,131.9,58.7C199.2,176.2,210.2,141.9,205.7,111.3L205.7,111.3z"/>
        <path d="M93.2,88.2c-4.2,1.2-7.5,0.9-7.6,0.8l0.4-4c0.5,0.1,12.4,1.2,17.2-11.4l3.7,1.4C103.8,83.4,97.9,86.9,93.2,88.2L93.2,88.2
          L93.2,88.2z"/>
        <path d="M154.1,86.5C144.5,82.7,142,73.1,142,73l3.9-1l-1.9,0.5l1.9-0.5c0.1,0.5,3.2,12.1,16.7,12.1v4
          C159.2,88.1,156.4,87.5,154.1,86.5L154.1,86.5z"/>
        <path d="M91.6,180c-2.6-0.1-4.8-1-5.3-3.5c-0.8-4,3-8.9,5.2-11.8c6.4-8.6,13.5-15.4,24.3-17.6c8.2-1.6,17.1-1.4,25,1.3
          c7.8,2.6,13.8,8.5,18.9,14.8c2.4,2.9,10.8,12,4,14.4c-3.2,1.1-7.6,0.4-10.9,0.3c-4.5-0.2-9-0.5-13.6-0.5c-8.7,0-17.5,0.1-26.2,0.6
          c-3.7,0.2-7.3,0.4-11,0.8C99.6,178.9,95.2,180.1,91.6,180L91.6,180z"/>
        <path fill="#FFFFFF" d="M113,108c0.3-10-10.9-18.7-23-15c-5.7,1.8-11.2,7.7-11.3,13.7c-0.3,12.5,11.6,19.2,23.3,16.3
          C110,121,112.9,112.2,113,108z"/>
        <path fill="#FFFFFF" d="M162.6,98.4c-2.8-3.5-7-5.8-11.5-6.4c-7.8-1.1-16.6,3-18.1,11.4c-1.3,7.4,3,15.5,9.8,18.6
          c7.3,3.3,17.7-0.4,21.5-7.4C167.1,109.3,166.3,103,162.6,98.4L162.6,98.4z"/>
        <path d="M102,105.2c-0.9-2.8-3.8-4.8-6.9-4c-3.8,1-5.7,5.7-3.6,9.1c3.1,4.9,10.6,2.5,10.7-3.1C102.3,106.4,102.2,105.8,102,105.2
          L102,105.2z"/>
        <path d="M155.5,107.4c-0.2-3.6-3.9-7.1-8.1-5.3c-7.7,3.3-0.7,15.1,5.9,10C155,110.8,155.6,109.1,155.5,107.4z"/>
      </svg>
    </template>

    <template v-if="data === 'face_tired'">
      <svg version="1.1" viewBox="0 0 248 248">
        <path class="fill-current" d="M201.9,102.1c-3.8-14.4-11.5-27.7-23.2-37.9c-41-35.8-110.2-23.9-130.5,28.6c-1.2,3-2.1,6-3,9.1
          C33.2,146.8,50.4,195.5,97,207c26.6,6.6,61.9-2,82-20.9C201.3,165.1,209.7,131.4,201.9,102.1L201.9,102.1z"/>
        <path d="M167.8,91.5l1,3.9c-17.5,4.3-25-9.9-25.1-10.1l3.6-1.8l-1.8,0.9l1.8-0.9C147.5,83.9,153.4,95,167.8,91.5L167.8,91.5
          L167.8,91.5z"/>
        <path d="M75.8,91.9c0.5,0.2,12.9,3.6,20.2-8.7l3.4,2c-8.9,15.1-24.6,10.6-24.8,10.5L75.8,91.9L75.8,91.9z"/>
        <polyline fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" points="160.1,103 141.7,119.9 163,134 "/>
        <polyline fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" points="84,102 105.3,118 82,134 "/>
        <path d="M89.6,174c-2.6-0.1-4.8-1-5.3-3.5c-0.8-4,3-8.9,5.2-11.8c6.4-8.6,13.5-15.4,24.3-17.6c8.2-1.6,17.1-1.4,25,1.3
          c7.8,2.6,13.8,8.5,18.9,14.8c2.4,2.9,10.8,12,4,14.4c-3.2,1.1-7.6,0.4-10.9,0.3c-4.5-0.2-9-0.5-13.6-0.5c-8.7,0-17.5,0.1-26.2,0.6
          c-3.7,0.2-7.3,0.4-11,0.8C97.6,172.9,93.2,174.1,89.6,174L89.6,174z"/>
      </svg>
    </template>

    <template v-if="data === 'face_toddler'">
      <svg version="1.1" viewBox="0 0 248 248">
        <path class="fill-current" d="M208.9,128.9c-0.4-3.6-1.5-7.1-3.8-9.9c-2.6-3.1-6.5-4.5-10.5-4.2c-1.2,0.1-2.4,0.2-3.6,0.5
          c-0.7-13.1-6.7-25.3-15-35.4c-21.5-26-58.7-34.8-87.4-14.5c-0.9,0.6-1.8,1.3-2.6,1.9c-10,7.7-17.5,18.2-22.9,29.5
          c-2.7,5.6-4.9,11.4-6.7,17.3c-2.9-1.1-6.2-0.9-9.1,0.4c-4.2,1.9-6.8,6.4-7.8,10.8c-2.1,9.4,0.9,20.1,9,25.7
          c3.6,2.5,8.1,3.7,12.5,3.3c5,13.6,14.3,25.3,26.2,33.6c13.4,9.2,30.2,13.5,46.3,11.4c14.8-1.9,28.6-8.7,39.5-18.8
          c5.3-5,9.9-10.9,13.4-17.4c0.9-1.6,1.6-3.2,2.4-4.9c0.4-0.9,0.8-1.9,1.1-2.8c0.2-0.4,0.3-1,0.7-1.2c0.6-0.3,1.9,0.1,2.6,0.1
          c1,0,2.1-0.1,3.1-0.4c2-0.5,3.8-1.5,5.4-2.8c3.4-2.7,5.4-6.6,6.4-10.8C209,136.7,209.4,132.7,208.9,128.9L208.9,128.9z"/>
        <path d="M104.8,112.8c-1.4-1.3-3.3-1.2-5.5,1.9c-3.8,5.5-0.9,14.1,2.9,13.9c4.5-0.3,5.2-6.4,4.9-9.8
          C106.8,116.3,106,114,104.8,112.8z"/>
        <path d="M101.9,130.6c-1.9,0-3.7-1.2-4.9-3.4c-2-3.5-2.4-9.3,0.6-13.6c1.5-2.2,3.2-3.4,4.9-3.6c1.3-0.1,2.6,0.3,3.6,1.3
          c1.5,1.5,2.6,4.2,2.9,7.2c0.1,1.1,0.4,6.6-2.4,9.8c-1.2,1.3-2.7,2.1-4.4,2.2L101.9,130.6L101.9,130.6z M102.9,114L102.9,114
          c-0.1,0-0.8,0.1-2,1.9c-2,2.9-1.7,7-0.4,9.3c0.6,1,1.1,1.4,1.5,1.4c0.7,0,1.2-0.3,1.6-0.8c1.3-1.5,1.6-4.6,1.4-6.9
          c-0.2-2.3-1-4-1.7-4.7l0,0C103.2,114.1,103,114,102.9,114L102.9,114L102.9,114z"/>
        <path d="M147.9,112.8c-1.5-1.3-3.4-1.1-5.4,2.2c-3.5,5.7-0.2,14.1,3.6,13.7c4.5-0.5,4.9-6.7,4.4-10.1
          C150.1,116.1,149.1,113.9,147.9,112.8L147.9,112.8z"/>
        <path d="M145.9,130.6c-1.9,0.1-3.8-1.1-5.1-3.2c-2.1-3.4-2.9-9.2-0.1-13.6c1.4-2.3,3-3.5,4.7-3.8c1.3-0.2,2.6,0.2,3.7,1.2
          c1.6,1.4,2.8,4,3.2,7c0.1,1.1,0.8,6.5-1.9,9.9c-1.1,1.4-2.6,2.2-4.3,2.4L145.9,130.6L145.9,130.6L145.9,130.6z M146.1,114L146.1,114
          c-0.1,0-0.8,0.2-1.9,2c-1.9,3-1.4,7.1,0.1,9.3c0.6,1,1.2,1.4,1.6,1.3c0.7-0.1,1.2-0.4,1.6-0.9c1.2-1.5,1.4-4.7,1.1-6.9
          c-0.3-2.3-1.2-3.9-1.9-4.6l0,0C146.3,114.1,146.2,114,146.1,114L146.1,114L146.1,114z"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M89,150.4c12.1,31.6,63,33,73.1,0"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M124.1,64.4c0,0,4.5-19.8-5.4-27.1s-17.2-3.4-19.9,1
          c-2.7,4.4-2.1,11.8,4.5,13.1s10.5-4.4,7.2-9.1"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M132.8,62.4c2.7-7.1,7.9-15.8,15.7-18.1
          c3.7-1.1,8.6-0.3,11.5,2.3c1.3,1.2,2.2,2.9,2,4.7c-0.6,3.7-6.1,6.6-8.9,3.4"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M148.6,67.6c0,0,10.9,11.8,23.2,0"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M104.2,70.2c0,0-4.7-14.9-20.9-11.7"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M95.4,76.9c-5.8-5.6-13.5-14.6-22.5-13.5
          c-6.7,0.8-12.3,10.9-2.7,11.8"/>
      </svg>

    </template>

    <template v-if="data === 'economy'">
      <svg version="1.1" viewBox="0 0 248 248">
        <path class="fill-current" d="M224.4,181.7c0-1.1,0-3.5,0-4.3c-0.1-4.6-0.2-9.1-0.3-13.7c-0.2-9.1-0.4-18.2-0.5-27.4
          c-0.1-4.2-0.1-8.5-0.1-12.7c0-5.4,0-12.1,0-17.5c0-1.4-6.4-0.8-8.8-0.9c-1.3-0.1-2.6,0.1-3.9,0.1c-1.3-6.8-1.8-9.8-2.9-16.3
          c-0.4-2.5-1.1-6.3-1.6-8.8c-0.8-3.9-0.8-4.5-1.7-5.1c-1.6-1.1-7.1,1.3-9.7,1.5c0,0-2.9-7.4-3.3-8.3c-1-2.6-2-5.2-3-7.9
          c-0.9-2.5-1.7-7.3-3.3-9.4c-0.6-0.7-8.1,1.6-11.9,3c-27.7,10-53.8,18.7-80.6,28.3c-18.2,6.5-36.4,13-54.4,20.1
          c-4.7,1.9-9.4,3.8-14.1,5.7c-1,0.4-2.2,0.6-2.2,1.7c0,1.3,2.9,8.8,4,11.9c1.5,4.3,3.1,8.7,4.6,13.1c3.2,10.5,7.4,20.8,11.2,31.2
          c1.9,5.3,3.6,10.6,5.9,15.7c1.1,2.4,2.6,1.6,11.8,1.6c7.4,0,14.9,0,22.3,0.1l44.6,0.1c19.3,0.1,38.6,0.2,57.9,0.2
          c12.6,0.1,25.1,0.1,37.7,0.1C224.1,183.8,224.4,183.5,224.4,181.7L224.4,181.7z"/>
        <polyline fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" points="223.4,105.3 48.2,105.3 48.2,183.8 "/>
        <polyline fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" points="204.6,75.2 53,105.3 34.8,109.8
          48.2,162.2 "/>
        <path d="M117.4,131.3h5.5v11.6h0.3l9.4-11.6h6.6l-9.7,11.7l9.8,14.5h-6.6l-7.2-10.8l-2.7,3.3v7.4h-5.5L117.4,131.3L117.4,131.3z"/>
        <path d="M142.1,137.9h5.3v3.4h0.2c0.7-2.4,2.5-3.7,4.7-3.7c0.6,0,1.2,0.1,1.7,0.2v4.8c-0.5-0.2-1.6-0.3-2.3-0.3
          c-2.4,0-4.2,1.7-4.2,4.1v11.1h-5.4L142.1,137.9L142.1,137.9z"/>
      </svg>
    </template>

    <template v-if="data === 'nappy'">
      <svg version="1.1" viewBox="0 0 248 248">
        <path class="fill-current" d="M217.3,100.1c-2.2-2-4.9-3.6-7.5-5.1c-2.8-1.6-6.9-4.2-9.6-1c-1.1,1.3-1.4,3.1-1.7,4.7
          c-0.3,1.7-0.5,3.5-0.6,5.2c-0.3,6.1,0.9,12.1,3.2,17.6c-5.6,3.5-11.8,6.1-18.3,7.2c-8.2,1.4-16.5,2.7-24.9,3.4c-0.8-1-1.6-2-2.4-3
          c-4.2-6.1-5.6-13.2-6.3-20.4c-0.2-2.1-0.4-4.2-0.6-6.2c5.8-0.2,11.5-1.9,17.1-3.2c3.5-0.8,7-1.7,10.4-2.6c1.4-0.4,2.9-0.7,4.2-1.4
          c1.6-0.9,2.2-2.6,2.8-4.3c0.8-2.3,1.4-4.6,1.9-7c3.9,0.3,7.8,0.3,11.7,0c1.7-0.2,3.6-0.2,5.2-0.8c2-0.8,2.5-2.6,2.5-4.5
          c0-3.7-0.2-7.3-0.5-11c-0.1-1.6-1.5-2.9-3.2-2.4c-4.8,1.4-9.7,1.5-14.6,1.1c0-0.1,0-0.2,0-0.2c-0.1-1.6-0.1-3.8-0.9-5.1
          c-1-1.6-2.9-1.5-4.5-1.7l-5.9-0.4c-6.5-0.5-13-1.1-19.5-1.2h0c-1.4,0-2.9,0-4.3,0c-0.2,0-0.5,0-0.7,0.1c-2.9-0.1-5.7-0.6-8.1-2.2
          c-0.9-0.6-1.7-1.4-2.7-1.9c-1.4-0.7-3-0.9-4.5-1c-2.7-0.2-5.4-0.3-8.1-0.2c-11.5,0.3-22.7,2.8-33.7,5.9c-9.8,2.7-20,5.8-27.8,12.7
          c-3.5,3.1-4.9,7.4-7.1,11.3c-2.3,3.9-6.1,5.9-9.8,8.3c-1.9,1.2-3.8,2.6-4.7,4.8c-1.1,2.5,0,4.8,0.1,7.4c0.1,1.9,0.1,3.8,0.3,5.6
          c-3.6,1.8-10.2,2.6-14.2,2.9c-1.4,0.1-2.5,1.1-2.5,2.5c-0.1,4.7,0.5,9.5,1.5,14.1c0.3,1.1,1.3,1.8,2.4,1.8
          c4.6,0.4,11.2-1.3,15.1-3.2c0.1,0.6,0.3,1.3,0.5,1.9c0.4,1.3,0.7,2.6,1.2,3.9c0.7,1.9,0.9,2.6,2.2,2.4c4.2-0.5,8.3-1.1,12.2-2.9
          c0.9-0.4,1.8-0.9,2.6-1.5c2.3,5.8,5.2,11.3,8.8,16.3c2.1,3,4.5,5.9,7,8.5c1.5,1.5,3.1,3.5,5.1,4.9c0.1,0.2,0.2,0.3,0.3,0.5
          c6.8,8,12.9,16.8,21.8,22.8c5.6,3.9,12.1,6.2,18.7,7.9c11.8,2.9,24.3,4.9,36.4,3.2c17.9-2.4,25.9-17.2,34.5-31.2
          c3.5-5.8,7-11.6,10.6-17.4c3.3-5.5,6.9-10.9,8.6-17.1c1.7-6.3,2.1-13.2,2.3-19.7C219.3,106.2,219.8,102.4,217.3,100.1L217.3,100.1z"
          />
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M103.2,180.9c-1.9-5.2-1.5-17.1-1.5-17.1
          c-1-0.6-6.5-4.7-14.8-12.3s-7.1-13.6-6.2-19.5s8.1-3.5,10.4-2.4s12.1,5.9,12.1,5.9l0.7-6.6c0.1-0.4,0.5-0.7,0.9-0.6
          c2.2,0.4,9.4,1.5,22.7,3.9c8.6,1.5,17.5,1.4,26.2,0.6c18.1-1.5,37.4-4.3,52.6-15c5.8-4.1,9.9-9,14-14.7"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M184.1,88.1c0,0,4.2-12.6,1.9-24.7"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M50.3,135c-2.8-6.1-4.5-12.7-5.6-19.3
          c-0.9-5.7-2.9-13.9-1.3-19.6c0.9-3.1,4.3-5.2,6.9-6.5"/>
      </svg>
    </template>

    <template v-if="data === 'diagram_cake'">
      <svg version="1.1" viewBox="0 0 248 248">
        <path class="fill-current" d="M192.7,160.6c-0.9-1.1-4.6-3.2-6.2-4.1c-1.9-1.1-3.8-2.2-5.7-3.4c-15.2-8.9-30.5-17.6-46-26
          c-3-1.6-6-3.2-8.9-4.9c7-11.1,14-22.2,20.9-33.2c3.6-5.8,7.3-11.5,10.9-17.3c1.8-2.9,7.5-11.3,9.2-14.2c1.8-3.1-17.9-12-25.1-14
          c-15.7-4.4-30.8-3.5-46.6,0.3C80.5,47.4,66.8,54.7,57,66.4c-9.5,11.3-15.5,25.2-19.4,39.3c-4,14.3-5.7,29.5-3.1,44.2
          c2.5,14.4,9.7,27.3,20.5,37c10.7,9.5,24,15.9,37.6,20.1c15.6,4.8,32.7,6.8,48.7,2.4c13.4-3.7,25.2-12,34.4-22.3
          c4.7-5.2,8.8-11,12.4-17c0.8-1.4,1.7-2.9,2.4-4.4C191.5,163.9,193.2,161.2,192.7,160.6L192.7,160.6z"/>
        <path class="fill-current" d="M222.7,108.8C222,89.5,214.2,70.1,200,59c-2.4-1.9-9.3-8.2-12.5-7.8c-1.6,0.2-1.3,0.8-4,4.5
          c-9.2,12.8-21.5,32.9-30,46.2c-2.8,4.3-5.5,8.6-8.1,13c-1.3,2.2-1.2,2.2,0.9,3.4c11.5,6.5,23.3,12.5,34.5,19.4
          c5.9,3.6,11.8,7.2,17.7,10.8c1.4,0.9,2.8,1.7,4.3,2.6c1.5,0.9,4.1,2.6,4.7,2.4c0.6-0.2,2.6-2.8,3.5-3.9
          C219.9,138.2,223.1,122.9,222.7,108.8L222.7,108.8z"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="43.5" y1="179.7" x2="125.8" y2="122.1"/>
        <polyline fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" points="103.1,38.1 125.8,121.6 125.8,121.6
          113,215.4 "/>
      </svg>
    </template>

    <template v-if="data === 'diagram_bars'">
      <svg version="1.1" viewBox="0 0 248 248">
        <path class="fill-current" d="M142.3,179.1c-0.4-4.4-0.3-8.8-0.6-13.1c-0.7-11.3-0.6-22.4-0.9-33.7c-0.4-14.8-0.6-29.4-0.4-44.2
          c0.1-7.2,0-16.9,0.1-24.2c0.1-4.6-0.2-5.8-5.6-5.6c-5.6,0.2-11.2,0.1-16.7,0.1c-5.5,0-11-0.1-16.6,0.2c-1.1,0.1-2.4-0.2-2.9,1.1
          c-0.4,0.8-0.5,1.8-0.6,2.7c-0.3,2.6-0.3,5.2-0.3,7.8c0,1.1,0,2.3,0,3.4c0.1,7.3,0.2,14.6,0.3,21.9c0.2,16.2,0.2,32.5,0.4,48.7
          c0.1,8.4,0.2,16.8,0.2,25.2c0,4.1,0,8.1,0,12.2c0,3.1-0.5,8.5,0.9,11.4c1.2,2.4,8.2,1.4,11.8,1.4c5.2-0.1,11,0.1,16.2,0.2
          c3.1,0.1,11.8,0.1,13.8-1.2c1.8-1.2,1.2-4,1.1-7.7C142.6,184.5,142.4,180.2,142.3,179.1L142.3,179.1z"/>
        <path class="fill-current" d="M194.2,92.1c-0.1-1.3-1-2.5-2.3-2.5c-10.8,0.2-23.4,0.5-34.2,0.5c-1.9,0-5.4-0.2-6.4,0.6
          c-0.9,0.8-0.8,4.8-0.7,7c0.1,3.2,0.2,6.4,0.3,9.6c0.2,6.6,0.4,13.1,0.5,19.7c0.2,12-0.1,24-0.3,36c-0.1,6.1-0.2,12.1-0.1,18.2
          c0,4.1-0.2,12.3,2.3,12.3c11.8,0,25.4,0.2,37.2-0.3c1.8-0.1,5.1,0.2,5.1-2.5c0-31.5,1.5-63.8-1-95.3
          C194.4,94.4,194.3,93.3,194.2,92.1L194.2,92.1z"/>
        <path class="fill-current" d="M87.7,129.4c-12.1,0.2-22.2,0.5-35.2,0.4c-1.3,0-2.3,1.2-2.3,2.5c-0.4,19.8-0.3,39.5,0.3,59.2
          c0,1.3,1.2,2.8,2.4,3.1c4.1,0.9,9.1,0.7,13.2,0.5c7.2-0.4,14.3-0.8,21.5-1.1c1.3-0.1,2.3-1.1,2.3-2.5c0-19.6,0-40.1,0-59.7
          C90,130.6,88.9,129.4,87.7,129.4L87.7,129.4z"/>
        <polyline fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" points="215.5,206.8 37.8,206.8 37.8,50.9 "/>
        <polyline fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" points="26.8,62.5 37.5,48 48.9,61.6 "/>
        <polyline fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" points="204.6,195.7 219.1,206.4 205.5,217.8 "/>
      </svg>
    </template>

    <template v-if="data === 'dog'">
      <svg version="1.1" viewBox="0 0 248 248">
        <path class="fill-current" d="M219.4,70.6c-3.1-2.9-8-4.1-12-5.1c-2.3-0.6-4.6-1.1-6.9-1.8c-1.1-0.9-2.1-2-3-3.1c-1.8-2.1-3.5-4.2-5.6-6
          c-2.2-2-4.6-3.5-7.3-4.6c-2.8-1-5.8-1.4-8.7-1.5c-2.8-0.1-5.7,0-8.5,0c-2.7,0-5.4,0-8.1,0.6c-5.8,1.4-9.1,6.6-11.6,11.6
          c-1.7,3.3-2,7.1-3,10.6c-2.9,10.1-10.1,19.1-20.7,21.3c-10.5,2.2-21.6,0.3-32.2,0.5s-20.7,1.6-29.6,7c-1.8,1.1-3.6,2.4-5.1,3.8
          c-5.9-4-11.1-9.1-11.6-16.7c-0.3-5.1,1.2-10,3.5-14.6c1.2-2.2,2.5-4.4,3.9-6.5c1.4-2.1,2.8-4.3,2.4-6.9c-0.5-2.6-2.8-4.3-5.4-4.2
          c-2.6,0-4.8,1.4-6.7,3.1c-3.3,3-6.1,7-8.2,11c-4.3,8.4-6,18.3-4.2,27.6c1.1,5.8,4.8,10,8.9,14.1c2.6,2.6,5.3,5,8.4,7
          c-0.5,1.5-0.8,3.2-1.1,4.8c-1.4,10.5,0.2,21.5-4.4,31.4c-2.3,5-5.4,9.6-7.3,14.9c-2,5.6-1.5,10.4-0.2,16c0.8,3.4,1.5,6.8,2.1,10.2
          c0.5,2.6,0.8,5.1,1.2,7.6c0.4,2.2,1.1,4.8,2.9,6.3c2,1.7,4.9,1.8,7.3,2.1c2.5,0.3,5.2,0.2,7.5-0.7c4.3-1.7,7.5-5.9,6.2-10.7
          c-0.1-0.4-0.3-0.9-0.4-1.2c-1.1-2.6-3.3-4.4-5.4-6.2c-1-0.9-0.9-3-1-4.2c-0.3-2.8-0.4-5.5-0.3-8.2c0.5-0.6,1.1-1.2,1.8-1.8
          c1.1,3.8,1.7,7.8,2.8,11.6c0.8,2.9,1.8,5.7,2.9,8.5s1.9,5.4,3.2,8c1.2,2.4,3.3,4.2,6,4.6c2.8,0.5,5.9,0,8.8-0.1c1.4-0.1,3,0,4.3-0.7
          c1.3-0.7,2.2-2,2.7-3.4c0.9-2.2,0.8-4.8,0-7c-0.7-2.1-2.1-3.8-3.7-5.2c-1.3-1.1-2.7-2-4.2-2.9c-0.3-0.2-0.6-0.4-0.8-0.7
          c-0.2-0.3-0.3-0.7-0.4-1.1c-0.4-2.1-0.9-4.2-1.4-6.2s-1.2-4-0.8-6.1c0.3-1.8,1.5-3,2.8-4.1c3.1-2.5,6.3-4.9,8.4-8.3
          c2.4-3.9,4.3-8.1,6.6-12c0.8-1.3,1.7-2.2,3-2.8c4,2.4,8.9,3.7,13.2,5c8,2.4,16.2,3,24.4,2.9c0.6,0,1.2,0,1.9-0.1
          c0.5,1.7,1,3.5,1.4,5.2c0.9,3.8,1.7,7.8,1.7,11.7c0,7.9-4.4,15.1-3.9,23c0.1,2,0.4,3.9,0.9,5.9c0.5,1.8,1.1,3.7,2.6,4.9
          c1.6,1.3,3.7,1.6,5.6,1.8c2,0.3,4,0.4,6,0.4c4.2,0,8.3-0.1,12.5-0.2c3.4-0.1,7-0.2,9.7-2.6c3.1-2.7,3.2-7.4,1.4-10.9
          c-0.5-0.9-1.1-1.7-1.7-2.5c-0.6-0.7-1.4-1.2-1.9-1.9c-0.9-1.1-1-2.9-1.3-4.3c-0.4-1.7-0.6-3.5-0.7-5.2c-0.3-5.9,0.9-11.8,2.6-17.4
          c1.8-5.7,4.3-11.1,6.6-16.7c2.8-6.5,2.5-13.8,2.7-20.7c0.1-3.8,0.7-7.6,1.8-11.3c0.9-3.3,2.7-6.4,3.3-9.7c0.7-3.6,1.1-10.9,6-11.4
          c2.5-0.2,4.8,0.1,7.3-0.2c4.4-0.5,9.4-0.6,13.6-2.2c4.5-1.8,6.8-5.7,7.9-10.2C221.7,79.2,223,74,219.4,70.6L219.4,70.6z"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M156,210.1c6.4-2,7.3-4.8,6.9-9.8
          c-0.4-4.8-4.6-6.1-6.6-8.7s-1.4-16.5,11.2-30.3c2.7-3,5.5-6,7.8-9.3"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M56.7,178c0,0,12.6-3.3,19-14.4S87.1,147.3,97,150"
          />
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M169.4,56.4c0,0-10-1.3-13.4,2.5s-9,18.7-1.9,26.7
          s14.3,4.3,15.6,3.2s4.6-3.9,4-11.7s-0.9-14.5,1.2-16.9S172.7,56.6,169.4,56.4L169.4,56.4z"/>
      </svg>
    </template>

    <template v-if="data === 'frida'">
      <svg version="1.1" viewBox="0 0 248 248">
        <path class="fill-current" d="M219.6,133.9c-4.3-5-11.8-4-17.2-1.7c-0.7,0.3-1.4,0.6-2.1,1c0.4-0.2-1.5-2.8-1.7-3c-0.9-0.8-2-1.4-3.1-1.7
          c-7.2-2-16,2.7-21.7,6.6c-5.5,3.7-10.4,8.2-15.7,12.1c-5.1,3.7-11,7.3-17.5,8c-4.4,0.5-8.6-0.6-12.5-2.4
          c-9.2-7.8-20.9-12.1-32.9-12.8c-12.2-0.6-24.2,2.9-34,10.1c-8.8,6.5-18.3,12.2-27.4,18.2c-6.1,4-9.2,7.6-8.3,15.3
          c0.9,7,3.4,14.1,7,20.2c6.8,11.6,18.7,8.4,28.7,3c5.3-2.8,10.5-8.4,16.5-9.7c6.9-1.5,14,4.5,20.5,6.9c6.8,2.5,13.2,5.8,20.1,8.1
          c6.7,2.2,13.6,3,20.6,2c6.5-1,12.7-3.5,18.4-6.7c11.1-6.3,20.9-19.3,30.1-28.1c5.9-5.7,12.1-11.3,18.3-16.7
          c3.9-3.4,8.2-6.5,11.9-10.1c3.5-3.4,5.6-7.7,5.2-12.6C222.5,137.3,221.2,135.7,219.6,133.9L219.6,133.9z"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M105.2,174.5c9.6-0.8,19.3-0.6,28.9,0.6
          c4.2,0.5,8.3,1.2,12.5,1.9c4.4,0.7,9,1.9,13.5,1.7c1.9-0.1,3.8-0.5,5.5-1.4c5-2.7,5.6-8.7,2.1-12.8c-3.1-3.6-8.1-5.9-12.6-7.1
          c-6.8-1.6-13.8-0.3-20.6-1.4c-6.5-1-11.7-4.2-15.7-9.8"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="204.1" y1="131.9" x2="162" y2="160.8"/>
        <path class="fill-current" d="M204.1,67.4c0-14-9.9-26.8-23-31.8c-18.6-7-28.7,4.7-42.2,15.1c0.8-0.6-9.5-9.4-10.6-10.1
          c-9.7-6-22.5-3.3-30.1,4.8c-18.2,19.2,1.2,51.2,15,67c6.5,7.4,13.6,14.3,20.3,21.5c6.1,6.6,35.7-17,40.9-20.7
          c13.5-9.5,26.7-24.3,29.3-41.1C204,70.6,204.1,69,204.1,67.4z"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M187.6,52.3c0,0,13.2,21.8-15.8,48.9"/>
      </svg>
    </template>

    <template v-if="data === 'hoover'">
      <svg version="1.1" viewBox="0 0 248 248">
        <path class="fill-current" d="M223.5,152.4c-1.4-8.5-4.4-16.7-8.8-24.1c-4-6.9-9.3-14.8-17.1-17.7c-5.9-2.2-12.4-2.2-18.6-1.2
          c-6.3,1-12.4,3.1-18.3,5.4c-5.7,2.2-11.3,4.8-16.6,7.8c-10.8,5.8-20.8,12.9-30.3,20.6c-8.8,7.1-18.4,15.5-20.7,27.2
          c-1,5.4-0.6,11.2,2.1,16.1c2.8,5.2,8.1,8.4,13.6,10.1c6.4,1.9,13.2,1.9,19.9,1.9c6.9,0,13.9,0.1,20.9,0.1
          c13.8,0.1,27.6,0.2,41.3,0.1c5.6,0,11.5-0.2,16.8-2.3c5.5-2.1,9.7-6.1,12.3-11.3c2.5-5.1,3.6-10.7,4.2-16.3
          C224.7,163.4,224.4,157.8,223.5,152.4L223.5,152.4z"/>
        <path class="fill-current" d="M94,55c-2.5-0.8-4.9-1.7-7.4-2.5c-1.2-0.4-2.8,0.5-3.1,1.8c-4.3,17.3-8.6,34.7-13.1,52
          c-4.5,17.3-9.2,34.5-14.1,51.7c-2.1,7.3-4.2,14.6-6.3,21.9c-0.2-0.1-0.3-0.1-0.5-0.2c-1.7-0.6-3.4-1.2-5.2-0.9
          c-1.7,0.4-3,1.6-3.6,3.2c-0.2,0.6-0.4,1.4-0.5,2.1c-3.6-1.1-8.9-2.8-12.4-4.1c-1.6-0.6-2.2,3.4-2.9,5.5c-1,3.4-2.3,7.1-0.7,7.7
          c1.8,0.7,5.8,2,7.6,2.6l6.2,1.9c8.3,2.5,16.5,5,24.8,7.5c2.1,0.6,4.1,1.2,6.2,1.9c2.1,0.6,7.9,2.7,8.6,2c1.2-1.1,3.3-6.6,3.8-10.9
          c0.3-2.2-7.4-3.5-11.2-4.6c-1.2-0.4-2.3-0.7-3.5-1.1c1-2.7,1.4-6.1-1.4-7.4c-1.1-0.5-2.3-0.9-3.6-1.3c5.3-15.2,9.7-30.7,13.7-46.2
          c4.3-16.9,8.3-33.9,12.5-50.9C90.5,77,93,67.5,95.8,58C96.2,56.7,95.3,55.4,94,55L94,55z"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M89.9,55.3c0,0,1.9-18,13.8-16.3
          c8.2,1.1,12.5,10,13.5,17.4c1.6,11.4-5.2,16.9-11.3,25.5c-6.9,9.6-12.6,20.5-11.7,32.7c0.6,8,5,13.2,1.2,21.2
          c-3.4,7-9.8,12.1-13.1,19.2c-6,12.6,0.5,21.3,13.5,22"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M201.8,171.2c-2.8-4.8-7.2-8.6-12.8-10
          c-6.5-1.7-12.9-0.2-18.3,3.6c-9.6,6.6-13.5,17.7-8.6,28.6c8.8,19.8,39.1,15.9,42.9-5.3C205.9,182.3,204.7,176.2,201.8,171.2z"/>
      </svg>
    </template>

    <template v-if="data === 'process_learning_1'">
      <svg version="1.1" viewBox="0 0 248 248">
        <path class="fill-current" d="M211.3,113.6c-0.7-5.9-1.9-11.8-4-17.6c-7-20.3-21.2-37.5-40.1-47.8c-4.4-2.4-8.9-4.4-13.7-6
          c-3.3-1.1-6.7-2.1-10.1-2.6c0.3-2.6,0.8-5.3,1.2-7.9c0.9-5.9-4.4-2.8-6.7-1.9c-4.1,1.6-7.8,3.9-11.6,6.2c-3.9,2.4-7.8,4.7-11.6,7.1
          c-1.4,0.9-1.7,2.8-0.5,4c5.4,5.5,10.8,11,16.2,16.5c1.3,1.3,2.6,2.7,4,4c1.3,1.3,4.6,4.7,6.7,3.4c1-0.6,1.1-2.8,1.2-3.8
          c0.2-1.6,0.1-3.3,0-4.9c0-0.6,0.2-6.5,0-6.5c18.1,4.9,36.6,20.3,43.4,37.8c2.8,7.2,5.6,14.9,7.2,22.5c1.9,8.9,1.3,17.9,0.4,26.9
          c-0.1,1,0.9,2.3,1.9,2.5l12.8,2.4c1.7,0.3,3.1-0.7,3.2-2.5C212.1,134.6,212.5,124,211.3,113.6L211.3,113.6z"/>
        <path class="fill-current" d="M197.9,156.1c-10.6,2.1-21,5.2-31,9.4c-1.8,0.8-2.6,2.8-1.1,4.3c2.3,2.5,4.7,4.9,7.1,7.2
          c-0.4,0.3-0.7,0.6-1.1,0.9c-6.4,5.2-13.7,9.6-21.4,12.5c-9.2,3.4-19.2,3.3-28.8,2.5c-9.1-0.8-18.1-2.6-26.6-5.9
          c-3.8-1.5-7.6-3.3-11.1-5.5c-3-1.9-6.4-5.7-10.1-5.9c-2.6-0.2-4.4,2.3-6,3.9c-2,2.2-3.8,4.5-5.4,7c-0.8,1.3-0.2,2.7,0.9,3.5
          c14.6,11.2,31.5,20.1,49.9,22.6c13.8,1.9,26.7-0.6,39.9-4.5c5.2-1.6,10.4-3.2,15.3-5.7c5.1-2.7,9.1-6.6,13.3-10.6
          c1.5-1.4,3-2.8,4.4-4.3c2,1.3,4,2.6,6,3.8c1.9,1.2,4.1,3.3,6.6,2.6c2.2-0.7,3-3,3.2-5.1c1-10,0.4-20.4-0.9-30.3
          C201,156.9,199.7,155.8,197.9,156.1L197.9,156.1z"/>
        <path class="fill-current" d="M65.2,139.5c-2.8,1.1-5.7,2.2-8.5,3.5c-2.8-13.4-1.8-27.5,2.8-40.4c3.9-10.7,10.4-20.2,18.7-28
          c4.2-3.9,8.8-7.4,13.6-10.6c2.4-1.6,4.9-3.1,7.5-4.5c2.1-1.2,5.8-2.1,6.3-4.9c0.5-3.2-2.2-6.4-3.9-8.9c-1.5-2.2-3.3-4.6-6.2-3.9
          c-3.4,0.8-7.2,3.3-10.3,4.9C78,50.3,71,54.2,64.7,59.2C46.3,74,37.9,100.1,37,123c-0.3,9.5,0.8,19,3.3,28.1c0,0.2-4.6,2.6-5,2.9
          c-2.2,1.3-4.6,1.9-6.4,3.7c-0.1,0.1-0.2,0.3-0.3,0.4c-1,2,2,4.1,3.3,5.1c2.2,1.6,4.7,2.9,7.1,4.2c1,0.5,2.1,1.1,3.2,1.6
          c5.3,2.9,10.7,5.4,16.2,7.8c1.7,0.7,3.1-1,3.3-2.5c1.3-10.7,3.6-21.3,6.8-31.6C69,140.9,67.1,138.8,65.2,139.5L65.2,139.5z"/>
        <path class="fill-current" d="M83.9,162.4c0-0.1,0-0.1-0.1-0.2c-0.5-1.5-2-2.7-3.6-2.9c-3.5-0.5-6.6,3.5-3.9,6.5c0.8,0.8,1.8,1.3,3,1.4
          c1.1,0.1,2.2-0.1,3.1-0.7C83.7,165.6,84.3,163.9,83.9,162.4L83.9,162.4z"/>
        <path class="fill-current" d="M99.9,153.6c-1-1.9-3-3.3-5.4-3.2c-3,0.1-6.8,2.7-7.2,5.9c-0.1,0.9,0.1,1.8,0.5,2.6c1,1.9,3.1,3.2,5.2,3.4
          c3.2,0.3,6.7-1.9,7.3-5.2C100.7,155.9,100.5,154.6,99.9,153.6L99.9,153.6z"/>
        <path class="fill-current" d="M173.3,106.6c0-0.1,0-0.1,0-0.2c-1.1-4.6-5.1-7.1-9.2-8.7c-3.4-1.4-7.1-1.8-10.7-1.6
          c-2.3-6-7.8-10.7-14.1-11.8c-6.4-1.1-13,1.4-17.2,6.1c-2.1-1.4-4.6-2.3-7-2.9c-3.4-0.7-7.1-0.7-10.3,0.9c-4.5,2.3-6.2,7.1-7.1,11.8
          c-5-2.1-10.9-1.8-14.9,2.3c-3.6,3.7-3.9,9.7-0.8,13.5c-4.2,4.7-4.3,11.9,0.2,16.7c1.9,2.4,4.7,4.2,7.7,5.1c4.9,1.4,9.9,0.3,14.5-1.6
          c1.8,6.8,2.4,14.5,9.1,18.6c7.1,4.3,16.5,1.9,21.7-4.2c2.3-2.8,3.9-6,4.9-9.4c2.4,1.5,4.9,2.7,7.7,3.3c3.8,0.7,7.7,0.1,11.2-1.6
          c3.2-1.7,6.5-4.7,7-8.5c0.4-3.1-1.1-5.5-2.2-8.1c3.1-1.3,5.6-3.7,7.3-6.6C172.9,115.8,174.3,111.1,173.3,106.6L173.3,106.6z"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M122.3,89c0,0-6.2-7.1-17-3.5
          c-4.6,1.5-9.1,4.5-10.6,13.2c0,0-22.2-4.3-13.6,16.8c0,0-11.6,9.3-1.8,19.8c4.3,4.6,9.1,5.2,21.2,3c0,0,5.8,19,24.5,16.1
          C140,152,137.4,140,137.4,140s24.4,7.9,27.9-13.2c0,0,16.8-4.8,9.6-21.8c0,0-4.2-13.1-23.2-9.2c0,0,1-13-14.9-15.1
          C136.7,80.7,124.5,78.7,122.3,89L122.3,89z"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M91.8,152.7c-3.4,1.8-5.1,6.8-2.5,10.1
          c2.3,3,6.4,3.2,9.4,1.1c3.9-2.7,3-9-1-11.1C95.9,151.8,93.6,151.8,91.8,152.7L91.8,152.7z"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M74.7,160.2c-1.5,0.7-2.5,2.2-2.1,4.4
          c0.6,2.9,3.3,5.3,6.4,3.6c2.6-1.4,2.8-4.7,0.9-6.8C78.5,159.7,76.3,159.4,74.7,160.2L74.7,160.2z"/>
      </svg>
    </template>

    <template v-if="data === 'process_learning_2'">
      <svg version="1.1" viewBox="0 0 248 248">
        <path class="fill-current" d="M198.9,76.7c-3.9-5.2-8.2-10-12.9-14.5c-3.3-3.1-6.8-6-10.6-8.5c1.8-2.9,3.7-5.8,5.6-8.7
          c4.3-6.5-3.7-5.6-6.9-5.8c-5.7-0.3-11.4,0.5-17,1.2c-5.8,0.8-11.7,1.4-17.5,2.2c-2.1,0.3-3.5,2.5-2.8,4.5
          c3.5,9.4,6.9,18.8,10.4,28.1c0.8,2.3,1.7,4.6,2.5,6.9c0.8,2.2,2.9,8.1,6.1,7.6c1.5-0.2,2.8-2.8,3.4-3.9c1.1-1.8,1.9-3.8,2.7-5.8
          c0.3-0.7,3.7-7.6,3.5-7.7c18.8,15.5,32.4,43.7,31.1,67.9c-0.1,1.2-0.1,2.4-0.2,3.6c-0.1,2.2,1.4,4.2,3.7,4.7l15.9,2.9
          c2.7,0.5,5.2-1.5,5.3-4.3C221.9,122,214.2,97,198.9,76.7L198.9,76.7z"/>
        <path class="fill-current" d="M193.3,165.2c-13.8-2.7-27.8-3.9-41.9-3.8c-2.5,0-4.4,2.1-3.4,4.6c1.5,4.1,3.2,8.2,5.1,12.2
          c-0.6,0.2-1.2,0.4-1.8,0.6c-10.2,3.2-21.2,4.9-31.9,4.6c-12.7-0.4-24.8-5.4-35.9-11.1c-1.2-0.6-2.5-1.3-3.7-2
          c-1.4-0.8-3.2-0.4-4.1,1l-11.3,17.7c-0.8,1.3-0.4,3,0.9,3.7c15.4,8.5,31.7,11.6,49.1,13.3c7,0.7,14.1,1.2,21.2,0.6
          c7.5-0.7,14.2-3.6,21.2-6.2c2.5-0.9,5-2,7.4-3c1.8,2.5,3.6,5,5.3,7.5c1.7,2.4,3.4,6,6.7,6.3c3,0.2,5.1-2.1,6.4-4.5
          c6.1-11.6,10.4-24.3,13.6-37C196.5,167.6,195.6,165.7,193.3,165.2L193.3,165.2z"/>
        <path class="fill-current" d="M81.2,122.6c-3.9-0.3-7.9-0.6-11.9-0.8c4.4-17.2,13.5-33.1,26.3-45.5c6.2-6,13.2-11,20.8-15
          c1.7-0.9,2.3-2.9,1.5-4.6l-8.5-18c-0.9-1.9-3.2-2.6-5-1.6c-20,11.3-36.4,30.5-46.8,50.1c-5.8,10.8-10,22.6-12.2,34.7
          c0,0.2-6.8,0.5-7.5,0.5c-3.3,0.3-6.4-0.5-9.6,0.7c-0.2,0.1-0.5,0.2-0.6,0.3c-2.3,1.8,0,6,1,7.8c1.7,3.1,3.8,6.1,5.9,8.9
          c0.9,1.2,1.9,2.4,2.8,3.7c4.5,6.3,9.4,12.4,14.5,18.3c1.6,1.8,4.2,0.6,5.2-1c7.6-11.8,16.4-22.8,26-33
          C84.8,126.3,83.8,122.8,81.2,122.6L81.2,122.6z"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M75.3,188.6c0,0,17.2,10.1,39.9,9.1"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M198.6,93c0,0,15.3,15.3,15.3,47.4"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M100.9,50.7c0,0-30.6,21.6-39.2,44.7"/>
      </svg>
    </template>

    <template v-if="data === 'process_learning_3'">
      <svg version="1.1" viewBox="0 0 248 248">
        <path class="fill-current" d="M211.3,113.6c-0.7-5.9-1.9-11.8-4-17.6c-7-20.3-21.2-37.5-40.1-47.8c-4.4-2.4-8.9-4.4-13.7-6
          c-3.3-1.1-6.7-2.1-10.1-2.6c0.3-2.6,0.8-5.3,1.2-7.9c0.9-5.9-4.4-2.8-6.7-1.9c-4.1,1.6-7.8,3.9-11.6,6.2c-3.9,2.4-7.8,4.7-11.6,7.1
          c-1.4,0.9-1.7,2.8-0.5,4c5.4,5.5,10.8,11,16.2,16.5c1.3,1.3,2.6,2.7,4,4c1.3,1.3,4.6,4.7,6.7,3.4c1-0.6,1.1-2.8,1.2-3.8
          c0.2-1.6,0.1-3.3,0-4.9c0-0.6,0.2-6.5,0-6.5c18.1,4.9,36.6,20.3,43.4,37.8c2.8,7.2,5.6,14.9,7.2,22.5c1.9,8.9,1.3,17.9,0.4,26.9
          c-0.1,1,0.9,2.3,1.9,2.5l12.8,2.4c1.7,0.3,3.1-0.7,3.2-2.5C212.1,134.6,212.5,124,211.3,113.6L211.3,113.6z"/>
        <path class="fill-current" d="M197.9,156.1c-10.6,2.1-21,5.2-31,9.4c-1.8,0.8-2.6,2.8-1.1,4.3c2.3,2.5,4.7,4.9,7.1,7.2
          c-0.4,0.3-0.7,0.6-1.1,0.9c-6.4,5.2-13.7,9.6-21.4,12.5c-9.2,3.4-19.2,3.3-28.8,2.5c-9.1-0.8-18.1-2.6-26.6-5.9
          c-3.8-1.5-7.6-3.3-11.1-5.5c-3-1.9-6.4-5.7-10.1-5.9c-2.6-0.2-4.4,2.3-6,3.9c-2,2.2-3.8,4.5-5.4,7c-0.8,1.3-0.2,2.7,0.9,3.5
          c14.6,11.2,31.5,20.1,49.9,22.6c13.8,1.9,26.7-0.6,39.9-4.5c5.2-1.6,10.4-3.2,15.3-5.7c5.1-2.7,9.1-6.6,13.3-10.6
          c1.5-1.4,3-2.8,4.4-4.3c2,1.3,4,2.6,6,3.8c1.9,1.2,4.1,3.3,6.6,2.6c2.2-0.7,3-3,3.2-5.1c1-10,0.4-20.4-0.9-30.3
          C201,156.9,199.7,155.8,197.9,156.1L197.9,156.1z"/>
        <path class="fill-current" d="M65.2,139.5c-2.8,1.1-5.7,2.2-8.5,3.5c-2.8-13.4-1.8-27.5,2.8-40.4c3.9-10.7,10.4-20.2,18.7-28
          c4.2-3.9,8.8-7.4,13.6-10.6c2.4-1.6,4.9-3.1,7.5-4.5c2.1-1.2,5.8-2.1,6.3-4.9c0.5-3.2-2.2-6.4-3.9-8.9c-1.5-2.2-3.3-4.6-6.2-3.9
          c-3.4,0.8-7.2,3.3-10.3,4.9C78,50.3,71,54.2,64.7,59.2C46.3,74,37.9,100.1,37,123c-0.3,9.5,0.8,19,3.3,28.1c0,0.2-4.6,2.6-5,2.9
          c-2.2,1.3-4.6,1.9-6.4,3.7c-0.1,0.1-0.2,0.3-0.3,0.4c-1,2,2,4.1,3.3,5.1c2.2,1.6,4.7,2.9,7.1,4.2c1,0.5,2.1,1.1,3.2,1.6
          c5.3,2.9,10.7,5.4,16.2,7.8c1.7,0.7,3.1-1,3.3-2.5c1.3-10.7,3.6-21.3,6.8-31.6C69,140.9,67.1,138.8,65.2,139.5L65.2,139.5z"/>
        <path class="fill-current" d="M150.6,112.2c-5.7-11-18.6-18.8-31.9-15.8c-12.4,2.7-23,13.2-23.1,26.3c0,5,1.7,9.7,4.2,13.9
          c2.4,3.9,5.9,7.3,7.7,11.5c0.9,2.2,1,4.7,2.4,6.8c0.3,0.5,0.7,0.9,1.1,1.3c-0.1,2.5-0.1,5.1,0.3,7.6c0.2,1.7,0.9,3.6,2.2,4.7
          c0.4,0.4,0.9,0.7,1.4,0.9c0.7,1.7,1.7,3.3,3.4,4.1c2.2,1,4.7,0.7,7.1,0.7c2.3,0,4.7,0,6.9-1.1c1-0.6,1.9-1.4,2.5-2.4
          c0.3-0.6,0.5-1.1,0.6-1.7c0.2-0.1,0.5-0.3,0.7-0.5c1.5-1.2,2.2-3.1,2.3-4.9c0.1-1.6,0-3.3,0-4.9c0-0.5,0-1.1,0-1.6
          c2.4-1.7,3.7-5,4.3-7.9c0.4-2.1,1.1-3.7,2.3-5.5c3.3-4.8,6.9-8.8,8.1-14.7S153.2,117.3,150.6,112.2L150.6,112.2z"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="109.5" y1="156.6" x2="141.9" y2="156.6"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="109.5" y1="163" x2="127.5" y2="162.8"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="156.6" y1="107.1" x2="168.7" y2="101.4"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="159.5" y1="121.4" x2="174.1" y2="122.1"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="123" y1="88.4" x2="121.7" y2="73.8"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="98.3" y1="98.2" x2="89.4" y2="89.7"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="88.4" y1="123.6" x2="76.4" y2="123.6"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="141.9" y1="95.8" x2="150.9" y2="84.6"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="91.2" y1="110.6" x2="76.4" y2="104.3"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M130.6,104.3c0,0,23.1,9.6,10.4,32.4"/>
      </svg>
    </template>

    <template v-if="data === 'showerhead'">
      <svg version="1.1" id="Layer_2_00000132789283398997477350000007409754261332251556_"
         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 248 248"
         enable-background="new 0 0 248 248" xml:space="preserve">
        <path class="fill-current" d="M224.7,58.3c-0.3-0.8-0.8-1.5-1.5-2.2c-2.6-2.7-7.3-4.3-10.9-2.5c-2.5,1.2-4,3.8-5,6.4
          c-14.2-6.9-30.2-9.9-46-8.3c-4.4,0.5-8.8,1.2-12.9,2.9c-2.7-1.3-5.6-2.2-8.4-2.9c-15.8-4-32.3-2-47.8,2.2
          C78.8,57.3,66.4,63.9,57.8,75c-0.9,1.2-4.7,8.7-5.8,9c-5.9,1.5-12.5,3-15.1,9.2c-3.6,8.6-2.9,20.8,2.5,28.4
          c3.8,5.3,9.7,8.9,15.6,11.7c9.8,4.6,20.3,7.3,30.8,10c17.2,4.3,42.2,11.4,57.2-2.2c7.3-6.7,13.4-18.4,10.6-28.5
          c-0.4-1.3-0.9-2.6-1.6-3.7c2.9-5.5,4.5-11.6,6-17.6c0.4-1.7,3.6-18.3,2.7-18.2c13.7-1,27.7-0.4,39.7,6.6c-0.9,2.3-1.7,4.7-2.2,7.1
          c-0.9,3.8-0.9,7.9,1.5,11.1c2.3,3.1,6.1,4.4,9.8,3c5.3-2.1,7.7-7.9,9.2-12.9c1.9-6.3,3.3-12.7,5.1-19.1
          C224.7,65.3,226.1,61.6,224.7,58.3L224.7,58.3z"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M39.1,87.4c0,0,27.3-10.8,65.6,0
          s49.8,24.2,49.8,24.2"/>
        <path d="M77.7,99.6c-3.2,0-3.2,5,0,5S81,99.6,77.7,99.6z"/>
        <path d="M49.2,105.4c-3.2,0-3.2,5,0,5S52.4,105.4,49.2,105.4z"/>
        <path d="M62,121.7c-3.2,0-3.2,5,0,5C65.2,126.7,65.2,121.7,62,121.7z"/>
        <path d="M66.2,108.9c-3.2,0-3.2,5,0,5S69.4,108.9,66.2,108.9z"/>
        <path d="M82.3,127.9c-3.2,0-3.2,5,0,5S85.5,127.9,82.3,127.9z"/>
        <path d="M97.7,120.8c-3.2,0-3.2,5,0,5S100.9,120.8,97.7,120.8z"/>
        <path d="M84.7,111.6c-3.2,0-3.2,5,0,5S87.9,111.6,84.7,111.6z"/>
        <path d="M106.8,108.5c-3.2,0-3.2,5,0,5S110.1,108.5,106.8,108.5z"/>
        <path d="M112.2,122.1c-3.2,0-3.2,5,0,5S115.4,122.1,112.2,122.1z"/>
        <path d="M117.7,136.2c-3.2,0-3.2,5,0,5S120.9,136.2,117.7,136.2z"/>
        <path d="M128.1,128.3c-3.2,0-3.2,5,0,5S131.3,128.3,128.1,128.3z"/>
        <path d="M128.6,113.7c-3.2,0-3.2,5,0,5S131.9,113.7,128.6,113.7z"/>
        <path d="M57.9,156.2c-0.1-0.7-0.3-1.5-0.5-2.2c-0.1-0.5-0.2-1.5-0.7-1.8c-1.2-0.6-3,4-3.3,4.7c-0.7,1.2-1.3,2.5-1.7,3.8
          c-0.8,2.2-1.6,6.1,1,7.5c1.3,0.7,2.8,0.2,3.8-0.7c1.7-1.5,2.2-3.8,2.1-6c-0.1-1.7-0.3-3.4-0.6-5C58,156.4,58,156.3,57.9,156.2
          L57.9,156.2z"/>
        <path d="M91.3,171.6c-0.2-1.3-0.5-2.7-0.9-3.9c-0.4-1.1-0.8-2.2-1.2-3.2c-0.4-0.9-0.7-2.1-1.4-2.8c-0.9-0.9-1.6,2.2-1.8,2.6
          c-0.9,3.2-1.7,6.4-1.9,9.7c-0.1,1,0,2,0.6,2.9c1,1.6,3.2,2.1,4.8,1.3c1.3-0.7,1.9-2.2,2-3.7C91.5,173.4,91.4,172.5,91.3,171.6
          L91.3,171.6z"/>
        <path d="M115.5,198.9c-0.1-0.5-0.2-1-0.4-1.4c-0.6-2-1.4-4-2.2-5.9c-0.2-0.5-1.3-3.8-2.3-2.7c-0.9,1-2.1,3.9-2.5,5.2
          c-1.1,3.2-2.2,10,1.5,12.1c1.2,0.7,2.7,0.6,3.8-0.2C115.9,204.3,116.1,201.5,115.5,198.9L115.5,198.9z"/>
        <path d="M68,198.2c-0.2-1.2-0.6-2.4-0.9-3.5c-0.5-1.5-0.8-3-1.4-4.4c-0.5-1.1-1.1-0.1-1.4,0.5c-0.5,0.9-0.9,1.9-1.4,2.8
          c-1.1,2.3-2,4.7-2.3,7.2c-0.1,0.8-0.1,1.6,0.1,2.3s0.5,1.5,1.1,2c0.7,0.7,1.8,0.9,2.7,0.8c1-0.1,2-0.6,2.6-1.3
          c1.2-1.4,1.3-3.4,1.1-5.1C68.2,199.1,68.1,198.6,68,198.2L68,198.2z"/>
        <path d="M125.7,169.8c-0.8-1.6-1.7-3-2.7-4.5c-0.5-0.8-2.1-3.2-2.8-3.8c-2.1-1.6-2.2,2.4-2.4,4.1c-0.1,1.5-0.2,2.9-0.2,4.4
          c0,2.4,0.6,5,3.2,5.8c1.5,0.5,3.2,0,4.4-1.1C126.6,173.3,126.5,171.5,125.7,169.8L125.7,169.8z"/>
      </svg>
    </template>

    <template v-if="data === 'spray_bottle'">
      <svg version="1.1" viewBox="0 0 248 248">
        <path class="fill-current" d="M175.9,174.3c-1.8-6.2-4-12.4-6.1-18.6c-4.1-12.5-8.3-25-12.4-37.6c-2.1-6.3-4.2-12.6-6.2-18.9
          c-0.9-2.7-1.6-5.6-3.2-8.1c-1.6-2.5-3.9-4-6.4-5.4c-0.8-0.4-1.6-0.9-2.4-1.5c0.3,0,0.6,0,1,0c1.4-0.1,2.4-1.1,2.5-2.5
          c0.2-4.8,0.3-9.7,0.5-14.6c0.1-1.4-1.2-2.4-2.5-2.5c-0.9-0.1-1.8-0.1-2.7-0.2c0.1-2.7,2.3-5.2,4.8-6.2c4.3-1.7,9.1-0.6,13.3,0.6
          c2.4,0.7,4.7,1.4,7.1,1.9c2,0.4,4.2,0.8,6.1-0.5c2.3-1.6,1.9-4.5,0.1-6.2c-1.8-1.8-3.9-3.4-5.8-5.1c-3-2.7-6.1-5.3-9.1-8
          c-1.2-1.3-2.4-2.6-3.7-3.7c-4.4-3.8-9.3-6.8-15.2-7.1c-6.1-0.4-12.3-0.1-18.4-0.1c-6.2,0-12.4,0-18.6,0c-7.1,0-14.2,0-21.3-0.1
          c0,0,0,0-0.1,0c-1.4-0.3-3.2,0.4-3.2,2.3c-0.1,2.5-0.2,5-0.2,7.5c0,1.1,0.8,2.1,1.8,2.4c2.3,0.6,4.7,0.5,7.1,0.7
          c1.3,0.1,3,0.5,3.7,1.7c0.9,1.5,0.8,3.8,0.7,5.4c0,2.1-0.3,4.1-0.8,6.1c-1,3.9-2.8,7.6-5.4,10.6c-2.8,3.3-6.3,6-9.8,8.6
          c-0.9,0.7-1.5,1.7-1.2,2.8c0.3,1,1.3,2,2.4,1.8c7.6-1,13.9-5.4,19.1-10.8c2.7-2.8,5.2-5.9,7.9-8.8c0.7-0.7,1.3-1.4,2-2.1
          c0.3-0.3,0.6-0.6,0.9-0.9c0.3-0.3,1-0.7,1.1-1.1c0,0,0,0,0-0.1c0.4,0.1,0.8,0.2,1.2,0.3c1.1,0.3,2.3,0.5,3.4,0.8
          c1.2,0.3,2.4,0.6,3.6,0.9c0.6,0.1,1.1,0.3,1.7,0.4c0.1,0,0.4,0.1,0.6,0.2c0,0,0,0.1,0,0.1c-0.1,0.2,0,0.7,0,0.9c0,0.5,0,1.1,0,1.7
          c0,0.6,0,1.3,0,1.9v0.2c-0.3,0-0.5,0-0.7,0.1c-0.7,0.1-1.4,0.2-2.2,0.4c-1.1,0.2-1.8,1.4-1.8,2.4c0,5.1,0.1,10.1,0.1,15.2
          c0,1.4,1.1,2.4,2.5,2.5c0.5,0,1.1,0.1,1.6,0.1c-0.6,1.6-1.9,3-3,4c-2.1,2.1-4.3,4-5.8,6.6c-1.5,2.5-2.6,5.4-3.5,8.1
          c-1.1,3-2,6-2.9,9.1c-3.7,12.2-6.6,24.5-10.1,36.8c-1.8,6.1-3.7,12.1-5.8,18.1c-2.1,5.8-4.3,11.6-5.5,17.7
          c-1.1,5.7-1.4,11.5-0.1,17.2c1.2,5.3,3.8,10.8,8.4,13.9c2.5,1.7,5.5,2.3,8.5,2.4c3.2,0.2,6.4,0.1,9.6,0.1c6.6,0,13.1,0,19.7,0.1
          c6.6,0,13.3,0.1,19.9,0.1c6.2,0,12.6,0,18.8-1c5.3-0.8,10.4-3,13.4-7.6c3.1-4.7,3.3-10.7,3.4-16.1
          C178.3,187,177.7,180.6,175.9,174.3L175.9,174.3z"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="109.1" y1="84.9" x2="143.1" y2="84.9"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="109.1" y1="63.8" x2="143.9" y2="64.7"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="152.2" y1="130.8" x2="170.4" y2="186.4"/>
      </svg>
    </template>

    <template v-if="data === 'tablet'">
      <svg version="1.1" viewBox="0 0 248 248">
        <path class="fill-current" d="M213.2,60.6c-2.8-3.5-7.1-5.3-11.3-7c-23.4-9-50.1-17.5-74.2-24.5c-13.8-4-30.7-11.1-38.8,4.8
          c-9.7,18.8-17.7,38.6-25.5,58.2c-8.3,20.7-16.3,41.5-24.4,62.3L33,169.6c-1.8,4.7-4.1,9.6-2.2,14.6c2.8,7.5,11.8,9.8,18.9,11.8
          c16.4,4.8,32.8,9.5,49.2,14.2c8.3,2.4,16.7,4.8,25.1,7.2c7.6,2.2,15.9,5.8,23.9,6.4c6.9,0.5,11.8-4.6,14.4-10.3
          c9.1-19.8,17.1-40.9,24.4-61.3c8-22.3,16.3-45,24.8-67.1C214.2,77.8,219,67.9,213.2,60.6L213.2,60.6z"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="34" y1="160" x2="169" y2="203"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="86" y1="33" x2="218" y2="75"/>
        <path d="M153.5,42.1c-3.2,0-3.2,5,0,5S156.7,42.1,153.5,42.1z"/>
        <path d="M101,190.3c-0.3-0.3-0.6-0.6-1-0.8c-3.8-2.5-8.5-0.1-9.2,4.2c-0.2,1.3-0.1,2.7,0.6,3.9c2.8,4.6,11.6,3.4,11.6-2.5
          C103,193.3,102.2,191.5,101,190.3L101,190.3z"/>
        <polygon fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" points="99.8,66.7 93.9,81.3 108,86.1 113.8,71.5
          "/>
        <polygon fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" points="88.6,94.6 82.8,109.2 96.8,114 102.7,99.4
          "/>
        <polygon fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" points="78.7,122.5 72.8,137.1 86.9,141.9
          92.7,127.3 "/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="125.8" y1="79.6" x2="183.4" y2="98.2"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="122.7" y1="88.9" x2="152.2" y2="98.7"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="115.2" y1="109.6" x2="168.3" y2="126.6"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="106.6" y1="134.8" x2="145.4" y2="147.6"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="103.3" y1="144.2" x2="142.1" y2="156.4"/>
        <path d="M70,122.6c1.4,3.1,2.9,6.2,4.3,9.3c0.6,1.2,1,3,2.3,3.7c1.4,0.8,2.8-0.2,4-0.9c6-3.6,12-7.3,17.9-10.9
          c1.7-1,0.1-3.6-1.5-2.6c-5.2,3.2-10.4,6.3-15.6,9.5c-0.6,0.4-1.3,0.8-1.9,1.1c-0.3,0.2-1.8,0.8-1.3,1.2c-0.4-0.3-0.6-1.2-0.8-1.6
          l-0.9-2c-1.3-2.8-2.6-5.6-3.9-8.5C71.7,119.3,69.1,120.9,70,122.6L70,122.6L70,122.6z"/>
      </svg>
    </template>

    <template v-if="data === 'tannpuss'">
      <svg version="1.1" viewBox="0 0 248 248">
        <path class="fill-current" d="M194.6,33.2c-0.1-0.9-0.5-1.7-1.6-2.4c-1.9-1.1-4.6-0.5-5.9,1.2c-0.5,0.7-0.8,1.5-1.1,2.4
          c-2.7,7.2-5.4,14.4-8.4,21.5c-1.7,4-3.4,8.1-5.2,12.1c-1.5,3.2-2.9,7.1-6,9.1c-1.7,1.1-3.3,2.1-4.6,3.6c-1.2,1.4-2.3,3-3.1,4.7
          c-1.9,3.7-3.2,7.6-4.6,11.5c-2.8,7.9-5.2,16-8.2,24c-0.7,1.8-1.4,3.6-2.1,5.4c-4.6-0.7-9.2-0.9-13.9-0.9c0-1-0.1-2.1-0.1-3.1
          c-0.2-3.8-0.8-7.5-1.1-11.2c-0.4-3.8-0.6-7.7-0.7-11.6c-0.4-15.2,0.6-30.6-1.4-45.8c-0.5-4.2-1.4-8.3-2.2-12.5
          c-0.8-4-1.5-8.2-0.6-12.2c0.4-1.9-1.2-3.5-3.1-3.1c-11.6,2.3-23.2,4.9-34.7,7.8c-5.7,1.4-11.3,2.9-17,4.4c-2.8,0.8-5.5,1.5-8.2,2.4
          c-1.3,0.4-2.7,0.9-4,1.5c-1.4,0.6-2.8,1.2-3.2,2.8c-0.8,2.6,1.6,5.3,3.1,7.2c0.6,0.8,1.3,1.6,1.7,2.5c0.6,1.2,0.8,2.6,1,3.9
          c0.9,5.2,3.3,9.8,6.1,14.3c2.7,4.4,5.1,8.8,6.8,13.6c1.8,4.9,3.2,10,4.4,15.1c1.4,5.8,2.8,11.7,4.6,17.4c1.4,4.4,3.1,8.8,5.1,12.9
          c-2.5,0.5-8.6,1.9-10.3,3.7c-2.4,2.5,1.9,17.1,3.5,24.2c3.6,15.6,5.6,31.4,9.1,47c0.6,2.7,1.3,5.4,2,8c0.8,2.7,2.2,4.5,4.8,5.7
          c12.2,5.5,26.3,7,39.5,4.9c4.6-0.7,9.2-1.9,13.5-3.6c2.3-0.9,4.9-1.9,6.6-3.9c1.2-1.5,1.7-3.5,2.1-5.3c0.5-2.1,1.1-4.2,1.6-6.4
          c3.4-13.9,6.2-27.9,8.1-42c0.2-1.4,0.3-2.7,0.5-4.1c0.7-5.7,1.2-11.4,1.5-17c0.2-2.5,0.7-5.4-0.5-7.8c-1.2-2.4-4-3.3-6.3-4.2
          c-1.3-0.5-2.7-1-4-1.5c3.8-10.3,6.9-20.9,10.4-31.3c2.7-8.1,6-15.9,9.4-23.8c3.6-8.3,7.2-16.7,10.7-25c1.8-4.2,3.6-8.4,5.4-12.6
          C194.3,35.5,194.8,34.2,194.6,33.2L194.6,33.2z"/>
        <path fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" d="M74.1,134.9c0,0,10.6,8.1,49.9,8.4
          s45.8-8.4,45.8-8.4"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="176.4" y1="27.6" x2="188.9" y2="32.9"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="173.6" y1="35.2" x2="186.1" y2="40.7"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="170.6" y1="42.4" x2="183.1" y2="48"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="167.8" y1="49.6" x2="179.9" y2="55"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="55.1" y1="55.4" x2="127.3" y2="35.3"/>
      </svg>
    </template>

    <template v-if="data === 'clock'">
      <svg version="1.1" viewBox="0 0 248 248">
        <path class="fill-current" d="M209.2,99.7c-7.7-25.4-25.3-47.8-50.8-58.5c-42.9-18-99.4-1.2-116.4,44.2C19.4,145.6,52.7,208.9,118,212.6
          c21.4,1.2,44-1.7,61.4-14.3C211.5,175,219.7,134.6,209.2,99.7L209.2,99.7z"/>
        <path d="M130,118.3c-3.3-3.6-9.5-4-12.5,0.7c-2.7,4.1-1.1,10.7,3.9,12.1c5.7,1.6,12.5-3.8,10.3-9.9
          C131.3,120.1,130.7,119.1,130,118.3L130,118.3z"/>
        <polyline fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" points="146.5,53.5 124.2,123.5 168.5,152.5 "/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="124.2" y1="215.5" x2="124.2" y2="199.2"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="32.5" y1="123.4" x2="50" y2="123.4"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="215.5" y1="123.4" x2="199.3" y2="123.4"/>
        <line fill="none" stroke="#000000" stroke-width="4" stroke-miterlimit="10" x1="124.5" y1="32.5" x2="124.5" y2="48.5"/>
      </svg>
    </template>

    <template
    v-if="data === 'barnevogn'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M213.64,58.35c-8.71-15.19-23.11-24.08-40.36-27.85-4.15-.91-32.42-6.88-52.57,7.59-7,5.03-3.55,8.1,2.46,20.16,3.41,6.84,4.86,9.9,9.33,17.76,3.05,5.37,7.02,14.94,6.04,18.18-1.22,4.02-12.5,3.03-17.93,2.93-15.15-.27-30.31-.05-45.45-.51-5.43-.17-27.61-2.98-29.22,4.98-.92,4.57,1.82,10.71,2.88,15.13,1.52,6.35,3.04,12.71,4.56,19.06,2.18,9.09,2.99,21.75,12.92,25.85,4.12,1.7,17.43,1.53,21.82,1.54,31.84,.07,63.67,0,95.51,.03,8.84,.01,19.67,1.27,23.3-9.11,13.28-37.96,21.85-69.3,6.7-95.74Z" />
      <path
        d="M97.11,220.88c-1.75,0-3.58-.19-5.46-.57-7.67-1.55-12.65-6.51-14.02-13.97-1.02-5.56-.22-13.19,5.07-17.92,5.56-4.97,14.47-6.31,21.67-3.25,3.97,1.69,6.99,4.52,8.72,8.18,3.15,6.66,2.59,14.98-1.4,20.72-3.07,4.42-8.32,6.81-14.59,6.81Zm-.96-33.36c-4,0-7.94,1.35-10.78,3.88-4.06,3.63-4.63,9.72-3.8,14.22,1.07,5.82,4.83,9.54,10.87,10.77,2.63,.53,11.54,1.77,15.98-4.6,3.2-4.6,3.63-11.32,1.07-16.72-1.31-2.77-3.62-4.92-6.67-6.21h0c-2.11-.9-4.39-1.33-6.66-1.33Z" />
      <path
        d="M171.14,221.48c-1.06,0-2.12-.08-3.19-.25-6.09-.94-11.3-4.5-13.95-9.51-3.3-6.25-2.63-14.28,1.74-20.95,4.19-6.4,13.25-9.39,21.55-7.11h0c3.53,.97,6.5,2.83,8.6,5.39,6.21,7.57,5.42,20.33-1.68,27.32-3.35,3.3-8.08,5.11-13.07,5.11Zm.55-34.57c-5.16,0-10.12,2.26-12.6,6.05-3.57,5.46-4.17,11.93-1.55,16.9,2.06,3.9,6.18,6.67,11.02,7.42,4.87,.76,9.68-.65,12.85-3.77,5.71-5.62,6.37-15.87,1.39-21.93-1.57-1.91-3.84-3.32-6.57-4.07h0c-1.49-.41-3.02-.6-4.54-.6Z" />
      <rect x="105.38" y="174.38" width="60.76" height="4" transform="translate(-67.6 85.91) rotate(-28.43)" />
      <rect x="127.76" y="146.76" width="4" height="61.97" transform="translate(-89.22 199.65) rotate(-59.58)" />
      <polygon points="69.68 97.58 54.92 78.02 26.32 78.02 26.32 74.02 56.91 74.02 72.87 95.17 69.68 97.58" />
    </svg>
    </template>

    <template
    v-if="data === 'bamse'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M214.34,141.74c-2.67-5.43-6.15-10.5-9.75-14.92-1.76-2.16-25.68-20-31.57-22.92-.78-.39-1.07-1.35-.62-2.09,4.73-7.89,7.77-17.46,8.09-26.62,.05-1.34,.95-2.53,2.26-2.84,5.17-1.2,9.81-4.55,12.33-9.43,4.35-8.41,2.35-18.55-3.81-25.49-5.89-6.62-16.18-11.3-24.73-7.43-5,2.26-8.42,6.34-10.16,11.12-.32-.14-.63-.27-.94-.4-10.31-4.27-21.55-5.85-32.64-4.67-.63,.07-1.18,.23-1.67,.47-.18,.02-.36,.05-.54,.08-1.82,.37-3.6,.84-5.35,1.4-1.37,.44-2.84-.16-3.54-1.43-2.66-4.87-6.29-9.28-11.19-11.34-7.53-3.17-16.22-.48-22.08,4.76-5.75,5.14-8.91,13.23-7.59,20.88,.68,3.89,2.48,7.53,5.48,10.12,.62,.54,3.51,2.33,6,3.65,1.26,.67,1.82,2.16,1.38,3.52-.31,.96-.58,1.94-.78,2.92-.95,4.6-.45,9.3,.74,13.81,1.17,4.43,2.73,8.73,5.43,12.47,1.02,1.41,2.19,2.62,3.42,3.85,.57,.57,2.2,1.81,2.12,2.76-.11,1.39-3.98,2.57-5.57,2.76-12.35,1.43-30.03,1.13-42.11,.1-4.67-.4-9.66-.2-13.51,2.8-3.97,3.1-5.51,8.39-4.43,13.27,1.87,8.41,9.72,14.11,17.25,17.23,8.33,3.45,24.48,5.41,33.18,3.13,1.16-.3,3.11-.49,2.95,.7-.28,2.08-.92,4.03-1.11,5.49-.08,.65-.16,1.36-.62,1.83-2.18,2.23-11.02-1.03-13.49-1.48-4.81-.88-10.2-.42-14.99,.41-10.04,1.75-15.79,8.1-19.68,17.19-5,11.66,3.98,21.38,11.71,29.28,8.56,8.74,22.6,10.68,33.69,5.39,5.94-2.84,10.22-5.29,15.07-9.61,1.72-1.54,5.16-5.91,7.44-6.57,.14-.04,.29-.07,.45-.09,1.87-.24,16.58,.51,16.31,2.38-.61,4.3-1.04,8.63-.64,12.72,.6,6,4.3,11.33,8.76,15.21,9.15,7.96,21.96,10.58,33.12,5.31,11.58-5.47,15.99-16.68,16.05-28.87,0-5.28-2.51-10.25-3.75-15.3-1.53-6.21,.73-12.28,1.37-18.42,.18-1.71-.12-5.8,1.28-6.83,1.64-1.21,3.37,3.81,3.56,4.2,1.11,2.26,1.94,4.35,3.31,6.46,3.22,5.03,11.43,14.32,16.89,17.34,12.45,6.91,22.29-8.56,22.2-19.3-.06-6.49-2.02-12.93-4.98-18.94Z" />
      <g>
        <path
          d="M118.6,63.1c-.7-.49-1.67-.44-2.76,.69-1.91,1.99-.46,5.11,1.44,5.02,2.24-.1,2.61-2.33,2.46-3.57-.11-.88-.52-1.71-1.14-2.14Z" />
        <path
          d="M117.2,70.81c-1.58,0-3.06-1.03-3.79-2.66-.88-1.95-.5-4.21,.99-5.75,1.98-2.06,4.06-1.84,5.35-.95,1.05,.73,1.79,2.05,1.98,3.53,.08,.6,.21,2.69-1.14,4.29-.79,.94-1.91,1.47-3.22,1.53-.06,0-.11,0-.17,0Zm.37-5.9c-.08,.06-.17,.14-.29,.27-.28,.29-.39,.63-.32,1.02,.06,.33,.22,.54,.31,.61,.19-.02,.24-.08,.27-.11,.18-.21,.28-.76,.23-1.21-.03-.25-.11-.45-.18-.58Zm-.12-.17h0Z" />
      </g>
      <g>
        <path
          d="M146.28,65.49c-.7-.5-1.67-.46-2.76,.71-1.91,2.05-.46,5.26,1.44,5.17,2.24-.1,2.61-2.4,2.46-3.67-.11-.9-.52-1.76-1.14-2.21Z" />
        <path
          d="M144.88,73.37c-1.57,0-3-.99-3.76-2.63-.92-1.99-.55-4.31,.93-5.91,1.99-2.13,4.09-1.91,5.39-.97h0c1.04,.75,1.78,2.09,1.96,3.59,.08,.62,.21,2.77-1.16,4.39-.79,.93-1.89,1.46-3.19,1.52-.06,0-.12,0-.18,0Zm.37-6.06c-.07,.06-.16,.14-.27,.25-.37,.4-.39,.83-.34,1.13,.06,.36,.23,.59,.32,.67,.16-.02,.2-.07,.23-.1,.2-.23,.31-.83,.25-1.33-.03-.27-.11-.48-.19-.62Z" />
      </g>
      <path
        d="M127.56,93.4c-1.12,0-2.29-.29-3.48-1.03-3.37-2.12-5.44-6.17-6.18-7.82-1.22-2.72-.6-4.55,.13-5.61,1.92-2.78,6.56-2.93,9.16-2.77,4.24,.26,9.41,.99,11.75,4.35,.91,1.31,1.21,2.79,.86,4.26-.7,2.96-3.82,5.21-6.32,6.58l-.96-1.75,.91,1.78c-1.63,.89-3.66,2.01-5.87,2.01Zm-1.77-13.27c-2.44,0-4.07,.51-4.47,1.09-.29,.41,.07,1.32,.24,1.7,1.22,2.73,2.96,5,4.66,6.07,1.38,.87,2.74,.31,5.29-1.1l.05-.03c2.41-1.32,4.08-2.85,4.34-3.99,.06-.26,.08-.59-.25-1.06-1.53-2.21-6.93-2.53-8.71-2.64-.4-.02-.79-.04-1.16-.04Zm7.69,11.24l-.96-1.75,.96,1.75h0Z" />
      <path
        d="M139.49,103.69c-3.82,0-7.04-.9-9.6-2.68-1.81-1.26-2.99-2.77-3.75-4.12-1.37,1.27-3.35,2.64-5.96,3.3-4.08,1.05-8.55,.12-13.26-2.73l2.07-3.42c3.74,2.27,7.15,3.04,10.15,2.29,3.89-.97,5.89-4.25,5.91-4.29,.43-.72,1.27-1.11,2.1-.94,.83,.16,1.47,.81,1.6,1.64,.02,.11,.57,3.02,3.5,5.03,2.87,1.97,7.04,2.43,12.41,1.37l.77,3.93c-2.12,.42-4.09,.62-5.93,.62Z" />
      <path
        d="M64.65,195.58c-8.75,0-19.37-5.82-21.73-14.98-1.48-5.76,.87-12.87,5.86-17.69,4.45-4.3,9.94-5.9,15.07-4.39,5.8,1.7,12.76,4.46,15.23,10.71,2.06,5.24,.88,13.34-2.82,19.26-2.64,4.22-6.23,6.72-10.12,7.03h0c-.49,.04-.98,.06-1.48,.06Zm-4.61-33.6c-2.92,0-5.9,1.32-8.48,3.82-3.98,3.85-5.89,9.4-4.76,13.81,1.93,7.54,11.63,12.53,19.02,11.93h0c3.5-.28,5.92-3.35,7.05-5.16,2.98-4.77,4.07-11.66,2.49-15.68-1.82-4.63-7.71-6.89-12.63-8.33-.88-.26-1.78-.38-2.68-.38Z" />
      <path
        d="M145.72,216.48c-3.78,0-7.48-1.38-10.68-4.1-5.3-4.5-7.94-11.55-6.89-18.39,1-6.53,5.05-11.75,11.11-14.32,7.76-3.29,17.4-1.14,22.45,4.99,1.6,1.95,2.7,4.21,3.25,6.72,1.84,8.34-2.48,17.79-10.27,22.49-2.9,1.75-5.95,2.61-8.95,2.61Zm1.31-34.37c-2.11,0-4.24,.4-6.22,1.24-4.75,2.02-7.93,6.12-8.71,11.25-.84,5.48,1.28,11.13,5.53,14.73,4.43,3.77,9.89,4.17,14.98,1.11,5.46-3.29,10.11-10.6,8.43-18.2-.42-1.89-1.24-3.58-2.44-5.04h0c-2.69-3.28-7.09-5.09-11.58-5.09Z" />
    </svg>
    </template>

    <template
    v-if="data === 'ball'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M207.17,107.04c-7.16-33.07-34.65-57.89-66.91-66.04-13.5-3.41-26.49-1.6-39.61,2.51-6.58,2.06-12.88,4.97-18.75,8.59-11.5,7.11-21.33,16.96-28.28,28.56-18.19,30.32-16.31,69.42,5.7,97.27,11.61,14.69,28.49,24.03,46.51,28.51,20.32,5.06,40.07,1.45,59.31-6.04,8.75-3.41,16.24-8.14,22.19-15.49,5.84-7.22,10.38-15.6,14.09-24.09,7.47-17.09,9.71-35.48,5.75-53.78Z" />
      <path
        d="M150.23,105.92c-1.08,0-2.18-.12-3.26-.36-3.63-.82-6.93-4.05-8.21-8.06-1.21-3.79-.45-7.59,2.09-10.43,4.6-5.13,10.92-4.36,13.72-3.22,5.31,1.73,7.96,7.08,6.91,14-.36,2.35-1.73,4.45-3.88,5.91-2.06,1.41-4.68,2.15-7.38,2.15Zm-.32-18.91c-1.82,0-4.11,.55-6.07,2.74-2.15,2.39-1.71,5.12-1.26,6.54,.85,2.65,3.02,4.86,5.27,5.37,2.67,.6,5.55,.14,7.5-1.19,.86-.59,1.94-1.62,2.18-3.21,.36-2.37,.66-8.06-4.25-9.61l-.17-.06c-.13-.05-1.44-.57-3.2-.57Z" />
      <path d="M75.41,192.92l-3.29-2.28c6.48-9.35,68.15-86.4,70.77-89.67l3.12,2.5c-.64,.8-64.21,80.21-70.61,89.45Z" />
      <path
        d="M46.45,99.27l-1.11-3.84c28.25-8.16,52.64-8.97,68.13-8.22,16.83,.82,26.86,3.54,27.27,3.66l-1.07,3.86c-.4-.11-40.28-10.74-93.23,4.55Z" />
      <path
        d="M144.6,85.65c-.1-.31-10.53-31.53-32.17-43.56l1.95-3.5c12.08,6.72,20.69,18.84,25.79,27.82,5.49,9.66,8.12,17.66,8.24,17.99l-3.8,1.24Z" />
      <path d="M168.59,201.8l-3.93-.74c10.89-57.69-9.46-97.51-9.66-97.91l3.54-1.86c.21,.41,21.21,41.37,10.05,100.5Z" />
      <path d="M207.49,123.36c-16.03-19.95-47.87-26.86-48.19-26.93l.83-3.91c1.36,.29,33.56,7.28,50.48,28.34l-3.12,2.5Z" />
      <path d="M157.93,88.96l-2.3-3.27c.77-.54,18.96-13.26,34.8-16.26l.74,3.93c-15,2.84-33.05,15.47-33.24,15.6Z" />
    </svg>
    </template>

    <template
    v-if="data === 'babyrangle'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M208.71,65.37c-2.58-12.36-8.57-24.71-18.25-32.25-17.2-13.38-47.11-17-66.4-4.47-9.5,6.17-17.42,16.01-22,26.33-4.59,10.35-4.71,24.32-2.03,35.21,1.3,5.29,4.02,10.04,6.75,14.7,2.44,4.17,6.35,9.12,6.05,14.19-.22,3.7-2.82,6.66-4.76,9.63-2.26,3.47-4.53,6.93-6.8,10.39-.66,1.01-1.42,1.9-2.22,2.72l-1.08,.54c-.81,.12-1.62,.21-2.41,.43-2.03,.58-3.74,1.43-5.18,2.6-.41,.34-4.87-.99-5.63-1.08-2.21-.28-4.45-.38-6.68-.31-3.89,.12-7.79,.77-11.49,2.01-15.59,5.22-28.03,20.66-28.09,37.38-.05,9.93,.35,18.39,6.62,26.48,15.69,20.22,45.81,23.52,64.03,4.97,4.65-4.74,8.21-10.54,10.3-16.84,3.45-10.39,1.57-23.93-3.37-33.92-.67-1.36-1.1-2.85-1.07-4.37,.04-2.79-.28-4.68-1.51-9.13-.35-1.25,5.11-8.92,5.94-10.24,1.71-2.71,4.01-6.86,6.82-8.56,1.09-.66,2.34-1.01,3.59-1.26,1.15-.23,2.32-.39,3.49-.43,3.53-.1,6.86,1.3,10.31,1.89,3.9,.67,7.86,1.01,11.81,.96,7.65-.09,15.26-1.64,22.23-4.82,16.29-7.41,23.31-21.5,28.67-37.69,2.71-8.22,4.14-16.5,2.35-25.07ZM99.59,198.21c-3.72,4.7-6.67,7.19-12.16,9.3-17.72,6.79-39.16-10.6-32.23-29.43,2.12-5.76,5.87-11.02,10.88-14.59,6.48-4.62,16.37-4.83,24.25-1.71,12.63,5,13.73,13.98,13.84,24.18,.03,3.2-1.46,8.32-4.57,12.26Z" />
      <path
        d="M202.87,103.05c-37.63-7.09-59.87-25.27-71.9-39.27-13.08-15.23-17.17-28.64-17.34-29.2l3.83-1.14c.04,.13,4.08,13.29,16.68,27.89,11.61,13.45,33.08,30.93,69.47,37.79l-.74,3.93Z" />
      <path
        d="M198.04,115.09c-36.62-3.99-60.39-22.82-73.88-37.91-14.61-16.34-20.5-31.54-20.74-32.18l3.74-1.42c.06,.15,5.92,15.26,20.09,31.06,13.02,14.52,35.94,32.64,71.23,36.48l-.43,3.98Z" />
      <rect x="105.32" y="135.26" width="4" height="19.02" transform="translate(-71.77 164.8) rotate(-59.83)" />
    </svg>
    </template>

    <template
    v-if="data === 'blomst'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M219.21,89.05c-3.98-11.37-15.72-14.17-26.47-11.81-11.74,2.58-21.96,9.69-31.24,17-4.66,3.67-9.16,7.53-13.79,11.24-3.01,2.41-6.83,5.75-10.8,6.62l14.08-18.59c5.24-6.92,10.79-13.83,13.77-22.1,5.56-15.45-2.84-30.93-17.16-37.77-7.95-3.79-16.8-5.03-25.54-5.23-8.11-.18-16.58,.6-23.47,5.27-12.17,8.22-15.87,23.3-11.01,36.77,4.13,11.48,13.49,21.72,17.03,33.51-2.98-2.99-5.44-7.02-7.52-9.66-4.54-5.79-8.9-11.86-14.06-17.11-9.38-9.56-22.65-14.99-35.85-10.29-12.75,4.55-21.12,16.79-20.98,30.31,.14,13.41,6.42,26.58,19.51,31.39,5.85,2.15,12.31,2.47,18.49,2.59,7.99,.17,15.99,.22,23.98,.32,1.74,.03,10.37-.78,10.84,1.76,.21,1.13-8.5,1.9-10.08,2.07-8.15,.89-16.4,1-24.57,.35-11.84-.94-27.26-4.16-32.59,10.02-4.46,11.85,1.07,27.13,10.4,35.09,11.38,9.72,29,11.35,40.14,.36,8.18-8.08,12.81-21.34,22.53-27.93-.81,3.54-2.58,8.37-3.13,10-2.04,5.96-4.96,11.61-8.51,16.82-2.75,4.03-6.23,7.64-7.35,12.55-1.24,5.47,.96,11.05,4.33,15.29,10.78,13.57,41.92,18.66,46.84-2.95,2.34-10.24-2.58-19.21-6.65-28.23-2.62-5.81-3.81-11.45-3.17-17.83,.18-1.7,.47-3.39,.8-5.08,4.5,20.44,10.34,43.78,32.17,51.91,9.01,3.36,19.87,2.85,27.95-2.63,8.41-5.71,12.38-15.87,8.42-25.48-7.44-18.07-30.27-25.38-45.35-35.37-3.88-2.56-7.54-5.44-10.69-8.87-.24-.26-.7-.91-1.23-1.67,.52,.13,.99,.25,1.36,.4,11.22,4.32,21.51,10.69,32.35,15.82,8.65,4.1,21.36,11.45,30.18,4.43,8.3-6.61,12.3-19.1,14.44-29.06,2.25-10.44,5.26-23.81,1.6-34.23Z" />
      <path
        d="M117.8,147.1c-.62,0-1.24-.03-1.85-.08-15.39-1.28-20.22-16.3-19.64-25.47,.53-8.54,10.91-15.71,24.67-17.03,7.06-.67,13.55,2.87,17.38,9.49h0c4.58,7.94,4.01,17.85-1.39,24.11-4.86,5.64-12.06,8.97-19.17,8.97Zm4.91-38.66c-.45,0-.9,.02-1.35,.07-11.6,1.12-20.66,6.83-21.06,13.3-.32,5.14,1.93,20.06,15.98,21.23,6.35,.54,13.26-2.43,17.66-7.52,4.31-4.99,4.7-13.01,.95-19.5-2.81-4.87-7.2-7.57-12.18-7.57Z" />
      <path
        d="M115.13,211.04c-7.42,0-13.58-4.78-13.86-5l2.47-3.14c.06,.05,6.23,4.83,12.83,4.07,6.56-.75,9.54-3.98,9.66-4.11l3,2.64c-.16,.18-4.02,4.51-12.21,5.44-.64,.07-1.27,.11-1.89,.11Z" />
      <path
        d="M208.52,113.04l-3.89-.92,.25-1.06c1.27-5.32,3.63-15.22-.42-18.03-5.17-3.58-12.18-2.19-20.82,4.15l-2.37-3.22c10.17-7.46,18.74-8.88,25.46-4.22,6.3,4.36,3.63,15.55,2.03,22.24l-.25,1.05Z" />
    </svg>
    </template>

    <template
    v-if="data === 'bok'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M218.28,91.09c-2.26,1.07-2.49,3.02-4.02,4.72-9.84,10.93-12.42,16.31-17.35,22.09-5.52,7.18-7.65,9.59-8.97,11.14-3.84,4.5-7.64,9.02-11.46,13.54-6.83,8.07-13.67,16.15-20.5,24.23-1.9,2.25-16.95,21.67-21.23,24.27-15.39-3.69-30.95-6.63-46.54-9.37-8.37-1.47-16.73-2.9-25.09-4.34-4.69-.81-9.85-1.27-14.83-2.18-.01,0-3.38-1.85-3.39-1.85-6.61-3.76-5.99-8.75-7.89-15.08-.58-1.91,1.06-3.76,3.03-3.43,16.69,2.78,33.45,4.58,50.09,7.65,10.03,1.84,20.05,3.7,30.08,5.54,3.95,.72,8.07,2.01,11.42-.9,3.23-2.81,5.14-6.64,7.94-9.75,25.83-28.74,49.64-59.18,75.47-87.93,2.45-2.72,.67-7.36-2.87-7.94-17.62-2.9-35.18-6.17-52.73-9.49-8.78-1.66-17.55-3.33-26.33-4.98-4.01-.75-8.05-1.41-11.93-2.54-3.44-1-7.01-1.86-10.3,.23-5.13,3.25-8.21,8.78-11.35,13.8-3.54,5.66-8.81,10.32-13.24,15.33-9.75,11.02-18.22,23.04-27.75,34.19-4.38,5.12-9.26,9.77-14.02,14.52-6.71,6.7-12.61,14.14-18.61,21.45-1.85,2.25-3.68,5.16-4,8.11-.37,3.46,.96,6.9,2.07,10.1,1.09,3.15,1.95,6.41,3.41,9.42,.7,1.45,2.28,4.88,3.35,6.09,.98,1.09,1.22,1.82,2.45,2.68s2.97,1.78,4.34,2.21c2.59,.82,5.3,1.33,7.96,1.81,4.03,.74,8.1,1.21,12.14,1.91,9,1.55,17.99,3.09,26.99,4.67,8.07,1.41,16.14,2.87,24.19,4.45,4.12,.81,8.23,1.65,12.33,2.54,1.97,.42,3.92,.88,5.89,1.3,1.64,.35,3.12,1.12,4.74,1.48,.92,.21,1.87,.2,2.79,.34,1.13,.18,2.24,.6,3.38,.57,3.48-.09,5.16-4.52,7.14-6.78,1.86-2.12,3.97-4.02,5.71-6.24,1.77-2.25,3.41-4.6,5.26-6.79,9.73-11.5,19.47-23,29.2-34.51,9.45-11.18,18.91-22.36,28.36-33.53l7.15-8.44c1.87-2.21,4.42-4.43,5.22-7.29,1.25-4.43-3.19-9.13-7.69-7.01Z" />
      <path
        d="M128.15,180.82c-.55,0-1.1-.05-1.65-.16l-80.6-15.6,.76-3.93,80.6,15.6c1.67,.32,3.39-.28,4.5-1.58l78.2-91.96,3.05,2.59-78.2,91.96c-1.67,1.97-4.12,3.07-6.65,3.07Z" />
      <rect x="135.63" y="51.87" width="4" height="54.62" transform="translate(33.18 198.77) rotate(-78.78)" />
    </svg>
    </template>

    <template
    v-if="data === 'byggeklosser'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M118.39,150.53c-.23-4.53-.61-10.3-.63-14.84-.03-8.59,.06-15.3-.42-23.91-.08-1.35,.05-3.18-.7-3.96-.54-.71-1.84-1.15-2.79-1.63-7.91-3.96-13.53-6.2-21.13-9.9-6.34-3.09-14.36-8.25-17.97-7.43-6.75,1.52-14.61,3.98-21.11,6.19-6.31,2.16-12.25,3.66-18.22,6.61-3.94,2.02-6.17,1.86-6.4,5.93-.77,13.65-1.46,27.96-1.49,41.65-.02,7.2,1.36,6.36,9.23,12.49,4.92,3.83,9.93,7.34,14.88,11.14,4.34,3.34,9.67,6.68,14.48,9.53,3.15,1.87,9.08,.04,16.54-3.01,5.52-2.26,10.41-4.17,16-6.18,5.88-2.13,11.55-4.76,16.99-7.83,.5-.28,.92-.61,1.26-.97,.69-.59,1.2-1.48,1.39-2.71,.62-4.16,.3-6.96,.08-11.17Z" />
      <path class="fill-current"
        d="M220.32,125.7c-.21-1.22-.99-2.33-2.56-3.02-10.59-4.65-21.09-9.49-31.49-14.55-4.73-2.3-9.36-5.03-14.21-7.05-1.96-.82-4.02-.94-6.01-.16-2.33,.92-4.37,2.34-6.45,3.71s-4.2,2.2-6.29,3.35c-4.95,2.74-9.67,5.86-14.54,8.74-3.04,1.8-6.61,3.63-9.72,5.33-3.93,2.16-4.81,1.71-4.81,6.45,.01,17.28,.59,32.95,.9,50.23-.01,2.01-.19,3.62,1.48,4.31,1.17,.54,2.82,1.37,3.81,1.77,15.66,7.86,29.84,14.41,46.09,20.95,1.44,.58,2.73,.99,3.99,.38,1.38-.81,2.3-1.46,3.19-2.16,10.99-8.54,21.64-16.02,33.3-23.62,1.19-.77,1.87-1.8,2.15-2.89,.21-.54,.34-1.14,.36-1.81,.47-16.1-.42-32.22,.92-48.29,.05-.61,.01-1.17-.11-1.66Z" />
      <path class="fill-current"
        d="M211.1,111.69c-.1-5.99-.28-11.97-.48-17.96-.2-6.02-.37-12.05-.38-18.08,0-3.85,.06-7.7,.23-11.55,.08-1.88-.73-3.21-1.9-3.99-.38-.39-.86-.75-1.47-1.03-11.62-5.56-20.33-10.31-33-16.26-5.13-2.41-7.44-.69-10.17-.31-9.64,1.34-18.62,3.91-28.98,5.59-3.38,.55-8.69,.89-9.78,4.83-.85,3.07-.71,6.27-.73,9.37-.06,6.2-.24,12.34-.48,18.5s-.34,12-.34,17.99c0,1.49,1.56,2.94,2.85,4.3,1.82,1.92,3.04,3.37,4.47,5.1,5.76,6.99,13,11.91,19.29,18.33q1.52,1.55,4.89,1.22c16.59-.81,29.96-4.11,48.49-5.99,.54-.05,1.88-.21,2.33-.36,4.22-.44,5.26-1.24,5.23-4.29-.02-1.8-.04-3.59-.07-5.39Z" />
      <rect x="67.11" y="124.84" width="4" height="59.11" />
      <polygon points="68.97 128.3 27.67 106.78 29.52 103.24 69.26 123.94 116.4 106.79 117.77 110.55 68.97 128.3" />
      <rect x="150.2" y="67.91" width="4" height="59.99" />
      <polygon points="151.71 71.17 124.09 53.06 126.28 49.72 152.69 67.03 209.99 60.27 210.46 64.24 151.71 71.17" />
      <polygon points="177.57 150.68 123.61 127.97 125.16 124.28 177.37 146.26 219.39 123.93 221.26 127.46 177.57 150.68" />
      <rect x="176.99" y="148.43" width="4" height="57.74" transform="translate(-9.1 9.69) rotate(-3.02)" />
    </svg>
    </template>

    <template v-if="data === 'hand'">
      <svg version="1.1" viewBox="0 0 237.7 109.3">
        <path class="st0 fill-current" d="M235.4,48.1c-5.1-5.1-12.4-0.8-17.8,1.3c-3.4,1.3-6.7,2.9-10.1,4.2c-0.4,0.1-3.5,1.9-3.8,1.2
          c-0.5-0.6,1.6-1.5,2.5-2.2c3.4-2.8,7.3-4.4,10.9-6.8c6.3-4.2,6.4-14-1.5-16.4c-7.2-2.1-15.8,5.3-21,9.7c-6.8,5.8-13.7,11.6-12.5,9.3
          c3-6,16.5-15.7,10.6-23.2c-6.2-7.9-14,3.5-17.9,8c-5.8,6.8-10.9,14.7-17.2,21c-1.7,1.7-5.8,6.5-8.5,5.6c-2.7-0.9,1.5-3.9,2.3-4.8
          c3.5-3.6,7.2-7,10.5-10.8c2.8-3.3,6.7-7.2,6.9-11.9c0.1-4.1-4-5.8-7.1-4.3c-4.4,2.2-8.5,4.9-12.2,8.2c-2.8,2.4-4.8,5.3-8,7.3
          c-5.9,3.7-14.2,7.8-21.2,6.9c-6.2-0.8-13-3.7-18.7-6.4c-4.7-2.2-2.2-3,1.1-5c6-3.7,13.1-5.5,18.8-9.8c7-5.4,19.1-25.4,4.1-29
          c-6-1.4-12.4,2.2-17.2,5.5c-5.1,3.6-10.6,6.7-16.2,9.4c-6.2,2.9-11.6,7.6-17.9,9.9c-4.5,1.7-9.6,2-14.7,2.2
          c-7.6-0.2-15.3,0.3-22.1,3.2c-7.9,3.3-17.4,5.7-22.8,12.9c-3.3,4.4-6.9,8.4-8.8,13.6C3,64.5-2.2,75.7,1,83.7
          c3.2,8.1,13.6,7.9,20.4,10.2c12.4,4.2,26.1,2.3,38.8,3.5C68.1,98,76,99.2,83.7,101c8.6,2.2,16.8,5.5,25.6,6.8
          c8.6,1.3,17.3,1.4,26,1.4c0.5,0,1.1-0.1,1.6-0.2c28.1-1.7,53.2-19.3,76.7-34.5c6.4-4.1,12.9-8.1,19.1-12.6
          C236.9,58.7,240.1,52.8,235.4,48.1"/>
        <path d="M154.1,78.7c-6.1-0.1-12.2-1-18.1-2.9c-10.9-3.3-36.8-12.4-40.7-24.9c-1.2-3.8-0.3-7.5,2.5-11l3.1,2.5
          c-2,2.5-2.6,4.8-1.8,7.3c1.8,5.7,12.7,14.6,38,22.3c13.1,4,24.5,3.6,35.9-1.2c10.8-4.6,21.1-11,30.9-17.3l2.4-1.5l2.1,3.4l-2.4,1.5
          c-10,6.3-20.4,12.9-31.5,17.6C168.2,77.3,161.2,78.7,154.1,78.7"/>
        <rect x="157.6" y="55.1" transform="matrix(0.7472 -0.6646 0.6646 0.7472 6.0395 130.0132)" width="32.7" height="4"/>
      </svg>

    </template>

    <template v-if="data === 'fot'">

      <svg version="1.1" viewBox="0 0 133 164.8">
        <path class="st0 fill-current" d="M122.6,68.7c-1.5-0.1-3,0.2-4.4,0.6c-0.6,0.4-1.4,0.3-1.9-0.2c-0.4-0.4-0.7-0.9-0.9-1.5
          c-0.2-0.9-0.3-2.5,1-2.7c1.6-0.1,3.2,0.1,4.8,0.4c2.4,0.4,4.8,0.1,7-0.7c3.9-1.6,6.1-6.1,3.9-10c-1.8-3.2-5.3-3.9-8.8-3.3
          c-3.2,0.7-6.3,1.8-9.2,3.4c-0.2,0.1-0.4,0.2-0.6,0.2c-1.2,0.1-1-2.1-1-2.8c-0.2-1.9,1-2,2.6-2.1c3.2-0.4,7.1,0.3,9.9-1.4
          c3.6-2.2,5.4-7.1,3.3-10.9s-6.8-4.5-10.6-3.2c-2.9,0.9-7,5-10.4,5.2c-0.3,0-0.5-0.2-0.5-0.5c0-0.1,0-0.3,0.1-0.4c3-2.9,8-5,9.6-8
          c2.9-5.4-0.1-11.9-6.3-12.5c-6.4-0.7-9.6,4.5-11.8,9.6c-0.3,1-0.8,1.9-1.5,2.7c-1,1-2.4,0.9-3.7,0.9c-1.7-0.1-3.3-0.3-5-0.5
          c-1-0.1-2.1-0.2-3.1-0.5c-0.6-0.1-1.2-0.3-1.9-0.5c-0.4-0.1-1.4-0.3-1.5-0.7c0-0.1,0-0.2,0-0.4c0.2-0.9,0.9-0.9,1.7-1
          c2.2-0.3,4.3-0.8,6.4-1.6c3.6-1.2,7.2-3.1,9.2-6.4c4.4-6.9,0.9-15.8-6.6-18.7c-7.3-2.9-15.6,0-21.2,5.2c-2.8,2.6-5,5.9-6.3,9.5
          c-0.7,1.7-0.4,3.6-1,5.3c-0.5,1.6-1.8,2.4-3.2,3.2c-3.2,1.9-6.2,4.2-8.8,6.9c-2.6,2.9-4.6,6.8-6.3,10.3c-3.2,6.5-5.8,13.2-7.8,20.2
          c-2.1,6.8-4.6,13.3-7.4,19.8c-3.5,7.9-7.5,15.6-12.1,22.9c0,0,0,0,0,0c-3.7,5-7.1,10.2-10.2,15.6c-3.2,5.6-7.2,11.5-8.2,18
          c-1.9,11.7,5.3,23.7,17,26.4c12.7,2.9,23.6-6.3,32-14.7c13.3-13.5,28.2-25.5,44.2-35.6c4.5-2.8,9.3-5.1,13.4-8.5
          c6.9-5.5,9.9-13.3,10.4-21.7c0-0.2,0.1-0.3,0.2-0.4c0.4-0.2,0.8-0.4,1.2-0.4c3.2-0.6,5.7,1.2,8.9-0.3c2.1-1,3.6-2.9,4-5.2
          C132.6,72,127.5,68.6,122.6,68.7"/>
        <path d="M29.3,146.9c-1.2,0-2.4-0.1-3.6-0.3c-8.9-1.7-12.2-9.8-12.8-13.9l4-0.5l-2,0.3l2-0.3c0.1,0.4,1.3,8.9,9.5,10.5
          c11,2.1,20.8-8.9,28.6-17.7c1.8-2,3.5-4,5.2-5.6c9.5-9.7,23.9-23.2,38.5-27.5l1.1,3.8c-13.8,4.1-27.6,17-36.8,26.5
          c-1.6,1.6-3.2,3.5-5,5.5C50.3,136.3,40.9,146.9,29.3,146.9"/>
      </svg>

    </template>

    <template v-if="data === 'lightbulb'">

      <svg version="1.1" id="Component_24_4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
         y="0px" viewBox="0 0 163.5 163.5" style="enable-background:new 0 0 163.5 163.5;" xml:space="preserve">
      <path id="Path_105" class="st0 fill-current" d="M120.3,12.8c-5.4-5.4-12.2-9.3-19.7-11.1c-15.1-3.9-32.4-0.6-46.3,6c-6.9,3.3-11.8,9.2-15.9,16
        c-5.4,8.8-6.9,18.8-6.5,29c0.1,3.9,0.7,7.8,1.9,11.5c0.3,1.2,0.7,2.3,1.3,3.4c2.8,5.4,4.2,11.1,7.5,16.3c3.5,5.5,6.5,11.3,9.1,17.3
        c2.2,4.9,4.3,9.9,5,15.2c-1.6,1.2-3,2.6-4.3,4.1c-1.6,1.9-2,4.9,0,6.8c1.2,1.1,0.7,4.6,0.8,6.2l0.4,8c0.2,4.1,0.1,8.7,1.9,12.5
        c3.7,7.7,14.7,9.9,22.5,9.8c8.5-0.1,17.7-2.7,22.7-10.1c2.7-4,3.1-8.7,3.6-13.3c0.5-4.4,0.5-8.9,0-13.3c0.1-0.1,0.3-0.2,0.4-0.2
        c2.2-1,3.2-4.6,1.7-6.6c-1.3-1.9-2.7-3.7-4.4-5.3c1.5-4.8,3.4-9.4,5.7-13.9c2.5-4.5,5.2-8.8,8.2-13c5.8-8.4,12-16.7,14.7-26.7
        C135.3,43.5,132,24.8,120.3,12.8"/>
      <path id="Path_106" d="M85.4,112.2c-0.5-8.1,1.1-16.1,2.5-23.2c0.3-1.7,0.7-3.4,1.1-5.2c-3.6-0.9-7-2.8-9.7-5.4
        c-0.6,0.6-1.2,1.1-1.7,1.6c-2.3,2-5.3,3.1-8.3,3c0,0.3,0,0.6,0,0.9c0.7,14.3,0.3,28.2,0.3,28.3l-4-0.1c0.4-11.4,0.2-21.5-0.3-30
        C65.1,82,65,82,64.8,81.9c-7.8-3.4-13.6-13.4-14-20.3c-0.2-3.7,0.7-8.6,3.7-10.7c1.2-0.8,3.3-1.5,6.3-0.1c2.8,1.3,6.5,5.7,8.1,28.2
        c2.2,0.2,4.4-0.5,6-2c0.5-0.4,1-0.9,1.6-1.4c-0.1-0.1-0.2-0.3-0.3-0.4c-5.6-7-5.7-16.5-0.3-22.7c2-2.2,5.6-3.9,8.9-2.4
        c3.3,1.5,4.4,5.5,4.3,8.7C89,65,85.8,71,82,75.5c2.2,2.2,4.9,3.7,7.9,4.5c1.1-4.6,2.5-9.2,4.2-13.7c1.6-4.6,4-8.9,7.1-12.6
        c2-2.3,4-3.3,6.1-3.1c2,0.3,3.7,1.6,4.7,3.4c1.6,2.9,2.1,6.4,1.3,9.6c-0.7,3-2,5.8-3.8,8.3c-2.7,4.1-8.4,11.1-15.9,12.2
        c-0.1,0-0.3,0-0.4,0.1c-0.4,2-0.8,3.9-1.2,5.8c-1.4,6.8-2.9,14.6-2.5,22.2L85.4,112.2z M106.8,54.5c-0.7,0-1.5,0.6-2.5,1.7
        c-2.8,3.4-4.9,7.3-6.4,11.5c-1.5,4-2.8,8-3.8,12.1c5.5-1.5,9.8-6.9,12-10.2c1.5-2.1,2.6-4.4,3.2-6.9c0.6-2.3,0.3-4.7-0.8-6.7
        c-0.3-0.7-0.9-1.2-1.7-1.4C106.8,54.5,106.8,54.5,106.8,54.5 M57.7,54c-0.3,0-0.6,0.1-0.9,0.3c-1.4,0.9-2.1,4.4-2,7.2
        c0.3,5.1,4.6,12.8,10.1,16.1c-1.1-13.7-3.3-22-5.7-23.1C58.8,54.2,58.2,54,57.7,54 M82.2,53.5c-1.2,0.1-2.4,0.7-3.2,1.6
        c-4.6,5.3-3.3,12.9,0.3,17.5c3.1-3.9,5.8-8.9,5.9-14c0.1-1.8-0.4-4.2-2-4.9C82.9,53.6,82.6,53.5,82.2,53.5"/>
      <path id="Path_107" d="M78.9,129.9c-14.5,0-27.3-0.6-27.5-0.6l0.2-4c0.4,0,35.3,1.6,55.3-0.5l0.4,4
        C99.2,129.7,88.6,129.9,78.9,129.9"/>
      <path id="Path_108" d="M79,142.5c-6.7,0-13.3-0.7-19.9-2.1l0.9-3.9c0.2,0,18.9,4.3,36.1,0l1,3.9C91.2,141.9,85.1,142.6,79,142.5"/>
      </svg>

    </template>

    <template
    v-if="data === 'sint'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M202.29,111.78c-2.17-13.04-6.91-27.59-15.56-38.5-.12-.23-.26-.47-.43-.7-26.77-36.69-82.47-33.79-117-10.21-12.61,8.61-19.61,20.2-22.27,34.88-2.32,12.79-3.33,26.41-2.01,39.35,6.31,61.77,99.24,90.97,139.77,41.94,16.25-19.65,21.53-42.52,17.51-66.75Z" />
      <path
        d="M155.03,162.65c-6.45-6.31-12.01-11.76-30.58-12.65-16.5-.79-22.92,5.08-28.06,11.16l-3.05-2.58c6.86-8.12,14.83-13.37,31.31-12.57,20.08,.97,26.74,7.49,33.18,13.79l-2.8,2.86Z" />
      <path
        d="M147.89,104.92c-4.34,0-7.48-1.26-7.55-1.29l1.53-3.7c.47,.19,11.63,4.59,19.62-6.18l3.21,2.38c-5.29,7.12-11.84,8.79-16.82,8.79Z" />
      <path
        d="M100.15,105.91c-10.28,0-16.19-8-16.26-8.1l3.24-2.34-1.62,1.17,1.62-1.17c.3,.41,7.5,10,19.95,5.02l1.48,3.71c-3.06,1.22-5.87,1.71-8.41,1.71Z" />
      <g>
        <path
          d="M100.39,105.55c-1.32-1.27-3.15-1.15-5.2,1.8-3.6,5.18-.87,13.28,2.72,13.05,4.22-.26,4.92-6.07,4.63-9.28-.21-2.28-.98-4.46-2.14-5.57Z" />
        <path
          d="M97.75,122.4c-1.86,0-3.58-1.19-4.77-3.29-1.87-3.33-2.28-8.83,.56-12.91,1.46-2.09,3.04-3.24,4.71-3.4,1.29-.13,2.5,.32,3.52,1.3h0c1.46,1.4,2.49,3.96,2.75,6.83,.09,1.01,.41,6.21-2.26,9.34-1.1,1.28-2.56,2.02-4.23,2.12-.09,0-.18,0-.28,0Zm.91-15.62h-.01c-.25,.03-.92,.41-1.82,1.71-1.88,2.7-1.59,6.48-.36,8.66,.48,.84,.99,1.28,1.31,1.25,.6-.04,1.05-.26,1.45-.73,1.18-1.37,1.51-4.26,1.32-6.37-.19-2.14-.91-3.71-1.54-4.31h0c-.07-.06-.23-.21-.34-.21Z" />
      </g>
      <g>
        <path
          d="M152.3,105.55c-1.32-1.27-3.15-1.15-5.2,1.8-3.6,5.18-.87,13.28,2.72,13.05,4.22-.26,4.92-6.07,4.63-9.28-.21-2.28-.98-4.46-2.14-5.57Z" />
        <path
          d="M149.67,122.4c-1.86,0-3.58-1.19-4.77-3.29-1.87-3.33-2.28-8.83,.56-12.91,1.46-2.09,3.04-3.24,4.71-3.4,1.29-.13,2.5,.32,3.52,1.3h0c1.46,1.4,2.49,3.96,2.75,6.83,.09,1.01,.41,6.21-2.26,9.34-1.1,1.28-2.56,2.02-4.23,2.12-.09,0-.18,0-.28,0Zm.91-15.62h-.01c-.25,.03-.92,.41-1.82,1.71-1.88,2.7-1.59,6.48-.36,8.66,.48,.84,1.02,1.28,1.31,1.25,.6-.04,1.05-.26,1.45-.73,1.18-1.37,1.51-4.26,1.32-6.37-.19-2.14-.91-3.71-1.54-4.31h0c-.07-.06-.23-.21-.34-.21Z" />
      </g>
    </svg>
    </template>

    <template
    v-if="data === 'lei'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M199.95,89.87c-6.18-15.41-17.12-28.13-32.19-35.08-12.39-5.91-26.82-8.19-40.17-9.49-17.58-1.71-36.33,.47-51.44,10.27-7.38,4.78-13.11,11.17-18.01,18.41-5.13,7.55-10.38,15.67-13.02,24.48-4.94,16.49-4.12,37.24,1.1,53.56,11.28,35.33,47.69,50.25,82.16,51.14,15.82,.41,33.24-1.45,46.48-10.95,14.23-10.2,21.35-27.78,25.98-44.09,5.35-18.85,6.51-39.78-.9-58.26Z" />
      <path
        d="M93.91,170.43l-1.72-3.61c7.27-3.47,15.6-5.68,32.71-5.16,20.28,.62,23.29,1.71,27.86,3.35,.43,.15,.87,.31,1.34,.48l-1.31,3.78c-.49-.17-.94-.33-1.39-.49-4.2-1.52-6.98-2.52-26.62-3.12-16.29-.49-24.12,1.55-30.86,4.77Z" />
      <path
        d="M86.53,103.52l-3.36-2.17c7.97-12.34,21.59-10.5,22.17-10.42l-.58,3.96,.29-1.98-.28,1.98c-.48-.07-11.69-1.51-18.23,8.63Z" />
      <path d="M160.57,103.63c-.28-.37-6.94-9.01-18.34-6.56l-.84-3.91c13.96-3.01,22.05,7.62,22.39,8.07l-3.2,2.39Z" />
      <g>
        <path
          d="M101.84,108.15c-1.32-1.27-3.15-1.15-5.2,1.8-3.6,5.17-.87,13.27,2.71,13.04,4.21-.26,4.92-6.07,4.63-9.27-.21-2.27-.98-4.45-2.14-5.57Z" />
        <path
          d="M99.21,124.99c-1.86,0-3.58-1.18-4.77-3.29-1.87-3.32-2.28-8.82,.56-12.9,1.45-2.09,3.04-3.23,4.71-3.4,1.28-.12,2.5,.32,3.52,1.3h0c1.46,1.4,2.49,3.95,2.75,6.83,.09,1.01,.41,6.21-2.26,9.33-1.1,1.28-2.56,2.01-4.23,2.12-.09,0-.18,0-.27,0Zm.91-15.61h-.01c-.25,.03-.92,.41-1.82,1.7-1.88,2.7-1.58,6.47-.36,8.66,.48,.85,1.05,1.23,1.3,1.25,.6-.04,1.04-.26,1.45-.73,1.18-1.37,1.51-4.26,1.31-6.37-.19-2.14-.91-3.71-1.54-4.3h0c-.07-.06-.23-.21-.34-.21Z" />
      </g>
      <g>
        <path
          d="M149.79,108.15c-1.32-1.27-3.15-1.15-5.2,1.8-3.6,5.17-.87,13.27,2.71,13.04,4.21-.26,4.92-6.07,4.63-9.27-.21-2.27-.98-4.45-2.14-5.57Z" />
        <path
          d="M147.15,124.99c-1.86,0-3.58-1.18-4.76-3.29-1.87-3.32-2.28-8.82,.56-12.9,1.46-2.09,3.04-3.24,4.71-3.4,1.28-.12,2.5,.32,3.52,1.3h0c1.46,1.4,2.49,3.96,2.75,6.83,.09,1.01,.41,6.21-2.26,9.33-1.1,1.28-2.56,2.01-4.23,2.12-.09,0-.18,0-.27,0Zm.91-15.61h-.01c-.25,.03-.92,.41-1.82,1.71-1.88,2.7-1.58,6.47-.36,8.66,.48,.85,1.04,1.23,1.3,1.25,.6-.04,1.04-.26,1.45-.73,1.18-1.37,1.51-4.26,1.31-6.37-.19-2.14-.91-3.71-1.54-4.3h0c-.07-.06-.23-.21-.34-.21Z" />
      </g>
      <path
        d="M81.48,154.28c-1.14,0-2.3-.2-3.42-.6-3.14-1.13-5.24-3.02-6.23-5.64-2.32-6.1,2.15-14.25,5.4-20.2,.74-1.35,1.38-2.52,1.83-3.47l.42-.89c1.47-3.14,2.99-6.39,6.04-5.25,.04,.01,.08,.03,.11,.05,1.8,.8,3.15,4.98,3.97,8.35,.8,3.3,1.03,4.97,1.32,7.08,.09,.63,.18,1.32,.3,2.1,.06,.41,.13,.82,.19,1.23,.44,2.71,.94,5.79,.23,8.81-.73,3.11-2.7,5.74-5.41,7.22-1.49,.81-3.11,1.22-4.75,1.22Zm2.73-31.39c-.35,.66-.76,1.52-1.11,2.28l-.43,.91c-.5,1.05-1.16,2.27-1.94,3.68-2.72,4.97-6.83,12.48-5.17,16.86,.57,1.49,1.82,2.57,3.84,3.29,2.11,.76,3.84,.22,4.91-.37,1.71-.93,2.96-2.61,3.43-4.61,.53-2.26,.13-4.69-.28-7.27-.07-.42-.14-.85-.2-1.27h0c-.12-.81-.22-1.5-.31-2.15-.29-2.08-.5-3.58-1.25-6.69-.31-1.3-.95-3.33-1.49-4.67Zm-.62-1.24h0Z" />
    </svg>
    </template>

    <template
    v-if="data === 'knust'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M129.87,167.69c-8.62-8.99-16.64-18.47-24.1-28.42-.62-.83-.68-1.97-.13-2.85,2.18-3.55,4.45-7.05,6.55-10.63,4.84-8.23,9.46-16.59,14.08-24.95,1.26-1.65,1.57-4.12-.27-6.02-3.81-3.91-9.22-5.33-13.91-7.89-2.77-1.51-5.49-3.09-8.22-4.69-1.27-.75-1.64-2.42-.8-3.62,.82-1.18,1.65-2.36,2.5-3.52,1.47-2.02,2.93-4.49,4.75-6.28,2.79-3.79,5.28-6.18,1.24-8.27-12.34-6.4-25.63-12.77-39.91-10.42-11.53,1.9-22.19,8.39-28.92,17.99-3.72,5.31-5.72,11.34-7.62,17.49-2.24,7.23-3.59,14.39-2.79,21.99,1.14,10.87,4.03,22.09,10.17,31.38,6.41,9.7,14.83,19.38,23.45,27.17,7.77,7.03,16.78,12.84,25.2,19.05,8.57,6.32,17.71,11.82,26.27,18.11,2.47,1.81,6.65,1.16,7.2-2.37,1.5-9.69,3.63-19.24,6.4-28.66,.2-.69,.24-1.31,.15-1.88-.08-.93-.46-1.87-1.26-2.7Z" />
      <path class="fill-current"
        d="M207.24,61.86c-8.45-12.83-23.12-21.8-38.71-21.7-8.28,.05-16.68,2.67-23.45,7.44-3.52,2.49-6.58,5.44-9.14,8.92-1.98,2.69-3.24,5.17-5.67,10.04-2.11,4.23-4.06,5.88-.73,7.9,.07,.05,.13,.09,.19,.14,5.5,3.61,11.02,7.17,16.56,10.71,1.22,.78,1.56,2.4,.76,3.61-7.92,11.98-15.19,24.37-21.77,37.14-1.67,3.03-1.45,3.7,.14,5.52,7.08,8.07,13.52,16.62,19.37,25.62,.61,.94,.77,2.11,.44,3.19-1.36,4.43-2.04,8.34-4.25,13.14-1.05,2.27-1.9,4.63-2.41,7.08-.71,3.44-3.28,8.11-.99,9.25,2.18,1.09,3.17-.33,5-1.63,2.13-1.52,3.86-3.5,5.84-5.19,2.17-1.85,4.47-3.57,6.65-5.41,3.9-3.29,7.76-6.63,11.57-10.02,6.41-5.69,12.71-11.51,18.87-17.47,12.42-12.02,23.66-24.72,28.27-41.8,4.18-15.49,2.37-32.95-6.55-46.5Z" />
      <path
        d="M123.96,207.73l-3.88-.96,8.87-35.86-26.67-31.05c-.55-.64-.64-1.56-.22-2.3l22.8-39.72-24.49-15.89c-.45-.29-.77-.76-.87-1.28-.11-.53,0-1.08,.31-1.52l13.75-20.15,3.3,2.26-12.6,18.45,24.36,15.8c.89,.58,1.17,1.75,.65,2.67l-23.04,40.13,26.42,30.76c.42,.49,.58,1.16,.42,1.78l-9.12,36.86Z" />
    </svg>
    </template>

    <template
    v-if="data === 'hjerte'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M199.01,68.88c-1.66-9.56-7.87-17.17-15.24-23.13-13.97-11.27-32.81-16.57-50.28-10.99-9.3,2.97-17.09,8.56-23.02,16.28-5.54,7.23-9.25,15.68-14.5,23.08-13.47-9.62-28.91-15.54-45.19-7.04-17.18,8.97-22.1,29.2-16.48,46.71,5.78,17.96,17.84,34.55,32.14,46.69,16.08,13.67,35.76,23.26,53.92,33.85,3.92,2.28,4.44,.76,6.89-1.42,24.76-29.3,65.32-52.94,71.33-94.02,1.42-9.67,2.12-20.3,.44-29.99Z" />
      <path
        d="M174.46,215.96l-3.2-2.39,13.81-18.48c1.23-1.72,2.48-3.53,3.8-5.51,9.02-13.53,29.17-47.01,21.56-65.67-2.57-6.29-8.02-10.24-16.67-12.07-11.41-2.42-21.64,2.8-28.07,14.29-1.02,1.82-5.08,10.17-6.32,17.7-.12,.74-.64,1.34-1.35,1.58-.71,.23-1.49,.05-2.02-.47-5.49-5.39-12.98-8.73-19.6-8.73-.05,0-.11,0-.16,0-7.16,.06-13.09,3.57-16.28,9.62-3.6,6.84-3.03,15.56,1.45,22.21,4.45,6.6,10.94,11.99,17.21,17.2l1.63,1.36c3.1,2.59,8.37,6.72,13.47,10.72,2.19,1.72,4.34,3.4,6.24,4.91l-2.48,3.14c-1.9-1.5-4.04-3.18-6.23-4.9-5.12-4.02-10.42-8.17-13.57-10.8l-1.62-1.35c-6.49-5.39-13.2-10.97-17.98-18.04-5.3-7.85-5.95-18.18-1.67-26.31,3.9-7.4,11.11-11.68,19.79-11.76,.06,0,.13,0,.19,0,6.59,0,13.88,2.82,19.75,7.52,1.72-6.87,4.9-13.5,6.05-15.55,7.23-12.94,19.34-19.02,32.39-16.26,9.91,2.1,16.49,6.96,19.55,14.47,8.33,20.44-12.56,55.34-21.93,69.4-1.34,2.02-2.62,3.87-3.9,5.65l-13.84,18.52Z" />
    </svg>
    </template>

    <template
    v-if="data === 'tog'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M217.87,155.8c-1.98-.82-3.22-2.82-3.27-4.96-.19-10.26-.24-20.62-1.26-30.76-1.05-10.46-8.29-17.64-18.12-20.68-.42-.13-.84-.26-1.27-.37-1.55-.42-2.62-1.86-2.64-3.47-.08-6.81-.1-13.61-.06-20.41l1.75-.03c5.84-.17,5.84-9.19-1.66-9.41-11.07,.21-28.3-.29-39.37-.08-7.55,.19-6.52,10.58-.64,10l.1,.09c0,4.83-.02,9.66,0,14.49,0,2.4-.24,1.18,.01,3.57,0,.08,.04,.59,.08,1.24,.1,1.6-1.17,2.95-2.77,2.94-6.78-.07-13.59,.39-20.36,.23-1.32-.03-2.38-1.09-2.44-2.41-.3-6.53-.44-13.7-.5-20.23-.01-1.31,.92-2.44,2.2-2.69,.14-.03,.24-.05,.25-.05,5.94-.66,8.56-5.05,6.83-11.33-1.8-6.58-8.47-8.57-14.56-8.58-8.77,0-17.56,.48-26.33,.56-17.89,.16-35.78-.2-53.66-.9-4.11-.17-8.34-.37-11.74,2.34-2.77,2.2-4.18,5.69-3.25,9.2,1.19,4.51,4.76,6.41,8.88,7.13,1.03,.18,1.8,1.04,1.81,2.09,.1,13.17,.17,26.35,.19,39.51,.02,12.55,.43,25.28-.93,37.79-.16,1.44-1.19,2.63-2.58,3.03-.48,.14-.96,.3-1.42,.47-5.17,2-6.81,7.99-2.97,12.5,3.76,4.41,12.2,2.54,17.18,2.33,10.98-.48,21.94,.89,32.92,.61,39.41-1.02,78.81,1.06,118.22,.5,2.63-.04,5.27-.08,7.9-.17,6.1-.2,15.53,1.42,17.89-3.9,2.25-5.06-.37-8.52-4.4-10.19Z" />
      <path
        d="M141.48,194.67c-3.09,0-6.23-.67-9.14-2.04-5.62-2.67-9.65-8.46-10.27-14.76-.21-2.11,0-4.29,.61-6.49h0c1.86-6.71,6.92-11.85,12.89-13.1,2.69-.56,5.73-.39,9.29,.54,7.01,1.82,12.8,7.26,14.74,13.85,1.7,5.77,.31,11.49-3.93,16.12-3.52,3.83-8.77,5.88-14.19,5.88Zm-14.95-22.22c-.47,1.69-.64,3.43-.48,5.02,.48,4.86,3.7,9.49,8.01,11.54,6.35,3.02,14.37,1.76,18.66-2.93,3.25-3.55,4.33-7.91,3.04-12.28-1.56-5.27-6.23-9.64-11.91-11.12-2.94-.77-5.38-.93-7.46-.49-4.45,.93-8.41,5.05-9.86,10.25h0Z" />
      <path
        d="M184.01,194.66c-6.7,0-12.51-3.03-16.08-8.56-4.11-6.39-3.99-14.38,.3-20.37h0c.91-1.28,2.05-2.47,3.38-3.55,5.91-4.8,14.75-5.1,21.99-.74,6.93,4.18,10.35,11.26,8.91,18.49-1.02,5.15-3.57,12.22-13.87,14.26-1.58,.31-3.14,.47-4.65,.47Zm-1.35-32.31c-2.96,0-5.96,.85-8.52,2.93-1.06,.86-1.95,1.79-2.66,2.78h0c-3.33,4.65-3.41,10.88-.19,15.88,3.44,5.35,9.64,7.71,16.58,6.34,7.81-1.55,9.81-6.49,10.72-11.12,1.31-6.61-2.78-11.72-7.05-14.29-2.51-1.51-5.68-2.51-8.89-2.51Z" />
      <path
        d="M72.87,194.66c-3.34,0-6.79-.63-10.18-1.91-10.65-4.02-17.74-13.36-18.06-23.77h0c-.37-12.17,6.97-22.21,18.72-25.59,9.96-2.87,23.25-.22,30.48,12.13,5.83,9.97,4.62,23.47-2.82,31.4-4.75,5.07-11.23,7.74-18.13,7.74Zm-24.25-25.81c.27,8.79,6.35,16.7,15.48,20.15,6.94,2.62,16.89,2.74,23.98-4.82,6.17-6.58,7.18-18.29,2.29-26.65-6.56-11.22-18.06-12.57-25.92-10.31-7.54,2.17-16.21,9.13-15.83,21.62h0Z" />
      <path d="M97.48,124.03H56.64l.55-49.39h40.29v49.39Zm-36.79-4h32.79v-41.39H61.15l-.46,41.39Z" />
    </svg>
    </template>

    <template
    v-if="data === 'utropstegn'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M131.9,178.37c-11.37-10.02-29.67-6.12-35.39,8.08-2.81,6.98-1.9,14.73,2.3,20.95,8.01,11.83,25.06,15.83,35.25,4,8.22-9.54,7.34-24.65-2.17-33.02Z" />
      <path class="fill-current"
        d="M160.56,38.55c-3.6-10.73-29.15-7.88-40.48-7.81-17.59,.11-33.73-4.72-37.26,4.36-2.68,6.9-.35,20.34,2.24,28.79,2,6.55,4.37,12.98,5.79,19.67,1.46,6.84,2.44,13.79,3.59,20.67,1.59,9.56,2.58,19.18,4.12,28.75,1.22,7.6,4.46,19.32,10.57,25.23,3.32,3.21,6.37,2.86,10.85,2.86,7.98,.01,10.47-12.78,13.3-21.45,7.87-24.12,20.59-48.99,26.6-73.58,2.37-9.68,3.15-20.12,.68-27.5Z" />
      <rect x="89.02" y="95.26" width="98.61" height="4" transform="translate(6.34 202.96) rotate(-73.8)" />
      <path
        d="M115.07,218.96c-2.49,0-5.11-.42-7.74-1.3-8.5-2.84-17.6-11.14-15.27-25.18,1.21-7.32,4.39-12.76,9.46-16.17,8.71-5.87,19.75-3.49,20.21-3.38l-.88,3.9,.44-1.95-.43,1.95c-.1-.02-9.86-2.1-17.13,2.81-4.1,2.78-6.7,7.32-7.73,13.49-1.91,11.56,5.59,18.39,12.6,20.73,6.36,2.12,14.77,1.4,18.21-5.2l3.55,1.85c-2.83,5.42-8.61,8.44-15.28,8.44Z" />
    </svg>
    </template>

    <template
    v-if="data === 'tre'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M215.76,113.4c-2.98-7.89-6.62-15.84-13.48-21.07-1.78-1.37-3.67-2.58-5.64-3.66-2.62-1.44-4.58-3.85-5.28-6.75-1.34-5.59-4.31-10.73-9.14-14.56-3.98-3.17-8.44-4.64-13.08-5.05-4.35-.39-8-3.4-9.19-7.61-1.16-4.1-2.87-8.04-5.45-11.7-9.79-13.92-25.78-19.24-42.24-18.56s-31.8,12.58-38.48,27.68c-1.3,2.93-3.87,5.14-7,5.87-5.52,1.28-10.49,4.45-13.65,9.67-3.11,5.16-3.29,10.02-1.98,14.68,1.23,4.37-.63,9.05-4.41,11.57-3.9,2.6-7.39,5.76-10.23,9.67-8.56,11.77-8.73,28.32-.27,40.2,8.49,11.91,22.51,15.91,36.38,17.48,7.56,.86,15.73,1.76,22.96-1.14,7.76-3.13,13.51-5.55,22.34-5.89,6.45,1.8,11.78,5.81,17.13,9.02,7.52,4.52,16.19,6.48,24.84,7.28,16.5,1.53,33.29-.47,45.15-13.09,10.71-11.4,16.4-28.97,10.72-44.04Z" />
      <path
        d="M123.57,223.29h-4V106.31c-.13-1.72-1.27-22.12,13.71-40.5l3.1,2.53c-14.26,17.5-12.83,37.53-12.81,37.73v117.22Z" />
      <path d="M119.58,106.37c-1.71-23.96-17.91-30.73-18.59-31l1.47-3.72c.78,.31,19.22,7.87,21.11,34.44l-3.99,.29Z" />
      <path
        d="M119.58,167.52c-.02-.19-2.16-19.53-17.47-30.69-10.86-7.92-25.7-9.96-44.12-6.07l-.83-3.91c19.6-4.14,35.53-1.86,47.34,6.78,16.75,12.25,18.96,32.64,19.05,33.5l-3.98,.4Z" />
      <path d="M85.98,130.16c-.39-.42-9.53-10.35-9.11-21.28l4,.15c-.36,9.24,7.96,18.31,8.04,18.4l-2.93,2.73Z" />
      <path d="M122.33,158.13l-1.52-3.7c.5-.21,50.12-20.69,62.72-32.24l2.7,2.95c-13.12,12.03-61.83,32.14-63.9,32.99Z" />
      <path d="M159.17,141.26l-3.08-2.55c.08-.1,8.22-10.13,7.48-23.7l3.99-.22c.82,15.18-8.02,26.02-8.4,26.47Z" />
      <path d="M122.27,120.75l-1.39-3.75c.09-.03,9.04-3.42,12.18-9.35l3.54,1.87c-3.86,7.29-13.91,11.07-14.33,11.23Z" />
    </svg>
    </template>

    <template
    v-if="data === 'tommel'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M198.65,162.65c-.28-1.76-1.09-3.84-2.25-5.88-.91-1.61-.99-3.59-.16-5.24,3.17-6.3,2.69-14.91-.15-21.32-.42-.95-.89-1.93-1.41-2.89-.87-1.61-.88-3.53-.1-5.17,1.2-2.5,1.91-5.28,2.01-8.3,.34-9.8-6.11-17.68-14.78-21.43-9.25-3.99-20.21-5.03-30.16-4.06-4.37,.43-12.81,2.72-19.71,4.09-3.91,.78-9.65,2.65-8.9-1.26,3.03-15.78,13.15-36.54,5.96-51.03-3.37-6.77-5.96-7.97-15.29-7.4-10.21,.61-14.68,16.88-16.42,28.49-2.13,14.2-10.68,35.74-17.51,48.58-6.32,11.88-19.55,22.41-25.29,34.63-6.43,13.66-10.84,24.88-5.06,38.31,3.05,7.07,10.46,14,16.2,19.11,6.89,6.13,16.35,7.16,25.17,7.69,13.51,.81,27.2,3.08,40.7,3.23,9.07,.1,17.25,1.86,26.29,1.1,7.95-.67,16.39-1.12,23.78-4.37,6.17-2.7,11.7-7.89,13.27-14.64,.96-4.14,.37-8.14-1.08-11.89-.74-1.9-.47-4.07,.82-5.64,3.29-4,4.97-9.25,4.07-14.71Z" />
      <path
        d="M155.69,126.78c-.96,0-1.95,0-2.98-.01-2.65-.02-9.34-.44-14.11-3.78-3.95-2.77-7.18-8.02-5.5-13.52,1.42-4.62,6.26-7.15,10.32-7.99,6.91-1.42,12.9-1.78,18.7-2.14,2.06-.12,4.18-.25,6.3-.43l.33,3.99c-2.16,.18-4.31,.31-6.39,.43-5.66,.34-11.5,.7-18.14,2.06-2.8,.58-6.4,2.3-7.3,5.24-1.09,3.56,1.29,7.2,3.97,9.07,2.67,1.87,7.1,3.02,11.85,3.06,13.4,.13,20.48-.57,33.33-1.81l8.31-.81,.39,3.98-8.31,.81c-12,1.16-19.02,1.85-30.77,1.85Z" />
      <path
        d="M196.47,152.91c-5.48-.16-53.65-1.61-59.15-3.24-3.81-1.13-6.83-3.73-8.28-7.12-1.39-3.26-1.21-6.9,.51-10.25,2.91-5.65,10.28-6.65,15.66-7.38,.9-.12,1.76-.24,2.56-.37,4.41-.73,10.75-1.5,15.4-1.87l.31,3.99c-4.48,.35-10.81,1.12-15.06,1.83-.83,.14-1.73,.26-2.67,.39-4.76,.65-10.69,1.45-12.64,5.24-1.17,2.27-1.31,4.7-.39,6.85,.98,2.3,3.07,4.07,5.74,4.86,3.78,1.12,36.84,2.46,58.13,3.08l-.12,4Z" />
      <path
        d="M194.49,180.58c-4.92-.21-48.25-2.27-55.09-8.28-6.18-5.42-6.87-10.85-2.13-16.59,3.49-4.22,24.48-7.04,30.83-7.8l.48,3.97c-15.74,1.89-26.9,4.78-28.22,6.38-2.75,3.33-3.51,6.47,1.68,11.04,4.36,3.83,34.59,6.5,52.63,7.29l-.17,4Z" />
      <path
        d="M186.77,199.79c-.11-.01-10.94-1.06-23.69-2.61-9.45-1.15-12.77-5.51-13.9-8-1.36-2.99-1.09-6.36,.71-9,3.34-4.91,12.86-5.89,13.94-5.99l.36,3.98c-3.33,.3-9.26,1.72-10.99,4.25-1.01,1.48-1.15,3.39-.37,5.1,.79,1.73,3.23,4.77,10.74,5.68,12.7,1.54,23.49,2.59,23.59,2.6l-.38,3.98Z" />
    </svg>
    </template>

    <template
    v-if="data === 'flaske'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M196.01,27.21c-.2-.06-.39-.11-.58-.17-4.65-2.1-9.66-.07-13.24,3.61-4.43,4.57-6,10.76-9.82,15.71-3.68,4.76-10.94,.18-16.1,.62-5.08,.44-9.1,3.34-12.6,6.78-4.95-2.86-10.68-4.56-15.83-.86-5.11,3.68-9.07,10.2-7.02,16.49,.27,.83,.57,1.64,.89,2.46-2.01,.08-4,.36-5.95,.96-6.21,1.92-9.76,6.79-13.59,11.69-8.26,10.58-16.46,21.23-24.57,31.92-8.11,10.7-16.15,21.46-24.1,32.27-3.14,4.26-6.88,8.61-8.45,13.75-1.89,6.17,.32,11.93,3.61,17.17,7.86,12.5,19.24,22.38,31.9,29.81,11.38,6.68,27.78,15.96,41.3,10.97,3.72-1.37,6.91-3.82,9.48-6.81,8.44-9.79,15.3-21.41,22.37-32.21,8.04-12.28,15.81-24.73,23.52-37.22,3.3-5.33,8.47-11.42,10.04-17.59,.87-3.43,.34-7.22-.69-10.9,2.77-.03,5.44-.51,7.75-1.84,5.35-3.05,10-11.25,8.86-17.43-.65-3.51-3.05-6.43-5.95-8.97,1.11-3.37,1.87-6.88,1.32-10.4-.69-4.47-3.35-7.15-6.49-10.1-4.23-3.97-2.62-7.5,1.14-11.31,3.02-3.06,6.39-5.75,8.52-9.57,3.67-6.59,2.33-16.36-5.71-18.83Z" />
      <path d="M188.62,118.81c-51.32-14.7-70.39-47.37-71.17-48.76l3.48-1.98c.19,.33,19.14,32.67,68.8,46.89l-1.1,3.85Z" />
      <path d="M196.92,89.4c-27.95-4.31-44.96-23.69-45.67-24.51l3.03-2.61c.17,.19,16.8,19.1,43.25,23.17l-.61,3.95Z" />
      <rect x="43.81" y="130.43" width="97.07" height="4" transform="translate(-68.98 126.06) rotate(-52.85)" />
      <rect x="76.75" y="143.84" width="4" height="15.29" transform="translate(-88.94 119.38) rotate(-51.69)" />
      <rect x="91.2" y="123.26" width="4" height="15.09" transform="translate(-67.41 129.47) rotate(-54.05)" />
      <rect x="106.54" y="103.27" width="4" height="14.62" transform="translate(-44.76 133.14) rotate(-53.9)" />
    </svg>
    </template>

    <template
    v-if="data === 'tanke'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M222.22,114.14c-.68-2.33-1.7-4.66-2.95-6.73-.79-1.31-4.04-4.48-3.51-6.16,.57-1.74,2.52-2.4,3.76-3.53,1.42-1.29,2.28-2.9,3.01-4.69,3.11-7.62,3.64-17.67,1.23-25.52-2.25-7.36-7.41-14.18-14.57-17.12-8.75-3.6-17.29-4.43-25.64-2.3-1.41,.36-2.88-.29-3.55-1.61-4.86-9.41-13-17.17-23.7-18.73-9.06-1.32-18.1,2.17-23.72,9.03-.92,1.13-2.52,1.42-3.8,.74-13.88-7.38-29.55-13.47-44.89-7.4-6.86,2.71-12.93,7.9-16.1,14.74-.98,2.11-1.66,4.56-1.89,6.88-.11,1.06-.25,2.17-.83,3.05-2.07,3.11-9.01-.18-11.83-.45-8.23-.79-16.5,1.13-22.17,7.6-5.02,5.71-7.32,13.32-5.89,20.89,.79,4.17,2.74,8.14,5,11.67,.48,.76,.99,1.51,1.51,2.24,.97,1.38,.71,3.32-.64,4.37-5.95,4.63-9.3,12.44-9.05,20.03,.33,9.38,5.5,17.96,12.71,23.58,4.01,3.13,8.71,4.83,13.11,4.42,1.44-.13,2.8,.72,3.26,2.12,1.95,5.94,5.05,11.45,10.64,14.49,9.17,4.99,17.8,.43,26.24-3.64,1.47-.71,3.25-.16,4.04,1.28,4.7,8.58,14.12,14.11,23.78,15.24,12.7,1.48,24.55-4.8,34.05-13.03,1.05-.91,2.56-.98,3.72-.23,8.81,5.74,20.08,7.98,30.05,4.25,10.86-4.05,12.19-13.48,10.01-22.82-.47-2.01,.99-3.97,3.03-3.97,6.06,0,12.37,1.44,18.02-2.02,8.53-5.24,10.2-17.68,7.57-26.69Z" />
      <path
        d="M165.64,206.49c-1.98,0-3.81-.58-5.38-1.73-3.55-2.6-5.26-7.78-4.7-14.2,.84-9.5,8.11-13.1,11.83-13.67h.04c7.01-.93,15.73,3.35,18.17,12.08,2.55,9.12-7.31,13.21-14.51,16.2l-.79,.33c-1.6,.67-3.18,1-4.67,1Zm2.35-25.66c-.54,.09-7.69,1.52-8.45,10.07-.43,4.93,.71,8.9,3.07,10.63,2.04,1.49,4.47,.97,6.16,.27l.8-.33c8.07-3.35,13.67-6.13,12.19-11.43-1.86-6.63-8.45-9.88-13.77-9.2Z" />
      <path
        d="M187.49,222.51c-1.52,0-3.02-.39-4.28-1.17-2.36-1.47-3.66-4.05-3.66-7.28,0-5.8,4.83-7.96,8.19-8.03l.37-.06c2.08-.33,6.95-1.12,8.97,3.22,2.05,4.39-1.41,10.29-5.75,12.44-1.2,.59-2.53,.89-3.84,.89Zm.43-12.49c-.73,0-4.36,.25-4.36,4.03,0,1.82,.61,3.17,1.77,3.89,1.17,.73,2.88,.77,4.23,.1,2.8-1.39,4.85-5.13,3.9-7.16-.62-1.34-2.2-1.37-4.71-.96-.21,.03-.4,.06-.57,.09-.09,.01-.18,.02-.27,.02Z" />
    </svg>
    </template>

    <template
    v-if="data === 'hjelp'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M102.89,184.04c-11.14,7.43-11.42,22.6-3.65,31.82,1.92,2.87,4.7,5.22,8.25,6.55,13.52,5.07,28.08-5.83,27.95-20.08-.14-15.84-18.77-27.48-32.55-18.29Z" />
      <path class="fill-current"
        d="M173.65,45.86c-6.28-7.39-14.81-12.38-23.73-16.33-9.86-4.36-19.26-5.19-29.98-5.2-10.54,0-22.14-.16-32.23,3.21-8.27,2.76-16.37,9.22-22.2,15.41-7.13,7.57-8.9,16.83-9.87,26.73-.79,8.12-5.48,27.39,3.42,29.37,3.45,.77,17.28,2.51,23.18,1.5,4.72-.8,4.94-6.42,5.52-10.51,.77-5.33,.84-10.81,2.24-16.04,1.04-3.89,2.97-8.08,6.59-10.35,4.23-2.64,10.5-2.36,15.35-2.2,9.56,.32,19.07,2.86,27.45,7.29,4.59,2.43,7.2,4.74,10.65,8.59,3.95,4.4,2.91,11.93,1.39,17.26-2.56,9.02-3.96,17.98-12.35,23.82-5.12,3.56-11.36,5.12-17.14,7.53-6.32,2.63-11.81,6.58-13.91,13.2-2.23,7.04-1.81,14.59-1.64,21.86,.05,2.19,3.9,3.92,6.67,4.96,4.76,1.8,8.36,3.06,14.76,3.11,3.56,.02,3.97-5.91,5.01-9.32,1.91-6.3,4.27-13.09,10.71-16.12,3.46-1.63,7.3-2.35,10.92-3.59,17.32-5.96,27.08-23.61,30.69-40.26,4.04-18.67,1.32-38.85-11.5-53.92Z" />
      <path
        d="M111.66,224.04c-4.05,0-8.64-1.06-12.46-3.6-3.94-2.62-8.64-7.91-8.68-18.2-.07-17.21,11.77-22.86,18.04-23.44l.38,3.98c-.59,.06-14.49,1.66-14.42,19.44,.03,6.85,2.35,11.86,6.9,14.88,5.34,3.55,12.37,3.33,15.45,2.06l1.53,3.7c-1.8,.75-4.16,1.17-6.73,1.17Z" />
      <path
        d="M161.56,129.48l-2.57-3.07,1.28,1.53-1.28-1.53c.21-.18,21.34-18.26,19.06-45.94-2.41-29.26-27.58-43.45-42.88-45.52l.54-3.96c16.53,2.24,43.73,17.56,46.33,49.15,2.45,29.79-19.55,48.55-20.48,49.33Z" />
    </svg>
    </template>

    <template
    v-if="data === 'spire'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M220.82,88.75c-11.31-1.26-22.71-3.06-34.1-1.74-10.86,1.26-19.8,5.28-28.37,11.96-8.6,6.72-14.28,14.17-19.36,23.81-4.16,7.88-6.84,16.3-4.52,24.69-1.59,1.98-3.15,3.96-4.69,5.93-2.37,3.03-4.67,6.09-6.79,9.28-.49-6.94-1-13.88-1.51-20.81-.67-9.05-1.98-17.63-5.14-26.18-3.27-8.82-10.14-16.57-16.92-22.9-.04-.29-.11-.57-.2-.85,1.12-18.54,.36-38.48-16.01-50.47-15.63-11.46-37.94-15.19-56.87-15.47-2.46-.03-5.32,2.33-5,5,1.39,11.63,1.49,23.8,6.01,34.79,5.93,14.41,19.85,25.39,34.69,29.31,9.23,2.44,19.22,3,28.75,3.31,7.2,6.49,13.93,14,17.1,23.26,4.16,12.17,2.28,26.89,1.4,39.49-.96,13.81-3.16,26.49-5.73,40.1-.65,3.44-1.86,6.74-2.79,10.11-.62,2.23-2.68,6.66-.28,8.54,1.74,1.37,5.73,1.02,7.81,1.12,3.59,.17,9.69,.98,12.72-1.48,2.85-2.31,1.36-8.49,1.49-11.6,.4-9.74-.41-21.25,3.54-30.38,2.42-5.58,5.87-10.59,9.52-15.43,1.92-2.55,3.89-5.19,5.96-7.81,.09-.03,.19-.06,.28-.1,5.22,3.9,11.64,5.83,18.48,5.71,10.26-.17,22.49-4.23,31.11-9.82,8.4-5.45,14.3-15.8,19.71-23.99,6.35-9.59,11.67-19.86,14.53-31.05,.88-3.42-1.6-5.97-4.82-6.33Z" />
      <path d="M89.49,93.76c-.11-.23-11.18-22.8-46.79-45.53l2.15-3.37c36.68,23.41,47.8,46.22,48.25,47.18l-3.61,1.72Z" />
      <path d="M141.62,148.2l-3.27-2.31c.94-1.33,23.49-32.66,65.38-44.01l1.05,3.86c-40.52,10.98-62.94,42.15-63.16,42.46Z" />
    </svg>
    </template>

    <template
    v-if="data === 'sommerfulg'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M215.14,107.7c-5.07-4.65-11.28-4.34-17.63-3.05-11.4,2.32-22.88,4.27-34.22,6.9-11.21,2.6-21.61,6.49-32.36,10.45-4.19,1.54-8.26,3.04-12.75,3.4-6.16,.48-5.83-9.25-6.97-13.65-1.72-6.63-3.79-13.18-6.17-19.6-3.61-9.72-7.96-19.17-13.02-28.22-6.98-12.5-16.51-34.15-34.08-32.78-7.46,.58-11.11,6.42-12.82,13.08-4.52,17.67-6.54,36.41-6.2,54.64,.11,5.88,.88,11.91,3.39,17.29,1.23,2.63,2.86,5.06,4.89,7.14,2.27,2.33,7.45,3.56,5.6,7.76-3.82,8.68-14.35,10.36-20.45,16.83-5.45,5.77-4.08,13.66-1.33,20.35,6.08,14.77,19.96,21.69,35.33,16.29,3.51-1.22,6.86-2.9,10.06-4.76,2.09-1.22,9.95-9.49,11.67-4.43,.31,.91,.21,1.91,.14,2.87-.9,12.11,2.32,24.62,12.38,32.17,8.43,6.32,19.81,8.15,29.86,5.12,7.58-2.29,16.84-7.5,20.2-15.06,1.54-3.47,1.41-7.23,.95-10.91-.26-2.06-2-6.87-.32-8.6,1.97-2.03,4.97-1.9,7.53-2.5,18.02-4.24,33.33-20.16,45.08-33.57,5.13-5.86,10.92-12.4,13.97-19.62,2.47-5.86,2.21-12.99-2.73-17.52Z" />
      <path d="M83.59,185.1l-3.58-1.78c1.07-2.16,26.41-52.96,36.76-65.89l3.12,2.5c-10.08,12.6-36.04,64.65-36.3,65.17Z" />
      <path
        d="M128.61,110.31c-1.61,0-3.23-.39-4.7-1.42-2.87-2.02-3.93-5.42-4.59-8.49-4.16-19.31-1.53-43.2,6.69-60.88l3.63,1.69c-7.75,16.68-10.33,40.13-6.4,58.35,.5,2.31,1.24,4.83,2.99,6.06,2.4,1.69,6.15,.06,9.51-1.75,16.35-8.84,28.45-15.95,45.51-28.19l2.33,3.25c-17.24,12.36-29.44,19.54-45.94,28.46-1.85,1-5.42,2.93-9.01,2.93Z" />
    </svg>
    </template>

    <template
    v-if="data === 'sol'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M169.81,78.59c-4.36-4.98-9.62-9.18-15.53-12.24-18.01-9.33-40.7-6.73-58.74,1.18-25.77,11.3-38.55,41.99-36.82,67.52,.99,14.64,8.5,25.87,20.67,34.74,11.47,8.36,24.99,14.67,39.45,16.64,13.68,1.86,27.56-.65,38.62-8.68,10.44-7.58,17.03-18.7,21.07-30.33,4.5-12.96,6.46-27.33,4.38-40.96-1.56-10.25-6.24-20.01-13.12-27.86Z" />
      <rect x="210.89" y="130.01" width="4" height="32.63" transform="translate(-10.27 276.45) rotate(-64.75)" />
      <rect x="190.11" y="175.24" width="4" height="28.58" transform="translate(-77.07 170.37) rotate(-40.54)" />
      <rect x="142.51" y="198.43" width="4" height="30.95" transform="translate(-29.28 23.03) rotate(-8.28)" />
      <rect x="73.7" y="205.28" width="30.57" height="4" transform="translate(-136.28 218.99) rotate(-69.74)" />
      <rect x="31.69" y="174.87" width="27.98" height="4" transform="translate(-84.94 49.17) rotate(-31.17)" />
      <rect x="19.65" y="121.96" width="28.12" height="4" />
      <rect x="44.73" y="62.57" width="4" height="30.35" transform="translate(-43.98 77.88) rotate(-59.08)" />
      <rect x="78.91" y="31.87" width="4" height="24.91" transform="translate(-11.28 40.86) rotate(-26.56)" />
      <rect x="116.2" y="32.86" width="26.88" height="4" transform="translate(81.55 160.19) rotate(-84.06)" />
      <rect x="166.86" y="49.29" width="31.92" height="4" transform="translate(13.93 138.06) rotate(-42.86)" />
      <rect x="194.36" y="94.12" width="32.61" height="4" transform="translate(-11.16 29.7) rotate(-7.86)" />
    </svg>
    </template>

    <template
    v-if="data === 'snakke'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M221.88,98.5c-.21-17.79-3.35-32.84-19.81-45.21-13.41-10.09-27.54-13.76-43.96-15.02-19.83-1.55-39.77-2.38-59.66-1.43-19.03,.91-41.64,3.85-56.28,17.22-20.74,18.93-18.24,54.77-12.94,79.62,3.2,15.06,13.89,28.76,27.99,35.12,17.57,7.93,38.35,6.86,57.15,8.05,5.25,.32,10.52,.45,15.78,.35,2.63-.04,5.27-.14,7.89-.3,1.87-.12,5.21-.95,6.47,.3,1.05,1.05,1.5,3.93,2.1,5.29,.81,1.79,1.7,3.54,2.74,5.22,2.09,3.4,4.68,6.59,7.6,9.4,13.21,12.75,33.69,18.04,51.43,11.93,3.81-1.31,1.78-3.08-1.02-4.14-5.25-2-12.71-11.51-14.86-16.05-1.9-4.02-7.73-12.22-3.23-15,.94-.58,2.41-.6,3.46-.75,1.96-.28,3.93-.49,5.9-.77,22.6-3.13,23.68-35.05,23.86-52.07,.08-7.25-.5-14.49-.59-21.75Z" />
      <path
        d="M122.29,172.55c-51.06-.95-67.57-8.69-80.17-22.65-12.22-13.54-10.17-46.3-10.07-47.69l3.99,.27c-.02,.32-2.03,32.46,9.05,44.74,9.99,11.07,22.7,20.32,77.27,21.33l-.07,4Z" />
      <path
        d="M208.42,89.22c-3.63-15.16-13.1-25.99-28.14-32.19-11.38-4.69-21.85-4.84-21.96-4.84l.03-4c.44,0,44.55,.79,53.96,40.1l-3.89,.93Z" />
    </svg>
    </template>

    <template
    v-if="data === 'smokk'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M182.34,41.6c-15.03-6.58-34.16-5.86-44.05,8.85-2.89,4.29-5.22,9.2-7.08,14.02-2.14,5.55-2.1,11.5-4.6,16.95-1.09,2.37-1.99,5.52-3.94,7.35-4.01,3.77-5.22-1.38-7.06-4.64-4.12-7.32-10.2-14.08-15.4-20.74-5.49-7.02-12.11-14.79-21.43-15.88-7.94-.93-15.36,3.22-16.9,11.41-.99,5.26,.62,10.22,2.66,15.01,3.17,7.45,6.11,15.11,10.63,21.84,1.74,2.59,3.47,5.29,4.04,8.36,1.28,6.86-4.1,9.71-9.66,11.53-8.62,2.81-14.84,7.24-21.11,13.76-6.1,6.36-10.12,13.38-11,22.25-.85,8.43,.96,17.35,4.04,25.2,5.97,15.21,17.57,27.69,33.23,33.03,7.79,2.66,16.38,3.33,24.45,1.62,8.46-1.78,14.26-6.97,19.26-13.76,2.84-3.85,5.72-7.84,7.82-12.15,1.02-2.11,1.89-4.27,2.5-6.52,.61-2.29,.6-5.07,2.5-6.71,4.93-4.25,11.22-.43,15.04,3.09,4.48,4.12,9.39,7.87,14.99,10.25,8.84,3.76,23.13,4.33,27.99-5.88,3.9-8.18-.19-18.57-5.55-25.06-5.08-6.15-11.06-11.99-16.64-17.69-3.82-3.89-8.85-7.3-11.25-12.35-.29-.62-.52-1.43-.06-1.94,.31-.34,.82-.4,1.28-.42,6.42-.18,12.17,.69,18.67-.68,18.17-3.83,29.94-17.66,32.67-35.73,2.92-19.32-8.48-36.69-26.02-44.36ZM103.26,182.34c-14.26,17.1-41.94,3.95-45.05-15.99-.94-6.04,.48-11.81,3.37-17.11,2.04-3.74,4.9-9.94,9.62-10.72,5.61-.92,6.33,6.82,8.31,10.39,2.55,4.6,6.01,8.7,10.03,12.09,2.05,1.73,4.26,3.28,6.59,4.62,1.8,1.04,6.1,1.99,7.37,3.44,1.41,1.62,2.65,3.5,3.04,5.63,.58,3.15-1.36,5.34-3.28,7.63Z" />
      <path
        d="M145.15,177.13c-60.84-40.65-69.91-76.8-70.27-78.32l3.89-.91-1.95,.46,1.95-.46c.08,.36,9.23,36.25,68.6,75.92l-2.22,3.33Z" />
      <path
        d="M136.54,88.79l-3.52-1.9c2.09-3.87,3.19-8.21,4.35-12.81,1.23-4.87,2.5-9.91,5.08-14.45,5.4-9.53,15.81-14.8,27.15-13.75l-.37,3.98c-9.75-.9-18.69,3.6-23.3,11.74-2.31,4.07-3.46,8.63-4.68,13.46-1.17,4.63-2.38,9.43-4.71,13.73Z" />
      <path d="M136.24,126.75c-11.53-7.39-18.98-19.35-19.29-19.85l3.41-2.1c.07,.12,7.3,11.7,18.04,18.58l-2.16,3.37Z" />
    </svg>
    </template>

    <template
    v-if="data === 'smil'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M202.52,101.94c-3.6-13.36-10.87-25.86-22.02-36.32-31.53-29.56-92.78-33.74-126.97,10.47-12.8,16.55-11.58,41.83-9.19,62.34,1.9,16.35,5.82,34.07,17.69,46.46,12.04,12.55,30.59,17.09,47.46,19.05,18.49,2.14,39.1,.72,56.49-6.17,17.19-6.81,24.68-24.37,31.57-40.04,8-18.22,9.83-37.79,4.98-55.79Z" />
      <path
        d="M125.95,176.5c-24.17,0-36.17-12.23-40.21-17.49l3.17-2.44c3.68,4.79,14.66,15.93,37.04,15.93s30.49-11.07,32.94-15.83l3.56,1.83c-2.78,5.41-11.91,18.01-36.5,18.01Z" />
      <path
        d="M82.93,90.92l-2.73-2.92c13.16-12.32,26.66-3.57,26.79-3.48l-2.22,3.33,1.11-1.66-1.1,1.67c-.44-.29-11.04-7.04-21.84,3.07Z" />
      <path d="M166.21,89.71c-.41-.36-10.09-8.76-21.98-.74l-2.24-3.32c14.52-9.8,26.77,.97,26.89,1.08l-2.67,2.98Z" />
      <g>
        <path
          d="M99.04,105.78c-1.41-1.35-3.34-1.22-5.52,1.91-3.82,5.5-.92,14.1,2.88,13.86,4.48-.28,5.23-6.44,4.92-9.85-.22-2.42-1.04-4.73-2.28-5.92Z" />
        <path
          d="M96.24,123.55c-1.92,0-3.72-1.24-4.95-3.43-1.97-3.5-2.4-9.28,.59-13.58,1.52-2.19,3.17-3.39,4.91-3.56,1.33-.12,2.58,.33,3.64,1.35,1.53,1.47,2.61,4.15,2.88,7.18,.1,1.06,.44,6.54-2.37,9.82-1.15,1.34-2.67,2.1-4.41,2.21-.09,0-.19,0-.28,0Zm.96-16.59h-.02c-.08,0-.81,.13-2.02,1.87-2.02,2.91-1.71,6.98-.39,9.33,.56,.99,1.13,1.42,1.5,1.39,.66-.04,1.18-.3,1.62-.82,1.27-1.48,1.63-4.59,1.43-6.86-.21-2.31-.99-4-1.67-4.65h0c-.18-.17-.33-.26-.45-.26Z" />
      </g>
      <g>
        <path
          d="M154.16,105.78c-1.41-1.35-3.34-1.22-5.52,1.91-3.82,5.5-.92,14.1,2.88,13.86,4.48-.28,5.23-6.44,4.92-9.85-.22-2.42-1.04-4.73-2.28-5.92Z" />
        <path
          d="M151.36,123.55c-1.92,0-3.72-1.24-4.95-3.43-1.97-3.5-2.4-9.28,.59-13.58,1.52-2.19,3.17-3.39,4.91-3.56,1.33-.12,2.58,.33,3.64,1.35,1.53,1.47,2.61,4.15,2.88,7.18,.1,1.06,.44,6.54-2.37,9.82-1.15,1.34-2.67,2.1-4.41,2.21-.09,0-.19,0-.28,0Zm.96-16.59h-.02c-.08,0-.81,.13-2.02,1.87-2.02,2.91-1.71,6.98-.39,9.33,.56,.99,1.13,1.42,1.5,1.39,.66-.04,1.18-.3,1.62-.82,1.27-1.48,1.63-4.59,1.43-6.86-.21-2.31-.99-4-1.67-4.65h0c-.18-.17-.33-.26-.45-.26Z" />
      </g>
    </svg>
    </template>

    <template
    v-if="data === 'regn'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M227.14,102.16c-2.06-11.93-9.99-21.34-21.83-22.68-3.78-.43-4.51-1.02-6.01-4.6-1.14-2.69-2.4-5.15-4.36-7.32-4.17-4.65-9.96-7.2-15.94-8.04-3.24-.45-6.42-.42-9.66-.05-1.21,.14-2.98,.83-4.17,.55-.39-.1-.63-.11-.79-.1,.03-.18,0-.52-.23-1.12-.86-2.33-.95-4.97-1.91-7.35-1.3-3.28-3.13-6.22-5.38-8.89-8.96-10.57-26.32-15.84-39.4-11.99-5.94,1.75-10.5,5.99-13.95,11.16-1.92,2.88-3.41,6.03-4.79,9.22-.06,.14-.15,.34-.28,.59-.6,1.21-2.09,1.68-3.26,1-12.04-6.97-25.15-11.45-38.38-3.54-7.32,4.38-16.08,16.62-15.94,26.89,.02,1.2-.93,2.17-2.12,2.31h-.02c-4.4,.54-8.79,2.17-12.12,5.3-7.66,7.18-7.28,20.07-5.24,29.69,2.28,10.75,8.19,18.33,18.66,21.15,5.98,1.6,12.26,.97,17.36-2.01,.83-.49,1.89-.4,2.64,.22,1.79,1.48,3.43,3.29,5.02,4.64,3.43,2.91,7.21,5.16,11.55,6.18,4.11,.99,8.37,1.02,12.51,.17,2.27-.47,4.49-1.28,6.57-2.32,.61-.3,1.23-.74,1.85-1.21,1.17-.88,2.84-.49,3.51,.81,.61,1.19,1.15,2.37,1.66,3.15,1.58,2.44,3.3,4.8,5.46,6.71,4.53,4.01,10.25,5.55,16.04,6.08,10.68,.99,23.45-.28,32.17-7.36,2.13-1.74,3.88-3.73,5.4-6.06,.68-1.06,1.27-2.86,2.18-3.7,1.61-1.47,2.2-.99,4.31-.67,5.81,.86,11.66,1.9,17.43,.03,2.73-.88,4.93-3.25,7.66-2.95,2.12,.24,4.36,1.45,6.56,1.78,5.69,.85,11.73,.12,16.8-2.86,10.88-6.37,12.44-21.21,10.43-32.83Z" />
      <path
        d="M76.5,198.53c-1.36,0-2.76-.29-4.15-.88-3.16-1.35-7.89-5.22-4.02-16.02,1.53-4.27,10.75-15.63,11.79-16.92,.43-.52,1.08-.8,1.75-.72,.67,.07,1.26,.47,1.57,1.07,.11,.22,2.75,5.42,3.31,17.44,.32,6.79-1.52,11.92-5.16,14.45-1.51,1.05-3.26,1.58-5.1,1.58Zm4.48-28.48c-3.71,4.72-8.07,10.66-8.88,12.93-3.17,8.86,.59,10.47,1.83,11,1.98,.84,3.89,.74,5.39-.3,2.44-1.69,3.69-5.69,3.45-10.98-.28-5.98-1.09-10.1-1.78-12.64Z" />
      <path
        d="M111.26,220.7c-1.23,0-2.51-.2-3.81-.61-2.94-.92-5.06-2.88-6.16-5.68-1.5-3.84-.67-8.14,.82-10.71,2.18-3.76,11.2-15.62,11.58-16.12,.41-.54,1.06-.84,1.74-.78,.67,.05,1.28,.43,1.6,1.02,.19,.34,4.58,8.36,4.94,18.51,.19,5.55-1.46,9.94-4.64,12.37-1.73,1.32-3.81,2-6.06,2Zm3.61-28.04c-2.84,3.78-7.84,10.54-9.29,13.05-1,1.73-1.52,4.78-.55,7.25,.64,1.65,1.86,2.76,3.62,3.31,2.41,.75,4.64,.49,6.26-.75,2.13-1.62,3.22-4.84,3.07-9.05-.2-5.73-1.87-10.8-3.1-13.81Z" />
      <path
        d="M152.09,203.06c-.66,0-1.35-.06-2.06-.17-3.01-.48-5.41-1.87-6.93-4.02-1.55-2.19-2.1-5.01-1.56-7.96,.82-4.46,10.72-17.58,12.7-20.16,.46-.6,1.22-.89,1.96-.75,.74,.14,1.34,.69,1.55,1.42,.16,.58,3.97,14.18,3.84,20.7-.08,3.95-1.32,7.04-3.59,8.94-1.58,1.33-3.6,2-5.91,2Zm2.85-26.58c-4.29,5.86-9.08,13-9.47,15.15-.34,1.88-.03,3.63,.89,4.93,.9,1.27,2.34,2.07,4.29,2.38,2.03,.32,3.64,0,4.78-.95,1.36-1.13,2.1-3.19,2.16-5.95,.08-3.9-1.53-11.07-2.65-15.56Z" />
    </svg>
    </template>

    <template
    v-if="data === 'lys'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path class="fill-current"
        d="M170.49,47.98c-5.75-5.86-16.19-11.68-25.5-14.07-17.31-4.46-44.08-2.96-59.99,4.67-7.87,3.77-13.54,10.56-18.26,18.33-6.18,10.14-7.88,21.54-7.5,33.28,.37,11.5,4.72,22.61,10.53,32.41,5.35,9.02,14.7,15.88,18.99,25.39,2.55,5.65,4.98,11.32,5.69,17.43-1.77,1.38-3.4,2.89-4.89,4.66-1.86,2.23-2.32,5.62,0,7.8,1.35,1.27,.81,5.33,.89,7.07l.51,9.14c.25,4.73,.13,9.93,2.23,14.3,4.2,8.84,16.87,11.33,25.78,11.21,9.78-.14,20.31-3.07,26.07-11.55,3.09-4.57,3.53-9.93,4.08-15.28,.53-5.17,.64-10.16,.01-15.25,.17-.09,.32-.18,.49-.25,2.47-1.2,3.68-5.3,1.99-7.55-1.4-1.86-2.99-4.38-5.01-6.06,1.94-5.42,3.74-10.81,6.49-15.91,3.88-7.2,11.51-12.27,16.2-19.1,5.34-7.78,10.55-15.85,13.02-25.06,5.46-20.36,1.67-41.82-11.83-55.59Z" />
      <path
        d="M127.84,157.24c-.53-9.24,1.3-18.41,2.9-26.5,.4-2.01,.83-4.1,1.31-6.26-4.21-.81-8.22-3.36-11.46-6.38-.73,.73-1.46,1.4-2.18,2-2.95,2.5-6.24,3.61-9.69,3.32,.02,.42,.05,.86,.07,1.29,.83,16.35,.35,32.31,.34,32.47l-4-.12c.33-10.61,.22-23.23-.48-34.62-.2-.08-.4-.16-.59-.25-8.86-3.84-15.46-15.22-15.84-23.03-.2-4.11,.76-9.73,4.14-12.01,1.36-.92,3.67-1.69,7-.14,3.1,1.44,7.32,6.43,9.11,32.34,2.63,.38,5.09-.39,7.35-2.31,.66-.56,1.33-1.18,2.01-1.84-.18-.21-.36-.43-.53-.64-7.2-8.92-5.61-19.67-.32-25.69,2.18-2.48,6.2-4.37,9.87-2.67,3.71,1.71,4.91,6.11,4.79,9.65-.24,7.21-4.02,14.13-8.35,19.29,2.76,2.58,6.2,4.81,9.66,5.43,1.26-5.18,2.83-10.61,4.89-15.94,2.4-6.2,4.97-10.78,8.09-14.41,2.21-2.58,4.42-3.71,6.72-3.46,2,.22,3.82,1.53,5.12,3.7,1.81,3.02,2.32,6.87,1.45,10.83-.65,2.94-2.03,5.93-4.33,9.41-3.08,4.63-9.52,12.6-18.09,13.9-.23,.04-.46,.06-.69,.09-.53,2.36-1,4.65-1.44,6.83-1.56,7.86-3.33,16.76-2.83,25.49l-3.99,.23Zm24.23-66.5c-1.06,0-2.26,1.13-3.09,2.1-2.81,3.28-5.16,7.49-7.39,13.25-1.85,4.78-3.3,9.67-4.48,14.39,6.62-1.5,11.86-8.08,14.45-11.99,2.02-3.05,3.22-5.61,3.76-8.05,.64-2.92,.29-5.8-.97-7.91-.62-1.04-1.41-1.7-2.12-1.78-.05,0-.1,0-.16,0Zm-56.31-.6c-.65,0-.99,.23-1.15,.34-1.76,1.19-2.54,5.26-2.38,8.5,.3,6.09,5.55,15.23,12.13,18.91-1.11-14.22-3.22-25.64-6.66-27.23-.83-.39-1.46-.51-1.93-.51Zm28.14-.57c-1.43,0-2.97,.88-3.91,1.95-5.47,6.22-3.86,15.22,.43,20.53,.05,.06,.09,.11,.14,.17,3.71-4.53,6.9-10.43,7.1-16.5,.07-2.16-.54-4.99-2.47-5.89-.4-.19-.84-.27-1.28-.27Z" />
      <path
        d="M120.07,180.78c-16.67,0-31.26-.66-31.5-.67l.19-4c.4,.02,40.5,1.84,63.48-.59l.42,3.98c-9.34,.99-21.42,1.29-32.58,1.29Z" />
      <path
        d="M120.16,195.25c-12.48,0-22.12-2.22-22.72-2.36l.92-3.89c.21,.05,21.7,4.98,41.58,0l.97,3.88c-7.1,1.77-14.3,2.37-20.74,2.37Z" />
    </svg>
    </template>

    <template
    v-if="data === 'baby'">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 248" aria-hidden="true">
      <path
        d="M123.28,89.42c-1.66,0-3.17-.36-4.52-1.08-4.33-2.31-5.13-7.41-5.16-7.63l3.96-.59-1.98,.29,1.98-.3s.57,3.37,3.11,4.71c2,1.05,4.84,.72,8.44-1l1.72,3.61c-2.78,1.32-5.3,1.98-7.55,1.98Z" />
      <path
        d="M152.67,96.86c-1.53,0-2.96-.28-4.29-.84-4.79-2.01-6.29-6.91-6.35-7.11l3.84-1.14-1.92,.57,1.91-.58s1.06,3.33,4.11,4.59c2.5,1.03,5.78,.49,9.73-1.6l1.88,3.53c-3.24,1.72-6.21,2.58-8.9,2.58Z" />
      <path
        d="M148.18,74.86c-3.63-2.04-5.33-5.45-5.67-9.18-1.8-.27-3.55-1.05-5.18-2.34-3.62-2.88-5.56-6.12-5.76-9.64-.29-5.1,3.29-8.75,3.44-8.9l2.83,2.83s-2.48,2.58-2.27,5.88c.14,2.32,1.57,4.58,4.25,6.7,.91,.73,1.85,1.2,2.82,1.42,.26-1.83,.79-3.64,1.52-5.34,2.35-5.52,6.23-8.54,9.65-7.5,1.73,.52,2.84,1.91,3.04,3.79,.41,3.89-3.08,9.28-7.62,11.77-.9,.49-1.8,.86-2.7,1.1,.23,2.37,1.23,4.6,3.62,5.94l-1.96,3.49Zm4.25-22.28c-1.03,0-3.08,1.72-4.59,5.27-.39,.91-.75,2.02-1.01,3.22,.16-.07,.31-.16,.47-.24,3.51-1.92,5.76-6.02,5.57-7.84-.03-.32-.09-.34-.22-.38-.07-.02-.14-.03-.22-.03Z" />
      <path class="fill-current"
        d="M202.07,65.21c-1.21-5.08-2.85-10.03-5.22-14.76-5.76-11.52-17.84-18.35-29.88-21.58-14.47-3.89-27.7-1.23-40.92,5.48-22.8,11.57-33.1,38.29-43.53,59.87-4.24,8.77-9.28,16.68-15.74,24-6.41,7.28-13.31,14.14-18.93,22.09-10.48,14.81-13.85,33.82-4.88,50.22,8.65,15.8,25.79,25.38,43.03,28.81,17.16,3.42,34.98,1.29,50.99-5.68,17.13-7.46,29.85-20.67,40.12-35.99,10.74-16.01,22.36-34.05,25.67-53.35,2.29-13.39,4.37-23.45,2.77-36.9-.88-7.42-1.75-14.93-3.49-22.21Zm-11.31,43.19c-4.31,5.55-11.55,3.13-17.21,4.52-4.92,1.2-7.4,5.74-11.2,8.6-4.45,3.34-10.16,4.9-15.66,5.09-11.45,.38-23.24-4.61-32.78-10.63-4.18-2.64-8.16-6.14-9.13-11.21-1.35-7.04,.55-14.12,1.54-21.07,1.39-9.71,3.26-20.26,10.03-27.81,6.9-7.69,16.22-14.77,26.76-15.87,5.65-.59,12.43,.32,17.84,1.91,6.1,1.79,10.91,6.02,15.52,10.21,4.61,4.19,6.25,8.12,7.28,14.17,1.17,6.83,1.17,13.96,.19,20.8-.03,.25-.05,.49-.05,.72-.42,2.71-2.54,6.37,4.7,6.44,7.95,.07,4.14,11.6,2.16,14.14Z" />
      <path
        d="M121.59,150.54c-4.66,0-9.43-.24-14.31-.71-28.05-2.73-49.29-12.17-50.18-12.57l1.64-3.65c.21,.1,21.62,9.6,49.04,12.25,36.52,3.52,65.79-6.13,87.01-28.69l2.91,2.74c-18.97,20.17-45.09,30.63-76.12,30.63Z" />
      <path
        d="M139.2,197.01c-4.63,0-10.05-.37-15.96-1.47-16.69-3.1-40.56-12.84-59.47-41.63l3.34-2.2c14.52,22.11,33.59,35.52,56.68,39.86,17.27,3.25,30.44-.09,30.58-.12l1.01,3.87c-.38,.1-6.62,1.69-16.18,1.69Z" />
    </svg>
    </template>

  </figure>

</template>

<script>
export default {
  name: 'Pictogram',
  props: {
    data: [String, Boolean],
    color: String
  },
}
</script>
