<template>

  <div class="component-inner">

    <figure>

      <img
      v-if="block.media.image"
      class="object-cover"
      :src="`${baseURL}/${block.media.image.sizes['1536x1536']}`"
      :height="block.media.image.sizes['1536x1536-height']"
      :width="block.media.image.sizes['1536x1536-width']"
      :alt="block.media.image.alt" />

        <figcaption
        v-if="block.media.caption"
        class="pt-4">{{ block.media.caption }}</figcaption>

      </figure>

  </div>

</template>

<script>
export default {
  name: 'Media',
  data: () => ({
    baseURL: process.env.MIX_API_BASE_URL
  }),
  props: {
    block: Object
  },
}
</script>
