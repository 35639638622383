<template>

  <div
  v-if="acf && acf[0]"
  class="type"
  :class="[post.type]"
  :data-slug="post.slug">

    <section
    v-if="acf[0].components"
    v-intersect="{observerOptions: {rootMargin: '0px', threshold: 0.0}, true: ['-intersected'], disposeWhen: true }"
    v-for="(block, index) in acf[0].components"
    :key="index"
    class="section"
    :class="[block.acf_fc_layout, block.options && block.options.color ? 'bg-'+ block.options.color : 'bg-white', block.layout]">

      <component :is="block.acf_fc_layout" :block="block" :key="index" />

    </section>

  </div>

</template>

<script>
import { EventBus } from '@/store/hero.js';
import postMeta from '@/mixins/postMeta.js';

import Accordion from '@/component/Post/Accordion'
import Related from '@/component/Block/Related'

import Media from '@/component/Block/Media'
import Oembed from '@/component/Block/Oembed'
import Quote from '@/component/Block/Quote'
import Paragraph from '@/component/Block/Paragraph'


export default {
  components: {
    Accordion,
    Media,
    Oembed,
    Paragraph,
    Quote,
    Related,
  },
  data : () => ({
    hero: [],
    post: null,
    acf: Object,
    errors: [],
    baseURL: process.env.MIX_API_BASE_URL,
  }),
  mixins: [
    postMeta,
  ],
  methods: {
    async getPage() {
      try {
        const response = await axios.get(
          this.baseURL + '/api/wp/v2/kontakt', {
            params: {
              slug: this.$route.params.slug,
            }
          }
        )
        const results = response.data
        if ( !results || results.length === 0 ) {}

        this.post = results.map(post => ({
          id: post.id,
          type: post.type,
          slug: post.slug,
          title: post.title.rendered,
          media: post.featured_media,
          head: post.yoast_head_json,
        }))[0]

        this.acf = results.map(post => ({
          components: post.acf.blocks
        }))

        this.hero = results.map(post => ({
          id: post.id,
          label: false,
          title: post.acf.hero.title,
          excerpt: post.acf.excerpt.excerpt,
          type: post.type,
          date: post.date,
          url: post.link,
          layout: this.setLayout(post.type),
          breadcrumb: ['post', 'ressurs'].includes(post.type) ? post.omrader : false,
          content: (post.acf.options.layout != 'superimpossed' ) ? this.setContent(post.type, post.acf.options.layout, post.acf.hero, post.acf.assets) : false,
          color: post.acf.options.color ? post.acf.options.color : 'gray-light',
          image: false,
          video: false,
        }))
      }
      catch (e) {
        this.errors.push(e)
      }
      return this.doAction()

    },

    doAction() {
      // EventBus.$emit('EventBus', this.hero[0])
      this.$store.dispatch('updateHero', this.hero[0]);
      // this.setHero(this.hero[0])
    },

    setHero(data) {
      EventBus.$emit('EventBus', data)
    },

    resetData() {
      this.acf = Object
    },

    setContent(type, style, post) {

      let data = Object

      if (['post', 'page'].includes(type)) {
        switch (style) {
          case 'content':
            data = {
              color: post.sidebar.color,
              title: post.content.content.subtitle ? post.content.content.subtitle : false,
              wysiwyg: post.content.content.wysiwyg ? post.content.content.wysiwyg : false,
              links: post.content.links
            }
            break;
          case 'icon':
            data = {
              // color: post.sidebar.color,
              item: post.icon.icon ? post.icon.icon : false
            }
            break;

          case 'illustration':
            data = {
              // color: post.sidebar.color,
              item: post.illustration.illustration ? post.illustration.illustration : false
            }
            break;

          default:
            data = false
        }
      } else {
        data = {
          color: post.sidebar.color
        }
      }
      return data

    },

    setLayout(e) {
      let expr = e
      let data = String

      switch (expr) {
        case 'superimposed':
          data = e
          break;

        default:
          data = e
      }
      return data
    },

    observerElement() {
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if(entry.isIntersecting){
            //console.log('Observed');
          }
        });
      },
      {
        rootMargin: '-50% 0% -50% 0%'
      })

      document.querySelectorAll('.article').forEach(item => {
        observer.observe(item)
      })
    }
  },
  mounted() {
    this.getPage()
  },
  watch: {
    $route: {
      immediate: true,
      handler: function(newVal, oldVal) {
        this.isModalVisible = newVal.meta && newVal.meta.isModalVisible;
      }
    },
    '$route.params.slug'(newSlug, oldSlug) {
      this.resetData()
      this.getPage()
    }
  }
}
</script>
