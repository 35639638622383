<template>

  <div class="component-inner break-words">

    <router-link
    class="button-text flex items-center text-brand-green"
    :to="relativeURL(block.button.url.ID)">
      <span class="">{{ block.button.label ? block.button.label : 'Les mer' }}</span>

      <icon graphic="arrow" class="arrow-right"/>
    </router-link>

  </div>

</template>

<script>
import Icon from '@/component/Icon'

export default {
  name: 'Readmore',
  props: {
    block: Object
  },
  components: {
    Icon,
  },
  methods: {
    relativeURL(id) {
      return this.$store.getters.getRelativeUrl(id)
//       let filteredPosts = ''
//
//       if ( this.$store.getters.getPagesAndPosts.find( post => post.id === id ) ) {
//         filteredPosts = this.$store.getters.getPagesAndPosts.find( post => post.id === id )
//         filteredPosts = filteredPosts.vueData.url
//       }
//
//       //console.log(filteredPosts)
//       return filteredPosts

    }
  },
}
</script>
