export default {
  data: () => ({
    // post: [],
  }),
  metaInfo() {
    return {
      title: this.postTitle,
      meta: this.postMeta,
      script: [{
        type: "application/ld+json",
        json: this.postSchema,
      }],
      changed(newInfo, addedTags, removedTags) {
        // console.log("vue-meta changed", newInfo, addedTags, removedTags)
      }
    }
  },
  computed: {
    aThePost() {
      return this.post
      // if (this.post && this.post.length) {
      //   return this.post
      // } else if (this.post && Object.keys(this.post).length) {
      //   return [this.post]
      // }
    },
    head() {
      if (this.post && (this.post.head || this.post.yoast_head_json)) {
        let head = this.post.head || this.post.yoast_head_json;
        return head
      }
    },
    postSchema() {
      // if (this.aThePost && this.aThePost.length) {
//       if (this.post && (this.post.head || this.post.yoast_head_json)) {
//         let head = this.post.head || this.post.yoast_head_json;
//         // let head = this.aThePost[0].head;
//
//         if (head.schema) {
//           return head.schema
//         }
//         return {}
//       }
      if (this.head && this.head.schema) {
        return this.head.schema
      }
      return {}
    },
    postTitle() {
      if (this.head) {
      // if (this.aThePost && this.aThePost.length) {
        // let head = this.aThePost[0].head;
        let head = this.head;

        if (head.title) {
          return head.title
        }
        return "Sammen på vei – NFP"
      }
      return "Sammen på vei – NFP"
    },
    postMeta() {
      let aTheMeta = [
        // { name: "google-site-verification", content: "9zR9Qn0SooOZWj1TyRzOLU6Gcre1QnNpVWsUzh6SAEY" },
      ];

      if (this.head) {
        let head = this.head;

        // console.log(head);

        if (head.robots) {
          let aTheRobots = Object.values(head.robots);
          // if (aTheRobots.length) {
          //   aTheMeta.push({ name: "robots", content: aTheRobots.join(", ") });
          // }
        }

        if (head.og_locale) {
          aTheMeta.push({ property: "og:locale", content: head.og_locale });
        }

        if (head.og_site_name) {
          aTheMeta.push({ property: "og:site_name", content: head.og_site_name });
        }

        if (head.og_title) {
          aTheMeta.push({ property: "og:title", content: head.og_title });
        }

        if (head.og_url) {
          aTheMeta.push({ property: "og:url", content: head.og_url });
        }

        if (head.og_type) {
          aTheMeta.push({ property: "og:type", content: head.og_type });
        }

        if (head.og_image && head.og_image.length) {
          let oTheImage = head.og_image[0];
          aTheMeta.push({ property: "og:image", content: oTheImage.url });
        }

        if (head.og_description) {
          aTheMeta.push({ property: "og:description", content: head.og_description });
        }

        if (head.twitter_card) {
          aTheMeta.push({ name: "twitter:card", content: head.twitter_card });
        }
      }
      // console.log(aTheMeta);
      return aTheMeta
    }
  }
}
