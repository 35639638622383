<template>

  <div class="component-inner">

    <article
    class="feature-article lg:flex gap-8 xl:gap-40 content-center items-center"
    v-if="post && post">

      <header class="text-column grow-0 ">
        <h3 class="block-heading medium">{{ post.title }}</h3>
        <div class="lead mb-16" v-if="post.excerpt" v-html="post.excerpt" />

        <router-link
        class="button-text flex items-center text-xl text-brand-green" :to="post.url">
          <span>{{ block.feature.label ? block.feature.label : 'Les mer' }}</span>

          <icon graphic="arrow" class="arrow-right"/>
        </router-link>

      </header>

      <figure v-if="post.media.image || post.featured" class="image-column grow">

        <img
        class="object-cover aspect-video"
        v-if="post.featured"
        :src="`${baseURL}/${post.featured.media_details.sizes['large'].source_url}`"
        :height="post.featured.media_details.sizes['large'].height"
        :width="post.featured.media_details.sizes['large'].width"
        :alt="post.media.alt" />

        <img
        class="object-cover aspect-video"
        v-else-if="post.media.image"
        :src="`${baseURL}/${post.media.image.sizes['large']}`"
        :height="post.media.image.sizes['large-height']"
        :width="post.media.image.sizes['large-width']"
        :alt="post.media.image.alt" />



      </figure>

    </article>

  </div>

</template>

<script>
  import Icon from '@/component/Icon'

export default {
  name: 'Feature',
  props: {
    block: Object
  },
  data : () => ({
    post: null,
    acf: Object,
    errors: [],
    baseURL: process.env.MIX_API_BASE_URL,
  }),
  components: {
    Icon,
  },
  methods: {
    async getPost(type, slug) {
      try {
        const response = await axios.get(
          this.baseURL + '/api/wp/v2/' + type, {
            params: {
              slug: slug,
            }
          }
        )
        const results = response.data
        // console.log(results);
        if ( !results || results.length == 0 ) {
          // //console.log('Error, 404')
          // const e = new Error('No data');
          // e.statusCode = 404;
          // throw e;
        }
        this.post = results.map(post => ({
          id: post.id,
          type: post.type,
          slug: post.slug,
          url :post.vueData.url,
          title: post.title.rendered,
          excerpt: post.acf.excerpt.excerpt,
          media: post.acf.hero.media.landscape ? post.acf.hero.media.landscape : false,
          featured: post.better_featured_image
        }))[0]

        this.acf = results.map(post => ({
          components: post.acf.blocks
        }))
      }
      catch (e) {
        this.errors.push(e)
      }
      return this.doAction()

    },

    doAction() {},
  },

  mounted() {
    const type = `${this.$props.block.feature.item.post_type}s`
    const slug = this.$props.block.feature.item.post_name
    this.getPost(type, slug)
  }

}
</script>
