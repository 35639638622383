<template>
  <div
  v-if="post"
  class="type"
  :class="[post.type]"
  :data-slug="post.slug">

    <section class="component login bg-gray-light">

      <div class="component-inner">
        <!-- <div class="flex flex-col lg:flex-row gap-8 xl:gap-20 2xl:gap-32 w-full max-w-screen-2xl mx-auto py-20 pt-40 xl:pt-40 z-10"> -->
          <header class="block-header">
            <h1>{{ post.title }}</h1>
            <div class="lead regular" v-html="post.excerpt.excerpt" />
          </header>

          <form class="login-form" v-on:submit.prevent="validateBeforeSubmit">
            <div class="form-group text-left mb-5">
              <label class="custom-label control-label hidden">Brukernavn</label>
              <input class="form-control w-full p-4 bg-white " type="text" name="username" placeholder="Brukernavn" v-model.trim="username">
            </div>

            <div class="form-group text-left mb-5">
              <label class="custom-label control-label hidden">Password</label>
              <input class="form-control password-field w-full p-4 bg-white " type="password" name="password" placeholder="Passord" v-model.trim="password">
            </div>

            <div class="text-red-400" v-if="errorMessage">
              <p>{{ errorMessage }}</p>
            </div>

            <div class="links">
              <button class="button green text-green-dark " type="submit">Logg inn</button>
              <a class="forgot-password" :href="`${baseURL}/wp-login.php?action=lostpassword`">Glemt passord</a>
              <!-- <router-link class="forgot-password" :to="'/glemt-passord'">Glemt passord?</router-link> -->
            </div>


          </form>



        <!-- </div> -->

      </div>

    </section>

  </div>
</template>

<script>
// import { EventBus } from '@/store/hero.js';
import postMeta from '@/mixins/postMeta.js';

export default {
  components: {},

  data : () => ({
    // hero: [],
    // post: null,
    errors: [],
    error: Boolean = false,
    errorMessage: null,
    observer: null,
    password: null,
    username: null,
    baseURL: process.env.MIX_API_BASE_URL,
  }),

  mixins: [
    postMeta,
  ],

  computed: {
    post() {
      const post = this.$store.getters.getPageBySlug("innlogging");

      if (post) {
        return {
          id: post.id,
          type: post.type,
          slug: post.slug,
          title: post.title.rendered,
          excerpt: post.acf.excerpt,
          media: post.featured_media,
          template: post.template ? post.template : 'default',
          head: post.yoast_head_json,
        }
      }
      return false
    }
  },

  methods: {
//     async getPage() {
//       try {
//         const response = await axios.get(
//           this.baseURL + '/api/wp/v2/pages', {
//             params: {
//               slug: 'innlogging',
//             }
//           }
//         )
//         const results = response.data
//         //console.log( response.data);
//         if ( !results || results.length == 0 ) {
//           const e = new Error('No data');
//           e.statusCode = 404;
//           throw e;
//         }
//
//         this.post = results.map(post => ({
//           id: post.id,
//           type: post.type,
//           slug: post.slug,
//           title: post.title.rendered,
//           excerpt: post.acf.excerpt,
//           media: post.featured_media,
//           template: post.template ? post.template : 'default',
//           head: post.yoast_head_json,
//         }))[0]
//
//         this.hero = false
//
//       }
//       catch (e) {
//         this.errors.push(e);
//         this.$router.push({ name: 'PageNotFound' })
//       }
//       return this.doAction()
//
//     },
//
//     doAction() {
//       // EventBus.$emit('EventBus', this.hero[0])
//       this.$store.dispatch('updateHero', this.hero[0]);
//
//       if (this.post && this.post.title) {
//         this.$store.dispatch('setViewTitle', this.post.title);
//       }
//       // this.setHero(this.hero[0])
//     },
//
//     setHero(data) {
//       // EventBus.$emit('EventBus', data)
//     },

    resetData() {},

    validateBeforeSubmit () {
      if (this.username.length && this.password.length) {
        // console.log(this.username, this.password);

        this.$store.dispatch('login', { username: this.username, password: this.password }).then(result => {
          // console.log('login result', result);

          if (result.success) {
            // this.$router.push('/kompasset');
            this.$router.push({ name: 'ResourceMain'});
          } else {
            this.errorMessage = result.message;
          }
        })
      }
      // if (this.password === 'nfpdemo') {
      //   this.error = false
      //   // TODO: denne kan vi bruke når API er på plass:
      //   if (this.username.length && this.password.length) {
      //     this.$store.dispatch('login', { username: this.username, password: this.password }).then(result => {
      //       //console.log(result);
      //       this.$router.push('/ressursportal');
      //     })
      //   }
      //
      //   // midlertidig setter vi bare passordet direkte:
      //   // this.$store.dispatch('setUserPassword', this.password)
      //   // localStorage.setItem('user-password', this.password)
      //   this.$router.push('/ressursportal')
      // } else {
      //   this.error = true
      // }
    },
  },

  mounted() {
    this.$store.dispatch("updateHero", false);
    // this.getPage()
  },

  watch: {
    $route: {
      immediate: true,
      handler: function(newVal, oldVal) {
        this.isModalVisible = newVal.meta && newVal.meta.isModalVisible;
      }
    },
    // '$route.params.slug'(newSlug, oldSlug) {
    //   this.resetData()
    //   this.getPage()
    // }
  }
}
</script>
