<template>

  <div class="component-inner">
    <ul v-if="block.link" class="buttons-container">

      <li class="block-link-item "
      v-for="(item, index) in computedList"
      :key="index">

        <a
        v-if="item.link_type == 'external'"
        class="block-link"
        :href="item.link_uri" target="_blank" rel="nofollow">
          <span class="title">{{ item.label ? item.label : 'Last ned' }}</span>
          <icon class="arrow arrow-right" :graphic="isMobile ? 'arrow' : 'arrow-large'"></icon>
        </a>

        <a
        v-if="item.link_type == 'document'"
        class="block-link "
        :href="item.link_asset.url" target="_blank" rel="nofollow">
        <span class="title">{{ item.label ? item.label : item.link_asset.title }}</span>
        <icon class="arrow arrow-right" :graphic="isMobile ? 'arrow' : 'arrow-large'"></icon>
        </a>

        <router-link
        v-if="item.link_type == 'page'"
        class="block-link"
        :to="relativeURL(item.link_object.ID)">
          <span class="title">{{ item.label ? item.label : item.link_object.post_title }}</span>
          <icon class="arrow arrow-right" :graphic="isMobile ? 'arrow' : 'arrow-large'"></icon>
        </router-link>


        <router-link
        v-if="item.link_type == 'resources'"
        class="block-link"
        :to="{ path: `/biblioteket/${item.link_filtered_resources}` }">
          <span class="title">{{ item.label ? item.label : 'Biblioteket' }}</span>

          <icon class="arrow arrow-right" :graphic="isMobile ? 'arrow' : 'arrow-large'"></icon>
        </router-link>

      </li>
    </ul>
  </div>

</template>

<script>

import Icon from '@/component/Icon.vue'

export default {
  name: 'LinkBlocks',
  props: {
    block: Object,
    options: Object
  },

  components: {
    'icon': Icon,
  },

  methods: {
    relativeURL(id) {
      return this.$store.getters.getRelativeUrl(id)
//       let filteredPosts = ''
//
//       if ( this.$store.getters.getPagesAndPosts.find( post => post.id === id ) ) {
//         filteredPosts = this.$store.getters.getPagesAndPosts.find( post => post.id === id )
//         filteredPosts = filteredPosts.vueData.url
//       }
//
//       //console.log(filteredPosts)
//       return filteredPosts

    }
  },
  computed: {
    computedList() {

      let listItems = []
      let totalItems = []

      if ( this.$props.block.link.link_items.items.length > 0 ) {
        totalItems.push(...this.$props.block.link.link_items.items)
        listItems.push(...totalItems.slice(0, this.items))
        this.showLoadMore = listItems.length < totalItems.length ? true : false

        return listItems
      }
    },
    isMobile() {
      if (this.$store.getters.getWindowWidth < 768) {
        return true
      }
      return false
    }
  },
}
</script>
