<template>

    <div class="component-inner">
        <div class="component-header">
            <h2 v-if="block.heading">{{ block.heading }}</h2>
            <p v-if="block.ingress">{{ block.ingress }}</p>
        </div>

        <div class="graphic-grid">
            <div class="item one">
                <svg id="arrow-one" xmlns="http://www.w3.org/2000/svg" width="194.874" height="33.022" viewBox="0 0 194.874 33.022">
                    <g transform="translate(-459.526 -1677.795)">
                        <line x2="183" transform="translate(460.526 1694.309)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="2" stroke-dasharray="20"/>
                        <path d="M-10563.82-6600.054l14.794,14.821-14.794,15.373" transform="translate(11202.026 8279.264)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="2"/>
                    </g>
                </svg>

                <svg id="arrow-eight" xmlns="http://www.w3.org/2000/svg" width="15.714" height="58.944" viewBox="0 0 15.714 58.944">
                    <g transform="translate(-187.143 -475.5)">
                        <line y2="50.237" transform="translate(194.999 476)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="1" stroke-dasharray="10"/>
                        <path d="M0,0,7.006,7.02,0,14.3" transform="translate(202.15 526.737) rotate(90)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="1"/>
                    </g>
                </svg>


                <div
                :v-if="block.grid_one.icon"
                class="illustration flex-none  w-24 h-24 lg:w-32 lg:h-32 3xl:w-40 3xl:h-40">
                    <pictogram :data="block.grid_one.icon" :color="block.grid_one.iconColor" />
                </div>

                <h3 v-if="block.grid_one.heading" class="heading">{{ block.grid_one.heading }}</h3>
                <p v-if="block.grid_one.text" class="text">{{ block.grid_one.text }}</p>
            </div>

            <div class="item two">
                <svg id="arrow-three" xmlns="http://www.w3.org/2000/svg" width="606.095" height="125.126" viewBox="0 0 606.095 125.126">
                    <g transform="translate(-866.531 -1539.998)">
                        <path d="M-10563.82-6600.054l14.794,14.821-14.794,15.373" transform="translate(8242.12 12025.643) rotate(25)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="2"/>
                        <path d="M-10329.055-6585.6s236.579-228.115,600.245-62.3" transform="translate(11197 8249.307)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="2" stroke-dasharray="20"/>
                    </g>
                </svg>


                <svg id="arrow-two" xmlns="http://www.w3.org/2000/svg" width="187.874" height="33.022" viewBox="0 0 187.874 33.022">
                    <g transform="translate(-842 -1677.795)">
                        <line x2="144" transform="translate(875 1694.309)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="2" stroke-dasharray="20"/>
                        <path d="M-10563.82-6600.054l14.794,14.821-14.794,15.373" transform="translate(11577.5 8279.264)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="2"/>
                        <circle cx="9" cy="9" r="9" transform="translate(842 1685.309)" fill="#093640"/>
                    </g>
                </svg>

                <div
                :v-if="block.grid_two.icon"
                class="illustration flex-none  w-24 h-24 lg:w-32 lg:h-32 3xl:w-40 3xl:h-40">
                    <pictogram :data="block.grid_two.icon" :color="block.grid_two.iconColor" />
                </div>

                <h3 v-if="block.grid_two.heading" class="heading">{{ block.grid_two.heading }}</h3>
                <p v-if="block.grid_two.text" class="text">{{ block.grid_two.text }}</p>

                <svg id="arrow-six" xmlns="http://www.w3.org/2000/svg" width="115.127" height="102.434" viewBox="0 0 115.127 102.434">
                    <g transform="translate(-137.985 -873.412)">
                        <line y2="88.216" transform="translate(193 888.412) rotate(30)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="1" stroke-dasharray="10"/>
                        <line y2="88.216" transform="translate(198.095 888.412) rotate(-30)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="1" stroke-dasharray="10"/>
                        <path d="M0,0,7.007,7.02,0,14.3" transform="translate(154.835 968.818) rotate(120)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="1"/>
                        <path d="M0,0,7.007,7.02,0,14.3" transform="translate(248.646 961.667) rotate(60)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="1"/>
                        <circle cx="5" cy="5" r="5" transform="translate(190 873.412)" fill="#093640"/>
                    </g>
                </svg>

            </div>

            <div class="item three">
                <div
                :v-if="block.grid_three.icon"
                class="illustration flex-none  w-24 h-24 lg:w-32 lg:h-32 3xl:w-40 3xl:h-40">
                    <pictogram :data="block.grid_three.icon" :color="block.grid_three.iconColor" />
                </div>

                <h3 v-if="block.grid_three.heading" class="heading">{{ block.grid_three.heading }}</h3>
                <p v-if="block.grid_three.text" class="text">{{ block.grid_three.text }}</p>

                <svg id="arrow-four" xmlns="http://www.w3.org/2000/svg" width="33.022" height="77.901" viewBox="0 0 33.022 77.901">
                    <g transform="translate(-1151.989 -2035.5)">
                        <line x1="0.001" y2="63" transform="translate(1168.498 2036.5)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="2" stroke-dasharray="20"/>
                        <path d="M-10563.82-6600.054l14.794,14.821-14.794,15.373" transform="translate(-5416.457 12661.027) rotate(90)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="2"/>
                    </g>
                </svg>

            </div>

            <div class="item four">
                <div
                :v-if="block.grid_four.icon"
                class="illustration flex-none  w-24 h-24 lg:w-32 lg:h-32 3xl:w-40 3xl:h-40">
                    <pictogram :data="block.grid_four.icon" :color="block.grid_four.iconColor" />
                </div>

                <h3 v-if="block.grid_four.heading" class="heading">{{ block.grid_four.heading }}</h3>
                <p v-if="block.grid_four.text" class="text">{{ block.grid_four.text }}</p>

                <svg id="arrow-five" xmlns="http://www.w3.org/2000/svg" width="206.701" height="260.19" viewBox="0 0 206.701 260.19">
                    <g transform="translate(-1385.202 -2026.321)">
                        <path d="M-10563.82-6600.054l14.794,14.821-14.794,15.373" transform="translate(-9162.424 -4314.957) rotate(180)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="2"/>
                        <path d="M73.794-7492.472s191.036-13.342,192.309-242.674" transform="translate(1324.794 9762.473)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="2" stroke-dasharray="20"/>
                    </g>
                </svg>

            </div>

            <div class="item last">
                <svg id="arrow-seven" xmlns="http://www.w3.org/2000/svg" width="181.366" height="88.117" viewBox="0 0 181.366 88.117">
                    <g transform="translate(-109.317 -1428.728)">
                        <line y2="88.216" transform="translate(110 1429.412) rotate(-30)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="1" stroke-dasharray="10"/>
                        <line y2="88.216" transform="translate(290 1429.411) rotate(30)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="1" stroke-dasharray="10"/>
                        <path d="M0,0,7.007,7.02,0,14.3" transform="translate(160.551 1502.667) rotate(60)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="1"/>
                        <path d="M0,0,7.007,7.02,0,14.3" transform="translate(251.836 1509.817) rotate(120)" fill="none" stroke="#093640" stroke-linecap="round" stroke-width="1"/>
                    </g>
                </svg>

                <div
                :v-if="block.grid_box.icon"
                class="illustration flex-none  w-24 h-24 lg:w-32 lg:h-32 3xl:w-40 3xl:h-40">
                    <pictogram :data="block.grid_box.icon" :color="block.grid_box.iconColor" />
                </div>

                <div class="content">
                    <h3 v-if="block.grid_box.heading" class="heading">{{ block.grid_box.heading }}</h3>
                    <p v-if="block.grid_box.text" class="text">{{ block.grid_box.text }}</p>
                  
                    <a v-if="block.grid_box.link"
                        :href="block.grid_box.link.url" 
                        :target="block.grid_box.link.target || '_self'" 
                        rel="noopener noreferrer" 
                        class="link-button">
                        {{ block.grid_box.link.title }}
                        <svg class="flex-none h-4 w-auto fill-current px-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z"/></svg>
                    </a>

                </div>

            </div>

        </div>
    </div>
  
  </template>
  
  <script>
  import Pictogram from '@/component/Block/Pictogram'

  export default {
    name: 'InfoGraphic',
    
    props: {
      block: Object,
    },
    components: {
        Pictogram,
    },
  }
  </script>
  