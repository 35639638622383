<template>

  <div
  v-if="acf"
  class="type"
  :class="[post.type]"
  :data-slug="post.slug">

    <section
    v-if="acf.blocks"
    v-intersect="{observerOptions: {rootMargin: '0px', threshold: 0.0}, true: ['-intersected'], disposeWhen: true }"
    v-for="(block, index) in acf.blocks"
    :key="`section_${block.acf_fc_layout}_${index}`"
    class="section"
    :class="[block.acf_fc_layout, block.options && block.options.layout ? block.options.layout : '', (block.acf_fc_layout === 'quote' && block.options.color) ? 'bg-' +block.options.color : null]">

      <component
      :is="block.acf_fc_layout"
      :block="block"
      :options="block.options"
      :key="`component_${block.acf_fc_layout}_${index}`" />

    </section>

  </div>
</template>

<script>
// import { EventBus } from '@/store/hero.js';

import postMeta from '@/mixins/postMeta.js';

import Accordion from '@/component/Post/Accordion'
import Related from '@/component/Block/Related'

import Media from '@/component/Block/Media'
import Oembed from '@/component/Block/Oembed'
import Quote from '@/component/Block/Quote'
import Paragraph from '@/component/Block/Paragraph'
import VideoText from '@/component/Block/VideoText'




export default {
  components: {
    Accordion,
    Media,
    Oembed,
    Paragraph,
    Quote,
    Related,
    VideoText,
  },
  data : () => ({
    // post: null,
    // hero: [],
    // post: [],
    categories: [],
    // acf: Object,
    errors: [],
    baseURL: process.env.MIX_API_BASE_URL,
  }),

  mixins: [
    postMeta,
  ],

  computed: {
    post() {
      // console.log("VIEW: ARTICLE", this.$route);
      let sTheSlug = this.$route.params.slug;
      if (sTheSlug) {
        return this.$store.getters.getPostBySlug(sTheSlug);
      } else {
        // console.log("ARTICLE NOT FOUND", this.$route);
        return false
      }
    },
    acf() {
      if (this.post) {
        return this.post.acf
      }
      return false
    }
  },

  methods: {
    async getPage() {
      try {
        const response = await axios.get(
          this.baseURL + '/api/wp/v2/posts', {
            params: {
              slug: this.$route.params.slug,
              post_type: 'post',
            }
          }
        )
        const results = response.data
        if ( !results || results.length == 0 ) {
          // console.log('Error, 404')
          const e = new Error('No data');
          e.statusCode = 404;
          throw e;
        }
        this.post = results.map(post => ({
          id: post.id,
          type: post.type,
          slug: post.slug,
          title: post.title.rendered,
          media: post.featured_media,
          head: post.yoast_head_json,
        }))[0]

        this.acf = results.map(post => ({
          components: post.acf.blocks ? post.acf.blocks : false
        }))

        this.hero = results.map(post => ({
          id: post.id,
          label: false,
          title: post.acf.hero.title ? post.acf.hero.title : post.title.rendered,
          excerpt: post.acf.excerpt.excerpt,
          type: post.type,
          date: post.date,
          modified: post.modified,
          url: post.link,
          layout: this.setLayout(post.acf.options.layout),
          content: (post.acf.options.layout != 'superimpossed' ) ? this.setContent(post.type, post.acf.options.layout, post.acf.hero) : false,
          breadcrumb: ['post', 'ressurs'].includes(post.type) ? post.categories : false,
          color: post.acf.options.color ? post.acf.options.color : 'gray-light',
          image: false,
          video: false,
        }))
      }
      catch (e) {
        this.errors.push(e)
        this.$router.push({ name: 'PageNotFound' })
      }
      return this.doAction()

    },

    doAction() {
      // EventBus.$emit('EventBus', this.hero[0])
      // this.setHero(this.hero[0])
      this.$store.dispatch('updateHero', this.hero[0]);

      if (this.post && this.post.title) {
        this.$store.dispatch('setViewTitle', this.post.title);
      }
      // document.title = this.$store.getters.getTitle(this.post.title);
    },

    setHero(data) {
      // EventBus.$emit('EventBus', data)
    },

    resetData() {
      this.acf = Object
    },

    // setBreadcrumbs() {
    //   console.log(this.categories)
    //   return this.categories
    // },

    setContent(type, style, post) {

      let data = Object

      if (['post', 'page'].includes(type)) {
        switch (style) {
          case 'content':
            data = {
              color: post.sidebar.color ? post.sidebar.color : 'green-light',
              title: post.content.content.subtitle ? post.content.content.subtitle : false,
              wysiwyg: post.content.content.wysiwyg ? post.content.content.wysiwyg : false,
              links: post.content.links
            }
            break;
          case 'icon':
            data = {
              color: post.icon.iconColor,
              item: post.icon.icon ? post.icon.icon : false
            }
            break;

          case 'illustration':
            data = {
              item: post.illustration.illustration ? post.illustration.illustration : false
            }
            break;

          default:
            data = false
        }
      } else {
        data = {
          color: post.sidebar.color
        }
      }
      return data

    },

    setLayout(e) {
      let expr = e
      let data = String

      switch (expr) {
        case 'superimposed':
          data = e
          break;

        default:
          data = e
      }
      return data
    },

    observerElement() {
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if(entry.isIntersecting){
            // console.log('Observed');
          }
        });
      },
      {
        rootMargin: '-50% 0% -50% 0%'
      })

      document.querySelectorAll('.article').forEach(item => {
        observer.observe(item)
      })
    }
  },

  mounted() {
    // this.getPage()
  },

  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     vm.resetData()
  //     vm.getPage()
  //   })
  // },

  watch: {
    $route: {
      immediate: true,
      handler: function(newVal, oldVal) {
        this.isModalVisible = newVal.meta && newVal.meta.isModalVisible;
      }
    },
    // '$route.params.slug'(newSlug, oldSlug) {
    //   this.resetData()
    //   this.getPage()
    // }
  }
}
</script>
