<template>
  <div
  v-if="post"
  class="type"
  :class="[post.type]"
  :data-slug="post.slug">

    <section class="component login forgotten bg-gray-light">

      <div class="component-inner">
        <div class="flex flex-col lg:flex-row gap-8 xl:gap-20 2xl:gap-32 w-full max-w-screen-2xl mx-auto py-20 pt-40 xl:pt-40 z-10">
          <header class="w-full max-w-xl lg:max-w-[400px] xl:max-w-[600px]">
            <h1>{{ post.title }}</h1>
            <div class="lead regular" v-html="post.excerpt.excerpt" />
          </header>

          <div class="hero-aside w-full flex-1 self-end">

            <form v-on:submit.prevent="sendRequest" v-if="!passwordRequestSent">
              <div class="form-group text-left mb-5">
                <label class="custom-label control-label hidden">Epostadresse</label>
                <input class="form-control w-full p-4 bg-white text-base md:text-2xl" type="text" name="email" placeholder="Epostadresse" v-model="email">
              </div>
              <div class="text-red-400" v-if="error">
                <p>Feil brukernavn eller passord</p>
              </div>
              <button class="green text-green-dark my-10 py-8" type="submit">Nullstill passord</button>


            </form>

            <div v-else>
              Takk, sjekk epost
            </div>

          </div>

        </div>

      </div>

    </section>

  </div>
</template>

<script>
// import { EventBus } from '@/store/hero.js';
import postMeta from '@/mixins/postMeta.js';

export default {
  components: {},

  data : () => ({
    // hero: [],
    // post: null,
    errors: [],
    error: Boolean = false,
    observer: null,
    password: null,
    passwordRequestSent: false,
    baseURL: process.env.MIX_API_BASE_URL,
    email: "",
  }),

  mixins: [
    postMeta,
  ],

  computed: {
    post() {
      const post = this.$store.getters.getPageBySlug("glemt-passord");

      if (post) {
        return {
          id: post.id,
          type: post.type,
          slug: post.slug,
          title: post.title.rendered,
          excerpt: post.acf.excerpt,
          media: post.featured_media,
          template: post.template ? post.template : 'default',
          head: post.yoast_head_json
        }
      }
      return false
    }
  },

  methods: {
//     async getPage() {
//       try {
//         const response = await axios.get(
//           this.baseURL + '/api/wp/v2/pages', {
//             params: {
//               slug: 'glemt-passord',
//             }
//           }
//         )
//         const results = response.data
//         //console.log( response.data);
//         if ( !results || results.length == 0 ) {
//           const e = new Error('No data');
//           e.statusCode = 404;
//           throw e;
//         }
//
//         this.post = results.map(post => ({
//           id: post.id,
//           type: post.type,
//           slug: post.slug,
//           title: post.title.rendered,
//           excerpt: post.acf.excerpt,
//           media: post.featured_media,
//           template: post.template ? post.template : 'default',
//           head: post.yoast_head_json,
//         }))[0]
//
//         this.hero = false
//
//       }
//       catch (e) {
//         this.errors.push(e)
//         this.$router.push({ name: 'PageNotFound' })
//       }
//       return this.doAction()
//
//     },

    doAction() {
      // EventBus.$emit('EventBus', this.hero[0])
      this.$store.dispatch('updateHero', this.hero[0]);

      if (this.post.length && this.post.title) {
        this.$store.dispatch('setViewTitle', this.post.title);
      }
      // this.setHero(this.hero[0])
    },

    setHero(data) {
      // EventBus.$emit('EventBus', data)
    },

    resetData() {},

    sendRequest() {


    },

    validateBeforeSubmit () {
      // if (this.password === 'nfpdemo') {
      //   this.error = false
      //   localStorage.setItem('user-password', this.password)
      //   this.$router.push('/kompasset')
      // } else {
      //   this.error = true
      // }
    },

    requestPasswordReset(sThePassword) {
      //
      this.requestPasswordReset = true
    }
  },

  mounted() {
    this.$store.dispatch("updateHero", false);
    // this.getPage()
  },

  watch: {
    $route: {
      immediate: true,
      handler: function(newVal, oldVal) {
        this.isModalVisible = newVal.meta && newVal.meta.isModalVisible;
      }
    },
    // '$route.params.slug'(newSlug, oldSlug) {
      // this.resetData()
      // this.getPage()
    // }
  }
}
</script>
