<template>
  <div class="component-inner">

    <header class="mb-28" v-if="block.title">
      <h2 class="mb-0">{{ block.title }}</h2>
    </header>

    <div class="mx-auto max-w-screen-xl">

      <article
      v-for="(post) in posts"
      :key="post.id"
      class="flex flex-col lg:flex-row gap-8 justify-between lg:items-end mb-8">

        <figure class="flex-1 -mx-8 lg:mx-0">

          <router-link
          class=""
          :to="post.url">

          <img
          v-if="post.media"
          class="lg:aspect-video object-cover"
          :src="`${baseURL}/${post.media.media_details.sizes['large'].source_url}`"
          :height="post.media.media_details.sizes['large'].height"
          :width="post.media.media_details.sizes['large'].width"
          :alt="post.media.alt" />

          </router-link>

        </figure>

        <header class="flex-1">
          <h3>
            <router-link
            class="underline hover:no-underline"
            :to="'/' +(post.type === 'post' ? 'artikler/' : '') +post.slug">
            {{ post.title }}
            </router-link>
          </h3>

          <div class="lead regular" v-html=" post.excerpt" />
        </header>

      </article>

    </div>

  </div>


</template>

<script>
export default {
  name: 'Article',
  props: {
    block: Object
  },
  data() {
    return {
      posts: [],
      baseURL: process.env.MIX_API_BASE_URL,
    }
  },
  methods: {
    async getPosts(id) {
      try {
        const response = await axios.get(
          this.baseURL + '/api/wp/v2/posts', {
            params: {
              categories: id,
            }
          }
        )
        const results = response.data
        if ( !results || results.length == 0 ) {}
        this.posts = results.map(post => ({
          id: post.id,
          type: post.type,
          slug: post.slug,
          url: post.vueData.url,
          title: post.title.rendered,
          excerpt: post.acf.excerpt.excerpt,
          media: post.better_featured_image
        }))

      }
      catch (e) {
        this.errors.push(e)
      }
      return this.doAction()

    },

    extractImage(post) {
      const defaultImg = {
        url: 'http://placehold.it/210x140?text=N/A',
        caption: post.title,
      }
      if (!post.multimedia) {
        return defaultImg
      }
      let imgObj = post.multimedia.find(
        media => media.format === 'mediumThreeByTwo210'
      )
      return imgObj ? imgObj : defaultImg
    },

    doAction() {},

    resetData() {},
  },

  mounted() {
    this.getPosts(this.$props.block.category.term_id)
  },

}
</script>
