// jQuery (document).ready (function ($) {
//
//    var t, c;
//    var SECOND = 1000;
//    var MINUTE = 60 * SECOND;
//    var warn_span = 60;
//    var logout_span = counter = 30;
//
//    function countdown ()
//    {
//        $("#message").html ("<font color=red>" + i18n.WARN_IDLE_START + " " + counter + " " + i18n.WARN_IDLE_END + "</font>");
//       if (counter--) c = setTimeout (countdown, SECOND);
//    }
//
//    function warn ()
//     {
//        countdown ();
//        clearTimeout (t);
//        // logout defined in pedigree.js
//       t = setTimeout (logout, logout_span * SECOND);
//     }
//
//     function reset ()
//     {
//       clearTimeout (t);
//       clearTimeout (c);
//       counter = logout_span;
//       $("#message").html ("");
//       t = setTimeout (warn, warn_span * MINUTE);
//     }
//
//    $("body").on ("load click mousemove mousedown scroll keypress keyup", reset);
//
//    reset ();
//
// });

import _throttle from 'lodash/throttle'

export default {
  data: () => ({
    eventList: "load click mousemove mousedown scroll keypress keyup touchstart",
    waitForJQ: null,
    t: null,
    c: null,
    SECOND: 1000,
    warn_span: 60,
    logout_span: 30,
    counter: 30,
    handleThrottledReset: null,
  }),
  computed: {
    MINUTE() {
      return 60 * this.SECOND;
    },
    logoutWarningMessage() {
      return `Du vil snart logges ut pga. inaktivitet. Utlogging om ${this.counter} sekunder`
    },
    showIdleWarning() {
      if (this.c && this.userID) {
        return true
      }
      return false
    },
    userID() {
      return this.$store.getters.getUserID
    },
    timestamp() {
      return this.$store.getters.getTimestamp
    }
  },
  methods: {
    checkTimestamp(newStamp) {
      if (!isNaN(this.timestamp)) {
        let iTheDifference = Math.floor((newStamp - this.timestamp) / 1000 / 60);

        if (iTheDifference <= this.warn_span) {
          return true
        }
        return false
      }
      return false
    },
    countdown() {
      // console.log("countdown called", this.counter);

      if (this.counter) {
        this.counter -= 1;
        this.c = setTimeout(() => {
          this.countdown();
        }, this.SECOND)
      }
    },
    warn() {
      this.countdown();
      clearTimeout(this.t);

      this.t = setTimeout(() => {
        this.logout();
      }, this.logout_span * this.SECOND)
    },
    reset() {
      // console.log("reset called", this.checkTimestamp(Date.now()));
      clearTimeout(this.t);
      clearTimeout(this.c);

      let now = Date.now();

      console.log("RESET", now);

      if (this.checkTimestamp(now)) {
        this.$store.dispatch("setTimestamp", now);
      }

      // console.log("reset called", this.t, this.c);
      this.counter = this.logout_span;
      this.c = null;

      this.t = setTimeout(() => {
        this.warn();
      }, this.warn_span * this.MINUTE)

    },
    logout() {
      this.$store.dispatch("logOut");
      this.removeIdleListeners();
    },
    handleIdle() {
      if (this.checkTimestamp(Date.now()) && (this.userID || this.c)) {
        this.reset();
      }
    },
    addIdleListeners() {
      this.reset();
      this.eventList.split(" ").forEach((eventName) => document.body.addEventListener(eventName, this.handleThrottledReset));
    },
    removeIdleListeners() {
      console.log("removing logout listener");
      this.eventList.split(" ").forEach((eventName) => document.body.removeEventListener(eventName, this.handleThrottledReset));
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.handleThrottledReset = _throttle(this.handleIdle, 10000);

      // let now = Date.now();
      // this.checkTimestamp(now);

      // console.log("MOUNTED", this.userID, "timeout:", this.checkTimestamp(Date.now()));

      if (this.userID && this.checkTimestamp(Date.now())) {
        this.addIdleListeners();
      } else if (this.userID) {
        if (window.Ajax) {
          this.logout();
        } else {
          this.waitForJQ = setInterval(() => {
            if (window.Ajax) {
              clearInterval(this.waitForJQ);
              this.logout();
            }
          }, 50);
        }

      }
    })
  },
  beforeDestroy() {
    this.removeIdleListeners();
  },
  watch: {
    userID: {
      // immediate: true,
      handler(id) {
        // console.log("userID change", id, "timeout:", this.checkTimestamp(Date.now()));

        if (id && this.checkTimestamp(Date.now())) {
          // this.$nextTick
          this.addIdleListeners();
        } else {
          // this.waitForJQ = setInterval(() => {
          //   if (window.Ajax) {
          //     clearInterval(this.waitForJQ);
          //     this.logout();
          //   }
          // }, 50);
        }
      }
    }
  }
}
