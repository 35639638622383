<template>

  <div class="component-inner">

    <article
    class=""
    v-if="post && post">

      <router-link
      class="text-xl text-black flex flex-col gap-8 -card"
      :to="post.url">


        <figure
        v-if="post.media.image"
        class="flex justify-items-stretch">

          <img
          class="object-cover aspect-video"
          :src="`${baseURL}/${post.media.image.sizes['large']}`"
          :height="post.media.image.sizes['large-height']"
          :width="post.media.image.sizes['large-width']"
          :alt="post.media.image.alt" />

        </figure>


        <header class="text-black justify-items-stretch">
          <h3 class="medium mb-5 underline">{{ post.title }}</h3>
          <div class="lead book" v-html="post.excerpt.excerpt" />
        </header>

      </router-link>

    </article>

  </div>

</template>

<script>

export default {
  name: 'Feature',
  props: {
    block: Object
  },
  data : () => ({
    post: null,
    acf: Object,
    errors: [],
    baseURL: process.env.MIX_API_BASE_URL,
  }),
  methods: {

    extractImage(post) {
      const defaultImg = {
        url: 'http://placehold.it/210x140?text=N/A',
        caption: post.title,
      }
      if (!post.multimedia) {
        return defaultImg
      }
      let imgObj = post.multimedia.find(
        media => media.format === 'mediumThreeByTwo210'
      )
      return imgObj ? imgObj : defaultImg
    },

    async getPost() {
      try {
        const type = `${this.$props.block.feature.item.post_type}s`
        const response = await axios.get(
          this.baseURL + `/api/wp/v2/${type}` , {
            params: {
              slug: this.$props.block.feature.item.post_name,
            }
          }
        )
        const results = response.data
        if ( !results || results.length == 0 ) {

        }
        this.post = results.map(post => ({
          id: post.id,
          type: post.type,
          slug: post.slug,
          url: post.vueData.url,
          title: post.title.rendered,
          excerpt: post.acf.excerpt,
          media: post.acf.hero.media.landscape ? post.acf.hero.media.landscape : '',
        }))[0]

        this.acf = results.map(post => ({
          components: post.acf.blocks
        }))
      }
      catch (e) {
        this.errors.push(e)
      }
      return this.doAction()

    },

    doAction() {},
  },
  mounted() {
    this.getPost()
  },
}
</script>
