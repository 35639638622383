export default {
  data: () => ({
    observer: null,
    intersecting: [],
    // windowHeight: window.innerHeight,
  }),
  computed: {
    windowHeight() {
      return this.$store.getters.getWindowHeight
    },
    scrollTop() {
      return this.$store.getters.getScrollTop
    },
  },
  methods: {
    // onResize() {
    //   this.windowHeight = window.innerHeight;
    //   // console.log(this.windowHeight);
    // },
    observeElements(entries) {
      entries.map((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("-intersecting");
          // entry.target.classList.remove("-not-intersecting");
        } else {
          entry.target.classList.remove("-intersecting");
          // entry.target.classList.add("-not-intersecting");
        }
      });
    },
  },
  created() {
    // itemObserver.observe($(it)[0], { root: document.body, threshold: 0, rootMargin: '8rem' });
    this.observer = new IntersectionObserver(this.observeElements);
  },

  mounted() {
    // this.$nextTick(() => {
    //   window.addEventListener("resize", this.onResize)
    // })
  },

  beforeDestroy() {
    // window.removeEventListener("resize", this.onResize)
  },




//     observerElement() {
//       let observer = new IntersectionObserver((entries, observer) => {
//         entries.forEach(entry => {
//           if(entry.isIntersecting){
//             //console.log('Observed');
//           }
//         });
//       },
//       {
//         rootMargin: '-50% 0% -50% 0%'
//       })
//
//       document.querySelectorAll('.article').forEach(item => {
//         observer.observe(item)
//       })
//     }
}
