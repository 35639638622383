/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap')

window.Vue = require('vue').default

import router from '@/router/index.js'
import App from '@/App.vue'

import store from '@/store'

import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
  // optional pluginOptions
  keyName: "metaInfo",
  // refreshOnceOnNavigation: true
})

import VueIntersect from 'vue-intersect-directive'
Vue.use(VueIntersect)


Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('camelCase', function (str) {
  return str.toLowerCase()
    .replace( /[-_]+/g, ' ')
    .replace( /[^\w\s]/g, '')
    .replace( / (.)/g, function($1) { return $1.toUpperCase(); })
    .replace( / /g, '' );
})


Vue.filter('formatDate', function(value) {
  if (value) {
    const date = new Date(value)
    const options = {  year: 'numeric', month: 'numeric', day: 'numeric' }
    return date.toLocaleDateString('nb-NO', options)
  }
})

Vue.config.debug = true;
Vue.config.devtools = true;


// router.beforeEach((to, from, next) => {
//   if (to.meta.requiresAuth)
//     if (!localStorage.getItem('user-id')) next('/innlogging')
//     else next()
//   else
//     next()
// })

// router.afterEach((to, from) => {
  // Vue.nextTick(() => {
    // console.log('meta', to.meta, 'params', to.params);
    // if (to.params && to.params.title) {
    //   document.title = to.params.title + ' | Sammen på vei'
    // } else if (to.meta && to.meta.title) {
    //   document.title = to.meta.title + ' | Sammen på vei'
    // } else {
    //   document.title = 'Sammen på vei'
    // }
  // })
// })

const app = new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App)
})
