<template>

  <div class="component-inner">

    <template v-if="block.navigation_cards.navigation_card && block.options.layout !== 'single'">

      <article
      v-for="(item, index) in block.navigation_cards.navigation_card"
      :key="index"
      class="card-item  content-center items-center justify-between"><!--lg:flex-->

        <header class="card-header flex-1 mb-12">

          <div class="wrapper">

            <h3 class="title medium mb-3">{{ item.title }}</h3>
            <h3 class="subtitle regular" v-if="item.subtitle && item.subtitle.length">{{ item.subtitle }}</h3>

            <router-link
            v-if="item.button"
            class="read-more-link button-text flex items-center lg:text-2xl text-brand-green"
            :to="relativeURL(item.button.url.ID)">
              <span>{{ item.button.label ? item.button.label : 'Les mer' }}</span>

              <icon graphic="arrow" class="arrow-right"/>
            </router-link>

          </div>

        </header>

        <div
        :v-if="item.illustration"
        class="illustration flex-1 aspect-video lg:aspect-square">
          <Illustration :data="item.illustration" />
        </div>

      </article>

    </template>

    <article
    v-if="block.single_card && block.options.layout === 'single'"
    class="card-item flex  flex-wrap lg:content-center lg:items-center">

      <header class="card-header flex-1">
        <h5 class="book lead mb-6">{{ block.single_card.super }}</h5>
        <h3 class="block-heading medium">{{ block.single_card.title }}</h3>

        <router-link
        v-if="block.single_card.button"
        class="read-more-link button-text flex items-center text-xl lg:text-2xl text-brand-green"
        :to="relativeURL(block.single_card.button.url.ID)">
          <span>{{ block.single_card.button.label ? block.single_card.button.label : 'Les mer' }}</span>

          <icon graphic="arrow" class="arrow-right"/>
        </router-link>

      </header>

      <div
      :v-if="block.options.icons.icon"
      class="illustration flex-none  w-24 h-24 lg:w-32 lg:h-32 3xl:w-40 3xl:h-40">
        <pictogram :data="block.options.icons.icon" :color="block.options.icons.iconColor" />
      </div>

    </article>

  </div>

</template>

<script>
import Icon from '@/component/Icon'
import Pictogram from '@/component/Block/Pictogram'
import Illustration from '@/component/Block/Illustration'

export default {
  name: 'Card',
  props: {
    block: Object
  },
  components: {
    Icon,
    Pictogram,
    Illustration,
  },
  methods: {
    relativeURL(id) {
      return this.$store.getters.getRelativeUrl(id)
//       let filteredPosts = ''
//
//       if ( this.$store.getters.getPagesAndPosts.find( post => post.id === id ) ) {
//         filteredPosts = this.$store.getters.getPagesAndPosts.find( post => post.id === id )
//         filteredPosts = filteredPosts.vueData.url
//       }
//
//       //console.log(filteredPosts)
//       return filteredPosts

    }
  },
}
</script>
