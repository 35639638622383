<template>

  <div class="component-inner">

    <router-link
    v-if="block.button"
    class="internal-button"
    :class="[(['text'].includes(block.button.style)) ? 'button-text' : 'button', block.button.style]"
    :to="relativeURL(block.button.url.ID)">
      <span class="">{{ block.button.label ? block.button.label : block.button.url.post_title }}</span>

      <icon graphic="arrow-stroke" class="arrow-right"/>

    </router-link>

  </div>

</template>

<script>
import Icon from '@/component/Icon'

export default {
  name: 'InternalButton',
  props: {
    block: Object,
    options: Object
  },
  components: {
    Icon,
  },
  methods: {
    relativeURL(id) {
      return this.$store.getters.getRelativeUrl(id)
//       let filteredPosts = ''
//
//       if ( this.$store.getters.getPagesAndPosts.find( post => post.id === id ) ) {
//         filteredPosts = this.$store.getters.getPagesAndPosts.find( post => post.id === id )
//         filteredPosts = filteredPosts.vueData.url
//       }
//
//       //console.log(filteredPosts)
//       return filteredPosts

    }
  },
}
</script>
