<template>

  <div
  v-if="acf"
  class="main-content type post"
  :class="[post.type]"
  :data-slug="post.slug">

    <!-- <Tips
    v-if="tips && tips[0].content"
    :data="tips" /> -->

    <section
    v-if="acf.blocks"
    v-intersect="{observerOptions: {rootMargin: '0px', threshold: 0.0}, true: ['-intersected'], disposeWhen: true }"
    v-for="(block, index) in acf.blocks"
    :key="block.acf_fc_layout + index"
    class="section"
    :class="[block.acf_fc_layout, (block.acf_fc_layout === 'quote' && block.options.color) ? 'bg-' +block.options.color : null]">

      <component
      :is="block.acf_fc_layout"
      :block="block"
      :options="block.options"
      :key="index" />

    </section>

  </div>
</template>

<script>
// import { EventBus } from '@/store/hero.js';

import postMeta from '@/mixins/postMeta.js';

import Tips from '@/component/Block/Tips'


import Accordion from '@/component/Post/Accordion'
import Related from '@/component/Block/Related'

import Media from '@/component/Block/Media'
import Oembed from '@/component/Block/Oembed'
import Quote from '@/component/Block/Quote'
import Paragraph from '@/component/Block/Paragraph'

export default {
  components: {
    Accordion,
    Media,
    Oembed,
    Paragraph,
    Quote,
    Related,
    Tips
  },

  data: () => ({
    // post: [],
    // head: null,
    tips: [],
    // acf: Object,
    errors: [],
    baseURL: process.env.MIX_API_BASE_URL,
    // documentHead: "",
  }),

  mixins: [
    postMeta,
  ],

  computed: {
    post() {
      return this.$store.getters.getResourceBySlug(this.$route.params.slug)
    },
    title() {
      if (this.post) {
        return this.post.title
      }
    },
    acf() {
      if (this.post) {
        return this.post.acf
      }
      return null
    },
  },

  methods: {
    async setPage() {
      console.log("setPage", this.$route.params.slug);
      // this.post = await this.$store.getters.getResourceBySlug(this.$route.params.slug);
      console.log("setPage", this.post);
      // this.$store.dispatch('updateHero', this.hero);
      // console.log(this.post);
      // this.$store.dispatch('setViewTitle', (this.post.title));
    },
//     async getPage() {
//       try {
//         const response = await axios.get(
//           this.baseURL + 'api/wp/v2/ressursbank', {
//             params: {
//               slug: this.$route.params.slug,
//             }
//           }
//         )
//
//         const results = response.data;
//         if ( !results || results.length == 0 ) {
//           this.errors.push('Error, ' +results)
//           //console.log('Error, 404')
//           const e = new Error('No data');
//           e.statusCode = 404;
//           throw e;
//         }
//
//         console.log("RESOURCE SINGLE", results);
//
//         this.post = results.map(post => ({
//           id: post.id,
//           type: post.type,
//           slug: post.slug,
//           title: post.title.rendered,
//           media: post.featured_media,
//           head: post.yoast_head_json,
//         }))
//
//         this.acf = results.map(post => ({
//           components: post.acf.blocks
//         }))
//
//         this.hero = results.map(post => ({
//           id: post.id,
//           label: post.acf.excerpt.super ? post.acf.excerpt.super : false,
//           title: post.acf.hero.title ? post.acf.hero.title : post.title.rendered,
//           excerpt: post.acf.excerpt.excerpt ? post.acf.excerpt.excerpt : false,
//           type: post.type,
//           date: post.date,
//           modified: post.modified,
//           url: post.link,
//           layout: this.setLayout(post.type),
//           breadcrumb: ['post', 'ressurs'].includes(post.type) ? post.omrader : false,
//           content: (post.acf.options.layout != 'superimpossed' ) ? this.setContent(post.type, post.acf.options.layout, post.acf.hero, post.acf.assets) : false,
//           color: post.acf.options.color ? post.acf.options.color : 'gray-light',
//           image: false,
//           video: false,
//         }))
//       }
//       catch (e) {
//         console.error('RESOURCE', e);
//         this.errors.push(e)
//         this.$router.push({ name: 'PageNotFound' })
//       }
//
//       return this.doAction()
//
//     },
//
//     doAction() {
//       // EventBus.$emit('EventBus', this.hero[0])
//       this.$store.dispatch('updateHero', this.hero[0]);
//       console.log(this.post);
//       // document.title = `${this.post.title} | ${this.$store.getters.getTitle}`;
//       this.$store.dispatch('setViewTitle', (this.post.title));
//
//       this.$store.dispatch('updateHero', this.hero[0]);
//       // this.setHero(this.hero[0])
//     },

    resetData() {
      this.acf = Object
    },

//     setContent(type, style, post, assets) {
//
//       let data = Object
//
//       //console.log(post.assets)
//
//       if (['post', 'page'].includes(type)) {
//         switch (style) {
//           case 'content':
//             data = {
//               color: post.sidebar.color,
//               title: post.content.content.subtitle ? post.content.content.subtitle : false,
//               wysiwyg: post.content.content.wysiwyg ? post.content.content.wysiwyg : false,
//               links: post.content.links
//             }
//             break;
//           case 'icon':
//             data = {
//               color: post.sidebar.color,
//               item: post.icon.icon ? post.icon.icon : false
//             }
//             break;
//
//           case 'illustration':
//             data = {
//               color: post.sidebar.color,
//               item: post.illustration.illustration ? post.illustration.illustration : false
//             }
//             break;
//
//           default:
//             data = false
//         }
//       } else {
//         data = {
//           color: post.sidebar.color ? post.sidebar.color : 'blue-light',
//           links: assets ? assets.assets : false
//         }
//       }
//       return data
//
//     },
//
//     setLayout(e) {
//       let expr = e
//       let data = String
//
//       //console.log(e)
//
//       switch (expr) {
//         case 'superimposed':
//           data = e
//           break;
//
//         default:
//           data = e
//       }
//       return data
//     },

    observerElement() {
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if(entry.isIntersecting){
            //console.log('Observed');
          }
        });
      },
      {
        rootMargin: '-50% 0% -50% 0%'
      })

      document.querySelectorAll('.article').forEach(item => {
        observer.observe(item)
      })
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.dispatch('setActiveResource', to.params.slug)
      // vm.$meta().refresh()
      // vm.setPage()
      // vm.resetData()
      // vm.getPage()
    })
  },

  watch: {
    $route: {
      immediate: true,
      handler: function(newVal, oldVal) {
        this.isModalVisible = newVal.meta && newVal.meta.isModalVisible;
      }
    },

    // '$route.params.slug'(newSlug, oldSlug) {
    //   this.resetData()
    //   this.getPage()
    // }

  }
}
</script>
