<template>

  <div class="component-inner border-t border-brand-green pt-10">

    <header class="flex items-center justify-between mb-8">

      <h3 class="medium self-start mb-0">{{ block.category.name | capitalize  }}</h3>

      <router-link
      class="button-text flex items-center text-xl text-brand-green"
      :to="'/' +block.category.slug">
        <span>Les alle {{ block.category.slug }}</span>
        <svg class="flex-none h-4 w-auto fill-current px-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z"/></svg>
     </router-link>

    </header>

    <div class="grid lg:grid-cols-3 gap-8">

      <article
      class="flex flex-col"
      v-for="(post) in posts"
      :key="post.id">

        <router-link
        class="button-text text-xl text-black" :to="post.url">

          <!-- <pre class="bg-grey-light break-words">
            {{ post }}
          </pre> -->

          <figure
          v-if="post.featured"
          class="flex justify-items-stretch mb-10">

            <img
            class="object-cover aspect-5/4"
            :src="`${baseURL}/${post.featured.media_details.sizes['large'].source_url}`"
            :height="post.featured.media_details.sizes['large'].height"
            :width="post.featured.media_details.sizes['large'].width"
            :alt="post.featured.alt" />

          </figure>

          <header class="text-black justify-items-stretch">
            <h3 class="medium mb-5 underline">{{ post.title }}</h3>
            <div class="lead book" v-html="post.excerpt.excerpt" />
          </header>

        </router-link>

      </article>

    </div>

  </div>

</template>

<script>

export default {
  name: 'Category',
  props: {
    block: Object
  },

  data : () => ({
    posts: [],
    errors: [],
    baseURL: process.env.MIX_API_BASE_URL,
  }),

  methods: {
    async getPosts() {
      try {
        const response = await axios.get(
          this.baseURL + '/api/wp/v2/posts', {
            params: {
              categories: this.$props.block.category.term_id,
              per_page: 3
            }
          }
        )
        const results = response.data
        if ( !results || results.length == 0 ) {}
        this.posts = results.map(post => ({
          id: post.id,
          type: post.type,
          slug: post.slug,
          url: post.vueData.url,
          title: post.title.rendered,
          excerpt: post.acf.excerpt,
          media: post.better_featured_image,
          featured: post.better_featured_image
          // thumbnail: this.extractImage(res).url,
          // caption: this.extractImage(res).caption,
          // byline: res.byline,
          // published_date: res.published_date,
        }))
      }
      catch (e) {
        this.errors.push(e)
      }
      return this.doAction()

    },

    doAction() {},

  },

  mounted() {
    this.getPosts()
  },

}
</script>
