<template lang="html">
  <section class="search-form">
    <div class="inner-content flex items-center gap-4">

      <form role="search" class="search-form-el" v-on:submit.prevent="conditionalSubmitForm">
        <label class="sr-only" for="searchField">{{ searchLabel }}</label>
        <input @focus="toggleSearchActive(true)" @blur="waitThenBlur" class="search-field focus:outline-none text-xl" type="search" :placeholder="searchLabel" :aria-label="searchLabel" v-model="sTheSearchTerm" />

        <router-link class="button submit-search" :to="{ name: 'ResourceOverview', query: { searchTerm: searchTerm } }" v-if="bTheLinkToResults">
          <icon graphic="search"></icon>
          <span class="sr-only">Søk</span>
        </router-link>

        <button class="submit-search" v-else @click="submitSearch">
          <icon graphic="search"></icon>
          <span class="sr-only">Søk</span>
        </button>

      </form>

      <div class="search-suggestions" :class="{ '-hide' : !showSuggestions }" :aria-hidden="!showSuggestions">
        <div class="sr-only" aria-live="polite">
          <template v-if="showSuggestions">Showing {{ filteredResults.length }} search results:</template>
        </div>

        <ul class="items" v-if="filteredResults.length">
          <suggestion :showsuggestions="showSuggestions" v-for="(item, index) in filteredResults" :key="`search_result_${item.id}_${index}`" :item="item"></suggestion>
        </ul>

        <div class="items" v-else>
          <span class="suggestion">
            <span class="title md:text-sm lg:text-md xl:text-lg">Søket ga ingen treff</span>
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import Suggestion from './SearchSuggestion.vue'
import Icon from '@/component/Icon.vue'

import _debounce from 'lodash/debounce'


export default {
  name: 'SearchForm',
  data: () => ({
    isSearchActive: false,
    sTheSearchTerm: "",
    bTheIntentToTravel: false,
  }),
  components: {
    'icon': Icon,
    'suggestion': Suggestion,
  },
  computed: {
    searchLabel() {
      return "Søk i biblioteket"
    },
    searchTerm() {
      return this.$store.getters.getSearchTerm
    },
    // searchTerm: {
    //   get() {
    //     return this.$store.getters.getSearchTerm
    //   },
    //   set(value) {
    //     _debounce(function() {
    //       this.$store.dispatch('filterResources', { param: 'searchTerm', value: value })
    //     }, 250);
    //     // this.$store.dispatch('filterResources', { param: 'searchTerm', value: value })
    //   }
    // },
    filteredResults() {
      return this.$store.getters.getFilteredResources
    },
    bTheLinkToResults() {
      if (this.$route.name !== 'ResourceOverview') {
        return true
      }
      return false
    },
    showSuggestions() {
      if (this.searchTerm && this.filteredResults && this.isSearchActive && this.$route.name !== 'ResourceOverview') { //  && this.isSearchActive
      // if (this.searchTerm && this.filteredResults && this.$route.name !== 'ResourceOverview' && this.$route.name !== 'ResourcePosts') {
        return true
      }
      return false
    }
  },
  methods: {
    applyTerm(event, value) {
      event.preventDefault();
      this.searchTerm = value;
    },
    submitSearch(event, val) {
      // event.preventDefault();
      this.toggleSearchActive(false);
      // console.log('hva skjer nå');
    },
    conditionalSubmitForm(event, val) {
      console.log("conditionalSubmitForm", event, val);
      if (this.bTheLinkToResults) {
        this.$router.push({ name: 'ResourceOverview', query: { searchTerm: this.searchTerm } })
      } else {
        this.submitSearch(event, val);
      }
    },
    updateSearchTerm: _debounce(function(term) {
      if (term) {
        this.$store.dispatch('filterResources', { param: 'searchTerm', value: term })
      } else {
        this.$store.dispatch('filterResources', { param: 'searchTerm', value: "" })
      }

    }, 250),
    toggleSearchActive(bool) {
      this.isSearchActive = bool;
    },
    waitThenBlur() {
      // setTimeout(() => {
      //   this.toggleSearchActive(false);
      // }, 100)
    },
    clickToBlur(e) {
      if (!e.target.closest(".search-form")) {
        this.toggleSearchActive(false);
      }
      // this.toggleSearchActive()
    }
  },
  mounted() {
    if (this.searchTerm) {
      this.sTheSearchTerm = this.searchTerm;
    }

    document.querySelector("#app").addEventListener("click", this.clickToBlur)
  },
  destroyed() {
    document.querySelector("#app").removeEventListener("click", this.clickToBlur)
  },

//   beforeRouteLeave(to, from, next) {
//
//   },

  watch: {
    sTheSearchTerm: {
      handler(term) {
        this.updateSearchTerm(term);
      },
      // immediate: true,
    },
    filteredResults: {
      handler() {
       // this.$store.dispatch("setResourceLoadingStatus", false);
      }
    }
  }
}
</script>

<style lang="scss">

</style>
