<template>
  <transition-group class="resources-list" name="list" tag="section" @before-leave="beforeItemLeave">
    <singleitem
      v-for="(post, index) in filteredResults"
      :post="post"
      :key="`resource_post_${post.id}_${index}`"
      :data-index="index">
    </singleitem>

    <div key="filterNotFound" class="list-empty xl:col-span-3 p-4" v-if="filteredResults.length === 0">
      <h4 class="medium mb-0">Finner ingen treff på ditt søk …</h4>
    </div>
  </transition-group>
</template>

<script>
  import SingleItem from './ResourceSingleItem.vue'
  import _isEqual from 'lodash/isEqual'

  export default {
    name: 'ResourcePosts',
    components: {
      singleitem: SingleItem,
    },

    computed: {
      routeQuery() {
        return this.$store.getters.getRouteQuery
      },
      filteredResults() {
        return this.$store.getters.getFilteredResources
      },
    },
    methods: {
      // maintain a transitioning item's position and dimensions while it is absolutely positioned
      beforeItemLeave(el) {
        // console.log($(el), $(el).offset().left);
        // console.log(el, window.getComputedStyle(el));
        // if (el.classList.contains('card')) {
        const {marginLeft, marginTop, width, height} = window.getComputedStyle(el);
        el.style.left = `${el.offsetLeft - parseFloat(marginLeft, 10)}px`;
        el.style.top = `${el.offsetTop - parseFloat(marginTop, 10)}px`;
        el.style.width = width;
        el.style.height = height;

        this.$nextTick(() => {
          console.log($(el), $(el).offset().left);
        })
        // }
      },
    },
    watch: {
      routeQuery(newQuery, oldQuery) {
        if (!_isEqual(newQuery, oldQuery)) {
          this.$router.replace({ query: newQuery });
          // console.log(newQuery, oldQuery, this.$route);
        }
      }
    },
    beforeUpdate() {
      console.log("resource posts beforeUpdate");

    }
  }
</script>
