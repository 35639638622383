<template>
  <div
  v-if="isDataLoaded"
  class="type"
  :class="[post.type]"
  :data-slug="post.slug">

    <Anchor
    :block="anchorItems"
    v-if="anchorItems.length" />

    <section
    v-if="acf.blocks"
    v-intersect="{observerOptions: {rootMargin: '0px', threshold: 0.0}, true: ['-intersected'], disposeWhen: true }"
    v-for="(block, index) in acf.blocks"
    :key="`section_${block.acf_fc_layout}_${index}`"
    :data-uuid="setComponentUUID(block, index)"
    class="component"
    :class="[block.acf_fc_layout, block.options && block.options.layout ? block.options.layout : '', block.options && block.options.color ? 'bg-'+ block.options.color : 'bg-white']">

      <component
      :is="block.acf_fc_layout"
      :block="block"
      :key="`component_${block.acf_fc_layout}_${index}`"/>

    </section>

  </div>
</template>

<script>
// import { EventBus } from '@/store/hero.js';
import { mapActions, mapGetters } from 'vuex';
import { isMobileOnly } from 'mobile-device-detect';

import postMeta from '@/mixins/postMeta.js';

import Accordion from '@/component/Block/Accordion'
import Anchor from '@/component/Block/Anchor'
import Card from '@/component/Block/Card'
import Category from '@/component/Block/Category'
import Enhanced from '@/component/Block/Content'
import Description from '@/component/Block/Description'
import Feature from '@/component/Block/Feature'
import Guide from '@/component/Block/Guide'
import Link from '@/component/Block/Link'
import Media from '@/component/Block/Media'
import Quote from '@/component/Block/Quote'
import Related from '@/component/Block/Related'
import Story from '@/component/Block/Story'
import InfoGraphic from '@/component/Block/InfoGraphic'


export default {
  components: {
    Accordion,
    Anchor,
    Card,
    Category,
    Enhanced,
    Description,
    Feature,
    Guide,
    Link,
    Media,
    Quote,
    Related,
    Story,
    InfoGraphic
  },

  data: () => ({
    hero: [],
    // post: null,
    posts: [],
    // acf: Object,
    errors: [],
    scrollTo: [],
    // API: String,
    API: process.env.MIX_API_URL,
    baseURL: process.env.MIX_API_BASE_URL,
  }),

  mixins: [
    postMeta,
  ],

  computed: {
    post() {
      const post = this.$store.getters.getPageBySlug('forside');

      if (post) {
        return {
          id: post.id,
          type: post.type,
          slug: post.slug,
          title: post.title.rendered,
          media: post.featured_media,
          head: post.yoast_head_json,
          acf: post.acf,
        }
      }
      return false
    },

    acf() {
      if (this.post) {
        return this.post.acf
      }
      return false
    },

    anchorItems() {
      let aTheItems = [];

      if (this.acf && this.acf.blocks && this.acf.blocks.length) {

        this.acf.blocks.forEach((block, i) => {
          if (block.anchor && block.anchor.anchor) {
            let sTheLabel = null;

            if (block.anchor.label) {
              sTheLabel = block.anchor.label
            } else if (block.options.title) {
              sTheLabel = block.options.title;
            }

            if (sTheLabel) {
              aTheItems.push({
                title: sTheLabel,
                index: i,
                id: `anchor-item_${i}`
              });
            }
          }
        })
      }

      return aTheItems
    },

    isDataLoaded() {
      if (this.post) {
        return true
      }
      return false
    }
  },

  methods: {
    async getPage() {
      //console.log('this.API', this.API);
      this.$store.dispatch('updateRenderingItems', { name: 'home', value: true });

      try {
        const response = await axios.get(
          this.API + '/pages', {
            params: {
              slug: 'forside',
            }
          }
        )
        const results = response.data

        // console.log("FORSIDEN", this.$store.getters.getPageBySlug('forside'));

        if ( !results || results.length == 0 ) {
          const e = new Error('No data');
          e.statusCode = 404;
          throw e;
        }

        this.post = results.map(post => ({
          id: post.id,
          type: post.type,
          slug: post.slug,
          title: post.title.rendered,
          media: post.featured_media,
          head: post.yoast_head_json,
        }))[0]

        //console.log('this.post', this.post);

        this.acf = results.map(post => ({
          components: post.acf.blocks
        }))

        this.hero = results.map(post => ({
          id: post.id,
          label: false,
          title: post.acf.hero.title,
          excerpt: post.acf.excerpt.excerpt,
          type: post.type,
          date: post.date,
          url: post.link,
          layout: this.setLayout(post.acf.options.layout),
          color: post.acf.options.color ? post.acf.options.color : 'gray-light',
          // image: post.acf.hero.media.landscape.image ? {
          //   id: post.acf.hero.media.landscape.image.id,
          //   alt: post.acf.hero.media.landscape.image.alt,
          //   caption: post.acf.hero.media.landscape.image.caption,
          //   description: post.acf.hero.media.landscape.image.description,
          //   sizes: post.acf.hero.media.landscape.image.sizes
          // } : false,
          image: this.setImage( post.acf.hero.media.landscape.image, post.acf.hero.media.portrait.image ),
          video: false,
        }))
      }
      catch (e) {
        this.errors.push(e)
        this.$router.push({ name: 'PageNotFound' })
      }
      return this.doAction()
    },

    doAction() {
      // EventBus.$emit('EventBus', this.hero[0])
      this.$store.dispatch('updateHero', this.hero[0]);
      // this.setHero(this.hero[0])
      this.$store.dispatch('setViewTitle', '');

      this.$store.dispatch('updateRenderingItems', { name: 'home', value: false });

      if (this.acf[0] && this.acf[0].components) {
        this.scrollData(this.acf[0].components)
      }

    },

    scrollData(data) {
      if ( data.length ) {
        let newData = []

        data.forEach((value, index) => {
          if ( value.anchor.anchor ) {
            newData.push({
              id: value.anchor.uuid ? value.anchor.uuid : index,
              title: value.anchor.label ? value.anchor.label : (value.options && value.options.title ? value.options.title : 'No label'),
              type: value.acf_fc_layout,
            });
          }
        })

        this.scrollTo = newData

      }
    },

    setComponentUUID(block, i) {
      let anchorItem = this.anchorItems.find(item => item.index === i);

      if (anchorItem) {
        return anchorItem.id
      }
      // console.log('setComponentUUID', block);
      // if (block.anchor && block.anchor.uuid) {
      //   return block.anchor.uuid
      // }
      return '';
    },

    setHero(data) {
      // EventBus.$emit('EventBus', data)
    },

    resetData() {
      this.acf = Object
    },

    setLayout(e) {
      let expr = e
      let data = String

      switch (expr) {
        case 'superimposed':
          data = e
          break;

        default:
          data = e
      }
      return data
    },

    setImage(landscape, portrait) {

      let image = {}

      if ( isMobileOnly ) {
        console.log('Is mobile, ' +isMobileOnly)
        if ( portrait ) {
          image = {
            id: portrait.id,
            alt: portrait.alt,
            caption: portrait.caption,
            description: portrait.description,
            //url: `https://nfp.stage.webcore.no/${portrait.sizes['large']}`,
            // url: this.baseURL + portrait.sizes['large'],
            url: `${this.baseURL}/${portrait.sizes['large']}`,
            height: `${landscape.sizes['large-height']}`,
            width: `${landscape.sizes['large-width']}`
          }
        } else {
          if ( landscape ) {
            image = {
              id: landscape.id,
              alt: landscape.alt,
              caption: landscape.caption,
              description: landscape.description,
              // url: `https://nfp.stage.webcore.no/${landscape.sizes['large']}`,
              url: `${this.baseURL}/${landscape.sizes['large']}`,
              height: `${landscape.sizes['large-height']}`,
              width: `${landscape.sizes['large-width']}`
            }
          }

        }

      } else {
        console.log('Is mobile, ' +isMobileOnly)
        if ( landscape ) {
          image = {
            id: landscape.id,
            alt: landscape.alt,
            caption: landscape.caption,
            description: landscape.description,
            // url: `https://nfp.stage.webcore.no/${landscape.sizes['2048x2048']}`,
            url: `${this.baseURL}/${landscape.sizes['2048x2048']}`,
            height: `${landscape.sizes['2048x2048-height']}`,
            width: `${landscape.sizes['2048x2048-width']}`
          }
        }
      }
      //console.log(image)
      return image

    },

    observerElement() {
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if(entry.isIntersecting){
            //console.log('Observed');
          }
        });
      },
      {
        rootMargin: '-50% 0% -50% 0%'
      })

      document.querySelectorAll('.article').forEach(item => {
        observer.observe(item)
      })
    },

    relativeURL(id) {
      return this.$store.getters.getRelativeUrl(id)
//       let filteredPosts = ''
//
//       if ( this.$store.getters.getPagesAndPosts.find( post => post.id === id ) ) {
//         filteredPosts = this.$store.getters.getPagesAndPosts.find( post => post.id === id )
//         filteredPosts = filteredPosts.vueData.url
//       }
//
//       //console.log(filteredPosts)
//       return filteredPosts

    }
  },

  // computed: {
  //   ...mapGetters(['getPagesAndPosts', 'getNotFound']),
  //   isDataLoaded() {
  //     const nestedLoaded = Object.keys(this.acf).map(key => this.acf[key].length !== 0)
  //     return this.acf && nestedLoaded.length !== 0
  //   }
  // },

  created() {
    // this.getPage()
    this.$nextTick(() => {
      // this.API = process.env.MIX_API_URL

    });
  },

  mounted() {
    this.$nextTick(() => {
      // console.log('home updated', new Date());
    })
  }
}
</script>
