<template>


    <router-link :to="postLink">

      <figure v-if="postImage" class="post-image-container flex justify-items-stretch">
        <img :srcset="postImage.srcSet" :sizes="postImage.sizes" :src="postImage.src" :alt="postImage.alt" />
      </figure>

      <header class="link-header grow flex flex-col justify-between bg-brand-green text-gray-dark justify-items-stretch">
        <h4 class="article-title">{{ post.title }}</h4>

        <span class="link-btn flex items-center text-green-dark">
          <span class="text">Les mer</span>
          <icon graphic="arrow" class="arrow-right"/>
        </span>
      </header>

    </router-link>

</template>

<script>
  import Icon from '@/component/Icon'

  export default {
    data: () => ({
      baseURL: process.env.MIX_API_BASE_URL,
    }),
    props: {
      item: Object,
    },
    components: {
      Icon,
    },
    computed: {
      slug() {
        return this.item.slug
      },
      post() {
        return this.item
        // return {
        //   id: this.item.ID,
        //   type: this.item.type,
        //   slug: this.item.slug,
        //   // url: this.item.vueData.url,
        //   // url: `/artikler${this.item.vueData.url}`,
        //   title: this.item.title.rendered,
        //   excerpt: this.item.acf.excerpt,
        //   media: this.item.acf.hero.media.landscape ? this.item.acf.hero.media.landscape : '',
        //   featured: this.item.better_featured_image
        // }
      },
      postImage() {
        let aTheImg = {
          alt: "",
          src: "",
          sizes: "",
          srcSet: "",
        };

        if (this.post) {
          if (this.post.featured) {
            let img = this.post.featured;
            let sizes = img.media_details.sizes;

            aTheImg.alt = img.alt_text;
            aTheImg.src = `${this.baseURL}/${img.source_url}`;

            if (sizes) {
              aTheImg.sizes = "(min-width: 1024px) 33vw, (min-width: 601px) 50vw, 100vw";

              Object.keys(sizes).forEach(key => {
                let sTheSize = `${this.baseURL}/${sizes[key].source_url} ${sizes[key].width}w`;

                if (aTheImg.srcSet.length) {
                  aTheImg.srcSet = `${aTheImg.srcSet}, ${sTheSize}`;
                } else {
                  aTheImg.srcSet = sTheSize;
                }
              });
            }

            return aTheImg;

          } else if (this.post.media && this.post.media.image) {
            let img = this.post.media.image;
            let sizes = img.sizes;

            aTheImg.alt = img.alt;
            aTheImg.src = img.src;

            Object.keys(sizes).forEach(key => {
              if (key.includes('-width')) {

                let sTheHandle = key.replace('-width', '');

                let sTheSize = `${this.baseURL}/${sizes[sTheHandle]} ${sizes[key]}w`;

                if (aTheImg.srcSet.length) {
                  aTheImg.srcSet = `${aTheImg.srcSet}, ${sTheSize}`;
                } else {
                  aTheImg.srcSet = sTheSize;
                }
              }
            });

            return aTheImg;
          }

          return null;
        }
        return null;
      },
      postLink() {
        if (this.post && this.post.type) {
          if (this.post.type === "ressurs") {
            return { name: 'ResourceSingle', params: { slug: this.post.slug } };
          } else if (this.post.type === "page") {
            return { name: 'Page', params: { slug: this.post.slug } };
          } else {
            return { name: 'Article', params: { slug: this.post.slug } };
          }
        }
        return false
      },
    }
  }
</script>
