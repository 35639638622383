import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

import _isEqual from 'lodash/isEqual'

import Home from '@/view/Home'
import Page from '@/view/Page'
import Annual from '@/view/Annual'
import Archive from '@/view/Archive'
import Contact from '@/view/Contact/Contact'
import Article from '@/view/Article'
import Contacts from '@/view/Contact/ContactSingle'
import Login from '@/view/Login'
import Forgotten from '@/view/User/Forgotten'
import PageNotFound from '@/view/PageNotFound'

// import Sitemap from '@/view/Sitemap'

import ResourceMain from '@/view/Resource/ResourceMain'
import ResourceOverview from '@/view/Resource/ResourceOverview'
import ResourceSingle from '@/view/Resource/ResourceSingle'
// import ResourcePosts from '@/view/Resource/ResourcePosts'

Vue.use(Router)

const router = new Router({
// export default new Router({
  mode: 'history',
  linkActiveClass: '',
  linkExactActiveClass: 'router-link-active',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      props: true,
    },
    {
      path: '/404',
      name: "PageNotFound",
      component: PageNotFound,
      props: true,
      meta: {
        title: '404'
      }
    },

    // {
    //   path: '/:slug/:slug1/:slug2',
    //   // name: 'Article',
    //   component: Article,
    //   props: true,
    // },
    // {
    //   path: '/for-gravide/:slug',
    //   name: 'ExperiencesPage',
    //   component: Page,
    //   props: true,
    // },
    {
      path: '/for-gravide/erfaringer/:slug',
      name: 'Experiences',
      component: Article,
      props: true,
    },
    // {
    //   name: 'Sitemap',
    //   path: '/sitemap_index.xml',
    //   component: Sitemap,
    //   props: true,
    // },
    // {
    //   path: '/kunnskapsgrunnlag/:slug',
    //   name: 'Page',
    //   component: Page,
    //   props: true,
    // },
    {
      path: '/kunnskapsgrunnlag/rapporter/arsrapport',
      name: 'AnnualReport',
      component: Annual,
      props: true,
      meta: {
        title: 'Årsrapport'
      }
    },
    {
      path: '/kunnskapsgrunnlag/rapporter/:slug',
      name: 'Reports',
      component: Article,
      props: true,
    },
    {
      path: '/kunnskapsgrunnlag/studier/:slug',
      name: 'Studies',
      component: Article,
      props: true,
    },
    {
      path: '/om-oss/kontakt',
      name: 'Contact',
      component: Contact,
      props: true,
    },
    {
      path: '/om-oss/kontakt/:slug',
      name: 'Contacts',
      component: Contacts,
      props: true,
      meta: {
        title: 'Kontakter'
      }
    },
    // {
    //   path: '/om-oss/:slug',
    //   // name: 'Page',
    //   component: Page,
    //   props: true,
    //   meta: {
    //     title: 'Om oss'
    //   }
    // },
    {
      path: '/innlogging',
      name: 'Login',
      component: Login,
      props: true,
      meta: {
        title: 'Innlogging'
      }
    },
    {
      path: '/glemt-passord',
      name: 'Forgotten',
      component: Forgotten,
      props: true,
      meta: {
        title: 'Glemt passord'
      }
    },
    {
      path: '/biblioteket',
      name: 'ResourceOverview',
      component: ResourceOverview,
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Biblioteket'
      },
      /*beforeEnter: (to, from, next) => {
        store.dispatch('ajax/isLoggedIn', {
          id: store.state.userID,
          success: function(data) {
            if (!data.isLoggedIn) {
              next({ name: 'Login' });
            } else {
              if (to.query && Object.keys(to.query).length) {
                if (to.query.areas) {
                  var arr = [];
                  for (let i of to.query.areas.split(',')) {
                    arr.push(Number(i));
                  }
                  store.dispatch('filterResources', { param: 'taxAreas', value: arr });
                }
    
                if (to.query.attr) {
                  var arr = [];
                  for (let i of to.query.attr.split(',')) {
                    if (isNaN(i)) {
                      arr.push(i)
                    } else {
                      arr.push(Number(i));
                    }
                  }
    
                  store.dispatch('filterResources', { param: 'taxAttributes', value: arr });
                }
    
                if (to.query.types) {
                  var arr = [];
                  for (let i of to.query.types.split(',')) {
                    arr.push(Number(i));
                  }
    
                  store.dispatch('filterResources', { param: 'taxTypes', value: arr });
                }
    
                if (to.query.bookmarks) {
                  store.dispatch('filterResources', { param: 'bookmarks', value: store.getters.getBookmarks });
                }
    
                if (to.query.searchTerm) {
                  store.dispatch('filterResources', { param: 'searchTerm', value: to.query.searchTerm });
                }
    
                next();
              } else {
                store.dispatch('resetResourceFilter');
                next();
              }
            }
          }
        });
      },*/
      beforeEnter: (to, from, next) => {
        if (to.query && Object.keys(to.query).length) {
          if (to.query.areas) {
            var arr = [];
            for (let i of to.query.areas.split(',')) {
              arr.push(Number(i));
            }
            store.dispatch('filterResources', { param: 'taxAreas', value: arr });
          }

          if (to.query.attr) {
            var arr = [];
            for (let i of to.query.attr.split(',')) {
              if (isNaN(i)) {
                arr.push(i)
              } else {
                arr.push(Number(i));
              }
            }

            store.dispatch('filterResources', { param: 'taxAttributes', value: arr });
          }

          if (to.query.types) {
            var arr = [];
            for (let i of to.query.types.split(',')) {
              arr.push(Number(i));
            }

            store.dispatch('filterResources', { param: 'taxTypes', value: arr });
          }

          if (to.query.bookmarks) {
            store.dispatch('filterResources', { param: 'bookmarks', value: store.getters.getBookmarks });
            // var arr = [];
            // for (let i of to.query.bookmarks.split(',')) {
            //   arr.push(Number(i));
            // }

            // store.dispatch('filterResources', { param: 'bookmarks', value: arr });
          }

          if (to.query.searchTerm) {
            store.dispatch('filterResources', { param: 'searchTerm', value: to.query.searchTerm });
          }

          next();
        } else {
          store.dispatch('resetResourceFilter');
          next();
        }
      },
    },
    {
      name: 'ResourceSingle',
      path: '/biblioteket/:categories*/:slug',
      alias: ['/biblioteket/:slug', '/biblioteket/:slug/', '/biblioteket/:categories*/:slug/'],
      component: ResourceSingle,
      props: true,
      meta: {
        requiresAuth: true
      },
      /*beforeEnter: (to, from, next) => {
        store.dispatch('ajax/isLoggedIn', {
          id: store.state.userID,
          success: function(data) {
            if (!data.isLoggedIn) {
              next({ name: 'Login' });
            } else {
              console.log('ResourceSingle beforeEnter', to);
              console.log(store.getters.getResourceBySlug(to.params.slug));
              next();
            }
          }
        });
      }*/
    },
    {
      path: '/kompasset',
      name: 'ResourceMain',
      component: ResourceMain,
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Kompasset'
      },
      /*beforeEnter: (to, from, next) => {
        store.dispatch('ajax/isLoggedIn', {
          id: store.state.userID,
          success: function(data) {
            store.dispatch('setActivePage', "kompasset");
            next();
          }
        });
      }*/
    },
    {
      name: 'ChildPage',
      path: '/:slug/:child',
      // alias: ['/kompasset/:child', '/om-oss/:child'],
      component: Page,
      props: true,
      // beforeEnter: (to, from, next) {
      //
      // }
    },
    {
      name: 'Page',
      path: '/:slug',
      // alias: ['/:slug/:slug1', '/kompasset/:child', '/om-oss/:child'],
      component: Page,
      props: true,
    },
    {
      name: 'Article',
      path: '/artikler/:slug',
      alias: ['/:parent/:slug', '/:child/:slug', '/:parent/:child/:slug'],
      component: Article,
      props: true,
    },

    // {
    //   path: '/:slug/:slug1',
    //   // name: 'Page',
    //   component: Page,
    //   props: true,
    // },
    // {
    //   path: '/:catchAll(.*)*',
    //   redirect: '/404'
    // },
    {
      path: '*',
      name: '404',
      component: PageNotFound,
      props: true,
      meta: {
        title: '404'
      }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    const scrollRestoration = history.scrollRestoration
    if (scrollRestoration === 'manual') {
      // //console.log('The location on the page is not restored, user will need to scroll manually.');
    }

    if (savedPosition) {
      return savedPosition
    // } else if (_isEqual(to.name, from.name)) {
    //   return;
    } else {
      return { x: 0, y: 0 }
    }
  },
});

router.beforeEach((to, from, next) => {

  if (to.meta.requiresAuth) {
    let waitForJQ;

    if (window.Ajax) {
      let id = store.getters.getUserID;

      if (id) {
        store.dispatch("ajax/login", {id}).then(response => {
          if (response.result === "success") {
            next()
          } else {
            next('/')
          }
        })
      } else {
        next('/')
      }

    } else {
      waitForJQ = setInterval(() => {
        if (window.Ajax) {
          clearInterval(waitForJQ);

          let id = store.getters.getUserID;

          if (id) {
            store.dispatch("ajax/login", {id}).then(response => {
              if (response.result === "success") {
                next()
              } else {
                next('/')
              }
            })
          } else {
            next('/')
          }
        }
      }, 50);
    }

    // next(vm => {
    //   console.log("requires auth next");
    //   store.dispatch("ajax/login", store.getters.getUserID).then(response => {
    //     console.log("athu/login response");
    //     if (!response.result === "success") {
    //       next('/innlogging')
    //     }
    //   })
    // })
  } else {
    if (to.name === "ChildPage") {
      // piss it, this is an article
      if (to.params.slug === "artikler" && to.params.child) {
        router.push({ name: "Article", params: { slug: to.params.child } })
      }
    }

    if (!from.name) {

      // console.log("router init", to);
      // let's assume this is the first pageload. Try to load this before starting all the other stuff

      store.dispatch("fetchFirstView", to).then(result => {
        // console.log("fetchFirstView result", result);

        // if (to.params.slug) {
        //   console.log("ROUTER TO", to, store.getters.getPostBySlug(to.params.slug));
        // }

        if (to.name === "ChildPage" && !result.length) {
          // this is probably not a child page after all, but an article
          router.push({ name: "Article", params: { slug: to.params.child } })
        } else if (to.name === "Page" && !result.length) {
          // similarly, this is probably not a page, but an article
          router.push({ name: "Article", params: { slug: to.params.slug } })
        } else {
          next();
        }
      })
    } else {
      next();
    }
  }


});

export default router
