<template>

  <div class="component-inner">

    <figure>

      <div
      class="video-container" v-html="block.embed.video" />

      <figcaption
      v-if="block.embed.caption"
      class="pt-4">{{ block.embed.caption }}</figcaption>

    </figure>


  </div>

</template>

<script>
export default {
  name: 'Oembed',
  props: {
    block: Object
  },
}
</script>
