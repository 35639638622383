<template>

  <blockquote class="component-inner">

    <div class="content">
      <p class="text">{{ block.quote.excerpt}}</p>
      <cite class="byline regular" v-if="block.quote.byline">{{ block.quote.byline }}</cite>
    </div>

    <div class="icon-column" v-if="block.options.icon">
      <pictogram :data="block.options.icon" :color="block.options.iconColor" />
    </div>

  </blockquote>


</template>

<script>
import Pictogram from '@/component/Block/Pictogram'

export default {
  name: 'Quote',
  props: {
    block: Object
  },
  components: {
    Pictogram,
  },
}
</script>
