<template>
  <div class="filter dropdown" :class="{ '-open': dropdownIsOpen }" :key="'filterBlock' + handle">
    <header class="flex dropdown-header justify-between items-center gap-4">
      <h5 class="flex-1">{{ heading }}</h5>

      <button class="dropdown-toggle submenu-toggle" @click="toggleSubmenu($event)">
        <span class="sr-only">Toggle dropdown: {{ heading }}</span>
        <i class="icon"></i>
      </button>
    </header>

    <transition-group name="slide" class="dropdown-content submenu-items" tag="section">
      <template
      v-for="items in blockData">

        <ul
        v-if="items.parent == '0'"
        :key="'ul_' + items.id"
        class="filter-content list-none dropdown-item px-4">

          <li
          :ref="'list_' + items.id"
          :data-id="items.id"
          :key="'li_' + items.id"
          class="filter-item flex flex-wrap justify-between items-center"
          :class="{ '-selected': selectedData.includes(items.id), 'dropdown': !itemIsCheckbox, '-open': openDropdownIDs.includes(items.id) }">

            <template v-if="itemIsCheckbox">
              <label class="flex-1 checkbox-toggle-label" :for="items.id">{{ items.title }}</label>
              <input class="checkbox" type="checkbox" :id="items.id" v-bind:value="items.id" v-model="selectedData" :disabled="!dropdownIsOpen"/>
            </template>

            <template v-else>
              <input class="non-checkbox" type="checkbox" :id="'checkbox_' + items.id" v-bind:value="items.id" v-model="selectedData" :disabled="!dropdownIsOpen" />
              <label class="flex-1 checkbox-toggle-label" :for="'checkbox_' + items.id">
                {{ items.title }}
              </label>

              <template v-if="parents.includes(items.id)">
                <input class="non-checkbox" type="checkbox" :id="'toggle_' + items.id" v-bind:value="items.id" v-model="openDropdownIDs" />
                <label class="dropdown-toggle" :for="'toggle_' + items.id">
                  <icon
                  graphic="arrow"
                  class="arrow"
                  :class="{ 'arrow-up': openDropdownIDs.includes(items.id), 'arrow-down': !openDropdownIDs.includes(items.id) }" ></icon>


                  <span class="sr-only">Toggle dropdown: {{ items.title }}</span>
                </label>
              </template>
            </template>

            <ul class="flex-none w-full sub list-none empty:hidden" :class="{ 'dropdown-content': !itemIsCheckbox }">
              <template
              v-for="(item, index) in blockData">

                <li
                v-if="item.parent === items.id"
                :data-parent="item.parent"
                :data-id="item.id"
                class="filter-item sub-item md:text-sm lg:text-md xl:text-lg"
                :key="'sub_item_' + item.id"
                :class="{ '-selected': selectedData.includes(item.id) }">
                  <template v-if="itemIsCheckbox">
                    <label class="flex-1 checkbox-toggle-label" :for="item.id"> {{ item.title }}</label>
                    <input class="checkbox" type="checkbox" :id="item.id" v-bind:value="item.id" v-model="selectedData" :disabled="!openDropdownIDs.includes(items.id)" />
                  </template>

                  <template v-else>
                    <input class="non-checkbox" type="checkbox" :id="item.id" v-bind:value="item.id" v-model="selectedData" :disabled="!openDropdownIDs.includes(items.id)" />
                    <label class="flex-1 checkbox-toggle-label" :for="item.id"> {{ item.title }}</label>
                  </template>
                </li>

              </template>
            </ul>
          </li>
        </ul>

      </template>
    </transition-group>
  </div>
</template>
<style lang="scss">


</style>
<script>

  import Icon from '@/component/Icon.vue'

  export default {
    name: 'FilterBlock',
    data: () => ({
      dropdownActiveState: true,
      openDropdownIDs: [],
      // selectedIDs: [],
    }),

    props: {
      heading: String,
      handle: String,
      blockData: Array,
    },

    components: {
      'icon': Icon,
    },

    computed: {
      itemIsCheckbox() {
        if (this.handle === 'taxAttributes') {
          return true
        }
        return false
      },
      selectedData: {
        get() {
          return this.$store.getters.getResourceFilters[this.handle]
        },
        set(val) {
          if (val.length) {
            let iTheNewID = val.find(i => !this.selectedData.includes(i));

            // work out if the selected term is a parent or not, and alter the results.
            // Selecting a parent deselects its children, and vice versa

            if (iTheNewID) {
              let oTheTerm = this.blockData.find(item => item.id === iTheNewID);
              let aTheMatches = this.blockData.filter(term => val.includes(term.id) );

              if (oTheTerm.parent > 0) {
                for (let term of aTheMatches) {
                  if (term.parent > 0 && val.includes(term.parent)) {
                    val = val.filter(id => id !== term.parent)
                  }
                }
              } else {
                for (let term of aTheMatches) {
                  if (term.parent === oTheTerm.id && val.includes(term.id)) {
                    val = val.filter(id => id !== term.id)
                  }
                }
              }
            }
          }
          //this.$store.dispatch("setResourceLoadingStatus", true);
          this.$store.dispatch('filterResources', { param: this.handle, value: val })
        }
      },
      parents() {
        let aTheParents = [];

        if (this.blockData) {
          for (var item of this.blockData) {
            if (item.parent !== 0) {
              aTheParents.push(item.parent);
            }
          }
        }
        let aTheReturn = new Set([...aTheParents]);
        return [...aTheReturn]
      },
      windowWidth() {
        return this.$store.getters.getWindowWidth
      },
      dropdownIsOpen() {
        if (this.dropdownActiveState) {
          return true
        }
        // if (this.windowWidth >= 992) {
        //   return true
        // } else if (this.dropdownActiveState) {
        //   return true
        // }
        return false
      }
    },

    methods: {
      toggleSubmenu(e) {
        e.preventDefault();
        this.dropdownActiveState = !this.dropdownActiveState;
      },
      toggleSubDropdown(id) {

        // console.log(this.openDropdownIDs);
      }
    },

    mounted() {
      // console.log(this.blockData);

      for (let item of this.blockData) {
        if (item.parent === 0) {
          // this.openDropdownIDs.push(item.id)
        }
      }
    }
  }
</script>
