<template>
  <div
  v-intersect="{observerOptions: {rootMargin: '0px', threshold: 0.0025}, true: ['isBottom']}"
  class="scrollTop flex justify-end mx-auto max-w-screen-2xl px-4 lg:px-0">
    <button @click="moveTo" class="button -red -round">
      <icon class="arrow-up" graphic="arrow"></icon>
      <span class="sr-only">scroll to top</span>
    </button>
  </div>
</template>

<script>

import Icon from '@/component/Icon.vue'

export default {
  name: 'Anchor',
  components: {
    'icon': Icon,
  },
  methods: {
    moveTo() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
