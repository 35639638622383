<template>
  <li class="suggestion">
    <router-link :tabindex="showsuggestions ? 0 : -1" v-if="item" class="suggestion-link" :to="{ name: 'ResourceSingle', params: { slug: item.slug } }">
      <span class="title md:text-sm lg:text-md xl:text-lg" v-html="suggestionTitle"></span>

      <div class="pill temaark-label rounded-auto border-2 border-black text-xs md:text-sm lg:text-base" v-if="attributeMarker">
        {{ attributeMarker }}
      </div>
    </router-link>
  </li>
</template>
<script>

  export default {
    props: {
      item: Object,
      showsuggestions: Boolean,
    },
    computed: {
      searchTerm() {
        return this.$store.getters.getSearchTerm
      },
      attributeData() {
        return this.$store.getters.getAttributes
      },
      attributeMarker() {
        var aTheAttr = this.attributeData.filter(attr => attr.slug === 'temaark');

        if (aTheAttr.length && this.item.egenskaper.includes(aTheAttr[0].id)) {
          return aTheAttr[0].title
        } else {
          return false
        }
      },
      suggestionTitle() {
        var pattern = new RegExp(`(${this.searchTerm})`, 'gi');
        return this.item.title.replace(pattern, '<strong>$1</strong>');
      }
    },
  }
</script>
