<template>
  <aside class="footer-logo px-8">
    <div class="mx-auto max-w-screen-2xl">
      <div class="items"><!--gap-8 lg:gap-32 2xl:gap-64-->
        <figure class="logo-wrapper">
          <svg class="h-6 lg:h-8 w-auto" id="rbup" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164.1 34.91">
            <title>RBUP</title>
            <g>
              <path class="cls-5"
                d="M127.8,.09V21.63c0,2.19-.87,4.29-2.42,5.84-1.55,1.55-3.65,2.42-5.84,2.42s-4.29-.87-5.84-2.42c-1.55-1.55-2.42-3.65-2.42-5.84V.08h-4.94V21.62c-.01,1.74,.32,3.47,.98,5.08,.66,1.61,1.63,3.08,2.85,4.31,1.23,1.24,2.68,2.22,4.29,2.88,1.61,.67,3.33,1.01,5.07,1.01s3.46-.34,5.07-1.01c1.61-.67,3.07-1.65,4.29-2.88,1.23-1.24,2.2-2.7,2.85-4.31,.66-1.61,.99-3.34,.98-5.08V.09h-4.94Z" />
              <path class="cls-5"
                d="M69.71,9.81v-.12c0-2.55-1.01-4.99-2.82-6.8-1.8-1.8-4.25-2.82-6.8-2.82h-14V34.54h4.93v-15.11h2.32l9.69,15.12h6.25l-9.92-15.12h.74c1.26,0,2.51-.25,3.68-.73,1.17-.48,2.23-1.19,3.12-2.08,.89-.89,1.6-1.95,2.08-3.12,.48-1.17,.73-2.42,.73-3.68h0Zm-10.44,5.24h-8.29V4.45h8.29c1.37,0,2.68,.54,3.64,1.51,.97,.97,1.51,2.28,1.51,3.64v.31c0,1.37-.54,2.68-1.51,3.64-.97,.97-2.28,1.51-3.64,1.51Z" />
              <path class="cls-5"
                d="M154.48,.08h-14V34.54h4.92v-15.11h9.08c2.55,0,4.99-1.01,6.8-2.82,1.8-1.8,2.82-4.25,2.82-6.8v-.12c0-2.55-1.01-4.99-2.82-6.8-1.8-1.8-4.25-2.81-6.8-2.82h0Zm4.32,9.83c0,.68-.13,1.35-.39,1.97-.26,.62-.64,1.19-1.12,1.67-.48,.48-1.05,.86-1.67,1.12-.62,.26-1.29,.39-1.97,.39h-8.29V4.45h8.29c.68,0,1.34,.13,1.97,.39s1.19,.64,1.67,1.12c.48,.48,.86,1.05,1.12,1.67,.26,.62,.39,1.29,.39,1.97v.31Z" />
              <path class="cls-5"
                d="M99.72,9.64c0-2.53-1.01-4.96-2.8-6.75-1.79-1.79-4.22-2.8-6.75-2.8h-14.13V34.54h14.13c2.01,0,3.97-.63,5.6-1.81,1.63-1.18,2.85-2.84,3.47-4.75,.63-1.91,.64-3.97,.03-5.89-.61-1.92-1.81-3.59-3.43-4.78,1.2-.89,2.18-2.04,2.86-3.37,.67-1.33,1.03-2.81,1.03-4.3h0Zm-5.29,15.34c0,1.37-.54,2.68-1.51,3.64-.97,.97-2.28,1.51-3.64,1.51h-8.29v-10.62h8.29c1.37,0,2.68,.54,3.64,1.51,.97,.97,1.51,2.28,1.51,3.64v.31Zm0-15.1c0,.68-.13,1.35-.39,1.97-.26,.62-.64,1.19-1.12,1.67-.48,.48-1.05,.86-1.67,1.12-.62,.26-1.29,.39-1.97,.39h-8.29V4.43h8.29c1.37,0,2.68,.54,3.64,1.51,.97,.97,1.51,2.28,1.51,3.64v.3Z" />
            </g>
            <g>
              <path class="cls-4"
                d="M23.03,0c3.05,0,5.98,1.21,8.14,3.37,2.16,2.16,3.37,5.09,3.37,8.14v11.51H11.51V11.51c0-3.05,1.21-5.98,3.37-8.14,2.16-2.16,5.09-3.37,8.14-3.37h0Z" />
              <path class="cls-2"
                d="M11.51,11.51c-1.51,0-3.01,.3-4.41,.88-1.4,.58-2.67,1.43-3.74,2.5-1.07,1.07-1.92,2.34-2.5,3.74C.3,20.02,0,21.52,0,23.03H0C0,26.08,1.21,29.01,3.37,31.17c2.16,2.16,5.09,3.37,8.14,3.37h0V11.51h0Z" />
              <path class="cls-1"
                d="M34.54,23.03h-11.51c0,3.05-1.21,5.97-3.36,8.13-2.15,2.16-5.07,3.37-8.11,3.39h22.99v-11.51Z" />
              <path class="cls-6"
                d="M23.03,23.03H11.51v11.51h.04c3.05-.01,5.97-1.23,8.12-3.39,2.15-2.16,3.36-5.08,3.36-8.13h0Z" />
              <path class="cls-3" d="M34.54,11.51H11.51v11.51h23.03V11.51Z" />
            </g>
          </svg>
        </figure>

        <figure class="logo-wrapper">
          <svg class="h-8 lg:h-12 w-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 370 100" role="img">
            <title>Bufdir</title>
            <g>
              <path
                d="M108.077 28.8885C106.803 29.9404 105.996 31.4535 105.833 33.0978C105.67 34.7421 106.164 36.3841 107.208 37.6654C109.442 40.2 113.473 40.3193 116.142 38.1116C116.755 37.6464 117.262 37.0561 117.629 36.3798C117.996 35.7036 118.215 34.957 118.271 34.1896C118.327 33.4223 118.219 32.6518 117.954 31.9294C117.689 31.207 117.273 30.5493 116.735 30.0001C114.512 27.4385 110.673 26.8577 108.077 28.8885Z"
                fill="#ABC9BA"></path>
              <path
                d="M144.15 22.1731C144.15 22.1731 139.727 19.5731 125.561 11.8884C118.019 7.79228 107.015 3.51536 99.7923 1.78459C85.0384 -1.74233 71.7884 -0.019255 61.4692 6.78459C60.6615 7.31536 59.8756 7.87562 59.1115 8.46536L58.9807 8.56921C57.9807 8.24998 57.0307 7.98075 56.1423 7.76921C25.4269 0.411515 5.76918 17.1961 0.934564 45.2038C-3.90005 73.2115 10.2538 94.1192 38.2615 98.9577C51.3653 101.219 61.9884 99.5846 70.2653 96.1077C73.6528 97.3178 77.139 98.2315 80.6846 98.8384C108.45 103.631 126.838 91.4846 134.973 78.6269C139.154 72.0192 139.904 64.6154 138.773 54.6384C137.477 43.2154 139.069 38.4384 142.619 27.8384L144.15 22.1731ZM74.0576 39.2307C73.2868 39.4939 72.4699 39.595 71.6581 39.5275C70.8464 39.4601 70.0574 39.2257 69.3405 38.8389C68.6237 38.4521 67.9945 37.9214 67.4924 37.2799C66.9904 36.6385 66.6263 35.9003 66.423 35.1115C65.5346 31.6884 67.3961 28.2538 70.5576 27.5192C73.7 26.8461 77.0423 28.8846 78.0615 31.9692C79.0807 35.0538 77.3346 38.2 74.0576 39.2307ZM130.596 47.9461C130.842 50.7384 131.573 59.7384 131.696 62.3923C131.858 66.0192 130.454 71.0615 128.315 74.5038C121.623 85.273 105.946 95.273 82.0153 91.1269C81.2461 90.9961 80.5 90.8513 79.7769 90.6923C83.6608 87.8166 86.9838 84.2524 89.5807 80.1769C92.6807 75.2077 94.323 67.15 93.1346 57.2077C91.8923 46.8231 92.1307 44.4154 97.3923 27.4961C97.4615 27.2769 81.5346 17.9654 67.95 12.0384C76.1038 7.5769 86.4115 6.67305 97.95 9.4269C107.896 11.8154 127.565 22.9692 134.558 26.6615C130.769 36.0538 130.088 42.1923 130.6 47.9269L130.596 47.9461Z"
                fill="#ABC9BA"></path>
            </g>
            <g>
              <path
                d="M193.154 75.5884H170.769V31.2H191.831C201.562 31.2 207.092 35.5346 207.092 42.6653C207.1 44.6255 206.561 46.5491 205.537 48.2204C204.513 49.8916 203.043 51.2443 201.292 52.1269C206.092 54.0499 208.623 57.9269 208.623 62.6576C208.615 70.4576 202.95 75.5884 193.154 75.5884ZM191.023 40.3307H181.408V48.5307H191.154C194.354 48.5307 196.354 46.9923 196.354 44.2653C196.354 41.5384 194.354 40.3307 191.023 40.3307ZM192.423 56.923H181.423V66.4538H192.354C195.619 66.4538 197.685 64.7192 197.685 61.723C197.692 58.8461 195.769 56.923 192.423 56.923Z"
                fill="#1A1A1A"></path>
              <path
                d="M247.6 40V75.5885H237.404V71.5885C234.604 74.5885 230.865 76.3885 226.738 76.3885C218.661 76.3885 214.742 70.7885 214.742 63.5923V40H224.938V61.2577C224.938 64.2577 225.873 67.3231 230.469 67.3231C234.269 67.3231 237.392 64.8577 237.392 60.2577V40H247.6Z"
                fill="#1A1A1A"></path>
              <path
                d="M277.058 35.8C275.752 35.3348 274.379 35.0866 272.992 35.0654C270.392 35.0654 269.123 35.9346 269.123 38.2654V40H276.539V47.7308H269.123V75.5885H258.927V47.7308H253.396V40H258.927V38.5308C258.927 31.9346 263.262 27.2692 271.792 27.2692C273.926 27.2227 276.056 27.4685 278.123 28L277.058 35.8Z"
                fill="#1A1A1A"></path>
              <path
                d="M306.442 75.5885V71.5385C305.103 73.0999 303.434 74.3457 301.556 75.1868C299.678 76.028 297.638 76.4436 295.581 76.4038C285.581 76.4038 279.385 68.3269 279.385 57.8077C279.385 47.2115 285.581 39.2154 295.581 39.2154C299.912 39.2154 303.577 40.8115 306.442 44.0808V28H316.708V75.5885H306.442ZM298.177 47.9962C292.712 47.9962 290.1 52.5269 290.1 57.8615C290.1 63.1962 292.696 67.5923 298.177 67.5923C303.712 67.5923 306.442 63.1923 306.442 57.8615C306.442 52.4615 303.642 47.9962 298.177 47.9962Z"
                fill="#1A1A1A"></path>
              <path d="M326.127 35.6V28H336.323V35.6H326.127ZM326.127 75.6V40H336.323V75.5885L326.127 75.6Z"
                fill="#1A1A1A"></path>
              <path
                d="M367.185 50.6616C365.97 49.9555 364.589 49.5876 363.185 49.5962C358.919 49.5962 355.788 53.127 355.788 58.3923V75.5885H345.592V40H355.788V45.3308C357.988 41.7308 361.454 39.3308 365.254 39.3308C366.985 39.3308 368.85 39.8 369.869 40.4L367.185 50.6616Z"
                fill="#1A1A1A"></path>
            </g>
          </svg>
        </figure>

        <figure class="logo-wrapper -img">
          <img :src="require('../../../images/BFD-logo.png')" alt=""/>
        </figure>
      </div>


    </div>
  </aside>
</template>
<script>
  export default {
    data: () => ({
      baseURL: process.env.MIX_API_BASE_URL,
    }),
  }
</script>
