<template>

  <div
  v-if="dataCountainsAssets"
  class="hero-assets break-words w-full p-8 lg:p-12"
  :class="['bg-' +data.color]">

    <ul class="grid lg:grid-cols-1 gap-x-8 gap-y-4">
      <li
      class="asset font-SuisseIntlMedium text-2xl"
      v-for="(item, index) in data.links.asset"
      :key="index">
        <a
        class="flex items-center gap-4"
        :href="item.link_asset.url" target="_blank" rel="nofollow">
          <icon graphic="download"></icon>
          <span class="underline hover:no-underline">{{ item.label ? item.label : item.link_asset.title }}</span>
        </a>
      </li>
    </ul>

  </div>

</template>

<script>
import Icon from '@/component/Icon.vue'

export default {
  props: {
    data: Object
  },
  components: {
    'icon': Icon,
  },
  methods: {
    print() {
      return window.print()
    },
    toggleBookmark(id) {
      this.$store.dispatch('toggleBookmark', id)
    },
  },
  computed: {
    allBookmarks() {
      return this.$store.getters.getBookmarks
    },
    dataCountainsAssets() {
      console.log(this.data);
      if (this.data && this.data.links && Object.keys(this.data.links.asset).length) {
        return true
      }
      return false
    }
  }
}
</script>
