<template>
  <!--<illustration data="deltaker2" />-->
</template>

<script>
import { EventBus } from "@/store/hero.js";

const hero = {
  'id': '44',
  'color': 'gray-light',
  'image': false,
  'src': '',
  'label': '',
  'layout': 'illustration',
  'content': { "item": "kvinne1" },
  'title': 'Å nei! Siden er borte!',
  'excerpt': 'Beklager, siden du leter etter finnes ikke, eller nettadressen er feil.'
}


export default {
  components: {},
  data : () => ({
    hero
  }),
  methods: {
    setHero(data) {
      EventBus.$emit('EventBus', data)
    },
  },
  mounted() {
    // this.setHero(hero)
    this.$store.dispatch('updateHero', this.hero);
    this.$store.dispatch('setViewTitle', '404');
  },
}
</script>



