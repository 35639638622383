<template>
  <section class="tips-inner bg-red-light">

    <div class="component-inner">
      <header class="block-header border-black border-b">
        <h2 class="title">{{ block.heading ? block.heading : "Tips og triks" }}</h2>
      </header>

      <div class="paragraph lead" v-html="block.tips.wysiwyg" /><!-- data[0].content -->
    </div>

  </section>
</template>

<script>
export default {
  name: 'Tips',
  props: {
    data: [],
    block: []
  },
}
</script>
