<template>

  <nav class="component anchor" :class="menuClass">
    <div class="component-inner">
      <button class="toggle-menu flex items-center gap-4 w-full text-xl text-left text-gray-light" @click="toggleDropdown">
        <span>Gå til</span>

        <icon graphic="arrow" class="arrow-down"/>

        <i class="icon icon-x"></i>
      </button>

      <div class="menu-container" ref="menuContainer">
        <ul class="inner" ref="menuItems">
          <li class="menu-item" v-for="(item, index) in block" :key="index" :data-id="item.id">
            <span @click="moveTo(item.id)">{{ item.title ? item.title : item.type | capitalize }}</span>

            <icon graphic="arrow" class="arrow-down"/>
          </li>
        </ul>
      </div>
    </div>
  </nav>

</template>

<script>
import Icon from '@/component/Icon'

export default {
  name: 'Anchor',
  data : () => ({
    isActive: false,
    menuState: 'closed',
  }),
  props: {
    block: []
  },
  components: {
    Icon,
  },
  methods: {
    closeDropdown() {
      this.menuState = 'closing';

      setTimeout(() => {
        this.menuState = 'closed';
      }, 300);
    },
    openDropdown() {
      this.$refs['menuContainer'].style.setProperty('--max-height', `${this.$refs['menuItems'].scrollHeight}px`);
      this.menuState = "open";
    },
    toggleDropdown() {
      if (this.menuState === "open") {
        this.closeDropdown();
      } else {
        this.openDropdown();
      }
    },
    moveTo(id) {
      let element = document.querySelector('[data-uuid="' + id + '"]');
      if (element) {
        let top = element.offsetTop;

        window.scrollTo({
          top: top,
          left: 0,
          behavior: 'smooth'
        })
      }
    },
  },
  computed: {
    menuClass() {
      switch (this.menuState) {
        case "open":
          return "-open";
          break;
        case "closing":
          return "-open -closing";
          break;
        default:
          return "-closed";
      }
    },
  }
}
</script>
