<template>

  <div
  v-if="acf"
  class="type"
  :class="[post.type]"
  :data-id="post.id"
  :data-slug="post.slug">

    <Anchor
    :block="anchorItems"
    v-if="anchorItems.length" />

    <section
    v-if="acf.blocks"
    v-intersect="{observerOptions: {rootMargin: '0px', threshold: 0.0}, true: ['-intersected'], disposeWhen: true }"
    v-for="(block, index) in acf.blocks"
    :key="block.acf_fc_layout + index"
    :data-uuid="setComponentUUID(block, index)"
    class="component"
    :class="[block.acf_fc_layout, block.options && block.options.layout ? block.options.layout : '', block.options && block.options.color ? 'bg-'+ block.options.color : 'bg-white']">

      <component
      :is="block.acf_fc_layout"
      :block="block"
      :key="block.acf_fc_layout + index + '_component'" />

    </section>

  </div>
</template>

<script>
import { EventBus } from '@/store/hero.js';

import postMeta from '@/mixins/postMeta.js';

import Accordion from '@/component/Page/Accordion'
import linkBlock from '@/component/Page/LinkBlock'
import linkButtonsBlock from '@/component/Page/LinkButtonsBlock'
import Preamble from '@/component/Page/Preamble'
import categoryInclude from '@/component/Page/CategoryInclude'

// import Default from '@/Layout/Default'


import Anchor from '@/component/Block/Anchor'
import Card from '@/component/Block/Card'
import Category from '@/component/Block/Category'
import Enhanced from '@/component/Block/Content'
import Description from '@/component/Block/Description'
import Feature from '@/component/Block/Feature'
import Guide from '@/component/Block/Guide'
import Links from '@/component/Block/Link'
import Media from '@/component/Block/Media'
import Quote from '@/component/Block/Quote'
import Related from '@/component/Block/Related'
import Story from '@/component/Block/Story'

import Jumbotron from '@/component/Block/Jumbotron'


export default {
  components: {
    // Default,
    Accordion,
    Anchor,
    Card,
    Category,
    Enhanced,
    Description,
    Feature,
    Guide,
    Links,
    linkBlock,
    linkButtonsBlock,
    Media,
    Quote,
    Related,
    Story,
    Jumbotron,
    Preamble,
    categoryInclude
  },

  data : () => ({
    hero: [],
    // post: null,
    // acf: [],
    errors: [],
    scrollTo: {},
    observer: null,
    baseURL: process.env.MIX_API_BASE_URL,
  }),

  mixins: [
    postMeta,
  ],

  computed: {
    post() {
      return this.$store.getters.getPageBySlug("kompasset");
    },
    acf() {
      if (this.post) {
        return this.post.acf
      }
      return false
    },
    anchorItems() {
      let aTheItems = [];

      if (this.acf && this.acf.blocks && this.acf.blocks.length) {

        this.acf.blocks.forEach((block, i) => {
          if (block.anchor && block.anchor.anchor) {
            let sTheLabel = null;

            if (block.anchor.label) {
              sTheLabel = block.anchor.label
            } else if (block.options.title) {
              sTheLabel = block.options.title;
            }

            if (sTheLabel) {
              aTheItems.push({
                title: sTheLabel,
                index: i,
                id: `anchor-item_${i}`
              });
            }
          }
        })
      }

      return aTheItems
    }
  },

  methods: {
    async getPage() {
      try {
        const response = await axios.get(
          this.baseURL + '/api/wp/v2/pages', {
            params: {
              slug: 'kompasset',
            }
          }
        )
        const results = response.data
        if ( !results || results.length == 0 ) {
          const e = new Error('No data');
          e.statusCode = 404;
          throw e;
        }
        this.post = results.map(post => ({
          id: post.id,
          type: post.type,
          slug: post.slug,
          title: post.title.rendered,
          media: post.featured_media,
          template: post.template ? post.template : 'default',
          head: post.yoast_head_json,
        }))[0]

        this.acf = results.map(post => ({
          components: post.acf.blocks
        }))

        this.hero = results.map(post => ({
          id: post.id,
          label: post.acf.excerpt.super,
          title: post.acf.hero.title ? post.acf.hero.title : post.title.rendered,
          excerpt: post.acf.excerpt.excerpt ? post.acf.excerpt.excerpt : false,
          type: post.type,
          date: post.date,
          url: post.link,
          layout: this.setLayout(post.acf.options.layout),
          color: post.acf.options.color ? post.acf.options.color : 'gray-light',
          image: post.acf.hero.media.landscape.image ? {
            id: post.acf.hero.media.landscape.image.id,
            alt: post.acf.hero.media.landscape.image.alt,
            caption: post.acf.hero.media.landscape.image.caption,
            description: post.acf.hero.media.landscape.image.description,
            sizes: post.acf.hero.media.landscape.image.sizes
          } : false,
          video: false,
        }))
      }
      catch (e) {
        this.errors.push(e)
        this.$router.push({ name: 'PageNotFound' })
      }
      return this.doAction()

    },

    doAction() {
      // EventBus.$emit('EventBus', this.hero[0])
      this.$store.dispatch('updateHero', this.hero[0]);
      // this.setHero(this.hero[0])

      this.scrollData(this.acf[0].components)
    },

    scrollData(data) {
      if ( data.length ) {
        let newData = []

        data.forEach((value, index) => {
          if ( value.anchor && value.anchor.anchor ) {
            newData.push({
              id: value.anchor.uuid ? value.anchor.uuid : index,
              title: value.anchor.label ? value.anchor.label : value.options.title,
              type: value.acf_fc_layout,
            });
          }
        })
        this.scrollTo = newData
      }
    },

    setComponentUUID(block, i) {
      let anchorItem = this.anchorItems.find(item => item.index === i);

      if (anchorItem) {
        return anchorItem.id
      }
      // console.log('setComponentUUID', block);
      // if (block.anchor && block.anchor.uuid) {
      //   return block.anchor.uuid
      // }
      return '';
    },

    setHero(data) {
      EventBus.$emit('EventBus', data)
    },

    resetData() {
      this.scrollTo = {}
      this.acf = Object
    },

    setLayout(e) {
      let expr = e
      let data = String

      switch (expr) {
        case 'superimposed':
          data = e
          break;

        default:
          data = e
      }
      return data
    },

    observerElement() {
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if(entry.isIntersecting){
            //console.log('Observed');
          }
        });
      },
      {
        rootMargin: '-50% 0% -50% 0%'
      })

      document.querySelectorAll('.article').forEach(item => {
        observer.observe(item)
      })
    }
  },

  mounted() {
    // this.getPage()
    this.$store.dispatch('setViewTitle', 'Kompasset');
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.dispatch('ajax/isLoggedIn', {
        id: vm.$store.state.userID,
        success: function(data) {
          /*if (!data.isLoggedIn) {
            vm.$router.push({ name: 'Login' });
          } else {*/
            vm.$store.dispatch('setActivePage', "kompasset");
            next();
          /*}*/
        }
      });
    });
  },

  watch: {
    $route: {
      immediate: true,
      handler: function(newVal, oldVal) {
        this.isModalVisible = newVal.meta && newVal.meta.isModalVisible;
      }
    },
    // '$route.params.slug'(newSlug, oldSlug) {
    //   this.resetData()
    //   this.getPage()
    // }
  }
}
</script>
