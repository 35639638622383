<template>
  <div
  id="app"
  class="app-wrapper"
  :class="[{ 'menu--is-open' : isPanelOpen, '-ready' : !appIsRendering }, $route.name]"
  itemscope itemtype="http://schema.org/WebPage">
    <Header />
    <Hero />
    <main
    itemscope itemprop="mainContentOfPage"
    v-intersect="{observerOptions: {rootMargin: '0px 0px -200px 0px', threshold: 0.025}, true: ['-intersected'], onChange: handleIntersection }">

      <router-view />
      <Anchor v-show="scroll" />
    </main>


    <transition name="popup">
      <section class="popup idle-warning" v-if="showIdleWarning">
        <div class="overlay"></div>

        <div class="message">
          {{ logoutWarningMessage }}
        </div>
      </section>
    </transition>

    <Footer />
    <footer-logo />
  </div>
</template>

<script>
import Header from '@/section/Header.vue'
import Footer from '@/section/Footer.vue'
import FooterLogo from '@/section/FooterLogo.vue'
import Anchor from '@/section/Anchor.vue'
import Hero from '@/section/Hero/Hero.vue'

import _throttle from 'lodash/throttle'

import idle from '@/mixins/idle.js'

// import { store, mutations } from '@/store/store.js';
// import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Header,
    Anchor,
    Footer,
    FooterLogo,
    Hero
  },
  data() {
    return {
      scroll: Boolean = false,
      countries: [],
      handleThrottledScroll: null,
      viewReady: false,
    }
  },
  mixins: [
    idle,
  ],
  methods: {
    handleIntersection(isIntersecting, el, options) {
      this.scroll = isIntersecting
    },
    handleScroll(event) {
      // var oldVal = this.scrollTop;
      // var newVal = window.scrollY;
      // var threshold = 30;

      this.$store.dispatch('updateScrollTop', window.scrollY);
    },
    onResize() {
      this.$store.dispatch('onWindowResize', { width: window.innerWidth, height: window.innerHeight });
      // console.log(this.windowHeight);
    },
    resetIdle() {
      // this.$store.dispatch("resetIdleTimer");
    }
    // ...mapActions(['fetchPagesAndPosts']),
  },
  computed: {
    isPanelOpen() {
      // return store.isNavOpen
      return this.$store.getters.getNavOpenState
    },
    scrollTop() {
      return this.$store.getters.getScrollTop
    },
    appIsRendering() {
      if (this.$store.getters.getRenderingItems.length) {
        return true
      }
      return false
    },
    // idleMessage() {
    //   return this.$store.getters.getIdleMessage
    // }
  },
  created() {
    // this.fetchPagesAndPosts()
    // this.$store.dispatch('fetchPagesAndPosts');
    this.$store.dispatch('updateRenderingItems', { name: 'app', value: true });

    // console.log("ROUTE BEFORE INIT", this.$route);

    this.$store.dispatch('init');
  },
  mounted() {
    this.handleThrottledScroll = _throttle(this.handleScroll, 250);
    window.addEventListener('scroll', this.handleThrottledScroll);

    this.$nextTick(() => {
      this.viewReady = true;
      this.$store.dispatch('onWindowResize', { width: window.innerWidth, height: window.innerHeight });
      window.addEventListener("resize", this.onResize);
      this.$store.dispatch('updateRenderingItems', { name: 'app', value: false });
      this.$store.dispatch('afterInit');

//       "load click mousemove mousedown scroll keypress keyup".split(" ").forEach((eventName) => document.body.addEventListener(eventName, this.resetIdle));
//
//       this.resetIdle();

    })
  },
  updated() {
    // console.log('app updated', new Date());
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleThrottledScroll);
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    '$route' () {
      // this.isPanelOpen ? mutations.toggleNav() : false
      this.isPanelOpen ? this.$store.dispatch('toggleNav') : false
    }
  }
}
</script>

