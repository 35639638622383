<template>

  <div
  v-if="acf"
  class="type main-content"
  :class="[post.type]"
  :data-id="post.id"
  :data-slug="post.slug">

    <Anchor
    :block="anchorItems"
    v-if="anchorItems.length" />

    <section
    v-if="acf.blocks"
    v-intersect="{observerOptions: {rootMargin: '0px', threshold: 0.0}, true: ['-intersected'], disposeWhen: true }"
    v-for="(block, index) in acf.blocks"
    :key="`section_${block.acf_fc_layout}_${index}`"
    :data-uuid="setComponentUUID(block, index)"
    class="component"
    :class="[block.acf_fc_layout, block.options && block.options.layout ? block.options.layout : '', block.options && block.options.color ? 'bg-'+ block.options.color : 'bg-white']">

      <KeepAlive :max="10">

        <component
        :is="block.acf_fc_layout"
        :block="block"
        :key="`component_${block.acf_fc_layout}_${index}`" />

      </KeepAlive>

    </section>

  </div>
</template>

<script>
// import { EventBus } from '@/store/hero.js';

import postMeta from '@/mixins/postMeta.js';

import Accordion from '@/component/Page/Accordion'
import linkBlock from '@/component/Page/LinkBlock'
import LinkButtonsBlock from '@/component/Page/LinkButtonsBlock'
import Preamble from '@/component/Page/Preamble'
import categoryInclude from '@/component/Page/CategoryInclude'

// import Default from '@/Layout/Default'
import { isMobileOnly } from 'mobile-device-detect';
console.log('Is mobile, ' +isMobileOnly)


import Anchor from '@/component/Block/Anchor'
import Card from '@/component/Block/Card'
import Category from '@/component/Block/Category'
import Enhanced from '@/component/Block/Content'
import Description from '@/component/Block/Description'
import Feature from '@/component/Block/Feature'
import Guide from '@/component/Block/Guide'
import Links from '@/component/Block/Link'
import Media from '@/component/Block/Media'
import Quote from '@/component/Block/Quote'
import Related from '@/component/Block/Related'
import Story from '@/component/Block/Story'
import Studies from '@/component/Block/Studies'
import Contact from '@/component/Block/Contact'
import VideoText from '@/component/Block/VideoText'
import InfoGraphic from '@/component/Block/InfoGraphic'
import Jumbotron from '@/component/Block/Jumbotron'


export default {
  components: {
    // Default,
    Accordion,
    Anchor,
    Card,
    Category,
    Enhanced,
    Description,
    Feature,
    Guide,
    Links,
    linkBlock,
    LinkButtonsBlock,
    Media,
    Quote,
    Related,
    Story,
    Jumbotron,
    Preamble,
    categoryInclude,
    Studies,
    Contact,
    VideoText,
    InfoGraphic
  },

  data: () => ({
    // post: null,
    hero: [],
    // post: [],
    // acf: [],
    errors: [],
    scrollTo: [],
    observer: null,
    baseURL: process.env.MIX_API_BASE_URL,
  }),

  mixins: [
    postMeta,
  ],

  computed: {
    post() {
      // console.log("VIEW: PAGE", this.$route);
      let sTheSlug = this.$route.params.child ? this.$route.params.child : this.$route.params.slug;
      // console.log("PAGE", this.$store.getters.getPostBySlug(sTheSlug));
      return this.$store.getters.getPageBySlug(sTheSlug);
    },
    acf() {
      if (this.post) {
        return this.post.acf
      }
      return false
    },
    anchorItems() {
      let aTheItems = [];

      if (this.acf && this.acf.blocks && this.acf.blocks.length) {

        this.acf.blocks.forEach((block, i) => {
          if (block.anchor && block.anchor.anchor) {
            let sTheLabel = null;

            if (block.anchor.label) {
              sTheLabel = block.anchor.label
            } else if (block.options.title) {
              sTheLabel = block.options.title;
            }

            if (sTheLabel) {
              aTheItems.push({
                title: sTheLabel,
                index: i,
                id: `anchor-item_${i}`
              });
            }
          }
        })
      }
      return aTheItems
    }
  },

  methods: {
//     async getPage() {
//       try {
//         // console.log("PAGE ROUTE", this.$route);
//
//         let sTheSlug = this.$route.params.child ? this.$route.params.child : this.$route.params.slug
//
//         const response = await axios.get(
//           this.baseURL + '/api/wp/v2/pages', {
//             params: {
//               // slug: this.$route.params.slug,
//               slug: sTheSlug,
//             }
//           }
//         )
//         const results = response.data
//         if ( !results || results.length == 0 ) {
//           this.errors.push('Error, 404')
//
//           const e = new Error('No data');
//           e.statusCode = 404;
//           throw e;
//         }
//         this.post = results.map(post => ({
//           id: post.id,
//           type: post.type,
//           slug: post.slug,
//           title: post.title.rendered,
//           media: post.featured_media,
//           template: post.template ? post.template : 'default',
//           head: post.yoast_head_json,
//         }))[0]
//
//         this.acf = results.map(post => ({
//           components: post.acf.blocks
//         }))
//
//         this.hero = results.map(post => ({
//           id: post.id,
//           label: post.acf.excerpt.super,
//           title: post.acf.hero.title ? post.acf.hero.title : post.title.rendered,
//           excerpt: post.acf.excerpt.excerpt ? post.acf.excerpt.excerpt : false,
//           type: post.type,
//           date: post.date,
//           url: post.link,
//           layout: this.setLayout(post.acf.options.layout),
//           content: (post.acf.options.layout != 'superimpossed' ) ? this.setContent(post.type, post.acf.options.layout, post.acf.hero) : false,
//           button: post.acf.excerpt.button.url ? {
//             url: post.acf.excerpt.button.url,
//             label: post.acf.excerpt.button.label,
//             style: post.acf.excerpt.button.style,
//           } : false,
//           color: post.acf.options.color ? post.acf.options.color : 'gray-light',
//           image: this.setImage( post.acf.hero.media.landscape.image, post.acf.hero.media.portrait.image ),
//           video: false,
//         }))
//       }
//       catch (e) {
//         this.errors.push(e);
//         this.$router.push({ name: 'PageNotFound' })
//       }
//       // return this.doAction()
//
//     },
//
//     doAction() {
//       // EventBus.$emit('EventBus', this.hero[0])
//       this.$store.dispatch('updateHero', this.hero[0]);
//       // this.setHero(this.hero[0])
//       // document.title = `${this.post.title} | ${this.$store.getters.getTitle}`;
//
//       // document.title = this.$store.getters.getTitle(this.post.title);
//
//       if (this.post.length && this.post.title) {
//         this.$store.dispatch('setViewTitle', this.post.title);
//       }
//
//       if (this.acf[0] && this.acf[0].components) {
//         this.scrollData(this.acf[0].components)
//       }
//     },

//     scrollData(data) {
//       if ( data.length ) {
//         let newData = []
//
//         data.forEach((value, index) => {
//           if ( value.anchor.anchor ) {
//             newData.push({
//               id: value.anchor.uuid ? value.anchor.uuid : index,
//               title: value.anchor.label ? value.anchor.label : (value.options && value.options.title ? value.options.title : 'No label'),
//               type: value.acf_fc_layout,
//             });
//           }
//         })
//
//         this.scrollTo = newData
//
//       }
//     },

    setComponentUUID(block, i) {
      let anchorItem = this.anchorItems.find(item => item.index === i);

      if (anchorItem) {
        return anchorItem.id
      }
      return '';
    },

    setHero(data) {
      // EventBus.$emit('EventBus', data)
    },

    resetData() {
      this.scrollTo = []
      this.acf = []
    },

//     setContent(type, style, post) {
//
//       let data = Object
//
//       if (['post', 'page'].includes(type)) {
//         switch (style) {
//           case 'content':
//             data = {
//               color: post.sidebar.color,
//               title: post.content.content.subtitle ? post.content.content.subtitle : false,
//               wysiwyg: post.content.content.wysiwyg ? post.content.content.wysiwyg : false,
//               links: post.content.links,
//               icon: post.icon.icon ? {
//                 item: post.icon.icon,
//                 color: post.icon.iconColor
//               } : false
//
//             }
//             break;
//           case 'icon':
//             data = {
//               color: post.icon.iconColor,
//               item: post.icon.icon ? post.icon.icon : false
//             }
//             break;
//
//           case 'illustration':
//             data = {
//               // color: post.sidebar.color,
//               item: post.illustration.illustration ? post.illustration.illustration : false
//             }
//             break;
//
//           default:
//             data = false
//         }
//       } else {
//         data = {
//           color: post.sidebar.color
//         }
//       }
//       return data
//
//     },

    setLayout(e) {
      let expr = e
      let data = String

      switch (expr) {
        case 'superimposed':
          data = e
          break;

        default:
          data = e
      }
      return data
    },

    setImage(landscape, portrait) {

      let image = {}

      if ( isMobileOnly ) {
        console.log('Is mobile, ' +isMobileOnly)
        if ( portrait ) {
          image = {
            id: portrait.id,
            alt: portrait.alt,
            caption: portrait.caption,
            description: portrait.description,
            url: this.baseURL + `/${portrait.sizes['large']}`,
            height: `${landscape.sizes['large-height']}`,
            width: `${landscape.sizes['large-width']}`
          }
        } else {
          if ( landscape ) {
            image = {
              id: landscape.id,
              alt: landscape.alt,
              caption: landscape.caption,
              description: landscape.description,
              url: this.baseURL + `/${landscape.sizes['large']}`,
              height: `${landscape.sizes['large-height']}`,
              width: `${landscape.sizes['large-width']}`
            }
          }

        }

      } else {
        console.log('Is mobile, ' +isMobileOnly)
        if ( landscape ) {
          image = {
            id: landscape.id,
            alt: landscape.alt,
            caption: landscape.caption,
            description: landscape.description,
            url: this.baseURL + `/${landscape.sizes['2048x2048']}`,
            height: `${landscape.sizes['2048x2048-height']}`,
            width: `${landscape.sizes['2048x2048-width']}`
          }
        }
      }
      //console.log(image)
      return image

    },

    observerElement() {
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if(entry.isIntersecting){
            //console.log('Observed');
          }
        });
      },
      {
        rootMargin: '-50% 0% -50% 0%'
      })

      document.querySelectorAll('.article').forEach(item => {
        observer.observe(item)
      })
    }
  },

  mounted() {
    // this.getPage()
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      let sTheSlug = to.params.child ? to.params.child : to.params.slug;

      vm.$store.dispatch('setActivePage', sTheSlug)
      // vm.resetData()
      // vm.getPage()
    })
  },

  watch: {
    $route: {
      immediate: true,
      handler: function(newVal, oldVal) {
        this.isModalVisible = newVal.meta && newVal.meta.isModalVisible;
      }
    },
    // '$route.params.slug'(newSlug, oldSlug) {
    //   this.resetData()
    //   this.getPage()
    // }
  }
}
</script>
