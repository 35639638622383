<template>
  <div v-if="isDataAvailable">
    <section class="page-top component search">
      <div class="component-inner">
        <div class="inner-content w-full max-w-screen-2xl mx-auto md:py-20 pt-40 xl:pt-40 z-10">

          <header class="self-center w-full max-w-xl xl:max-w-[1000px]">
            <h1>Biblioteket</h1>
            <div class="lead regular">
              <p>Søk i programmets materiale. Bruk filtreringsfunksjonen for å innsnevre søket.</p>
            </div>
          </header>

          <div class="hero-aside w-full flex-1 self-end">
            <search-form></search-form>

            <div class="bookmark-wrapper">
              <button class="button -primary" v-on:click="toggleBookmarksFilter" :class="{ '-red': bookmarkFilterActive }">
                <icon :graphic="bookmarkFilterActive ? 'bookmark-filled' : 'bookmark'"></icon>
                <span class="">Bokmerkede sider</span>
              </button>
            </div>
          </div>

        </div>

      </div>
    </section>

    <section class="component resources">
      <div class="component-inner">
        <transition name="fade">
          <div class="overlay" v-if="isFilterOpen" @click="toggleFilter"></div>
        </transition>

        <!-- <aside class="filter w-full lg:w-1/4 3xl:w-full 3xl:max-w-sm" :class="{ '-in': isFilterOpen }"> -->
        <transition-group name="slide" tag="aside" class="filter"  :class="{ '-in': isFilterOpen }">

          <a class="skip-link" href="#resourceArticles" key="skipFilterLink" @click.stop.prevent="skipToContent">Skip to articles</a>

          <header class="filter-header flex justify-between items-center gap-4" key="filterHeader">
            <icon graphic="toggle-filter"></icon>
            <h6 class="flex-1">Filter</h6>
          </header>

          <filter-block key="typeFilterBlock" heading="Typer" handle="taxTypes" :blockData="taxTypes" v-if="taxTypes.length"></filter-block>

          <filter-block key="areaFilterBlock" heading="Programområder" handle="taxAreas" :blockData="taxAreas" v-if="taxAreas.length"></filter-block>

          <filter-block key="attributeFilterBlock" heading="Egenskap" handle="taxAttributes" :blockData="taxAttributes" v-if="taxAttributes.length"></filter-block>

          <button class="button -secondary close-filter" @click="toggleFilter" v-if="isMobile" key="toggleFilterBtn">
            <span>Vis Resultat</span>
            <icon class="arrow arrow-right" graphic="arrow"></icon>
          </button>
        </transition-group>
        <!-- </aside> -->

        <div id="resourceArticles" ref="resourceArticles" class="resources-wrap w-full lg:w-auto lg:flex-1">
          <h2 class="sr-only">Articles</h2>

          <button class="toggle-filter button -primary" @click="toggleFilter">
            <icon graphic="toggle-filter"></icon>
            <span>Vis filter</span>
          </button>

          <div class="component-meta" v-if="activeFilterTerms.length || bookmarkFilterActive"><!-- justify-between flex-none w-full flex items-center gap-4 -->
            <transition-group name="slide" tag="div" class="filter-terms" v-if="activeFilterTerms.length" @before-leave="beforeItemLeave">
              <button class="pill pill-button filter-term rounded-auto border-2 border-black md:text-sm lg:text-md xl:text-lg" v-for="item in activeFilterTerms" :key="'filterPill_' + item.id" @click="removeFilterTerm(item.handle, item.id)">
                <span class="text">{{ item.title }}</span>

                <div class="remove-term">
                  <span class="sr-only">Remove filter</span>
                  <i class="icon icon-x"></i>
                </div>
              </button>
            </transition-group>

            <!-- <div class="self-end" > -->
              <button class="remove-filters " v-if="filteredResults.length != posts.length" v-on:click="removeFilters">
                <icon graphic="clear-filters"></icon>

                <span>Nullstill filter</span>
              </button>
            <!-- </div> -->
          </div>

          <!-- <div style="opacity: 0;">reloading: {{ resourceLoadingStatus }}</div> -->

          <!-- <div key="loadingSpinner" class="spinner"  :class="{ '-rendering': resourceLoadingStatus }">her kommer en spinner. Loading: {{ resourceLoadingStatus }}</div> -->

          <!-- Articles -->
          <div v-if="load" class="resources__list_loader">
            <div class="resources__list_loader-image"></div>
          </div>
          <div v-else>
            <transition-group class="resources-list" name="list" tag="section" @before-leave="beforeItemLeave">
              <singleitem
                v-for="(post, index) in filteredResults"
                :post="post"
                :key="`resource_post_${post.id}_${index}`"
                :data-index="index">
              </singleitem>
              <div key="filterNotFound" class="list-empty xl:col-span-3 p-4" v-if="filteredResults.length === 0">
                <h4 class="medium mb-0">Finner ingen treff på ditt søk …</h4>
              </div>
            </transition-group>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>

<script type="text/javascript">
// import { EventBus } from '@/store/hero.js';
import _isEqual from 'lodash/isEqual'
import FilterBlock from './FilterBlock.vue'
import SingleItem from './ResourceSingleItem.vue'
import SearchForm from '@/component/Search/SearchForm.vue'
import Icon from '@/component/Icon.vue'
import ResourcePosts from '@/view/Resource/ResourcePosts'
// import _isEqual from 'lodash/isEqual'

export default {
  data : () => ({
    hero: [],
    // posts: [],
    errors: [],
    wpPosts: [],
    load: false,
    observer: null,
    /*pagination: {
      prevPage: '',
      nextPage: '',
      totalPages: '',
      from: '',
      to: '',
      total: '',
    },*/
    pagination: {
      perPage: 18,
      currentPage: 1,
    },
    searchTerm:'',
    // taxAreas: [],
    // taxTypes: [],
    // taxAttributes: [],
    // bookmarks: [],
    // myBookmarks: [2358, 2313],
    isDataAvailable: false,
    baseURL: process.env.MIX_API_BASE_URL,
    isFilterOpen: false,
    // windowWidth: 0,
  }),

  components: {
    singleitem: SingleItem,
    'filter-block': FilterBlock,
    'search-form': SearchForm,
    'icon': Icon,
    'resource-posts': ResourcePosts,
  },

  methods: {
    async getPosts() {
      // console.log('dispatching fetchResources');
      // this.$store.dispatch('fetchResources');
      this.isDataAvailable = true;
      return this.doAction()
    },

    getAttributeByID(id) {
      return this.$store.getters.getAttributeByID(id)
    },

    // configPagination(headers) {
    //   this.pagination.total = +headers['x-wp-total'];
    //   this.pagination.totalPages = +headers['x-wp-totalpages'];
    //   this.pagination.from = this.pagination.total ? ((this.postsData.page - 1) * this.postsData.per_page) + 1 : ' ';
    //   this.pagination.to = (this.postsData.page * this.postsData.per_page) > this.pagination.total ? this.pagination.total : this.postsData.page * this.postsData.per_page;
    //   this.pagination.prevPage = this.postsData.page > 1 ? this.postsData.page : '';
    //   this.pagination.nextPage = this.postsData.page < this.pagination.totalPages ? this.postsData.page + 1 : '';
    // },

    doAction() {
      // EventBus.$emit('EventBus', this.hero[0])
      this.$store.dispatch('updateHero', this.hero[0]);
      // this.setHero(this.hero[0])
    },

    setHero(data) {
      // EventBus.$emit('EventBus', data)
    },

    resetData() {
      this.posts = null
    },

    setLayout(e) {
      let expr = e
      let data = String

      switch (expr) {
        case 'superimposed':
          data = e
          break;

        default:
          data = e
      }
      return data
    },

    createString(data) {
      // //console.log(data)

      if ( data.length > 0 )
        return data.join(' ')

      return ''

    },

    observerElement() {
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if(entry.isIntersecting){
            //console.log('Observed');
          }
        });
      },
      {
        rootMargin: '-50% 0% -50% 0%'
      })

      document.querySelectorAll('.article').forEach(item => {
        observer.observe(item)
      })
    },

    // updateFilter() {
    //   this.taxAreas = this.taxAreas
    //   this.taxTypes = this.taxTypes
    //   this.taxAttributes = this.taxAttributes
    // },

    toggleBookmarksFilter() {
      if (this.bookmarkFilterActive) {
        this.$store.dispatch('filterResources', { param: 'bookmarks', value: [] })
      } else {
        this.$store.dispatch('filterResources', { param: 'bookmarks', value: this.bookmarks })
      }
    },

    removeFilters() {
      // this.taxAreas = []
      // this.taxTypes = []
      // this.taxAttributes = []
      // this.bookmarks = []
      // this.searchTerm = ''
      this.$store.dispatch('resetResourceFilter');
    },

    removeFilterTerm(handle, id) {
      if (handle === "bookmarks") {
        this.$store.dispatch('filterResources', { param: handle, value: [] });
      } else {
        this.$store.dispatch('filterResources', { param: handle, value: this.resourceFilters[handle].filter((iTheID) => iTheID !== id) });
      }
    },

    assetsCount(c) {
      // //console.log(c)

      let value = c
      value -= 2

      return value
    },
    // maintain a transitioning item's position and dimensions while it is absolutely positioned
    beforeItemLeave(el) {
      // console.log($(el), $(el).offset().left);
      // console.log(el, window.getComputedStyle(el));
      // if (el.classList.contains('card')) {

      // console.log($(el).position().top, $(el).position().left);

      const {marginLeft, marginTop, width, height} = window.getComputedStyle(el);
      el.style.left = `${$(el).position().left - parseFloat(marginLeft, 10)}px`;
      el.style.top = `${$(el).position().top - parseFloat(marginTop, 10)}px`;
      el.style.width = width;
      el.style.height = height;

      this.$nextTick(() => {
        // console.log($(el).offset().top, $(el).offset().left);
      })
      // }
    },

    skipToContent() {
      let nextEl = this.$refs.resourceArticles;

      console.log(nextEl);
      console.log('dette funker ikke og DET KLIKKER FOR MEG');

      if (nextEl) {
        nextEl.querySelector('button').focus();
      }
    },

    toggleFilter() {
      this.isFilterOpen = !this.isFilterOpen
    },

    toggleSubmenu() {

    }

  },

  computed: {
    resourceLoadingStatus() {
      console.log()
      return this.$store.getters.getResourceLoadingStatus
    },
    isMobile() {
      if (this.windowWidth < 1024) {
        return true;
      }
      return false;
    },
    paginatedResults() {
      return this.filteredResults.slice(0, this.pagination.perPage * this.pagination.currentPage + 1);
    },
    totalPages() {
      return Math.ceil(this.filteredResults.length / this.pagination.perPage);
    },
    posts() {
      return this.$store.getters.getResources
    },
    bookmarks() {
      return this.$store.getters.getBookmarks
    },
    taxAreas() {
      return this.$store.getters.getAreas
    },
    taxBookmarks() {
      return {
        handle: "bookmarks",
        title: "Bokmerker",
        id: "bookmarks",
      }
    },
    taxAttributes() {
      return this.$store.getters.getAttributes
    },
    taxTypes() {
      return this.$store.getters.getTypes
    },
    resourceFilters() {
      return this.$store.getters.getResourceFilters
    },
    filteredResults() {
      this.load = true
      const timestamp_start = Date.now();
      const resourcers = this.$store.getters.getFilteredResources
      const time = Date.now() - timestamp_start ;
      setTimeout(
        () => this.load = false ,
        time < 500 ? 500 - time : 500
      )

      return resourcers
    },
    routeQuery() {
      return this.$store.getters.getRouteQuery
    },
    activeFilterTerms() {
      var entries = [];

      if (this.resourceFilters) {
        for (let [key, resourceFilter] of Object.entries(this.resourceFilters)) {

          if (resourceFilter.length && key !== 'searchTerm') {
          // if (resourceFilter.length && key !== 'searchTerm') {

            var aTheActiveItems = this[key].filter((entry) => resourceFilter.includes(entry.id));
            for (let entry of aTheActiveItems) {
              entry['handle'] = key;
            }
            entries = entries.concat(aTheActiveItems)

            if (key === "bookmarks") {
              entries.push(this.taxBookmarks);
            }
          }
        }
      }

      return entries
    },
    bookmarkFilterActive() {
      if (this.resourceFilters && this.resourceFilters.bookmarks.length) {
        return true
      }
      return false
    },
    windowWidth() {
      return this.$store.getters.getWindowWidth
    },
    scrollTop() {
      return this.$store.getters.getScrollTop
    },
  },

  mounted() {
    this.getPosts()
    // this.$store.dispatch('fetchResources');
    // this.$store.dispatch('setViewTitle', 'Biblioteket');

    // window.addEventListener('resize', () => {
    //   this.windowWidth = window.innerWidth
    // })

    // this.getArea()
    // this.getType()
    // this.getAttribute()
  },

  beforeUpdate() {
    //this.$store.dispatch("setResourceLoadingStatus", true);

  },

  updated() {
    // console.log("UPDATED");
    // this.$store.dispatch("setResourceLoadingStatus", false);

    /*this.pagination.currentPage = 1;

    while (this.pagination.currentPage < this.totalPages) {
      setTimeout(() => {
        this.pagination.currentPage += 1;
        console.log(this.pagination.currentPage);
      }, 300)
    }*/

    this.$nextTick(() => {
      //console.log("UPDATED NEXTTIXK");
      //this.$store.dispatch("setResourceLoadingStatus", false);
    })
  },

  watch: {
    routeQuery(newQuery, oldQuery) {
      if (!_isEqual(newQuery, oldQuery)) {
        this.$router.replace({ query: newQuery });
        // console.log(newQuery, oldQuery, this.$route);
      }
    },
    $route: {
      immediate: true,
      handler: function(newVal, oldVal) {
        this.isModalVisible = newVal.meta && newVal.meta.isModalVisible;
      }
    },
    // '$route.params.slug'(newSlug, oldSlug) {
    //   // this.resetData()
    //   // this.getPosts()
    // },

    inputFilter( filter ) {
			this.$emit( 'onFilterToggle', filter );
		},
//     routeQuery(newQuery, oldQuery) {
//       // console.log(newQuery, oldQuery, this.$route);
//       console.log(this.scrollTop);
//
//       if (!_isEqual(newQuery, oldQuery)) {
//         this.$router.replace({ query: newQuery });
//         window.scrollTo(0, this.scrollTop);
//         console.log(this.scrollTop);
//       }
//     }
  }
}
</script>
