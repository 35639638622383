import Vue from 'vue';
import Vuex from 'vuex';
import Ajax from './ajax.js';
import VueMeta from 'vue-meta';
import router from '@/router/index.js'

Vue.use(Vuex);

function createString(data) {
  // //console.log(data)

  if ( data.length > 0 )
    return data.join(' ')

  return ''

}

export default new Vuex.Store({
  state: {
    title: 'Sammen på vei',
    viewTitle: '',
    allPagesAndPosts: [],
    isNotFound: false,
    firstInit: false,
    userName: null,
    userID: null,
    userPassword: null,
    token: null,
    scrollTop: 0,
    scrollDirection: null,
    windowHeight: 0,
    windowWidth: 0,
    types: null,
    timestamp: null,
    renderingItems: [],
    resources: [],
    resourceListItems: [],
    resourceLoadingStatus: false,
    // resourceRequestPage: 1,
    postsData: {
      page: 1,
      per_page: 100,
    },
    resourceFilters: {
      searchTerm: '',
      bookmarks: [],
      taxAreas: [],
      taxTypes: [],
      taxAttributes: [],
    },
    renderedResourceList: [],
    resourceParams: {
      per_page: 15,
      page: 1,
    },
    resourcePagination: {
      prevPage: '',
      nextPage: '',
      totalPages: '',
      from: '',
      to: '',
      total: '',
    },
    areas: [],
    categories: [],
    attributes: [],
    userBookmarks: [],
    isNavOpen: false,
    activePage: null,
    activeResource: null,
    hero: {},
    // restURL: 'https://nfp.stage.webcore.no/',
    restURL: process.env.MIX_API_URL,
    baseURL: process.env.MIX_API_BASE_URL,
  },
  mutations: {
    setPagesAndPosts(state, entries) {
      state.allPagesAndPosts = entries
    },
    setNotFound(state, isNotFound) {
      state.isNotFound = isNotFound;
    },
    SET_USER_PASSWORD(state, payload) {
      state.userPassword = payload
    },
    SET_TOKEN(state, payload) {
      state.token = payload
    },
    SET_USER_ID(state, payload) {
      state.userID = payload
    },
    SET_NAV_OPEN_STATE(state, bool) {
      state.isNavOpen = bool;
    },
    SET_USER_BOOKMARKS(state, payload) {
      Vue.set(state, 'userBookmarks', payload);
    },
    SET_TYPES(state, payload) {
      Vue.set(state, 'types', payload);
    },
    SET_AREAS(state, payload) {
      Vue.set(state, 'areas', payload);
    },
    SET_ATTRIBUTES(state, payload) {
      //console.log('SET_ATTRIBUTES', payload);
      Vue.set(state, 'attributes', payload);
    },
    SET_ACTIVE_PAGE(state, payload) {
      //console.log('SET_ATTRIBUTES', payload);
      state.activePage = payload;
    },
    SET_ACTIVE_RESOURCE(state, payload) {
      //console.log('SET_ATTRIBUTES', payload);
      state.activeResource = payload;
    },
    SET_CATEGORIES(state, payload) {
      Vue.set(state, 'categories', payload);
    },
    SET_RESOURCES(state, payload) {
      Vue.set(state, 'resources', payload);
    },
    SET_RESOURCE_LIST_ITEMS(state, payload) {
      Vue.set(state, 'resourceListItems', payload);
    },

    SET_RESOURCE_PAGE_NUMBER(state, n) {
      state.resourceParams.page = n;
    },
    SET_RESOURCE_PAGINATION(state, payload) {
      Vue.set(state, 'resourcePagination', payload);
    },
    SET_RESOURCE_REQUEST_PAGE(state, payload) {
      state.postsData.page = payload;
    },
    SET_VIEW_TITLE(state, payload) {
      state.viewTitle = payload;
    },
    SET_HERO(state, payload) {
      Vue.set(state, 'hero', payload);
    },
    SET_WINDOW_HEIGHT(state, payload) {
      state.windowHeight = payload;
    },
    SET_WINDOW_WIDTH(state, payload) {
      state.windowWidth = payload;
    },
    SET_RESOURCE_LOADING_STATUS(state, payload) {
      // state.resourceLoadingStatus = payload;
      console.log("SET_RESOURCE_LOADING_STATUS", payload);
      Vue.set(state, 'resourceLoadingStatus', payload);
    },
    SET_SCROLLTOP(state, payload) {
      state.scrollTop = payload;
    },
    SET_SCROLL_DIRECTION(state, payload) {
      state.scrollDirection = payload;
    },
    SET_TIMESTAMP(state, payload) {
      state.timestamp = payload;
    },
    SET_INIT_DONE(state, payload) {
      state.firstInit = payload;
    },
    UPDATE_RESOURCE_FILTER(state, payload) {
      Vue.set(state.resourceFilters, payload.param, payload.value);
    },
    SET_RENDERED_RESOURCE_LIST(state, payload) {
      Vue.set(state, "renderedResourceList", payload);
    },
    UPDATE_RENDERING_ITEMS(state, payload) {
      Vue.set(state, 'renderingItems', payload);
    }
  },
  actions: {
    init({ commit, dispatch }) {

      let token = localStorage.getItem('token');
      let userID = localStorage.getItem('user-id');
      let timestamp = localStorage.getItem('timestamp');

      if (timestamp) {
        commit("SET_TIMESTAMP", timestamp);
      }

      // dispatch('updateWindowWidth');

      if (token && userID && token !== "null") {
        commit('SET_TOKEN', token);
        commit('SET_USER_ID', userID);
        // dispatch('validate', token);
        dispatch('loadBookmarks');
      } else {

      }

      // var userPass = localStorage.getItem('user-password');
      // // TODO: vi får antagelig et token av Morten, som må sjekkes opp mot server ved oppstart. Dette å lagre passord i localstorage er vel ikke helt bra
      //
      // if (userPass) {
      //   dispatch('setUserPassword', userPass);
      //   var aTheBookmarks = localStorage.getItem('user-bookmarks');
      //
      //   if (aTheBookmarks) {
      //     dispatch('setUserBookmarks', aTheBookmarks)
      //   }
      //
      //   dispatch('fetchAllTypes');
      // } else {
      //
      // }
    },

    afterInit({ state, commit, dispatch }) {
      dispatch('fetchPagesAndPosts');
      dispatch('fetchCategories');

      if (state.token && !state.firstInit) {
        dispatch('fetchTypes');
        dispatch('fetchAreas');
        dispatch('fetchAttributes');
        dispatch('fetchResources');
        commit("SET_INIT_DONE", true);
      }

      // dispatch('fetchResourceBatch', { per_page: 12, page: 1 });
    },





    processPostBatch({state, commit}, posts) {
      let aTheArrays = state.allPagesAndPosts.concat(posts);
      let entries = aTheArrays.map(post => [`${post.id}`, post]);
      const map = new Map(entries);
      const newPosts = [...map.values()];

      commit('setPagesAndPosts', newPosts);
    },

    fetchPostBatch({ state, dispatch, commit, getters }, params) {
      return new Promise((resolve, reject) => {
        let oTheParams = {
          per_page: params.per_page,
          page: params.page,
          orderby: 'date'
        }

        if (params.ids) {
          oTheParams.in = params.ids;
        }

        axios.get(
          `${state.restURL}/${params.type}`, {
            params: oTheParams
          }
        ).then(response => {
          const results = response.data;
          dispatch("processPostBatch", results);

          return resolve({ params: params, total: Number(response.headers["x-wp-totalpages"]) })
        }).catch(err => {
          console.error(err);
          reject(err)
        })
      })
    },

    recursiveFetchPosts({ state, dispatch, commit, getters }, postType) {

      // brutally remove any and all resources before looking
      // commit('SET_RESOURCES', []);
      // commit('SET_RESOURCE_PAGE_NUMBER', 1);

      let params = {
        "per_page": 20,
        "page": 1,
        "type": postType
      };

      dispatch('fetchPostBatch', params).then(result => {
        // commit('SET_RESOURCE_PAGE_NUMBER', state.resourceParams.page + 1);
        return result
      }).then(result => {
        for (let i = 2; i <= result.total; i++) {
          dispatch('fetchPostBatch', { per_page: params.per_page, type: postType, page: i }).then(result => {
            // commit('SET_RESOURCE_PAGE_NUMBER', i + 1);
          }).catch(err => {
            // commit('SET_RESOURCE_PAGE_NUMBER', i); // don't know if this will do anything. Lets you try again, maybe?
            console.error(err);
          })
        }
      });
    },




    fetchPagesAndPosts({ state, dispatch, commit }) {

      dispatch("recursiveFetchPosts", "posts");
      dispatch("recursiveFetchPosts", "pages");

//       let params = {
//         'per_page': '100'
//       }
//
//       let promiseArray = [
//         axios.get(`${state.restURL}/pages`, { params }),
//         axios.get(`${state.restURL}/posts`, { params }),
//         // axios.get('https://nfp.stage.webcore.no/api/wp/v2/categories', { params })
//       ]
//
//       let returnArray = []
//
//       const response = Promise.allSettled(promiseArray)
//         .then((res) => {
//           res.forEach((item) => {
//             returnArray.push(...item.value.data)
//           })
//           //console.log(returnArray)
//           commit('setPagesAndPosts', returnArray)
//           return state.allPagesAndPosts
//         })
//         .catch((err) => {
//           //console.log(err, 'in the store action')
//         })
    },

    validate({ state }, token) {
      if (!state.userID) {
        return Promise.reject(new Error("User is not logged in"));
      }
      // return new Promise((resolve, reject) => {
      // // fetch(`${state.baseURL}/api/jwt-auth/v1/token/validate?token=${token}`, { method: 'POST' })
      // fetch(state.baseURL + '/api/jwt-auth/v1/token/validate?token=' + token, { method: 'POST' })
      //
      //   .then(response => response.json())
      //   .then(json => {
      //     // if (json.result === 'success') {
      //     console.log(json);
      //     if (json.success && json.data && json.data.token) {
      //
      //     }
      //   }).catch(err => {
      //     reject(new Error(err));
      //   })
      // })
    },

    logOut({ state, commit, dispatch, getters }, payload) {
      dispatch("ajax/logout", { id: state.userID, success: function() {
        commit('SET_RESOURCES', []);
        commit('SET_RESOURCE_PAGE_NUMBER', 1);
        dispatch("setUserID", null);
        dispatch("setTimestamp", null);
        dispatch("setToken", null);

        console.log("LOGOUT", "id:", state.userID, "time", state.timestamp);


        // Clear cookies
        document.cookie = "PHPSESSID=;max-age=-60; path=/;";
        document.cookie = "refresh_token=;max-age=-60; path=/;";
        
        // Clear local storage
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('token');

        if (router.currentRoute.meta && router.currentRoute.meta.requiresAuth) {
          router.push({ name: "Home" });
        }

        //window.location.reload();

      } });

    },


    login({ state, commit, dispatch, getters }, payload) {
      return new Promise((resolve, reject) => {
        fetch(state.baseURL + '/api/jwt-auth/v1/token/?username=' + payload.username + '&password=' + payload.password, { method: 'POST' })

          .then(response => response.json())
          .then(json => {
            // if (json.result === 'success') {

              console.log("login", json);
            if (json.success && json.data && json.data.token) {

              dispatch('setToken', json.data.token);
              dispatch('setUserID', json.data.id);
              dispatch("setTimestamp", Date.now());

              // console.log('logged in', json.data);

              dispatch('ajax/login', {
                id: json.data.id,
                // success: function(data) {
                //   console.log("STORE:JS IS LOGGED IN CALLBACK", data);
                //   // return data
                // }
              }).then(response => {

                // console.log("ajax/login response", response);
                // console.log("login response", response);

                if (response.result === "success") {

                  dispatch('fetchTypes');
                  dispatch('fetchAreas');
                  dispatch('fetchAttributes');
                  dispatch('fetchResources');

                  commit("SET_INIT_DONE", true);

                  dispatch('ajax/fetchBookmarks', {
                    id: json.data.id,
                    success: function(data) {
                      // console.log('ajax success', data.data);
                      if (data.data && data.data.length) {
                        // commit('SET_USER_BOOKMARKS', JSON.parse(data.data[0]));
                        dispatch('setUserBookmarks', JSON.parse(data.data[0]));
                      }
                    }
                  });
                }

                return resolve(response);
              })

              return resolve(json);
            } else if (json.message) {
              return resolve(json);
            } else {
              reject(new Error('API unresponsive'));
            }
          }).catch(err => {
            reject(new Error(err));
          })
      })
    },

//     configResourcePagination({ state, commit, getters }, headers) {
//       var pagination = {};
//       pagination.total = +headers['x-wp-total'];
//       pagination.totalPages = +headers['x-wp-totalpages'];
//       pagination.from = pagination.total ? ((state.postsData.page - 1) * state.postsData.per_page) + 1 : ' ';
//       pagination.to = (state.postsData.page * state.postsData.per_page) > pagination.total ? pagination.total : state.postsData.page * state.postsData.per_page;
//       pagination.prevPage = state.postsData.page > 1 ? state.postsData.page : '';
//       pagination.nextPage = state.postsData.page < pagination.totalPages ? state.postsData.page + 1 : '';
//
//       commit('SET_RESOURCE_PAGINATION', pagination);
//     },

    setDocumentHead({ state, commit }, payload) {
      if (payload.length) {
        const oThePayload = payload[0];
        console.log('setDocumentHead', oThePayload);
      }
    },

    fetchFirstView({ state, commit, dispatch }, to) {
      return new Promise((resolve) => {
        let sTheURL = "";
        let sTheSlug = "";
        let oTheParams = {};

        switch (to.name) {
          case "Home":
          axios.get(`${state.restURL}/pages`, { params: { slug: "forside" } }).then(response => {
            dispatch("processPostBatch", response.data)
            return resolve();
          })
          break;

          case "Page":
          case "ChildPage":
            sTheSlug = to.params.child ? to.params.child : to.params.slug

            axios.get(`${state.restURL}/pages`, { params: { slug: sTheSlug } }).then(response => {
              dispatch("processPostBatch", response.data)
              // let result = response.data;
              return resolve(response.data);
            })
            break;

          case "Contact":
            sTheSlug = "kontakt";

            axios.get(`${state.restURL}/pages`, { params: { slug: sTheSlug } }).then(response => {
              dispatch("processPostBatch", response.data)
              // let result = response.data;
              return resolve();
            })
            break;

          case "ResourceMain":
            sTheSlug = "kompasset";

            axios.get(`${state.restURL}/pages`, { params: { slug: sTheSlug } }).then(response => {
              dispatch("processPostBatch", response.data)
              return resolve();
            })
          break;

          case "Article":
          case "Experiences":
          case "Reports":
          case "Studies":
            axios.get(`${state.restURL}/posts`, { params: { slug: to.params.slug } }).then(response => {
              dispatch("processPostBatch", response.data)
              return resolve();
            })
            break;

          case "ResourceSingle":
            axios.get(`${state.restURL}/ressursbank`, { params: { slug: to.params.slug } }).then(response => {
              dispatch("processResources", response.data)
              // let result = response.data;
              return resolve();
            })
            break;

        default:
          return resolve();
        }
      })
    },

    processResources({ state, commit }, results) {
      var posts = results.map(post => ({
        id: post.id,
        type: post.type,
        slug: post.slug,
        title: post.title.rendered,
        media: post.featured_media,
        better_featured_image: post.better_featured_image,
        head: post.yoast_head_json ? post.yoast_head_json : null,
        date: post.date,
        modified: post.modified,
        template: post.template ? post.template : 'default',
        typer: post.typer,
        omrader: post.omrader,
        egenskaper: post.attributter,
        keywords: post.vue_meta.terms ? createString(post.vue_meta.terms) : '',
        links: post.acf.assets ? post.acf.assets.assets : false,
        excerpt: post.acf.excerpt,

        acf: post.acf,
        url: post.link,
        options: post.acf.options,
        blocks: post.acf.blocks,
      }));

      // let aTheOldPosts = [...state.resources];

      let aTheListItems = posts.map(post => ({
        id: post.id,
        type: post.type,
        slug: post.slug,
        title: post.title,
        typer: post.typer,
        omrader: post.omrader,
        egenskaper: post.egenskaper,
        keywords: post.keywords,
        links: post.links,
        excerpt: post.excerpt,
      }));

      const newListItems = [...new Set([...state.resourceListItems, ...aTheListItems])];
      commit('SET_RESOURCE_LIST_ITEMS', newListItems);

      for (let post of posts) {
        let fortnightDate = new Date(Date.now() - 1209600000).getTime();
        let published = new Date(post.date).getTime();
        let modified = new Date(post.modified).getTime();
        let compareDate = modified > published ? modified : published;

        if (fortnightDate < compareDate) {
          post.egenskaper.push("recent");
        }

        // was going to try to clean out old copies one by one, but it might be better to just delete the local stuff when running fetchResources
        // let oldHit = aTheOldPosts.find((p) => p.id === post.id);
        // if (oldHit) {
        //   aTheOldPosts.splice()
        // }
      }

      // const newPosts = state.resources.concat(posts);

      const newPosts = [...new Set([...state.resources, ...posts])];
      commit('SET_RESOURCES', newPosts);
    },

    fetchResourceBatch({ state, dispatch, commit, getters }, params) {
      return new Promise((resolve, reject) => {
        let oTheParams = {
          per_page: params.per_page,
          // per_page: state.postsData.per_page,
          page: params.page,
          orderby: 'date'
        }

        if (params.ids) {
          oTheParams.in = params.ids;
        }

        axios.get(
          `${state.restURL}/ressursbank`, {
            params: oTheParams
            // params: {
            //   per_page: params.per_page,
            //   // per_page: state.postsData.per_page,
            //   page: params.page,
            //   orderby: 'date'
            // }
          }
        ).then(response => {
          const results = response.data
          // console.log("RESOURCE RESPONSE", response);
          dispatch("processResources", results);
          // commit('SET_RESOURCE_PAGE_NUMBER', params.page + 1);

          return resolve({ params: params, total: Number(response.headers["x-wp-totalpages"]) })
        }).catch(err => {
          console.error(err);
          reject(err)
        })
      })
    },

    // first batch gets the total number of pages. After that, just repeat until done
    fetchResources({ state, dispatch, commit, getters }) {

      // brutally remove any and all resources before looking
      commit('SET_RESOURCES', []);
      commit('SET_RESOURCE_PAGE_NUMBER', 1);

      dispatch('fetchResourceBatch', state.resourceParams).then(result => {
        commit('SET_RESOURCE_PAGE_NUMBER', state.resourceParams.page + 1);
        return result
      }).then(result => {
        for (let i = 2; i <= result.total; i++) {
          dispatch('fetchResourceBatch', { per_page: state.resourceParams.per_page, page: i }).then(result => {
            commit('SET_RESOURCE_PAGE_NUMBER', i + 1);
          }).catch(err => {
            commit('SET_RESOURCE_PAGE_NUMBER', i); // don't know if this will do anything. Lets you try again, maybe?
            console.error(err);
          })
        }
      });
    },

    fetchTaxonomyBatch({ state, commit, getters }, params) {
      return new Promise((resolve, reject) => {
        axios.get(
          `${state.restURL}/${params.slug}`, {
            params: {
              per_page: '100',
              page: params.page,
              orderby: 'id',
              hide_empty: false,
            }
          }
        ).then(response => {
          const results = response.data

          return resolve({ results: results, params: params, total: Number(response.headers["x-wp-totalpages"]) })
        })
      })
    },

    fetchTaxonomyBatches({ state, dispatch, commit, getters }, params) {
      return new Promise((resolve) => {
        dispatch('fetchTaxonomyBatch', {
          slug: params.slug,
          page: 1,
        }).then(result => {
          if (result.total > 1) {
            let items = [...result.results];

            for (let i = 2; i <= result.total; i++) {
              dispatch('fetchTaxonomyBatch', {
                page: i,
                slug: result.params.slug,
              }).then(result => {
                items = [...new Set([...items, ...result.results])];
              })
            }
            return items
          } else {
            return result.results
          }
        }).then(result => {
          return resolve(result)
        })
      }).catch(err => {
        console.error(err)
      })
    },

    fetchTypes({ state, dispatch, commit, getters }) {
      dispatch('fetchTaxonomyBatches', { slug: "typer" }).then(results => {
        var typeData = results.map( cat => ({
          id: cat.id,
          slug: cat.slug,
          title: cat.name,
          parent: cat.parent
        }))

        Array.prototype.map( post => typeData.push( ...post.data ) )

        commit('SET_TYPES', typeData)
      })
    },

    fetchAreas({ state, dispatch, commit, getters }) {
      dispatch('fetchTaxonomyBatches', { slug: "omrader" }).then(results => {
        var typeData = results.map( cat => ({
          id: cat.id,
          slug: cat.slug,
          title: cat.name,
          parent: cat.parent
        }))

        Array.prototype.map( post => typeData.push( ...post.data ) )

        commit('SET_AREAS', typeData)
      })
    },

    fetchCategories({ state, dispatch, commit, getters }) {
      dispatch('fetchTaxonomyBatches', { slug: "categories" }).then(results => {
        var typeData = results.map( cat => ({
          id: cat.id,
          slug: cat.slug,
          title: cat.name,
          parent: cat.parent
        }))

        Array.prototype.map( post => typeData.push( ...post.data ) )

        commit('SET_CATEGORIES', typeData)
      })
    },

    fetchAttributes({ state, dispatch, commit, getters }) {
      dispatch('fetchTaxonomyBatches', { slug: "attributter" }).then(results => {
        var typeData = results.map( cat => ({
          id: cat.id,
          slug: cat.slug,
          title: cat.name,
          parent: cat.parent
        }))

        Array.prototype.map( post => typeData.push( ...post.data ) )

        typeData.push({
          id: "recent",
          slug: "recent",
          title: "Nylig lagt til/oppdatert",
          parent: 0
        });

        commit('SET_ATTRIBUTES', typeData)
      })
    },

    updateHero({ commit }, payload) {
      commit('SET_HERO', payload);
    },

    updateScrollTop({ commit, state }, iTheNumber) {
      var oldVal = state.scrollTop;
      var newVal = iTheNumber;

      if (newVal > oldVal) {
        commit('SET_SCROLL_DIRECTION', 'down');
      } else {
        commit('SET_SCROLL_DIRECTION', 'up');
      }

      commit('SET_SCROLLTOP', iTheNumber);
    },

    onWindowResize({ commit }, payload) {
      if (payload.width && payload.height) {
        // console.log(payload);
        commit('SET_WINDOW_WIDTH', payload.width);
        commit('SET_WINDOW_HEIGHT', payload.height);
      }
    },

    updateWindowWidth({ commit }) {
      commit('SET_WINDOW_WIDTH', window.innerWidth);
    },

    setUserID({ commit, state }, sTheID) {
      commit('SET_USER_ID', sTheID);
      localStorage.setItem('user-id', sTheID)
    },

    setUserName({ commit, state }, sTheName) {
      commit('SET_USER_NAME', sTheName);
      localStorage.setItem('user-name', sTheName)
    },

    setUserPassword({ commit, state }, sThePassword) {
      commit('SET_USER_PASSWORD', sThePassword);
      localStorage.setItem('user-password', sThePassword)
    },

    setViewTitle({ commit, state, getters }, sTheTitle = '') {
      commit('SET_VIEW_TITLE', sTheTitle);

      document.title = getters.getTitle;
    },

    setToken({ commit, state }, sTheToken) {
      commit('SET_TOKEN', sTheToken);
      localStorage.setItem('token', sTheToken)
    },

    setActivePage({ commit, state }, slug) {
      commit('SET_ACTIVE_PAGE', slug);
    },

    setActiveResource({ commit, state }, slug) {
      commit('SET_ACTIVE_RESOURCE', slug);
    },

    setUserBookmarks({ commit }, aTheIDs) {
      commit('SET_USER_BOOKMARKS', aTheIDs);
      localStorage.setItem('user-bookmarks', JSON.stringify(aTheIDs));
    },

    loadBookmarks({ commit }) {
      var sTheBookmarks = localStorage.getItem('user-bookmarks');

      if (sTheBookmarks) {
        commit('SET_USER_BOOKMARKS', JSON.parse(sTheBookmarks));
      }
    },

    toggleBookmark({ state, commit, dispatch, getters }, id) {
      var aTheBookmarks = [...getters.getBookmarks];

      if (getters.getBookmarks.includes(id)) {
        aTheBookmarks = aTheBookmarks.filter((entry) => entry !== id );
      } else {
        aTheBookmarks.push(id);
      }

      // axios.post(state.restURL + '/users/' + state.userID, {
      //   params: {
      //     id: state.userID, // skal denne sendes?
      //     meta: {
      //       bookmarks: aTheBookmarks
      //     }
      //   }
      // })

      commit('SET_USER_BOOKMARKS', aTheBookmarks);
      localStorage.setItem('user-bookmarks', JSON.stringify(aTheBookmarks))

      const resourceFilters = getters.getResourceFilters;

      if (resourceFilters.bookmarks && resourceFilters.bookmarks.length) {
        dispatch('filterResources', { param: 'bookmarks', value: getters.getBookmarks })
      }

      // payload.id, payload.bookmarks, payload.success

      dispatch('ajax/saveBookmarks', {
        id: state.userID,
        bookmarks: JSON.stringify(aTheBookmarks),
        success: function(data) {
          //console.log('successfully saved some bookmarks', data)
        }
      });
    },

    toggleNav({ commit, getters }) {
      commit('SET_NAV_OPEN_STATE', !getters.getNavOpenState)
    },

    filterResources({ commit, dispatch }, payload) {
      // console.log("FILTER RESOURCES");
      // { kind: str, value: id/arr/etc }
      if (('param' in payload) && ('value' in payload)) {
        // console.log("COMMIT FILTER RESOURCES", payload);
        commit('UPDATE_RESOURCE_FILTER', payload);
        // console.log("FILTER RESOURCES");
        // dispatch("setResourceLoadingStatus", true);
        // dispatch("updateRenderedResourceList");
      }
    },

    // this should probably be removed
//     updateRenderedResourceList({ state, commit, dispatch }) {
//       console.log("rendering resources");
//       const filter = state.resourceFilters;
//       // state.resources
//       if ( state.resources.length > 0 ) {
//
//         //console.log('wpPosts, ' +this.posts.length)
//         let filteredPosts = [...state.resources];
//
//         // match keyword against post titles or excerpts.
//         if ( filter.searchTerm ) {
//           const pattern = new RegExp( filter.searchTerm, 'i' );
//
// //           filteredPosts = filteredPosts.filter( post => {
// //
// //             let hit = false;
// //
// //             if (post.excerpt.super.match( pattern ) || post.excerpt.excerpt.match( pattern )) {
// //               hit = true;
// //             }
// //
// //             if (post.keywords.match( pattern ) || post.title.match( pattern )) {
// //               hit = true;
// //             }
// //
// //             if (post.links && post.links.asset && post.links.asset.length) {
// //               for (let item of post.links.asset) {
// //                 if (item.label.match(pattern)) {
// //                   hit = true;
// //                 }
// //               }
// //             }
// //
// //             if (hit) {
// //               // console.log(post)
// //             }
// //
// //             return hit;
// //           })
//
//           for (let i = 0; i < filteredPosts.length; i++) {
//             let hit = false;
//
//             if (filteredPosts[i].excerpt.super.match( pattern ) || filteredPosts[i].excerpt.excerpt.match( pattern )) {
//               hit = true;
//             }
//
//             if (filteredPosts[i].keywords.match( pattern ) || filteredPosts[i].title.match( pattern )) {
//               hit = true;
//             }
//
//             if (filteredPosts[i].links && filteredPosts[i].links.asset && filteredPosts[i].links.asset.length) {
//               for (let item of filteredPosts[i].links.asset) {
//                 if (item.label.match(pattern)) {
//                   hit = true;
//                 }
//               }
//             }
//
//             if (!hit) {
//               filteredPosts.splice(i, 1);
//             }
//           }
//
//           // console.log(filteredPosts);
//         }
//
//         if ( filter.bookmarks && filter.bookmarks.length ) {
//           //console.log('Bookmarks, ' +this.bookmarks.length)
//           filteredPosts = filteredPosts.filter( post => {
//             return filter.bookmarks.includes(post.id)
//           })
//         }
//
//         // further filter the results based on the taxArea filters.
//         if ( filter.taxAreas && filter.taxAreas.length ) {
//           //console.log('Areas, ' +this.taxAreas.length)
//           filteredPosts = filteredPosts.filter( post =>
//             post.omrader.some( terms =>
//               filter.taxAreas.includes( terms )
//             ),
//           )
//         }
//
//         // further filter the results based on the taxTypes filters.
//         if ( filter.taxTypes && filter.taxTypes.length ) {
//           //console.log('Types, ' +this.taxTypes.length)
//           filteredPosts = filteredPosts.filter( post =>
//             post.typer.some( terms =>
//               filter.taxTypes.includes( terms )
//             )
//           )
//         }
//
//         // further filter the results based on the taxAttributes filters.
//         if ( filter.taxAttributes && filter.taxAttributes.length ) {
//           //console.log('Types, ' +this.taxAttributes.length)
//           filteredPosts = filteredPosts.filter( post =>
//             post.egenskaper.some( terms =>
//               filter.taxAttributes.includes( terms )
//             )
//           )
//         }
//
//         //console.log('filteredPosts, ' +filteredPosts.length)
//         commit("SET_RENDERED_RESOURCE_LIST", filteredPosts);
//         // dispatch("setResourceLoadingStatus", false);
//       } else {
//         commit("SET_RENDERED_RESOURCE_LIST", []);
//         // dispatch("setResourceLoadingStatus", false);
//       }
//     },

    setResourceLoadingStatus({ commit }, bool) {
      commit("SET_RESOURCE_LOADING_STATUS", bool);
    },

    resetResourceFilter({ commit }) {
      commit('UPDATE_RESOURCE_FILTER', { param: 'taxAreas', value: [] });
      commit('UPDATE_RESOURCE_FILTER', { param: 'taxTypes', value: [] });
      commit('UPDATE_RESOURCE_FILTER', { param: 'taxAttributes', value: [] });
      commit('UPDATE_RESOURCE_FILTER', { param: 'bookmarks', value: [] });
      commit('UPDATE_RESOURCE_FILTER', { param: 'searchTerm', value: '' });
    },

    setTimestamp({ commit }, stamp) {
      commit('SET_TIMESTAMP', stamp);
      localStorage.setItem('timestamp', stamp);
    },

    updateRenderingItems({ state, getters, commit }, payload) {
      let renderingItems = getters.getRenderingItems;

      if (payload.value) {
        if (renderingItems.indexOf(payload.name) === -1) {
          let newArr = renderingItems.concat([payload.name]);
          commit('UPDATE_RENDERING_ITEMS', newArr);
        }

      } else {
        // console.log('removing', payload.name);
        commit('UPDATE_RENDERING_ITEMS', renderingItems.filter(item => item !== payload.name));
      }
    }
  },
  getters: {
    getTitle: (state) => {
       return state.viewTitle.length ? `${state.viewTitle} | ${state.title}` : state.title;
    },
    getScrollTop: (state) => state.scrollTop,
    getScrollDirection: (state) => state.scrollDirection,
    getPagesAndPosts: (state) => state.allPagesAndPosts,

    getPostByID: (state, getters) => (id) => {
      return getters.getPagesAndPosts.find( post => post.id === id )
    },
    getResourceLoadingStatus: (state) => state.resourceLoadingStatus,
    getResourceByID: (state, getters) => (id) => {
      return state.resources.find( post => post.id === id )
    },
//     getPostUrl: (state) => {
//       let filteredPosts = ''
//
//       if ( this.$store.getters.getPagesAndPosts.find( post => post.id === id ) ) {
//         filteredPosts = this.$store.getters.getPagesAndPosts.find( post => post.id === id )
//         filteredPosts = filteredPosts.vueData.url
//       }
//
//       //console.log(filteredPosts)
//       return filteredPosts
//
//     },

    getRelativeUrl: (state, getters) => (id) => {
      let oThePost = getters.getPagesAndPosts.find( post => post.id === id );

      if (oThePost) {
        // let sThePrefix = "";
        // if (oThePost.type === "page") {
        //   console.log("getRelativeUrl", oThePost);
        //
        //   if (oThePost.parent > 0) {
        //     let oTheParent = getters.getPagesAndPosts.find( post => post.id === parent );
        //
        //     if (oTheParent) {
        //       sThePrefix = `/${oTheParent.slug}/`;
        //     }
        //
        //
        //   }
        //
        // }


        return oThePost.vueData.url
      }
      return ""
    },

    getNotFound: (state) => state.isNotFound,
    getUserID: (state) => state.userID,
    getUserPassword: (state) => state.userPassword,
    getUserName: (state) => state.userName,
    getToken: (state) => state.token,
    getTimestamp: (state) => state.timestamp,
    getWindowWidth: (state) => state.windowWidth,
    getWindowHeight: (state) => state.windowHeight,

    getActivePage: (state) => state.activePage,
    getActiveResource: (state) => state.activeResource,

    getHero: (state) => {

      // console.log("router.currentRoute", router.currentRoute);

      return state.hero

      // if (router.currentRoute.name === "ResourceSingle") {
      //   const activePost = store.getters.getResourceBySlug()
      //   return {
      //     id: this.post.id,
      //     label: this.post.acf.excerpt.super ? this.post.acf.excerpt.super : false,
      //     title: this.post.acf.hero.title ? this.post.acf.hero.title : this.post.title.rendered,
      //     excerpt: this.post.excerpt.excerpt ? this.post.excerpt.excerpt : false,
      //     type: this.post.type,
      //     date: this.post.date,
      //     modified: this.post.modified,
      //     url: this.post.link,
      //     layout: this.setLayout(this.post.type),
      //     breadcrumb: ['post', 'ressurs'].includes(this.post.type) ? this.post.omrader : false,
      //     content: (this.post.acf.options.layout != 'superimpossed' ) ? this.setContent(this.post.type, this.post.acf.options.layout, this.post.acf.hero, this.post.acf.assets) : false,
      //     color: this.post.acf.options.color ? this.post.acf.options.color : 'gray-light',
      //     image: false,
      //     video: false,
      //   }
      // } else {
      //   return state.hero
      // }
    },

    getRenderingItems: (state) => state.renderingItems,

    getTypes: (state) => {
      if (state.types && state.types.length) {
        return state.types
      }
      return []
    },
    getAreas: (state) => {
      if (state.areas && state.areas.length) {
        return state.areas
      }
      return []
    },
    getAttributes: (state) => {
      if (state.attributes && state.attributes.length) {
        return state.attributes
      }
      return []
    },

    // getTypes: (state) => state.types,
    // getAreas: (state) => state.areas,
    // getAttributes: (state) => state.attributes,

    getFilter: (state) => state.resourceFilters,
    getSearchTerm: (state) => state.resourceFilters.searchTerm,

//     getResourceURL: (state) => (slug) => {
//       let aTheFound = state.resources.find(resource => resource.slug === slug);
//
//       if (aTheFound.length) {
//         let item = aTheFound[0];
//
//       the idea here was we might build the hierarchy of areas/types, and add it to the url. We're already doing something similar in the breadcrumbs.
//       but it's occurred to us there may be reasons not to – a url with hiearchy built-in implies you can remove part of the url, and get to some sort of archive page.
//       But as this is not the case, we are leaving this here for now
//
//       } else {
//         return false
//       }
//     },

    getResources: (state) => state.resources,
    getResourceFilters: (state) => state.resourceFilters,
    getRouteQuery: (state) => {// this will be added to the route's query where appropriate
      var r = state.resourceFilters;
      var q = {};

      if (r.taxAreas.length) {
        // q['areas'] = JSON.stringify(r.taxAreas);
        q['areas'] = r.taxAreas.join(',');
      }
      if (r.taxAttributes.length) {
        // q['attr'] = JSON.stringify(r.taxAttributes);
        q['attr'] = r.taxAttributes.join(',');
      }
      if (r.taxTypes.length) {
        // q['types'] = JSON.stringify(r.taxTypes);
        q['types'] = r.taxTypes.join(',');
      }
      if (r.bookmarks.length) {
        q['bookmarks'] = true;
        // q['bookmarks'] = r.bookmarks.join(',');
      }

      return q;
    },

    getFilteredResources: (state, dispatch) => {
      const filter = state.resourceFilters;

      if (state.resourceListItems.length > 0) {
        let filteredPosts = [...state.resourceListItems];

        // Function to check if a given item matches the search term pattern
        const matchesSearchTerm = (item, pattern) => {
          return (
            item.keywords.match(pattern) ||
            item.title.match(pattern) ||
            (item.links &&
              item.links.asset &&
              item.links.asset.some((item) => item.label.match(pattern)))
          );
        };

        // Filter items based on search term
        if (filter.searchTerm) {
          const pattern = new RegExp(filter.searchTerm, 'i');
          filteredPosts = filteredPosts.filter((item) =>
            matchesSearchTerm(item, pattern)
          );
        }

        // Filter items based on bookmarks
        if (filter.bookmarks && filter.bookmarks.length) {
          filteredPosts = filteredPosts.filter((item) =>
            filter.bookmarks.includes(item.id)
          );
        }

        // Filter items based on tax areas
        if (filter.taxAreas && filter.taxAreas.length) {
          filteredPosts = filteredPosts.filter((item) =>
            item.omrader.some((terms) => filter.taxAreas.includes(terms))
          );
        }

        // Filter items based on tax types
        if (filter.taxTypes && filter.taxTypes.length) {
          filteredPosts = filteredPosts.filter((item) =>
            item.typer.some((terms) => filter.taxTypes.includes(terms))
          );
        }

        // Filter items based on tax attributes
        if (filter.taxAttributes && filter.taxAttributes.length) {
          filteredPosts = filteredPosts.filter((item) =>
            item.egenskaper.some((terms) => filter.taxAttributes.includes(terms))
          );
        }

        return filteredPosts;
      }

      return [];
    },

    getNavOpenState: (state) => state.isNavOpen,
    getBookmarks: (state) => state.userBookmarks,
    getAreaByID: (state) => (id) => {
      var aTheFound = state.areas.filter(area => area.id === id);
      return aTheFound.length ? aTheFound[0] : false
    },
    getAttributeByID: (state) => (id) => {
      var aTheFound = state.attributes.filter(attr => attr.id === id)
      return aTheFound.length ? aTheFound[0] : false
    },
    getTypeByID: (state) => (id) => {
      var aTheFound = state.types.filter(type => type.id === id);
      return aTheFound.length ? aTheFound[0] : false
    },
    getResourceBySlug: (state) => (slug) => {
      return state.resources.find(resource => resource.slug === slug)
    },
    getPostBySlug: (state) => (slug) => {
      let oTheFilter = {
        type: 'post',
        slug: slug,
      };

      let items = state.allPagesAndPosts.filter((item) => {
        for (var key in oTheFilter) {
          if (item[key] === undefined || item[key] !== oTheFilter[key]) {
            return false;
          }
        }
        return true;
      });

      if (items.length) {
        // if (slug === "linda-tester") {
        //   console.log("NOT FALSE POST", items);
        // }

        return items[0];
      }
      return false;
      // return state.allPagesAndPosts.find(post => post.slug === slug)
    },
    getPageBySlug: (state) => (slug) => {
      let oTheFilter = {
        type: 'page',
        slug: slug,
      };

      let items = state.allPagesAndPosts.filter((item) => {
        for (var key in oTheFilter) {
          if (item[key] === undefined || item[key] !== oTheFilter[key]) {
            return false;
          }
        }
        return true;
      });

      if (items.length) {
        // if (slug === "linda-tester") {
        //   console.log("NOT FALSE PAGE", items);
        // }
        return items[0];
      }
      return false;
      // console.log("found this item", item);



      // let item = state.allPagesAndPosts.find(post => post.slug === slug)
      // return state.allPagesAndPosts.find(post => post.slug === slug)
    },
  },

  modules: {
    ajax: Ajax,
  }
})
