<template>
  <article
  :data-id="post.id"
  :data-keywords="post.keywords"
  :ref="postRef"
  class="card flex-none"
  >

    <header class="card-header">
      <div class="w-auto">
        <div class="pill temaark-label rounded-auto border-2 border-black py-2 px-5 text-xs md:text-sm lg:text-md xl:text-lg" v-if="attributeMarker">{{ attributeMarker }}</div>
      </div>

      <button title="Bokmerk artikkel" class="toggle-bookmark" @click="toggleBookmark(post.id)" tabindex="0">
        <span class="sr-only">toggle bookmark</span>
        <icon :graphic="allBookmarks.includes(post.id) ? 'bookmark-filled' : 'bookmark'"></icon>
      </button>
    </header>

    <div class="card-content">
      <label :id="`programLabel_${post.id}`" class="program-label text-xs md:text-sm lg:text-md xl:text-lg" v-if="postLabel">{{ postLabel }}</label>

      <router-link :aria-describedby="`programLabel_${post.id}`" class="hover:underline heading-link" :to="{ name: 'ResourceSingle', params: { slug: post.slug, categories: areaParam } }">
        <h3 class="medium mb-0 text-lg xl:text-xl 2xl:text-2xl" v-html="post.title" />
      </router-link>
    </div>

    <h4 class="sr-only" v-if="post.links && post.links.asset">Downloadable assets</h4>

    <ul class="card-assets" v-if="post.links && post.links.asset"><!--gap-x-8 gap-y-4 -->

      <li
      class="font-SuisseIntlMedium text-xs md:text-sm lg:text-md xl:text-lg"
      v-for="(item, index) in (post.links.asset).slice(0, assetsLimit)"
      :key="index">

        <a class="asset-link flex gap-4 items-baseline" :href="item.link_asset.url" target="_blank" rel="nofollow" >
          <icon graphic="asset"></icon>
          <span class="text-brand-blue underline hover:no-underline"><span class="sr-only">Last ned </span>{{ item.label ? item.label : item.link_asset.title }}</span>
        </a>

      </li>

      <span v-if="(post.links.asset).length > assetsLimit">(+ {{ assetsCount }} vedlegg)</span>
    </ul>

  </article>
</template>

<script>
  import Icon from '@/component/Icon.vue'
  import observerElement from '@/mixins/observerElement.js'

  export default {
    name: 'ResourceSingleItem',
    data: () => ({
      assetsLimit: 2,
      styles: null,
    }),
    props: {
      post: Object,
    },
    mixins: [
      observerElement,
    ],
    components: {
      'icon': Icon,
    },

    computed: {
      allBookmarks() {
        return this.$store.getters.getBookmarks
      },
      assetsCount() {
        return this.post.links.asset.length - this.assetsLimit
      },
      attributeData() {
        return this.$store.getters.getAttributes
      },
      attributeMarker() {
        var aTheAttr = this.attributeData.filter(attr => attr.slug === 'temaark');

        if (aTheAttr.length && this.post.egenskaper.includes(aTheAttr[0].id)) {
          return aTheAttr[0].title
        } else {
          return false
        }
      },
      areaLabel() {
        // var aTheAreas = this.post.omrader.map(id => {
        //   return this.$store.getters.getAreaByID(id)
        // })
        let aTheChildren = this.postAreas.filter(area => area.parent !== 0);

        return aTheChildren.length ? aTheChildren[0].title : false
      },
      typeLabel() {
        // var aTheAreas = this.post.omrader.map(id => {
        //   return this.$store.getters.getAreaByID(id)
        // })
        let aTheChildren = this.postTypes.filter(type => type.parent !== 0);

        return aTheChildren.length ? aTheChildren[0].title : false
      },
      postLabel() {
        if (this.areaLabel) {
          return this.areaLabel
        } else {
          return this.typeLabel
          // false
        }
      },
      typeData() {
        return this.$store.getters.getTypes
      },
      postTypes() {
        let aTheCats = [];

        for (const id of this.post.typer) {
          let oTheCat = this.$store.getters.getTypeByID(id);

          if (oTheCat) {
            aTheCats.push(oTheCat);
          }
        }

        return aTheCats
      },
      postAreas() {
        let aTheCats = [];

        for (const id of this.post.omrader) {
          let oTheCat = this.$store.getters.getAreaByID(id);

          if (oTheCat) {
            aTheCats.push(oTheCat);
          }
        }

        return aTheCats
      },
      areaParam() {
        if (this.postAreas.length) {
          return this.postAreas.map((area) => area.slug)
        }
        return null
      },
      postRef() {
        return `card_${this.post.id}`
      },
      windowWidth() {
        return this.$store.getters.getWindowWidth
      },
    },
    methods: {
      toggleBookmark(id) {
        this.$store.dispatch('toggleBookmark', id)
      },
      inlineStyle(el) {
        if (el) {
          const {marginLeft, marginTop, width, height} = window.getComputedStyle(el)

          const oTheVals = {
            left: `${el.offsetLeft - parseFloat(marginLeft, 10)}px`,
            top: `${el.offsetTop - parseFloat(marginTop, 10)}px`,
            width: width,
            height: height
          };

          console.log(oTheVals);

          return oTheVals
        }
        return "";
      },

      beforeItemLeave(el) {
        this.styles = this.inlineStyle(el);
      }
    },

    created() {

    },

    mounted() {
      this.$nextTick(() => {
        let el = this.$refs[this.postRef];

        if (el) {
          // this.styles = this.inlineStyle(el);
          this.observer.observe(el, { root: document.body, threshold: 0, rootMargin: '8rem' });
        }
      });
    },
    beforeDestroy() {
      this.observer.unobserve(this.$refs[this.postRef]);
    },
    watch: {
      // windowWidth: {
      //   handler: function(newVal, oldVal) {
      //     this.styles = "";
      //     this.$nextTick(() => {
      //       this.styles = this.inlineStyle(this.$refs[this.postRef]);
      //     })
      //   }
      // }
    }
  }
</script>
