<template>

  <div class="component-inner">

    <!-- <div class="lg:flex justify-start gap-8"> -->

      <div class="text-column">

        <h3 class="block-heading medium break-words">{{ block.options.title }}</h3>

        <div
        class="content-inner"
        v-for="(item, index) in sortArrays(block.blocks)"
        :key="index"
        :class="item.acf_fc_layout">

          <component
          :is="item.acf_fc_layout"
          :block="item"
          :key="index" />

        </div>

      </div>

      <div
      v-if="block.options.icon"
      class="icon-column flex flex-1 items-end">

        <Pictogram
        :data="block.options.icon"
        :color="block.options.iconColor" />

      </div>

    <!-- </div> -->

  </div>

</template>

<script>

import Links from '@/component/Block/Link'
import Paragraph from '@/component/Block/Paragraph'
import Readmore from '@/component/Block/Readmore'
import Pictogram from '@/component/Block/Pictogram'
import Media from '@/component/Block/Media'

export default {
  name: 'Enhanced',
  props: {
    block: Object
  },
  components: {
    Links,
    Paragraph,
    Readmore,
    Pictogram,
    Media
  },
  methods: {
    sortArrays(arrays) {
      return _.orderBy(arrays, 'name', 'asc');
    }
  }
}
</script>
