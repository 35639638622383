<template>

  <div class="component-inner">


      <div class="contact-cards">

        <article
        v-for="(item, index) in block.columns"
        :key="index"
        class="card "
        :class="[(['additional'].includes(item.acf_fc_layout) ? '-dark-bg' : '-light-bg')]">

          <header class="card-header">
            <h3 class="bold">{{ item.title }}</h3>
          </header>

          <div
          class="card-content content grow lg:text-xl mb-6 lg:mb-10"
          :class="[(['additional'].includes(item.acf_fc_layout) ? 'text-gray-dark' : 'text-black')]"
          v-html="item.excerpt" />

          <div
          v-if="item.contact"
          class="card-contact contact lg:text-xl mt-auto">
            <dl class="email definition-list" v-if="item.contact.email">
              <dt class="label">E-post:</dt>
              <dd class="value" v-html="item.contact.email"></dd>
            </dl>

            <!-- <dl class="phone" v-if="item.contact.phone">
              <dt class="label flex-none font-SuisseIntlBold">{{ item.contact.phone_label }}:</dt>
              <dd class="flex-1 font-regular">{{ item.contact.phone }}</dd>
            </dl> -->

            <dl class="phone definition-list" v-if="item.contact.phone_numbers">
              <div class="list-item" v-for="entry in item.contact.phone_numbers">
                <dt class="label">{{ entry.label }}:</dt>
                <dd class="value">{{ entry.number }}</dd>
              </div>
            </dl>

            <dl class="address definition-list" v-if="item.contact.address">
              <dt class="label">Besøksadresse:</dt>
              <dd class="value">{{ item.contact.address.street }} {{ item.contact.address.zip }} {{ item.contact.address.city }}</dd>
            </dl>

            <!-- <dl class="county definition-list" v-if="item.contact.county">
              <dt class="label">Kommune:</dt>
              <dd class="value">{{ item.contact.county }}</dd>
            </dl> -->

          </div>


          <router-link
          v-if="item.button"
          class="button-text w-auto max-w-max inline-flex items-center self-end text-xl mt-auto"
          :class="[(['text'].includes(item.button.style)) ? 'button-text' : 'button', item.button.style]"
          :to="relativeURL(item.button.url.ID)">
            <span class="">{{ item.button.label ? item.button.label : item.button.url.post_title }}</span>

            <icon graphic="arrow" class="arrow-right"/>
          </router-link>

        </article>

      </div>

  </div>
</template>

<script>
import InternalButton from './InternalButton.vue'
import Icon from '@/component/Icon'
// import PhoneNumbers from './partials/PhoneNumbers.vue'

export default {
  props: {
    block: Object
  },
  components: {
    InternalButton,
    Icon,
    // 'phone': PhoneNumbers,
  },
  methods: {
    relativeURL(id) {
      return this.$store.getters.getRelativeUrl(id)
//       let filteredPosts = ''
//
//       if ( this.$store.getters.getPagesAndPosts.find( post => post.id === id ) ) {
//         filteredPosts = this.$store.getters.getPagesAndPosts.find( post => post.id === id )
//         filteredPosts = filteredPosts.vueData.url
//       }
//
//       //console.log(filteredPosts)
//       return filteredPosts
    },
  },
}
</script>
