<template>
  <header ref="siteHeader" class="site-header header z-10 w-full text-black" itemscope itemtype="https://schema.org/WPHeader" :class="headerClass">
    <nav class="navbar" role="navigation">
      <div class="navbar-inner flex items-center justify-between px-8 py-6 mx-auto">
        <div class="flex self-start md:self-center items-center justify-start order-2">
          <router-link class="inline-flex items-center text-black" to="/">
            <span class="sr-only">Til forsiden</span>
            <icon graphic="logo"></icon>
          </router-link>
        </div>

        <div class="sm:flex sm:gap-12 flex-1 min-w-0 items-center">
          <button class="hamburger" :class="{ 'active hamburger--is-open' : isNavOpen }" @click.prevent="toggle" aria-expanded="true" aria-controls="primary-menu">
            <span class="sr-only">Toggle main menu</span>
            <div class="hamburger__item hamburger__item--first"></div>
            <div class="hamburger__item hamburger__item--middle"></div>
            <div class="hamburger__item hamburger__item--last"></div>
          </button>


          <nav class="header-nav">
            <router-link
            v-if="!isLoggedIn"
            :to="{ name: 'Login' }">Logg inn</router-link>

            <router-link
            v-if="isLoggedIn"
            :to="{ name: 'ResourceMain' }">Kompasset</router-link>

            <router-link
            v-if="isLoggedIn"
            :to="{ name: 'ResourceOverview' }">Biblioteket</router-link>

            <a href="/" v-if="isLoggedIn" @click="logOut">Logg ut</a>

          </nav>

        </div>
      </div>
    </nav>
    <Sidebar>
      <nav class="nav-primary" role="navigation" aria-label="Primary menu">
        <ul class="sidebar-panel-nav">
          <li class="nav-item"
          v-for="item in primary" :key="item.id" :data-id="item.id">
            <router-link :to="relativeURL(Number(item.id))">{{ item.title }}</router-link>
          </li>
        </ul>
      </nav>

      <nav class="nav-secondary" role="navigation" aria-label="Secondary menu">
        <ul class="sidebar-panel-nav">
          <li class="nav-item"
          v-for="item in secondary" :key="item.id" :data-id="item.id">
            <router-link :to="relativeURL(Number(item.id))">{{ item.title }}</router-link>
          </li>

          <li key="toLogin" v-if="!isLoggedIn" class="nav-item -mobile-item">
            <router-link to="/innlogging">Logg inn</router-link>
          </li>

          <li key="toResourceMain" v-if="isLoggedIn" class="nav-item -mobile-item">
            <router-link
            v-if="isLoggedIn"
            :to="{ name: 'ResourceMain' }">Kompasset</router-link>
          </li>

          <li key="toResourcePortal" v-if="isLoggedIn" class="nav-item -mobile-item">
            <router-link
            v-if="isLoggedIn"
            :to="{ name: 'ResourceOverview' }">Biblioteket</router-link>
          </li>

          <li key="toLogout" v-if="isLoggedIn" class="nav-item -mobile-item">
            <a href="/" @click="logOut">Logg ut</a>
          </li>
        </ul>
      </nav>
    </Sidebar>
  </header>

</template>

<script>
// import { headroom } from 'vue-headroom'
import Icon from '../component/Icon.vue'
import Nav from '../component/Nav/Nav.vue'
import NavItem from '../component/Nav/NavItem.vue'
import Sidebar from '../component/Nav/Sidebar.vue';
// import { store, mutations } from '@/store/store.js';

export default {
  components: {
    // headroom,
    Icon,
    Nav,
    NavItem,
    Sidebar
  },
  data: () => ({
    primary: [],
    secondary: [],
    baseURL: process.env.MIX_API_BASE_URL,
  }),
  computed: {
    isNavOpen() {
      return this.$store.getters.getNavOpenState
    },

    // isBurgerActive() {
    //   // return store.isNavOpen;
    //   return this.$store.getters.getNavOpenState
    // },

    token() {
      // return localStorage.getItem('user-password')
      return this.$store.getters.getToken
    },

    userID() {
      return this.$store.getters.getUserID
    },

    isLoggedIn() {
      if (this.userID)
        return true
      else
        return false
    },

    scrollTop() {
      return this.$store.getters.getScrollTop
    },

    scrollDirection() {
      return this.$store.getters.getScrollDirection
    },

    navbarHeight() {
      if (this.$refs && this.$refs.siteHeader) {
        return this.$refs.siteHeader.clientHeight
      }
      return 120
    },

    headerClass() {
      var returnClass = '';

      if (this.isNavOpen) {
        returnClass += '-nav-open ';
      } else {
          returnClass += '-nav-bg ';
        if (this.scrollTop >= this.navbarHeight) {
          if (this.scrollDirection === 'down') {
            returnClass += '-nav-up ';
          } else {
            returnClass += '-nav-down ';
          }
        }
      }

      return returnClass;
    }
  },
  methods: {
    async primaryNav() {
      try {
        const response = await axios.get(
          this.baseURL + '/api/menus/v1/menus/8', {
            params: {}
          }
        )
        const results = response.data
        if ( !results || results.length == 0 ) {}
        this.primary = results.map(post => ({
          id: post.object_id,
          type: post.object,
          title: post.title,
        }))
      }
      catch (e) {
        this.errors.push(e)
      }
      // return this.doAction()

    },

    async secondaryNav() {
      try {
        const response = await axios.get(
          this.baseURL + '/api/menus/v1/menus/66', {
            params: {}
          }
        )
        const results = response.data
        if ( !results || results.length == 0 ) {}
        this.secondary = results.map(post => ({
          id: post.object_id,
          type: post.object,
          title: post.title,
        }))
      }
      catch (e) {
        this.errors.push(e)
      }
      // return this.doAction()

    },

    relativeURL(id) {
      return this.$store.getters.getRelativeUrl(id)
//       let filteredPosts = ''
//
//       if ( this.$store.getters.getPagesAndPosts.find( post => post.id === id ) ) {
//         filteredPosts = this.$store.getters.getPagesAndPosts.find( post => post.id === id )
//         filteredPosts = filteredPosts.vueData.url
//       }
//
//       //console.log(filteredPosts)
//       return filteredPosts

    },

    toggle() {
      // mutations.toggleNav();
      this.$store.dispatch('toggleNav')
    },

    logOut() {
      this.$store.dispatch('logOut')
      this.$router.push('/innlogging')
    }

  },

  mounted() {
    this.primaryNav()
    this.secondaryNav()
  },

  watch: {
    isLoggedIn(newVal, oldVal) {
      //console.log(newVal)
    },
    // isBurgerActive(newVal, oldVal) {
    //   if (newVal) document.documentElement.style.overflow = "hidden"
    //   else document.documentElement.style.overflow = "auto"
    // }
  }

}
</script>
