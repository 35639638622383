<template>

  <div class="component-inner">

    <h1>{{ block.options.title ? block.options.title : block.acf_fc_layout | capitalize  }}</h1>
    <pre class="bg-gray-light rounded-md p-4">{{ block }}</pre>

  </div>

</template>

<script>
export default {
  name: 'Story',
  props: {
    block: Object
  },
}
</script>
