<template>

  <div class="component-inner">

    <header class="block-header">
      <h2 class="block-title">{{ block.options.title | capitalize  }}</h2>
    </header>

    <article
    v-for="(item, index) in block.guide.items"
    :key="index"
    class="guide-item  mx-auto"><!--md:flex gap-20 lg:gap-40 md:max-w-screen-lg content-center items-center justify-between -->

      <header class="text-column flex-1">
        <div class="max-w-full">
          <h3 class="article-title title">{{ item.title }}</h3>
          <p class=" book">{{ item.excerpt }}</p>
        </div>
      </header>

      <div class="image-column">
        <GuidePath :path="index" />

        <Illustration
        :data="item.illustration" />
      </div>
    </article>

  </div>

</template>

<script>
import Illustration from './Illustration.vue'
import GuidePath from './guide/GuidePath.vue'

export default {
  name: 'Guide',
  props: {
    block: Object
  },

  components: {
    Illustration,
    GuidePath
  }
}
</script>
