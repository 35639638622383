<template>

  <div class="component-inner -mx-8 lg:mx-auto" >

    <div class="items" ref="itemGrid"><!--slider-wrapper-->

      <article v-for="(item, index) in block.descrition.items"  class="article-item " :key="'article_' + index" :data-id="`article_${index}`" ref="postItems">
        <!--flex-none lg:flex-none w-3/4 lg:w-1/4 flex flex-col items-center justify-items-center gap-4 text-center px-4-->

        <template :v-if="item.icon">
          <pictogram
          :data="item.icon"
          :color="item.iconColor" />

        </template>

        <header class="item-header">
          <h4 class="column-title medium">{{ item.title }}</h4>
          <p class=" excerpt" v-html="item.excerpt" />
        </header>

        <router-link
        v-if="item.url"
        class="button-text flex items-center text-brand-green" :to="relativeURL(item.url.ID)">

          <span v-if="item.read_more_label">{{ item.read_more_label }}</span>
          <span v-else><span class="sr-only">{{ item.title }}: </span>Les mer</span>

          <icon graphic="arrow" class="arrow-right"/>
        </router-link>

      </article>

    </div>

    <div class="dot-nav">
      <i class="dot" :class="{ '-active': visiblePosts[`article_${index}`] }" v-for="(item, index) in block.descrition.items" :data-id="index" :key="index"></i>
    </div>

  </div>

</template>

<script>

// import Icon from './Icon.vue'
import Icon from '@/component/Icon'
import Pictogram from '@/component/Block/Pictogram'


export default {
  name: 'Description',
  data: () => ({
    visiblePosts: {},
    itemObserver: null,
  }),
  props: {
    block: Object
  },
  components: {
    Icon,
    Pictogram,
  },
  methods: {
    relativeURL(id) {
      return this.$store.getters.getRelativeUrl(id)
//       let filteredPosts = ''
//
//       if ( this.$store.getters.getPagesAndPosts.find( post => post.id === id ) ) {
//         filteredPosts = this.$store.getters.getPagesAndPosts.find( post => post.id === id )
//         filteredPosts = filteredPosts.vueData.url
//       }
//
//       //console.log(filteredPosts)
//       return filteredPosts

    },
    observePosts() {
      const options = {
        root: this.$refs.itemGrid,
        threshold: 0.5,
      };

      this.itemObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry, index) => {
          let sTheID = entry.target.getAttribute('data-id');
          this.visiblePosts[sTheID] = entry.isIntersecting;
        });
      }, options);

      this.$refs.postItems.forEach((item) => {
        this.itemObserver.observe(item);
      });
    }
  },
  mounted() {
    if (this.block.descrition.items.length) {

      let oTheItems = {};

      this.$refs.postItems.forEach((item) => {
        oTheItems[item.getAttribute('data-id')] = false;
      });

      this.$set(this, "visiblePosts", oTheItems);

      this.observePosts();
    }
  },
  destroyed() {
    if (this.itemObserver) {
      this.itemObserver.disconnect();
    }
  }
}
</script>
