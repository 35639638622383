<template>

    <div class="component-inner">
        <div class="component-video-text-text">
            <h2 v-if="block.heading">{{ block.heading }}</h2>
            <div v-if="block.wysiwyg" v-html="block.wysiwyg" />
        </div>

        <div class="component-video-text-video">
            <figure v-if="block.video">
                <div class="video-container" v-html="block.video" />
            </figure>
        </div>
    </div>
  
  </template>
  
  <script>
  export default {
    name: 'VideoText',
    props: {
      block: Object,
    },
  }
  </script>
  