<template>
  <div class="component-inner">

    <header class="mb-28"
    v-if="block.options.title">
      <h2 class="mb-0">{{ block.options.title | capitalize  }}</h2>
    </header>

    <div class="items">

      <article v-for="(post, index) in block.studiesGroup.study" :key="index" class="item flex flex-col lg:flex-row gap-8 justify-between lg:items-stretch">

        <figure v-if="post.image" class="image-column">

          <img
          class="lg:aspect-video object-cover"
          :src="`${baseURL}/${post.image.sizes['large']}`"
          :height="post.image.sizes['large-height']"
          :width="post.image.sizes['large-width']"
          :alt="post.image.alt" />

        </figure>

        <header class="text-column flex flex-col justify-items-stretch">
          <h3 class="medium">{{ post.title }}</h3>
          <div class="lead regular lg:mb-4" v-html=" post.excerpt" />

          <Links :block="post.link" />

        </header>

      </article>

    </div>

  </div>
</template>

<script>
import Links from '@/component/Block/StudiesLink'


export default {
  data: () => ({
    baseURL: process.env.MIX_API_BASE_URL,
  }),
  props: {
    block: Object
  },
  components: {
    Links
  }
}
</script>
