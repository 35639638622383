<template>

  <div class="component-inner"><!-- flex flex-col lg:flex-row items-top justify-center gap-20 lg:gap-40-->
    <div v-if="block.options && block.options.illustration" class="image-column "><!--flex-none w-full lg:flex-1 order-last lg:-order-none-->
      <Illustration v-if="block.options.illustration" :data="block.options.illustration" />
    </div>

    <div class="text-column "><!--flex-none w-full lg:flex-1 lg:w-1/2 self-start-->
      <header :v-if="block.options && block.options.title">
        <h3 class="accordion-title medium">{{ block.options.title | capitalize }}</h3>
      </header>

      <Accordion v-if="block.accordion.accordion_items" :data="block.accordion.accordion_items" />
    </div>
  </div>

</template>

<script>
import Accordion from '@/component/Accordion/Accordion'
import Illustration from '@/component/Block/Illustration'

export default {
  props: {
    block: Object
  },
  components: {
    Accordion,
    Illustration,
  },
}
</script>
