<template>

  <div class=" regular lead" v-html="block.preamble.preamble" />

</template>

<script>
export default {
  props: {
    block: Object,
    options: Object
  },
}
</script>
