<template>
  <transition
  name="fade"
  :duration="{ enter: 500, leave: 0 }">

    <section
    v-if="heroData"
    :key="heroData.id"
    :data-id="heroData.id"
    class="hero"
    :class="[heroData.type, heroData.layout, 'bg-' + heroData.color]">

      <div v-if="heroImage && heroImage.landscape && ['superimposed', 'text_image'].includes(heroData.layout)" :data-id="heroImage.landscape.id" class="hero-wrapper-image">

        <picture>
          <template v-if="heroImage.portrait && heroImage.landscape">
            <source media="(max-width: 767px)" :srcset="heroImage.portrait.sizes['large']"/>
            <source media="(min-width: 768px)" :srcset="heroImage.landscape.sizes['large']"/>

            <img :src="heroImage.landscape.url" :alt="heroImage.landscape.alt" />
          </template>

          <template v-else>
            <source :srcset="heroImage.landscape.sizes['large']"/>

            <img :src="heroImage.landscape.sizes['large']" :alt="heroImage.landscape.alt" />
          </template>
        </picture>
      </div>

      <div
      class="hero-wrapper mx-auto py-20 pt-40 xl:pt-40 z-10"
      :class="[(['superimposed'].includes(heroData.layout)) ? 'items-end' : 'items-start lg:items-center']"><!--max-w-screen-2xl -->

        <Breadcrumb
        v-if="['post', 'ressurs'].includes(heroData.type)"
        :type="heroData.type"
        :title="heroData.title"
        :areas="heroData.areas"
        :types="heroData.types" /><!--title="heroData.label ? heroData.label : heroData.title"-->

        <div class="hero-column column-left flex flex-col self-stretch"
        :class="[(['superimposed'].includes(heroData.layout)) ? 'justify-end' : '']"><!-- w-full lg:max-w-2xl 2xl:max-w-4xl --><!-- justify-center -->

          <header
          class="hero-header justify-between"
          :class="(['content', 'icon'].includes(heroData.layout) || heroData.button ? 'lg:my-auto' : '')">

            <p class="label lead" v-if="heroData.label" v-html="heroData.label" />
            <h1 class="title" v-html="heroData.title" />
            <p class="lead" v-if="heroData.excerpt" v-html="heroData.excerpt" />
            <!-- <h3 class="regular" v-if="heroData.excerpt" v-html="heroData.excerpt" /> -->
                      <!-- Code for link acf goes here -->
            <div class="hero-link" v-if="heroData.link">
              <a 
                :href="heroData.link.url" 
                :target="heroData.link.target || '_self'" 
                rel="noopener noreferrer" 
                class="link-button">
                {{ heroData.link.title }}
                <svg class="flex-none h-4 w-auto fill-current px-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z"/></svg>
              </a>
            </div>
          </header>

          <Meta
          v-if="[38, 40].includes(heroData.id) || ['post', 'ressurs'].includes(heroData.type)"
          :date="heroData.date"
          :modified="heroData.modified"
          :type="heroData.type"
          :post_id="heroData.id"
          :layout="heroData.layout" />

          <div class="hero-icon"
          v-if="['page'].includes(heroData.type) && ['content'].includes(heroData.layout) && heroData.content.icon">

            <HeroIcon v-if="heroData.content.icon && heroData.content.icon.item"
            :data="heroData.content.icon.item"
            :color="heroData.content.icon.color" />

          </div>


          <div
          class="hero-button w-full lg:mt-8 2xl:mt-auto"
          :class="(['content'].includes(heroData.layout) && heroData.button ? 'mt-16 lg:mt-auto' : '')"
          v-if="['content'].includes(heroData.layout) && heroData.button">

          <router-link
            class="w-auto max-w-max inline-flex items-center text-xl text-brand-green"
            :class="[heroData.button.style]"
            :to="relativeURL(heroData.button.url.ID)">
              <span class="">{{ heroData.button.label ? heroData.button.label : heroData.button.url.post_title }}</span>
              <svg class="flex-none h-4 w-auto fill-current px-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z"/></svg>
          </router-link>




          </div>


        </div>
          

        <div class="hero-column column-right flex lg:flex-1 self-end items-end lg:justify-end"><!-- w-full lg:flex-1 self-end items-end lg:justify-end -->

          <div v-if="false && heroImage && heroImage.landscape && heroData.type === 'post'" :data-id="heroImage.landscape.id" class="hero-image">
            <picture>
              <template v-if="heroImage.portrait && heroImage.landscape">
                <source media="(max-width: 767px)" :srcset="heroImage.portrait.sizes['large']"/>
                <source media="(min-width: 768px)" :srcset="heroImage.landscape.sizes['large']"/>

                <img :src="heroImage.landscape.url" :alt="heroImage.landscape.alt" />
              </template>

              <template v-else>
                <source :srcset="heroImage.landscape.sizes['large']"/>

                <img :src="heroImage.landscape.sizes['large']" :alt="heroImage.landscape.alt" />
              </template>
            </picture>
          </div>
          <!--KeepAlive :max="10" -->

            <search-form v-if="$route.name === 'ResourceMain'"></search-form>

            <component
            :is="(['icon', 'illustration', 'content', 'ressurs'].includes(heroData.layout)) ? 'Hero' + capitalize(heroData.layout) : false"
            :data="(['icon', 'illustration'].includes(heroData.layout)) ? heroData.content.item : heroData.content"
            :color="(['icon'].includes(heroData.layout)) ? heroData.content.color : ''"
            :key="heroData.id" />


        </div>

      </div>

      <div class="scroll-indicator">
        <icon graphic="arrow-stroke" class="arrow-down"/>
      </div>

    </section>

  </transition>
</template>

<script>
// import { EventBus } from '@/store/hero.js'

import SearchForm from '@/component/Search/SearchForm.vue'

import Meta from '@/section/Meta'
import Breadcrumb from '../Breadcrumb.vue'

import HeroContent from './Content.vue'
import HeroRessurs from './Assets.vue'


import HeroIcon from '@/component/Block/Pictogram'
import Icon from '@/component/Icon'

import HeroIllustration from '@/component/Block/Illustration'
import InternalButton from '../../component/Block/InternalButton.vue'

import { isMobileOnly } from 'mobile-device-detect'

export default {
  components: {
    Meta,
    Breadcrumb,
    HeroContent,
    HeroIcon,
    HeroIllustration,
    HeroRessurs,
    InternalButton,
    'search-form': SearchForm,
    Icon,
},
  data: () => ({
    baseURL: process.env.MIX_API_BASE_URL,
  }),

  computed: {
    heroData() {
      let sTheSlug = null;
      let oTheData = {};
      let bTheVideo = false;
      let bTheImage = false;
      let bTheLabel = false;
      let bTheButton = false;
      let bTheLinks = false;
      let bTheTextContent = false;
      let bTheBreadcrumb = false;
      let oThePost = {};
      let aTheLinks = null;
      let sTheTextContent = null;
      let sTheLayout = null;

      if (this.$route.name === "Home") {
        sTheSlug = "forside";
      } else if (this.$route.name === "Contact") {
        sTheSlug = "kontakt";
      } else if (this.$route.name === "ResourceSingle") {
        sTheSlug = this.$route.params.slug;
        sTheLayout = "ressurs";
      } else if (this.$route.name === "ResourceMain") {
        sTheSlug = "kompasset";
      } else if (this.$route.name === "Page") {
        sTheSlug = this.$route.params.slug;
        bTheButton = true;
      } else if (this.$route.name === "ChildPage") {
        sTheSlug = this.$route.params.child;
        bTheButton = true;
      } else if (this.$route.name === "Article") {
        sTheSlug = this.$route.params.slug;
      }

      if (sTheSlug) {

        // console.log("SLUG", sTheSlug, this.$route);

        switch (this.$route.name) {
          case "Home":
          case "Page":
          case "ChildPage":
          case "Contact":
            // oThePost = this.$store.getters.getPostBySlug(sTheSlug);
            oThePost = this.$store.getters.getPageBySlug(sTheSlug);
            bTheImage = true;
            // if (oThePost && oThePost.content && oThePost.content.links) {
            //   aTheLinks = oThePost.content.links;
            // }

            if (oThePost && oThePost.content && oThePost.content.content && oThePost.content.content.wysiwyg) {
              sTheTextContent = oThePost.content.content.wysiwyg;
            }

            break;

          case "Article":
            oThePost = this.$store.getters.getPostBySlug(sTheSlug);
            bTheImage = false;
            bTheImage = true;

            // console.log("STILL ARTICLE", oThePost);

            if (oThePost && oThePost.content && oThePost.content.content && oThePost.content.content.wysiwyg) {
              sTheTextContent = oThePost.content.content.wysiwyg;
            }

            break;

          case "ResourceMain":
            oThePost = this.$store.getters.getPageBySlug(sTheSlug);
            bTheImage = true;
            bTheLabel = true;
            break;

          case "ResourceSingle":
            oThePost = this.$store.getters.getResourceBySlug(sTheSlug);
            bTheImage = false;
            bTheLinks = true;
            bTheBreadcrumb = true;
            bTheTextContent = true;

            // if (oThePost && oThePost.assets) {
            //   aTheLinks = oThePost.assets.assets;
            // }

            break;
        }

        if (oThePost) {

          if (!sTheLayout) {
            sTheLayout = oThePost.acf.options.layout;
          }

          oTheData = {
            id: oThePost.id,
            excerpt: oThePost.acf.excerpt.excerpt,
            layout: sTheLayout,
            type: oThePost.type,
            date: oThePost.date,
            modified: oThePost.modified,
            url: oThePost.link,
            color: oThePost.acf.options.color ? oThePost.acf.options.color : 'gray-light',
            content: (oThePost.acf.options.layout != 'superimpossed' ) ? this.setContent(oThePost.type, oThePost.acf.options.layout, oThePost.acf.hero, oThePost.acf.assets) : false,
            video: false,
            link: oThePost.acf.hero.link || null, // Added line to include the single link object
            
          };

          if (bTheLabel) {
            oTheData.label = oThePost.acf.excerpt.super;
          }

          let sTheTitle = oThePost.acf.hero.title || oThePost.title.rendered || oThePost.title;
          // let sTheTitle = oThePost.title.rendered || oThePost.title;
          oTheData.title = sTheTitle;

          if (bTheImage) {
            oTheData.image = {
              landscape: oThePost.acf.hero.media.landscape.image,
              portrait: oThePost.acf.hero.media.portrait.image,
            };
          }

          if (bTheBreadcrumb) {
            if (oThePost.omrader) {
              oTheData.areas = oThePost.omrader;
            }
            if (oThePost.typer) {
              oTheData.types = oThePost.typer;
            }
          }

          if (bTheButton && oThePost.acf.excerpt.button.url) {
            oTheData.button = {
              url: oThePost.acf.excerpt.button.url,
              label: oThePost.acf.excerpt.button.label,
              style: oThePost.acf.excerpt.button.style,
            };
          }

          // console.log("HERO", oTheData);

          // console.log("HERO", oThePost, oTheData);

          return oTheData
        } else {
          return this.$store.getters.getHero
        }
        return this.$store.getters.getHero
      } else {
        return this.$store.getters.getHero
      }
      return this.$store.getters.getHero
    },
    heroImage() {
      if (this.heroData && this.heroData.image) {
        return this.heroData.image
      }
      return false
    },
    hasImage() {
      if (this.heroData && this.heroData.image) {
        return true
      }
      return false
    },
    // activeResource() {
    //   return this.$store.getters.getActiveResource
    // },
  },

  methods: {
    capitalize(e) {
      return this.$options.filters.capitalize(e)
    },
    relativeURL(id) {
      return this.$store.getters.getRelativeUrl(id)
//       let filteredPosts = ''
//
//       if ( this.$store.getters.getPagesAndPosts.find( post => post.id === id ) ) {
//         filteredPosts = this.$store.getters.getPagesAndPosts.find( post => post.id === id )
//         filteredPosts = filteredPosts.vueData.url
//       }
//
//       //console.log(filteredPosts);
//       return filteredPosts

    },
    setContent(type, style, post, assets) {
      let data = Object;

      // console.log("HERO DATA", type, style, post);

      if (['post', 'page'].includes(type)) {
        switch (style) {
          case 'content':
            data = {
              color: post.sidebar.color,
              title: post.content.content.subtitle ? post.content.content.subtitle : false,
              wysiwyg: post.content.content.wysiwyg ? post.content.content.wysiwyg : false,
              links: post.content.links,
              icon: post.icon ? { item: post.icon.icon, color: post.icon.iconColor } : false,
            }
            break;
          case 'icon':
            data = {
              color: post.icon.iconColor,
              // color: post.sidebar.color,
              item: post.icon.icon ? post.icon.icon : false
            }
            break;

          case 'illustration':
            data = {
              color: post.sidebar.color,
              item: post.illustration.illustration ? post.illustration.illustration : false
            }
            break;

          default:
            data = false
        }
      } else {
        data = {
          color: post.sidebar.color ? post.sidebar.color : 'blue-light',
          links: assets ? assets.assets : false
        }
      }
      return data
    },
    setLayout(e) {
      let expr = e
      let data = String

      switch (expr) {
        case 'superimposed':
          data = e
          break;

        default:
          data = e
      }
      return data
    },
//     setImage(landscape, portrait) {
//
//       let image = {}
//
//       console.log("landscape, portrait", landscape, portrait);
//
//       if ( isMobileOnly ) {
//         console.log('Is mobile, ' +isMobileOnly)
//         if ( portrait ) {
//           image = {
//             id: portrait.id,
//             alt: portrait.alt,
//             caption: portrait.caption,
//             description: portrait.description,
//             url: this.baseURL + `/${portrait.sizes['large']}`,
//             height: `${landscape.sizes['large-height']}`,
//             width: `${landscape.sizes['large-width']}`
//           }
//         } else {
//           if ( landscape ) {
//             image = {
//               id: landscape.id,
//               alt: landscape.alt,
//               caption: landscape.caption,
//               description: landscape.description,
//               url: this.baseURL + `/${landscape.sizes['large']}`,
//               height: `${landscape.sizes['large-height']}`,
//               width: `${landscape.sizes['large-width']}`
//             }
//           }
//
//         }
//
//       } else {
//         console.log('Is mobile, ' +isMobileOnly)
//         if ( landscape ) {
//           image = {
//             id: landscape.id,
//             alt: landscape.alt,
//             caption: landscape.caption,
//             description: landscape.description,
//             url: this.baseURL + `/${landscape.sizes['2048x2048']}`,
//             height: `${landscape.sizes['2048x2048-height']}`,
//             width: `${landscape.sizes['2048x2048-width']}`
//           }
//         }
//       }
//       //console.log(image)
//       return image
//
//     },
  },

  created() {
    this.$store.dispatch('updateRenderingItems', { name: 'hero', value: true });
  },

  mounted () {
//     EventBus.$on('EventBus', data => {
//       this.data = data
//       //console.log('Updated', +Date.now())
//       //console.log(data.attributes);
//
//       if ( this.data && this.data.image )
//         document.body.classList.add('--has-image')
//       else
//         document.body.classList.remove('--has-image')
//     })

    // console.log('hero mounted');

    this.$nextTick(() => {
      // console.log('hero nexttick', new Date());
    })
  },
  updated() {
    // console.log('hero updated');

    this.$nextTick(() => {
      // console.log('hero updated nexttick', new Date());
      this.$store.dispatch('updateRenderingItems', { name: 'hero', value: false });
    })
  },
  watch: {
    hasImage: {
      handler(bool) {
        if (bool) {
          document.body.classList.add('--has-image')
        } else {
          document.body.classList.remove('--has-image')
        }
      },
      immediate: true,
    }
  }
}


</script>
