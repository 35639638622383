<template>

  <dl
  role="presentation" class="accordion-list">

    <div
    v-for="(item, index) in computedList"
    :key="index"
    class="accordion-item border-b border-brand-green mb-8"
    :class="itemClassList[index]">

      <dt class="item-header accordion-item-title">
        <button class="toggle accordion-item-trigger pb-8 flex justify-between w-full items-center text-left gap-4" @click="toggleDropdown(index, $event)">
          <span class="title accordion-item-title-text grow flex-1 mb-0">{{ item.title }}</span>

          <icon graphic="arrow-stroke" :class="{ 'arrow-up': itemStatesList[index] === 'open', 'arrow-down': itemStatesList[index] !== 'open'}"/>

        </button>
      </dt>

      <!-- <transition name="slide"> -->
        <dd class="item-details accordion-item-details" :ref="`accordion_item_details_${index}`" :class="{'-open': contentVisible === index }" ><!--v-show="contentVisible === index"-->
          <div :ref="`accordion_inner_${index}`" class="inner accordion-item-details-inner text-black pt-8 pb-16 px-10 text-lg xl:text-xl 2xl:text-2xl" v-html="item.paragraph" />
        </dd>
      <!-- </transition> -->

    </div>

    <button v-if="showLoadMore" class="flex items-center gap-4 text-brand-green font-SuisseIntlRegular text-xl mx-auto mt-16" @click="loadMore">
      <span>Last mer</span>
      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 384 512" class="flex-none hidden lg:block h-4 w-auto fill-current"><path d="M374.6 310.6l-160 160C208.4 476.9 200.2 480 192 480s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 370.8V64c0-17.69 14.33-31.1 31.1-31.1S224 46.31 224 64v306.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0S387.1 298.1 374.6 310.6z"></path></svg>
    </button>

  </dl>

</template>

<script>

import Icon from "../Icon.vue"

export default {
  name: 'Accordion',
  props: {
    data: Array,
  },
  data : () => ({
    contentVisible: false,
    showLoadMore: false,
    items: 6,
    itemStates: {},
  }),
  components: {
    "icon": Icon,
  },
  methods: {
    loadMore() {
      this.items = this.$props.data.length
    },
    closeDropdown(index) {
      this.itemStates[index] = 'closing';

      setTimeout(() => {
        this.itemStates[index] = 'closed';
        if (this.contentVisible === index) {
          this.contentVisible = false;
        }
      }, 300);
    },
    openDropdown(index) {
      this.contentVisible = index;

      this.computedList.forEach((el, ind) => {
        if (ind !== index) {
          this.closeDropdown(ind);
        }
      });

      this.$refs[`accordion_item_details_${index}`][0].style.setProperty('--max-height', `${this.$refs[`accordion_inner_${index}`][0].scrollHeight}px`);

      this.itemStates[index] = 'open';
    },
    toggleDropdown(index, e) {
      if (this.contentVisible === index) {
        this.closeDropdown(index);
      } else {
        this.openDropdown(index);
      }
    },
    setUpItemStates() {
      let aTheStates = {};

      this.$props.data.forEach((el, ind) => {
        aTheStates[ind] = 'closed';
      });

      this.itemStates = aTheStates;
    }
  },
  computed: {
    computedList() {

      let listItems = []
      let totalItems = []

      if ( this.$props.data.length > 0 ) {
        totalItems.push(...this.$props.data)
        listItems.push(...totalItems.slice(0, this.items))
        this.showLoadMore = listItems.length < totalItems.length ? true : false

        return listItems
      }
    },
    itemStatesList() {// I find we need a computed list of states
      return Object.values(this.itemStates);
    },
    itemClassList() {
      let aTheClasses = [];

      if (this.itemStates) {
        Object.values(this.itemStates).forEach((item, index) => {
          switch (item) {
            case "open":
              aTheClasses.push("-open");
              break;
            case "closing":
              aTheClasses.push("-open -closing");
              break;
            default:
              aTheClasses.push("-closed");
          }
        })
      }

      return aTheClasses
    }
  },
  mounted() {
    this.setUpItemStates();
  }
}
</script>
