<template>

  <div class="component-inner">
    <div class="paragraph-content" :class="layoutClass">
      <h5 class="subtitle" v-if="block.content.subtitle">{{ block.content.subtitle }}</h5>
      <div v-if="block.content" v-html="block.content.wysiwyg" /><!-- class="lead" -->
    </div>
  </div>

</template>

<script>
export default {
  name: 'Paragraph',
  props: {
    block: Object,
    options: Object
  },
  computed: {
    layoutClass() {
      if (this.options) {
        let sTheClass = `-${this.options.layout}`;

        if (this.options.color && this.options.layout !== 'simple') {
          sTheClass = `${sTheClass} bg-${this.options.color}`;
        }
        return sTheClass
      }
      return ""
    }
  }
}
</script>
