<template>

    <div class="component-inner">


      <a  
        class="internal-button external"
        :class="[(['text'].includes(block.button.style)) ? 'button-text' : 'button', block.button.style]" 
        :target="block.button.link.target || '_self'" 
        :href="block.button.link.url">
        <span class="">{{ block.button.label ? block.button.label : block.button.link.title }}</span>

        <icon graphic="arrow-stroke" class="arrow-right"/>
    </a>
  
    </div>
  
  </template>
  
  <script>
  import Icon from '@/component/Icon'
  
  export default {
    name: 'ExternalButton',
    props: {
      block: Object,
      options: Object
    },
    components: {
      Icon,
    },
    methods: {

    },
  }
  </script>
  