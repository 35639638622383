<template>

  <div class="component-inner">

    <ul
    v-if="block.link">

      <li
      class="font-SuisseIntlMedium"
      v-for="(item, index) in block.link.link_items.items"
      :key="index">

        <a
        v-if="item.link_type == 'external'"
        class="flex items-center gap-2.5"
        :href="item.link_uri" target="_blank" rel="nofollow">
          <svg class="flex-none h-4 w-auto fill-current" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M280 80C266.7 80 256 69.25 256 56C256 42.75 266.7 32 280 32H424C437.3 32 448 42.75 448 56V200C448 213.3 437.3 224 424 224C410.7 224 400 213.3 400 200V113.9L200.1 312.1C191.6 322.3 176.4 322.3 167 312.1C157.7 303.6 157.7 288.4 167 279L366.1 80H280zM0 120C0 89.07 25.07 64 56 64H168C181.3 64 192 74.75 192 88C192 101.3 181.3 112 168 112H56C51.58 112 48 115.6 48 120V424C48 428.4 51.58 432 56 432H360C364.4 432 368 428.4 368 424V312C368 298.7 378.7 288 392 288C405.3 288 416 298.7 416 312V424C416 454.9 390.9 480 360 480H56C25.07 480 0 454.9 0 424V120z"/></svg>
          <span class="">{{ item.label ? item.label : 'Last ned' }}</span>
        </a>

        <a
        v-if="item.link_type == 'document'"
        class="flex items-center gap-2.5"
        :href="item.link_asset.url" target="_blank" rel="nofollow">
          <svg class="flex-none h-4 w-auto fill-current" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M416 128h-40C362.7 128 352 138.7 352 152c0 13.25 10.75 24 24 24H416c8.836 0 16 7.164 16 16v256c0 8.836-7.164 16-16 16H96c-8.836 0-16-7.164-16-16V192c0-8.836 7.164-16 16-16h40C149.3 176 160 165.3 160 152C160 138.7 149.3 128 136 128H96C60.65 128 32 156.7 32 192v256c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V192C480 156.7 451.3 128 416 128zM143.7 238.6C133.1 247.6 133.4 262.8 142.4 272.6l96 104.1c9.062 9.82 26.19 9.82 35.25 0l96-104.1c9-9.758 8.406-24.95-1.344-33.93c-9.781-9.07-24.97-8.414-33.91 1.344L280 298.9V24.02C280 10.76 269.3 0 256 0S232 10.76 232 24.02v274.9l-54.38-58.95C172.9 234.8 166.5 232.3 160 232.3C154.2 232.3 148.3 234.3 143.7 238.6z"/></svg>
          <span class="">{{ item.label ? item.label : item.link_asset.title }}</span>
        </a>

        <router-link
        v-if="item.link_type == 'page'"
        class="button-text flex items-center gap-2.5"
        :to="relativeURL(item.link_object.ID)">
          <svg class="flex-none h-4 w-auto fill-current rotate-90" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M280 80C266.7 80 256 69.25 256 56C256 42.75 266.7 32 280 32H424C437.3 32 448 42.75 448 56V200C448 213.3 437.3 224 424 224C410.7 224 400 213.3 400 200V113.9L200.1 312.1C191.6 322.3 176.4 322.3 167 312.1C157.7 303.6 157.7 288.4 167 279L366.1 80H280zM0 120C0 89.07 25.07 64 56 64H168C181.3 64 192 74.75 192 88C192 101.3 181.3 112 168 112H56C51.58 112 48 115.6 48 120V424C48 428.4 51.58 432 56 432H360C364.4 432 368 428.4 368 424V312C368 298.7 378.7 288 392 288C405.3 288 416 298.7 416 312V424C416 454.9 390.9 480 360 480H56C25.07 480 0 454.9 0 424V120z"/></svg>
          <span class="">{{ item.label ? item.label : item.link_object.post_title }}</span>
        </router-link>

      </li>
    </ul>


  </div>

</template>

<script>
export default {
  name: 'Link',
  props: {
    block: Object,
    options: Object
  },
  methods: {
    relativeURL(id) {
      return this.$store.getters.getRelativeUrl(id)
//       let filteredPosts = ''
//
//       if ( this.$store.getters.getPagesAndPosts.find( post => post.id === id ) ) {
//         filteredPosts = this.$store.getters.getPagesAndPosts.find( post => post.id === id )
//         filteredPosts = filteredPosts.vueData.url
//       }
//
//       //console.log(filteredPosts)
//       return filteredPosts

    }
  },
}
</script>
