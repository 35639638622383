<template>

  <div
  v-if="acf"
  class="type"
  :class="[post.type]"
  :data-id="post.id"
  :data-slug="post.slug">

    <section class="component team">
      <team-include />
    </section>

    <section
    v-if="acf.blocks"
    v-intersect="{observerOptions: {rootMargin: '0px', threshold: 0.0}, true: ['-intersected'], disposeWhen: true }"
    v-for="(block, index) in acf.blocks"
    :key="index"
    class="component"
    :class="[block.acf_fc_layout, block.options && block.options.layout ? block.options.layout : '', block.options && block.options.color ? 'bg-'+ block.options.color : 'bg-white']">

      <!-- <KeepAlive :max="10"> -->

        <component
        :is="block.acf_fc_layout"
        :block="block"
        :key="index" />

      <!-- </KeepAlive> -->

    </section>

  </div>
</template>

<script>
// import { EventBus } from '@/store/hero.js';

import postMeta from '@/mixins/postMeta.js';

import TeamInclude from '@/component/Page/teamInclude.vue';
import Contact from '@/component/Block/Contact'

export default {
  components: {
    TeamInclude,
    Contact,
  },
  data : () => ({
    hero: [],
    // post: null,
    // acf: Object,
    errors: [],
    scrollTo: {},
    observer: null,
    baseURL: process.env.MIX_API_BASE_URL,
  }),

  mixins: [
    postMeta,
  ],

  computed: {
    post() {
      let sTheSlug = "kontakt";
      return this.$store.getters.getPageBySlug(sTheSlug);
    },
    acf() {
      if (this.post) {
        return this.post.acf
      }
      return false
    }
  },

  methods: {
//     async getPage() {
//       try {
//         const response = await axios.get(
//           this.baseURL + '/api/wp/v2/pages', {
//             params: {
//               slug: 'kontakt',
//             }
//           }
//         )
//         const results = response.data
//         if ( !results || results.length == 0 ) {}
//         this.post = results.map(post => ({
//           id: post.id,
//           type: post.type,
//           slug: post.type + '/' +post.slug,
//           title: post.title.rendered,
//           media: post.featured_media,
//           template: post.template ? post.template : 'default',
//           head: post.yoast_head_json,
//         }))[0]
//
//         this.acf = results.map(post => ({
//           components: post.acf.blocks
//         }))
//
//         this.hero = results.map(post => ({
//           id: post.id,
//           label: post.acf.excerpt.super,
//           title: post.acf.hero.title ? post.acf.hero.title : post.title.rendered,
//           excerpt: post.acf.excerpt.excerpt ? post.acf.excerpt.excerpt : false,
//           type: post.type,
//           date: post.date,
//           url: post.link,
//           layout: this.setLayout(post.acf.options.layout),
//           content: (post.acf.options.layout != 'superimpossed' ) ? this.setContent(post.type, post.acf.options.layout, post.acf.hero) : false,
//           color: post.acf.options.color ? post.acf.options.color : 'gray-light',
//           image: post.acf.hero.media.landscape.image ? {
//             id: post.acf.hero.media.landscape.image.id,
//             alt: post.acf.hero.media.landscape.image.alt,
//             caption: post.acf.hero.media.landscape.image.caption,
//             description: post.acf.hero.media.landscape.image.description,
//             sizes: post.acf.hero.media.landscape.image.sizes
//           } : false,
//           video: false,
//         }))
//       }
//       catch (e) {
//         this.errors.push(e)
//       }
//       return this.doAction()
//
//     },

    doAction() {
      // EventBus.$emit('EventBus', this.hero[0])
      this.$store.dispatch('updateHero', this.hero[0]);
    },

    setHero(data) {
      // EventBus.$emit('EventBus', data)
    },

    resetData() {},

//     setContent(type, style, post) {
//
//       let data = Object
//
//       if (['post', 'page'].includes(type)) {
//         switch (style) {
//           case 'content':
//             data = {
//               color: post.sidebar.color,
//               title: post.content.content.subtitle ? post.content.content.subtitle : false,
//               wysiwyg: post.content.content.wysiwyg ? post.content.content.wysiwyg : false,
//               links: post.content.links
//             }
//             break;
//           case 'icon':
//             data = {
//               color: post.sidebar.color,
//               item: post.icon.icon ? post.icon.icon : false
//             }
//             break;
//
//           case 'illustration':
//             data = {
//               color: post.sidebar.color,
//               item: post.illustration.illustration ? post.illustration.illustration : false
//             }
//             break;
//
//           default:
//             data = false
//         }
//       } else {
//         data = {
//           color: post.sidebar.color
//         }
//       }
//
//       //console.log(data)
//       return data
//
//     },
//
//     setLayout(e) {
//       let expr = e
//       let data = String
//
//       switch (expr) {
//         case 'superimposed':
//           data = e
//           break;
//
//         default:
//           data = e
//       }
//       return data
//     },

    observerElement() {
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if(entry.isIntersecting){
            //console.log('Observed');
          }
        });
      },
      {
        rootMargin: '-50% 0% -50% 0%'
      })

      document.querySelectorAll('.article').forEach(item => {
        observer.observe(item)
      })
    }

  },

  mounted() {
    // this.getPage()
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      let sTheSlug = to.params.child ? to.params.child : to.params.slug
      vm.$store.dispatch('setActivePage', sTheSlug)
      // vm.resetData()
      // vm.getPage()
    })
  },

  watch: {
    $route: {
      immediate: true,
      handler: function(newVal, oldVal) {
        this.isModalVisible = newVal.meta && newVal.meta.isModalVisible;
      }
    },
    // '$route.params.slug'(newSlug, oldSlug) {
    //   this.resetData()
    //   this.getPage()
    // }
  }
}
</script>
