<template>
  <nav class="breadcrumb "><!-- my-16 -->
    <ul
    class="flex flex-wrap justify-start gap-2.5">
      <li
      v-if="['post'].includes(type)"
      class="flex items-center gap-2.5">
        <router-link class="font-SuisseIntlRegular text-brand-blue" to="/">Forside</router-link>
        <icon graphic="arrow"></icon>
      </li>

      <li
      v-if="['ressurs'].includes(type)"
      class="flex items-center gap-2.5">
        <router-link class="font-SuisseIntlRegular text-brand-blue" :to="{ name: 'ResourceOverview' }">Biblioteket</router-link>
        <icon graphic="arrow"></icon>
      </li>

      <template v-if="postCategories.length">
        <li
        v-for="(item, index) in postCategories"
        :key="index"
        class="flex items-center gap-2.5">
          <router-link
           v-if="['post'].includes(type)"
           class="font-SuisseIntlRegular text-brand-blue underline" :to="'/' +item.slug">{{ item.title | capitalize }}</router-link>

          <router-link
           v-if="['ressurs'].includes(type)"
          class="font-SuisseIntlRegular text-brand-blue" :to="{ name: 'ResourceOverview', query: { areas: JSON.stringify(item.id) }}">{{ item.title | capitalize }}</router-link>
          <icon graphic="arrow"></icon>
        </li>
      </template>

      <template v-if="!postCategories.length && postTypes.length">
        <li
        v-for="(item, index) in postTypes"
        :key="index"
        class="flex items-center gap-2.5">

        <router-link
           v-if="['ressurs'].includes(type)"
          class="font-SuisseIntlRegular text-brand-blue" :to="{ name: 'ResourceOverview', query: { types: JSON.stringify(item.id) }}">{{ item.title | capitalize }}</router-link>
          <icon graphic="arrow"></icon>
        </li>
      </template>

      <li>
        <span class="font-SuisseIntlBold">{{ title }}</span>
      </li>
    </ul>
  </nav>
</template>

<script>
import Icon from '@/component/Icon.vue'

export default {
  props: {
    type: String,
    title: String,
    // categories: Array
    areas: Array,
    types: Array,
  },
  data : () => ({
    category: [],
    cat: [],
    parentID: 0,
  }),
  components: {
    'icon': Icon,
  },
  computed: {
    allCategories() {
      if (this.type && this.type === "ressurs") {
        return this.$store.getters.getAreas
      }
      return false
    },
    allTypes() {
      if (this.type && this.type === "ressurs") {
        return this.$store.getters.getTypes
      }
      return false
    },
    postCategories() {
      let aTheCats = [];

      if (this.type && this.type === "ressurs") {
        if (this.areas && this.areas.length) {
          aTheCats = this.categoryParentLoop(this.areas, this.allCategories, null);
        }
      }

      return aTheCats
    },
    postTypes() {
      let aTheCats = [];

      if (this.type && this.type === "ressurs") {
        if (this.types && this.types.length) {
          aTheCats = this.categoryParentLoop(this.types, this.allTypes, null);
        }
      }

      return aTheCats
    }
  },
  methods: {
    categoryParentLoop(aTheCats, aAllTheCats, aTheReturnCats) {
      if (!aTheReturnCats) {
        aTheReturnCats = [];
      }

      for (const id of aTheCats) {
        let oTheCat = aAllTheCats.find(cat => cat.id === id);
        // console.log("ITERATION", this.parentID, id, oTheCat);
        if (oTheCat && oTheCat.parent === this.parentID && !aTheReturnCats.find(cat => cat.id === id)) {
          this.parentID = oTheCat.id;
          aTheReturnCats.push(oTheCat);
          return this.categoryParentLoop(aTheCats, aAllTheCats, aTheReturnCats);
        }
      }

      return aTheReturnCats
    }
  },
}
</script>
