<template>

  <div class="component-inner"
  :class="[block.options.layout === 'simple' ? 'border-t border-brand-green pt-10' : '']">

    <header class="block-header flex items-center justify-between mb-8"
    v-if="block.options.title">
      <h3 class="block-heading medium self-start mb-0">{{ block.options.title | capitalize  }}</h3>
    </header>

    <div class="posts" ref="relatedGrid"><!--grid lg:grid-cols-3 gap-8-->

      <article ref="articleItems" v-for="(item, index) in posts" :data-id="`article_${index}`" :class="postClass">
        <extended-link v-if="block.options.layout === 'extended'" :item="item" :data-id="item.id" :key="item.id"></extended-link>
        <simple-link v-else :item="item" :data-id="item.id" :key="item.id"></simple-link>
      </article>

    </div>

    <div class="dot-nav">
      <i class="dot" :class="{ '-active': visiblePosts[`article_${index}`] }" v-for="(item, index) in posts" :data-id="index" :key="index"></i>
    </div>
  </div>

</template>

<script>

import simpleLink from "./related/SimpleLink.vue";
import extendedLink from "./related/ExtendedLink.vue";

export default {
  name: 'Article',
  props: {
    block: Object
  },

  components: {
    "simple-link": simpleLink,
    "extended-link": extendedLink,
  },

  data : () => ({
    post: null,
    // posts: [],
    errors: [],
    baseURL: process.env.MIX_API_BASE_URL,
    visiblePosts: {},
    itemObserver: null,
  }),

  computed: {
    posts() {
      const aThePosts = [];
      if (this.block && this.block.related && this.block.related.objects && this.block.related.objects.length) {

        for (let item of this.block.related.objects) {
          let oThePost = null;

          switch (item.post_type) {
            case "post":
            case "page":
              oThePost = this.$store.getters.getPostByID(item.ID)

              if (oThePost) {
                aThePosts.push({
                  id: oThePost.id,
                  type: oThePost.type,
                  slug: oThePost.slug,
                  // url: this.item.vueData.url,
                  // url: `/artikler/${this.item.vueData.url}`,
                  title: oThePost.title.rendered,
                  excerpt: oThePost.acf.excerpt,
                  media: oThePost.acf.hero.media.landscape ? oThePost.acf.hero.media.landscape : '',
                  featured: oThePost.better_featured_image
                });
              }

              break;

            case "ressurs":
              oThePost = this.$store.getters.getResourceByID(item.ID)

              if (oThePost) {
                aThePosts.push(oThePost);
              }
              break;
          }
        }
      }

      return aThePosts
    },
    postClass() {
      if (this.block && this.block.options.layout) {
        if (this.block.options.layout === 'extended') {
          return "article-link extended flex flex-col -card";
        } else {
          return "article-link simple flex flex-col";
        }
      }
       return "";
    }
  },

  methods: {
    observePosts() {
      let oTheItems = {};

      this.$refs.articleItems.forEach((item) => {
        oTheItems[item.getAttribute('data-id')] = false;
      });

      this.$set(this, "visiblePosts", oTheItems);

      const options = {
        root: this.$refs.relatedGrid,
        threshold: 0.5,
      };

      this.itemObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry, index) => {
          let sTheID = entry.target.getAttribute('data-id');
          this.visiblePosts[sTheID] = entry.isIntersecting;
        });
      }, options);

      this.$refs.articleItems.forEach((item) => {
        this.itemObserver.observe(item);
      });
    },
//     async getPosts(type, id) {
//       try {
//         const response = await axios.get(
//         this.baseURL + `/api/wp/v2/${type}`, {
//             params: {
//               include: id,
//             }
//           }
//         )
//         const results = response.data
//         if ( !results || results.length == 0 ) {}
//
//         this.post = results.map(post => ({
//           id: post.id,
//           type: post.type,
//           slug: post.slug,
//           url: post.vueData.url,
//           title: post.title.rendered,
//           excerpt: post.acf.excerpt,
//           media: post.acf.hero.media.landscape ? post.acf.hero.media.landscape : '',
//           featured: post.better_featured_image
//         }))[0]
//       }
//       catch (e) {
//         this.errors.push(e)
//       }
//       return this.doAction()
//     },
//
//     doAction() {
//       this.posts.push(this.post)
//     },
  },

  mounted() {
    if (this.block && this.block.related && this.block.related.objects && this.block.related.objects.length) {

      let waitForThePostsToLoadYouFinickyBastard = setInterval(() => {
        if (this.$refs.articleItems) {
          this.observePosts();
          clearInterval(waitForThePostsToLoadYouFinickyBastard);
        }
      }, 20);

    }
  },
  destroyed() {
    if (this.itemObserver) {
      this.itemObserver.disconnect();
    }
  },
}
</script>
