<template>

  <div class="component-inner">

    <Accordion
    :v-if="block.accordion.accordion_items"
    :data="block.accordion.accordion_items" />

  </div>

</template>

<script>
import Accordion from '@/component/Accordion/Accordion'

export default {
  props: {
    block: Object
  },
  components: {
    Accordion,
  },
}
</script>
